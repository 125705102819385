import { cloneElement, ReactElement, useState } from 'react'
import { Box } from '@mui/material'
import { DataGridProps, GridDensity, GridState, GridValueGetterParams } from '@mui/x-data-grid'
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces'
import { GridDensityTypes } from '@mui/x-data-grid/models/gridDensity'
import { groupBy } from 'lodash'
import { DataGridStyled, saveState } from 'utility/dataGrid'
import { IDataGridColumn } from './DataGrid'
import GroupedGrid from './GroupedGrid'

type ISharedProps = {
  columns: IDataGridColumn[]
  [key: string]: unknown
}

type IGroupedDataGrid = Pick<DataGridProps, 'rows'> & {
  dataGrid: ReactElement
  groupColumn: IDataGridColumn
  initialState?: GridState
  stateKey?: string
  sharedProps: ISharedProps
}

export const GroupedDataGrid = ({
  dataGrid,
  groupColumn,
  rows,
  initialState,
  stateKey,
  sharedProps
}: IGroupedDataGrid) => {
  const rowsGroupedByField = groupBy(rows, (row) => {
    const value = row[groupColumn.field]

    if (!groupColumn.valueGetter) return value

    return groupColumn.valueGetter!({ value, row } as GridValueGetterParams)
  })

  const [density, setDensity] = useState<GridDensity>(
    initialState?.density?.value || GridDensityTypes.Standard
  )
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>()

  const stateProps = {
    density: density,
    columnVisibilityModel: columnVisibilityModel
  }

  return (
    <Box sx={{ height: '100%' }}>
      <GroupedGrid
        field={groupColumn.headerName as string}
        value="All (no grouping)"
        count={rows.length}
      >
        {cloneElement(dataGrid, {
          ...stateProps,
          onColumnVisibilityModelChange: (model: GridColumnVisibilityModel) =>
            setColumnVisibilityModel(model),
          onStateChange: (state: GridState) => {
            stateKey && saveState(stateKey, state)
            setDensity(state.density.value)
          }
        })}
      </GroupedGrid>

      {Object.keys(rowsGroupedByField).map((groupKey) => {
        const groupedRows = rowsGroupedByField[groupKey]
        return (
          <GroupedGrid
            key={groupKey}
            field={groupColumn.headerName as string}
            value={groupColumn.valueFormatter?.({ value: groupKey } as any) || groupKey}
            count={groupedRows.length}
          >
            <DataGridStyled
              {...sharedProps}
              {...stateProps}
              rows={groupedRows}
              rowCount={groupedRows.length}
            />
          </GroupedGrid>
        )
      })}
    </Box>
  )
}
