import { useState, BaseSyntheticEvent } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Box, Divider, List, ListItem, Popover, Typography } from '@mui/material'
import { Ingredient } from 'components/Events'
import { take } from 'lodash'

const MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY = 4

interface IMealIngredientsList {
  ingredients: Ingredient[]
}

export const MealIngredientsList = ({ ingredients }: IMealIngredientsList) => {
  const ingredientsTotal = ingredients?.reduce((total, ingredient) => {
    return total + ingredient.calories
  }, 0)

  const [anchorEl, setAnchorEl] = useState<any>(null)

  function handleClick(event: BaseSyntheticEvent) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const ingredientsFullList = ingredients?.map((ingredient: Ingredient) => (
    <ListItem disablePadding sx={{ justifyContent: 'space-between' }} key={ingredient.id}>
      <Typography variant="caption" fontWeight={400} marginRight={1}>
        {ingredient.description}
        <Typography ml={1} color="text.secondary" component="span" variant="caption">
          {`${ingredient.servingAmount} ${ingredient.servingUnits}`}
        </Typography>
      </Typography>
      <Box sx={{ lineHeight: 'normal' }}>
        <Typography fontWeight="bold" component="span" mr={0.2} variant="caption">
          {Math.round(ingredient.calories)}
        </Typography>
        <Typography fontWeight="bold" color="text.secondary" component="span" variant="caption">
          Cal
        </Typography>
      </Box>
    </ListItem>
  ))

  const moreIngredientsLabel =
    ingredientsFullList.length > MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY
      ? [
          <ListItem
            onClick={handleClick}
            disablePadding
            sx={{ justifyContent: 'center', cursor: 'pointer' }}
            key="more-ingredients-label"
          >
            <Typography variant="caption" fontWeight="bold" marginRight={1}>
              More Ingredients...
            </Typography>
          </ListItem>
        ]
      : []

  const ingredientsShortList = [
    ...take(ingredientsFullList, MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY),
    ...moreIngredientsLabel
  ]

  return (
    <List>
      <ListItem
        disablePadding
        onClick={handleClick}
        sx={{ justifyContent: 'space-between', cursor: 'pointer' }}
      >
        <Typography fontWeight="bold">Ingredients</Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography fontWeight="bold">
            {ingredientsTotal && Math.round(ingredientsTotal)}
          </Typography>
          <Typography fontWeight="bold" component="span" ml={0.6}>
            Cal
          </Typography>
          <FormatListBulletedIcon sx={{ marginLeft: '4px', color: 'orange' }} />
        </Box>
      </ListItem>
      <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
      <Box sx={{ overflowY: 'scroll', height: 120 }}>
        {ingredientsShortList && ingredientsShortList?.length ? (
          ingredientsShortList
        ) : (
          <Typography color="text.secondary" fontWeight={400} variant="caption" fontStyle="italic">
            No ingredients
          </Typography>
        )}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box p={2}>{ingredientsFullList}</Box>
      </Popover>
    </List>
  )
}
