import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Box } from '@mui/material'
import { EditorImage, IconButtonStyled } from 'components/MarkdownEditor/styles'
import { IElement } from 'components/MarkdownEditor/types'
import { Transforms } from 'slate'
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react'

export const ImageElement = ({ attributes, children, element }: IElement) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor as ReactEditor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <Box sx={{ position: 'relative' }} contentEditable={false}>
        <EditorImage src={element.url} active={selected && focused ? 1 : 0} />
        <IconButtonStyled
          size="small"
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          active={selected && focused ? 1 : 0}
        >
          <DeleteOutlinedIcon />
        </IconButtonStyled>
      </Box>
    </div>
  )
}
