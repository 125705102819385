import { buildVars } from 'context/gridVars'
import { IChangeCoachProps } from 'screens/AdminPanel/CoachAssignmentDataGrid/ChangeCoach/ChangeCoach'
import { COACH_ASSIGNMENT_DATA_GRID_STATE_KEY } from 'screens/AdminPanel/CoachAssignmentDataGrid/constants'
import { getCoachAssignmentViewColumns } from 'screens/AdminPanel/CoachAssignmentDataGrid/constants/columns'
import { columns } from 'screens/AdminPanel/MembersDataGrid/constants/columns'
import { MEMBERS_DATA_GRID_STATE_KEY } from 'screens/AdminPanel/MembersDataGrid/constants/constants'

const coachAssignmentViewColumns = getCoachAssignmentViewColumns({} as IChangeCoachProps)
const membersViewColumns = columns

export const coachAssignmentGridVars = buildVars(
  coachAssignmentViewColumns,
  COACH_ASSIGNMENT_DATA_GRID_STATE_KEY
)

export const membersGridVars = buildVars(membersViewColumns, MEMBERS_DATA_GRID_STATE_KEY)
