import { useState } from 'react'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import { Editor, EditorItems, EditorPreview } from 'screens/Knowledge/Editor'
import { LinkedAnswersCell } from 'screens/Knowledge/LinkedAnswersCell'
import { IKnowledgeAnswer } from 'types'
import { SearchAnswers } from './SearchAnswers'
import { useQuestionLinking } from './useQuestionLinking'

interface ILinkedAnswersEditCellProps extends Omit<GridRenderEditCellParams, 'row'> {
  row: IKnowledgeAnswer
}

export const LinkedAnswersEditCell = ({
  row,
  field,
  value,
  error
}: ILinkedAnswersEditCellProps) => {
  const apiRef = useGridApiContext()

  const { linkQuestion, unlinkQuestion, loading } = useQuestionLinking({
    onLinkingUpdated: (answers) => {
      apiRef.current.setEditCellValue({ id: row.id, field, value: answers })
    }
  })

  const [selectedAnswer, setSelectedAnswer] = useState<IKnowledgeAnswer | null>(null)
  const answers: IKnowledgeAnswer[] = value || []
  const selectedAnswerLinked = answers.some((answer) => answer.id === selectedAnswer?.id)

  const handleUpdateLink = () => {
    if (!selectedAnswer) {
      return
    }

    if (selectedAnswerLinked) {
      unlinkQuestion({
        variables: {
          id: row.id,
          knowledgeAnswerId: selectedAnswer.id
        },
        onCompleted: () => setSelectedAnswer(null)
      })
    } else {
      linkQuestion({
        variables: {
          id: row.id,
          knowledgeAnswerId: selectedAnswer.id
        }
      })
    }
  }

  return (
    <>
      <LinkedAnswersCell answers={answers} />
      <Editor
        title="Link an Answer"
        items={answers}
        row={row}
        field={field}
        value={value}
        error={error}
      >
        <EditorItems
          title={row.value}
          items={answers}
          selectedItem={selectedAnswer}
          onSelect={(item: IKnowledgeAnswer) => setSelectedAnswer(item)}
        >
          <SearchAnswers
            selectedAnswer={selectedAnswer}
            linkedAnswers={answers}
            onSelect={setSelectedAnswer}
          />
        </EditorItems>
        <EditorPreview
          item={selectedAnswer}
          value={selectedAnswer?.value}
          linked={selectedAnswerLinked}
          loading={loading}
          onUpdateLink={handleUpdateLink}
        />
      </Editor>
    </>
  )
}
