import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { TextareaAutosize, TextField, Typography } from '@mui/material'
import styled from '@mui/styled-engine'
import { Box } from '@mui/system'
import { theme } from 'theme'
import { spacingOne, spacingTwo } from 'utility/spacings'

export const UserPropertyTextFieldStyled = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    padding: 0
  },
  '& .MuiInputBase-input': {
    padding: 0
  },
  '& .MuiOutlinedInput-root': {
    fontSize: 'inherit'
  },
  '& fieldset': {
    border: 'none'
  }
})

export const UserPropertyTextFieldWrapperStyled = styled(Box)({
  minHeight: 36,
  maxHeight: '33.33vh',
  overflowY: 'scroll',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette['theme-primary']['level-3'],
  borderRadius: '4px',
  paddingLeft: spacingTwo,
  paddingTop: spacingOne,
  paddingBottom: spacingOne,
  paddingRight: spacingTwo,
  fontSize: 13,
  lineHeight: 1.3,
  display: 'block',
  '&:focus-within': {
    borderColor: theme.palette.grey[400]
  },
  p: {
    marginTop: 0,
    marginBottom: 0
  },
  ul: {
    paddingInlineStart: 25,
    marginBlockStart: 4,
    marginBlockEnd: 4
  },
  blockquote: {
    borderLeft: '2px solid',
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[600],
    fontStyle: 'italic',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 10
  },
  code: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '4px',
    padding: '0 5px'
  }
})

export const BackgroundTextFieldStyled = styled(TextareaAutosize)({
  border: 'none',
  backgroundColor: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 400,
  width: '100%',
  resize: 'none',
  '&:focus': {
    outline: 'none'
  }
})

export const SlotRightStyled = styled(Box)({
  display: 'flex'
})

export const QuestionMarkIconStyled = styled(QuestionMarkIcon)({
  fontSize: 12,
  marginRight: 5,
  cursor: 'pointer'
})

export const PreStyled = styled(Typography)({
  backgroundColor: theme.palette.grey[200],
  borderRadius: 4,
  padding: '0 5px',
  marginRight: 5,
  fontFamily: 'monospace'
})
