import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import { Stack } from '@mui/material'
import {
  messageHasReactions,
  MessageStatus,
  MessageText,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext
} from 'stream-chat-react'
import { CopyButton, CustomMessageTextBubble, CustomMessageTextBubbleSources } from './styles'
import { ICustomActions } from './types'

export const customActionDefinitions = {}

export const CustomMessage = () => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    isMyMessage,
    customMessageActions
  } = useMessageContext()

  const canReact = isReactionEnabled
  const hasReactions = messageHasReactions(message)
  const myMessage = isMyMessage()
  const [recentlyCopied, setRecentlyCopied] = useState(false)
  const recentlyCopiedTimer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    return () => {
      clearTimeout(recentlyCopiedTimer.current)
    }
  }, [])

  const handleShowSources = async (event: BaseSyntheticEvent) => {
    if (!customMessageActions) {
      return
    }

    const actions = customMessageActions as ICustomActions
    await actions['Show Sources'](message, event)
  }

  const handleCopy = () => {
    message.text && navigator.clipboard.writeText(message.text)
    setRecentlyCopied(true)
    recentlyCopiedTimer.current = setTimeout(() => setRecentlyCopied(false), 2500)
  }

  return (
    <Stack>
      <Stack>
        {showDetailedReactions && canReact && <ReactionSelector ref={reactionSelectorRef} />}
        <CustomMessageTextBubble myMessage={myMessage}>
          <MessageText />
          {!myMessage && (
            <CustomMessageTextBubbleSources onClick={handleShowSources}>
              <KeyboardTabIcon />
            </CustomMessageTextBubbleSources>
          )}
        </CustomMessageTextBubble>
        <MessageStatus />
        {hasReactions && <SimpleReactionsList />}
      </Stack>
      {!myMessage && (
        <Stack direction="row">
          <CopyButton endIcon={!recentlyCopied && <ContentCopyIcon />} onClick={handleCopy}>
            {recentlyCopied ? 'Copied!' : 'Copy'}
          </CopyButton>
        </Stack>
      )}
    </Stack>
  )
}
