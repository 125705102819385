import { theme } from 'theme'
import { IAllStatsQuery } from 'types'

export const statColor = ({
  range: { warning, danger, reverse } = {},
  value = 0
}: Partial<IAllStatsQuery['allStats']['statistics'][number]>) => {
  if (!value) {
    return theme.palette.grey[400]
  }

  if (!warning || !danger) {
    return
  }

  if (value < warning) {
    return reverse ? theme.palette.success.main : theme.palette.error.light
  }

  if (value >= warning && value <= danger) {
    return theme.palette.warning.light
  }

  return reverse ? theme.palette.success.main : theme.palette.error.light
}
