import { MetricList } from './MetricList'

const SCORE_METRICS = [
  { label: 'Peak', key: 'glucoseScorePeak' },
  { label: 'Exposure', key: 'glucoseScoreAuc' },
  { label: 'Stability', key: 'glucoseScoreDelta' },
  { label: 'Recovery', key: 'glucoseScore2hrDelta' }
]

interface IScoreMetricList {
  statistics: any
}

export const ScoreMetricList = ({ statistics }: IScoreMetricList) => {
  return <MetricList title="Score" data={statistics} metricEntries={SCORE_METRICS} />
}
