import { useReactiveVar } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import { Dialog, DialogTitle } from '@mui/material'
import { Tabs } from 'components'
import { saveEventDialogVar } from 'context'
import { EventTypes } from 'event.types'
import { SaveMeal } from './SaveMeal'
import { SaveNote } from './SaveNote'

const TABS = [
  {
    label: 'Add Note',
    content: <SaveNote />
  },
  {
    label: 'Add Meal',
    content: <SaveMeal />
  }
]

export const SaveEventDialog = () => {
  const { open, event, graphDataPoint } = useReactiveVar(saveEventDialogVar)

  const getContent = () => {
    if (graphDataPoint) {
      return (
        <>
          <DialogTitle>New Event</DialogTitle>
          <Tabs tabs={TABS} />
        </>
      )
    }

    if (event?.__typename === EventTypes.Note || event?.__typename === EventTypes.JournalEntry) {
      return (
        <>
          <DialogTitle>Update a note</DialogTitle>
          <SaveNote />
        </>
      )
    }

    if (event?.__typename === EventTypes.Meal) {
      return (
        <>
          <DialogTitle>Update a meal</DialogTitle>
          <SaveMeal />
        </>
      )
    }

    open && Bugsnag.notify('SaveEventDialog - content is undefined')
    return null
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => saveEventDialogVar({ open: false })}>
      {getContent()}
    </Dialog>
  )
}
