import { Box, IconButton, Link } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const Placeholder = styled(Box)({
  color: 'rgba(0, 0, 0, 0.6)',
  opacity: '1 !important'
})

export const IconButtonStyled = styled(IconButton)((props: { active: number }) => ({
  position: 'absolute' as const,
  top: '0.5em',
  left: '0.5em',
  opacity: props.active ? 1 : 0,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette['theme-primary']['level-1']
  }
}))

export const EditorImage = styled('img')((props: { active: number }) => ({
  display: 'block',
  maxWidth: '100%',
  maxHeight: '20em',
  marginLeft: '3px',
  boxShadow: props.active ? `0 0 0 3px ${theme.palette.info.light}` : 'none'
}))

export const InvisibleSpan = styled('span')({
  fontSize: 0
})

export const LinkStyled = styled(Link)((props: { active: number }) => ({
  boxShadow: props.active ? `0 0 0 3px ${theme.palette.info.light}` : 'none'
}))

export const MentionStyled = styled('span')(() => ({
  padding: '3px 3px 2px',
  margin: '0 2px',
  verticalAlign: 'baseline',
  display: 'inline-block',
  borderRadius: 4,
  backgroundColor: '#bdbdbd'
}))

export const MentionOptionsPopupStyled = styled('div')({
  top: '-9999px',
  left: '-9999px',
  position: 'absolute',
  zIndex: 9999999,
  padding: '3px',
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0 1px 5px rgba(0,0,0,.2)'
})

export const MentionOptionStyled = styled(Box)((props: { selected: number }) => ({
  padding: '1px 3px',
  borderRadius: '3px',
  backgroundColor: props.selected ? theme.palette['theme-primary']['level-4'] : 'transparent'
}))
