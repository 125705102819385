import { useReactiveVar } from '@apollo/client'
import { dateFilterVar, graphDateRangeVar } from 'context'

export const useGraphDateRange = () => {
  let filterStart, filterEnd
  const { startDate: startDateTime, endDate: endDateTime } = useReactiveVar(graphDateRangeVar)
  const { startDate, endDate } = useReactiveVar(dateFilterVar)

  filterStart = startDateTime || startDate
  filterEnd = endDateTime || endDate

  return { startDate: filterStart, endDate: filterEnd }
}
