import { useEffect, useState } from 'react'
import { Event } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import { useClientConfigQuery } from 'types'

export const useIsProcessing = () => {
  const { client } = useChatContext()
  const { data: clientConfigResult } = useClientConfigQuery()
  const responseTimeoutInSeconds =
    clientConfigResult?.clientConfig?.chatbot?.responseTimeoutInSeconds ?? 3

  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    let timeoutHandle: ReturnType<typeof setTimeout> | null = null

    const onNewMessage = (event: Event) => {
      if (event?.message?.user?.id === client?.userID) {
        setIsProcessing(true)

        timeoutHandle = setTimeout(() => {
          setIsProcessing(false)
        }, responseTimeoutInSeconds * 1000)
      } else {
        if (event?.message?.nutrisense_type === 'delimiter') {
          return // Do nothing
        }
        setIsProcessing(false)
        timeoutHandle && clearTimeout(timeoutHandle)
      }
    }

    if (client) {
      client.on('message.new', onNewMessage)
    }

    return () => {
      client?.off('message.new', onNewMessage)
      timeoutHandle && clearTimeout(timeoutHandle)
    }
  }, [client, responseTimeoutInSeconds])

  return isProcessing
}
