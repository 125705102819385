import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import CustomEventTypes from 'hooks/analytics/customEventTypes'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { noop } from 'lodash'
import { useBeforeunload } from 'react-beforeunload'
import { useParams } from 'react-router-dom'
import { SESSION_INACTIVE_RESCUE_TIME } from 'utility/defaults'
import { useCreateDietitianSessionMutation } from 'types'
import { clearStoredSessions, ISession, storedSessions, storeSession } from './sessionsStorage'

interface ITimeTracker {
  isActive?: boolean
  children: ReactNode | ReactNode[]
}

const isValidSession = ({ startedAt, endedAt, userId }: ISession) => {
  return startedAt && endedAt && startedAt < endedAt && (!userId || !isNaN(parseInt(userId)))
}

const defaultValues = {
  endTrackingSession: noop
}

const TimeTrackerContext = createContext(defaultValues)
export const useTimeTracker = () => useContext(TimeTrackerContext)

export const TimeTrackerProvider = ({ isActive: viewIsActive = false, children }: ITimeTracker) => {
  const { track } = useSegmentInteraction()
  const [activeSession, setActiveSession] = useState(false)
  const [userId, setUserId] = useState<string | undefined>(undefined)

  const { id: userIdFromParams } = useParams()

  const [createDietitianSession] = useCreateDietitianSessionMutation({
    onCompleted: (_, clientOptions) => {
      if (!clientOptions?.variables) return
      const { startedAt, endedAt, userId } = clientOptions.variables
      const durationInSeconds = Math.round((endedAt.getTime() - startedAt!.getTime()) / 1000)
      track(CustomEventTypes.NutritionistSession, { userId, durationInSeconds })
    }
  })

  const sessionStartTime = useRef<Date | null>(null)

  const sendSessionEvent = useCallback(
    ({ startedAt, endedAt, userId }: ISession) => {
      if (!isValidSession({ startedAt, endedAt, userId })) return

      createDietitianSession({
        variables: {
          userId: userId,
          startedAt: startedAt,
          endedAt: endedAt
        }
      }).catch(() => {
        storeSession({ startedAt, endedAt, userId })
      })
    },
    [createDietitianSession]
  )

  const startSession = useCallback(() => {
    setActiveSession(true)
    sessionStartTime.current = new Date()
  }, [])

  const endSession = useCallback(
    (withDelay: boolean = true) => {
      if (!activeSession) {
        return
      }

      setActiveSession(false)
      const sessionEndTime = new Date(Date.now() - (withDelay ? SESSION_INACTIVE_RESCUE_TIME : 0))
      sendSessionEvent({ startedAt: sessionStartTime.current, endedAt: sessionEndTime, userId })
    },
    [sendSessionEvent, activeSession, sessionStartTime, userId]
  )

  useEffect(() => {
    if (activeSession && !viewIsActive) endSession()
    if (!activeSession && viewIsActive) startSession()
  }, [activeSession, endSession, startSession, viewIsActive])

  useEffect(() => {
    endSession(false)
    setUserId(userIdFromParams)
    const sessions = storedSessions()
    clearStoredSessions()
    sessions.forEach((session: ISession) => sendSessionEvent(session))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdFromParams, sendSessionEvent])

  useBeforeunload(() => {
    storeSession({ startedAt: sessionStartTime.current, endedAt: new Date(), userId })
  })

  return (
    <TimeTrackerContext.Provider value={{ endTrackingSession: endSession }}>
      {children}
    </TimeTrackerContext.Provider>
  )
}
