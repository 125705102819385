import { Chip, Tooltip } from '@mui/material'
import { DashboardGraph } from 'settings.types'
import { useCurrentUserQuery } from 'types'
import { SelectSetting } from '.'
import { UserSettingsSection } from './UserSettingsSection'

export const KetoneThresholds = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const settings = currentUser?.settings

  const ketoneEnabled = settings?.dashboardGraph === DashboardGraph.Combined

  const ketoneStatusChip = ketoneEnabled ? (
    <Tooltip title="The member has the Ketone chart enabled on the mobile app">
      <Chip color="success" label="enabled" size="small" variant="outlined" />
    </Tooltip>
  ) : (
    <Tooltip title="The member has the Ketone chart disabled on the mobile app">
      <Chip color="default" label="disabled" size="small" variant="outlined" />
    </Tooltip>
  )

  const rows = [
    {
      label: 'Low',
      cells: [
        <SelectSetting
          key="ketoneLowOptions"
          clientConfigKey="ketoneLowOptions"
          settingKey="ketoneLowThreshold"
        />
      ]
    },
    {
      label: 'High',
      cells: [
        <SelectSetting
          key="ketoneHighOptions"
          clientConfigKey="ketoneHighOptions"
          settingKey="ketoneHighThreshold"
        />
      ]
    }
  ]

  return (
    <UserSettingsSection
      header="Ketones Thresholds"
      rows={rows}
      headerSlotRight={ketoneStatusChip}
    />
  )
}
