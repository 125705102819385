import { Alert, Box, Dialog, DialogContent, DialogProps, DialogTitle } from '@mui/material'
import { UserSettingsDialogContent } from './UserSettingsDialogContent'

const DIALOG_TITLE = 'Member App Settings'

const CHANGE_SETTINGS_TOOLTIP_TEXT =
  'Changes are visible in the mobile app only after closing and restarting the app.'
export const UserSettingsDialog = (props: DialogProps) => {
  return (
    <Dialog {...props} fullWidth maxWidth="xl">
      <DialogTitle sx={{ paddingBottom: 1 }}>{DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: 2 }}>
          <Alert severity="warning">{CHANGE_SETTINGS_TOOLTIP_TEXT}</Alert>
        </Box>
        <UserSettingsDialogContent />
      </DialogContent>
    </Dialog>
  )
}
