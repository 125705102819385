import React from 'react'
import imageExtensions from 'image-extensions'
import isHotkey, { isKeyHotkey } from 'is-hotkey'
import isUrl from 'is-url'
import { compact, flatMapDeep } from 'lodash'
import { BaseEditor, Editor, Range, Transforms } from 'slate'
import { IElement, TypedDescendant, UrlElement } from './types'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
  'mod+alt+c': 'code'
}

export const onKeyDownHandler = (editor: BaseEditor) => {
  return (event: React.KeyboardEvent) => {
    const { selection } = editor

    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault()
        const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS]
        toggleMark(editor, mark)
      }
    }
  }
}

export const getMentionedAdminIds = (val: TypedDescendant[]): string[] =>
  compact(
    flatMapDeep(
      val.map((descendant) => {
        if (descendant.type === 'mention') {
          return (descendant as TypedDescendant)?.mentionedUser?.id
        }

        return getMentionedAdminIds((descendant as IElement).children || [])
      })
    )
  )

export const toggleMark = (editor: BaseEditor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isMarkActive = (editor: BaseEditor, format: string) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format as keyof typeof marks] === true : false
}

export const isImageUrl = (url: string) => {
  if (!url) return false
  if (!isUrl(url)) return false
  const extension = new URL(url).pathname.split('.').pop()
  return extension && imageExtensions.includes(extension)
}

export const insertImage = (editor: BaseEditor, url: string) => {
  const text = { text: '' }
  const voidNode: UrlElement = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, voidNode)
}

export const insertLink = (editor: BaseEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  } else {
    const text = { text: url }
    const voidNode: UrlElement = { type: 'attachment', url, children: [text] }
    Transforms.insertNodes(editor, voidNode)
  }
}

export const wrapLink = (editor: BaseEditor, url: string) => {
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: UrlElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}
