import { Tooltip, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { MMM_DD, MMM_DD_HH_MM_A } from 'utility/timeFormats'
import { IChatConversationEvent } from 'types'

export const CreatedAtDate = ({ event }: { event: IChatConversationEvent }) => {
  const createdAtIso = parseISO(event.occurredAt)

  return (
    <Tooltip title={format(createdAtIso, MMM_DD_HH_MM_A)}>
      <Typography color="text.secondary" variant="caption">
        {format(createdAtIso, MMM_DD)}
      </Typography>
    </Tooltip>
  )
}
