import { DynamicFilterItemOperation } from 'types'

export const OPERATOR_TO_PREDICATE_MAP = {
  contains: DynamicFilterItemOperation.ICont,
  containsAnyOf: DynamicFilterItemOperation.ContAny,
  equals: DynamicFilterItemOperation.Eq,
  startsWith: DynamicFilterItemOperation.Start,
  endsWith: DynamicFilterItemOperation.End,
  isEmpty: DynamicFilterItemOperation.IsNull,
  isNotEmpty: DynamicFilterItemOperation.NotNull,
  isAnyOf: DynamicFilterItemOperation.In,
  is: DynamicFilterItemOperation.Eq,
  not: DynamicFilterItemOperation.NotEq,
  after: DynamicFilterItemOperation.Gt,
  onOrAfter: DynamicFilterItemOperation.Gteq,
  before: DynamicFilterItemOperation.Lt,
  onOrBefore: DynamicFilterItemOperation.Lteq,
  '=': DynamicFilterItemOperation.Eq,
  '!=': DynamicFilterItemOperation.NotEq,
  '>': DynamicFilterItemOperation.Gt,
  '>=': DynamicFilterItemOperation.Gteq,
  '<': DynamicFilterItemOperation.Lt,
  '<=': DynamicFilterItemOperation.Lteq
}
