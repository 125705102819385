import { Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette['theme-primary']['level-1'],
    maxWidth: 'none',
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette['theme-primary']['level-3']
  }
})

export const SectionTitle = styled(Typography)({
  fontSize: 20,
  marginLeft: 16,
  fontWeight: 700
})
