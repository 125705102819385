import { groupBy } from 'lodash'
import { StatKey } from 'stats.types'
import { StatList } from '.'
import { Stat } from './types'

interface IDrawerContentProps {
  stats: Stat[]
}

export const StatsDrawerContent = ({ stats }: IDrawerContentProps) => {
  const groupedStats = groupBy(stats, (stat: Stat) => stat.meta.section)
  const orderedStatSections = [StatKey.Score, StatKey.Glucose, StatKey.Macros, StatKey.Ketones]

  return (
    <>
      {orderedStatSections.map((statSection) => (
        <StatList
          statSection={statSection}
          statList={groupedStats[statSection]}
          key={statSection}
        />
      ))}
    </>
  )
}
