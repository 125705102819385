import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Box, Chip, Typography } from '@mui/material'
import { EventsType } from 'components/Events'
import { EventCardTitleStyled, TooltipStyled } from 'components/Events/EventCard/styles'
import { useTimeZone } from 'context'
import { formatDuration, hoursToMinutes, secondsToHours, secondsToMinutes } from 'date-fns'
import { ActivityEventTypes, EventTypes } from 'event.types'
import { theme } from 'theme'
import { MMM_DD_HH_MM_A, TWELVE_HOURS_TIME_FORMAT } from 'utility/timeFormats'

interface IEventTitle {
  title: string
  description: string | null
  event: EventsType
}
export const EventTitle = ({ title, description, event }: IEventTitle) => {
  const { formatWithTimeZone } = useTimeZone()
  const occurredAt = formatWithTimeZone(new Date(event.occurredAt), MMM_DD_HH_MM_A)

  let endedAt = null
  let startedAt = null
  let durationDescription = null
  const isCoachNote =
    (event.__typename === 'Note' || event.__typename === 'JournalEntry') &&
    'createdBy' in event &&
    event.createdBy === 'Coach'

  if (event.__typename === EventTypes.Activity && event.type === ActivityEventTypes.Exercise) {
    const startDate = new Date(event.startedAt)
    const endDate = new Date(event.endedAt)

    const durationSeconds = event.length

    const durationHours = secondsToHours(durationSeconds)
    const durationMinutes = secondsToMinutes(durationSeconds) - hoursToMinutes(durationHours)

    endedAt = formatWithTimeZone(endDate, TWELVE_HOURS_TIME_FORMAT)
    startedAt = formatWithTimeZone(startDate, MMM_DD_HH_MM_A)

    const duration = formatDuration(
      { hours: durationHours, minutes: durationMinutes },
      { delimiter: ', ' }
    )
    durationDescription = `. Duration: ${duration}`
  }

  return (
    <>
      <Box display="flex">
        <Typography fontWeight="bold" noWrap>
          {title}
        </Typography>
        <Typography marginLeft={0.5} marginRight={0.5} color="text.secondary">
          •
        </Typography>
        <Typography noWrap color="text.secondary">
          {!startedAt && occurredAt} {startedAt && `${startedAt}`}
        </Typography>
        {endedAt && (
          <>
            <ArrowRightIcon />
            <Typography noWrap color="text.secondary">
              {endedAt}
            </Typography>
          </>
        )}
        {isCoachNote && (
          <Chip
            size="small"
            sx={{ marginLeft: 1, backgroundColor: theme.palette['brand-primary'].light }}
            label="Nutritionist"
          />
        )}
      </Box>
      <Box>
        {description ? (
          <TooltipStyled placement="bottom-start" title={description}>
            <EventCardTitleStyled fontStyle="italic" color="text.secondary">
              {description}
              {durationDescription}
            </EventCardTitleStyled>
          </TooltipStyled>
        ) : (
          <Typography fontStyle="italic" color="text.secondary">
            No description
          </Typography>
        )}
      </Box>
    </>
  )
}
