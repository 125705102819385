import { gql } from '@apollo/client'

export const CoreAppointmentFields = gql`
  fragment CoreAppointmentFields on HealthPortalAppointment {
    id
    meetingAt
    meetingStatus
    actualDuration
    title
    appointmentType
    chargeType
    externalVideochatUrl
    startVideochatUrl
    agenda
    user {
      id
      fullName
      timeZone
    }
    insurancePolicy {
      unitsLeft
      unitsUsed
      unitsAuthorized
      lastState {
        kind
        reason
      }
    }
  }
`
