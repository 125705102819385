import { ReactNode } from 'react'
import { useAuthorizedFeature } from 'hooks'
import { HealthPortalFeature } from 'types'

interface IAuthorizedFeatureProps {
  feature: HealthPortalFeature
  children: ReactNode
}

export const AuthorizedFeature = ({ children, feature }: IAuthorizedFeatureProps) => {
  const featureAuthorized = useAuthorizedFeature(feature)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return featureAuthorized ? <>{children}</> : null
}
