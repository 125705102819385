import { useCallback, useEffect, useState } from 'react'
import { useSegment } from './useSegment'

export const useSegmentPage = () => {
  const { analytics } = useSegment()
  const [currentPage, setCurrentPage] = useState('')

  const trackPage = useCallback(
    (nextPage: string) => {
      if (currentPage !== nextPage) {
        setCurrentPage(nextPage)
      }
    },
    [currentPage]
  )

  useEffect(() => {
    analytics?.page(currentPage)
  }, [analytics, currentPage])

  return { trackPage }
}
