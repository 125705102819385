import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { ISO_DATE_FORMAT } from 'utility/timeFormats'

export interface IDropdownOptionProps {
  charge: {
    id: string
    amount: number
    created: string
  }
}

export const ChargeOption = ({ charge }: IDropdownOptionProps) => {
  return (
    <Box>
      <Typography variant="body1">Charge ID: {charge.id}</Typography>
      <Typography variant="caption">Amount: ${charge.amount} - </Typography>
      <Typography variant="caption">
        Created At: {format(new Date(charge.created), ISO_DATE_FORMAT)}
      </Typography>
    </Box>
  )
}
