import { useEffect, useMemo } from 'react'
import { privacyModeActiveVar } from 'context/ui/privacyModeContext'
import { debounce } from 'lodash'

export const PRIVACY_MODE_LOCAL_STORAGE_KEY = 'nutrisense:privacyMode'
export const PRESENTATION_WINDOW_COUNT_STORAGE_KEY = 'nutrisense:presentationWindowCount'
export const CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY = 'nutrisense:clearPrivacyMode'

const privacyModeClearDelay = 5000

export const usePrivacyMode = () => {
  const debouncedPrivacyModeClear = useMemo(() => {
    return debounce(() => {
      if (localStorage.getItem(CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY) === 'true') {
        localStorage.setItem(PRIVACY_MODE_LOCAL_STORAGE_KEY, 'false')
        localStorage.setItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY, '0')
        privacyModeActiveVar(false)
      }
    }, privacyModeClearDelay)
  }, [])

  useEffect(() => {
    privacyModeActiveVar(localStorage.getItem(PRIVACY_MODE_LOCAL_STORAGE_KEY) === 'true')
  }, [])

  useEffect(() => {
    if (localStorage.getItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY) === null) {
      localStorage.setItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY, '0')
    }
  }, [])

  useEffect(() => {
    const storageListener = (event: StorageEvent) => {
      const privacyMode = event.newValue === 'true'
      if (event.key === PRIVACY_MODE_LOCAL_STORAGE_KEY) {
        if (privacyMode) {
          localStorage.setItem(PRIVACY_MODE_LOCAL_STORAGE_KEY, 'true')
          privacyModeActiveVar(true)
        }
      }

      if (event.key === CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY) {
        debouncedPrivacyModeClear()
      }
    }

    window.addEventListener('storage', storageListener)

    return () => {
      window.removeEventListener('storage', storageListener)
    }
  }, [debouncedPrivacyModeClear])
}
