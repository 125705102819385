import { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { format } from 'date-fns'
import { useAuthorizedFeature } from 'hooks'
import { compact } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { YYYY_MM_DD } from 'utility/timeFormats'
import { getMinFutureDate } from 'utility/timeUtils'
import {
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation
} from 'types'

interface IUnpauseSubscriptionForm {
  subscriptionId: string
  unpauseAtFutureDate: boolean
  resumeDate: Date | null
  priority: OpsAccountRequestPriority
  note: string
}

export const UnpauseSubscriptionModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const [unpauseAtFutureDate, setUnpauseAtFutureDate] = useState(false)
  const { handleSubmit, control, register, reset, formState } = useForm<IUnpauseSubscriptionForm>({
    mode: 'onTouched',
    defaultValues: {
      subscriptionId: '',
      unpauseAtFutureDate: false,
      resumeDate: null,
      priority: OpsAccountRequestPriority.Regular,
      note: ''
    }
  })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessUnpauseSubscription
  )

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const subscriptions = compact(
    [currentUser?.ongoingCoreSubscription, currentUser?.ongoingDietitianSubscription].filter(
      (subscription) => subscription?.paused
    )
  )

  if (!currentUser || subscriptions.length === 0) {
    return null
  }

  const onClose = () => {
    reset()
    closeModal()
  }

  const unpauseSubscriptionHandler = async (formData: IUnpauseSubscriptionForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.UnpauseSubscription,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.UnpauseSubscription,
          unpauseSubscription: {
            subscriptionId: formData.subscriptionId,
            resumeDate:
              unpauseAtFutureDate && formData.resumeDate
                ? format(formData.resumeDate, YYYY_MM_DD)
                : null
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const innerForm = (
    <>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Select Subscription</InputLabel>
        <Controller
          name="subscriptionId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('subscriptionId', { required: true })}
              error={!!formState.errors.subscriptionId}
              label="Select Subscription"
            >
              <MenuItem value="" disabled>
                <em>Select Subscription</em>
              </MenuItem>
              {subscriptions.map((subscription) => (
                <MenuItem key={subscription.id} value={subscription.id}>
                  {subscription.primaryProduct.title}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Unpause at a future date ?</InputLabel>
        <Controller
          name="unpauseAtFutureDate"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('unpauseAtFutureDate')}
              error={!!formState.errors.unpauseAtFutureDate}
              label="Unpause at a future date?"
            >
              <MenuItem value="true" onClick={() => setUnpauseAtFutureDate(true)}>
                Yes
              </MenuItem>
              <MenuItem value="false" onClick={() => setUnpauseAtFutureDate(false)}>
                No
              </MenuItem>
            </Select>
          )}
        />
      </FormControl>
      {unpauseAtFutureDate && (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <Controller
            control={control}
            name="resumeDate"
            rules={{ required: unpauseAtFutureDate }}
            render={({ field: { onChange, value } }) => {
              return (
                <DesktopDatePicker
                  disablePast
                  minDate={getMinFutureDate()}
                  label="Resume Subscription Date"
                  value={value}
                  onChange={onChange}
                  slotProps={{
                    field: {
                      readOnly: true
                    }
                  }}
                />
              )
            }}
          />
        </FormControl>
      )}
    </>
  )
  return (
    <AccountRequestModal<IUnpauseSubscriptionForm>
      register={register}
      modalTitle="Resume Subscription"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(unpauseSubscriptionHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
