import { Paper } from '@mui/material'
import styled from '@mui/styled-engine'

import { spacingOne, spacingHalf } from 'utility/spacings'

export const TabsStyled = styled(Paper)({
  height: '100%',
  paddingRight: spacingOne,
  paddingLeft: spacingOne,
  paddingBottom: spacingHalf,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
})
