import { useEffect, useState } from 'react'
import { env } from 'config'
import { Channel, DefaultGenerics, StreamChat } from 'stream-chat'
import { useCreateChatTokenMutation } from 'types'

export type StreamUser = { id: string; fullName: string } | null | undefined

export const useStreamChatClient = ({
  userId,
  userName,
  userAvatar,
  shouldConnect = true
}: {
  userId?: string
  userName?: string
  userAvatar?: string | null
  shouldConnect?: boolean
}) => {
  const [client, setClient] = useState<StreamChat | null>(null)

  const [createChatTokenMutation] = useCreateChatTokenMutation()

  useEffect(() => {
    if (!userId || !shouldConnect) {
      return
    }

    const newClient = new StreamChat(env.REACT_APP_STREAM_CHAT_API_KEY)
    let interrupted = false

    const connectionPromise = newClient
      .connectUser(
        {
          id: userId,
          name: userName,
          image: userAvatar
        },
        async () => {
          const response = await createChatTokenMutation({ variables: { userId } })
          return response.data?.createChatToken?.value || 'invalid'
        }
      )
      .catch((error) => {
        throw error
      })
      .then(() => {
        if (!interrupted) {
          setClient(newClient)
        }
      })

    return () => {
      interrupted = true
      setClient(null)
      connectionPromise.then(() => newClient.disconnectUser())
    }
  }, [createChatTokenMutation, shouldConnect, userAvatar, userId, userName])

  return client
}

export const useStreamChatChannel = ({
  streamChatClient,
  channelType,
  channelName
}: {
  streamChatClient: StreamChat<DefaultGenerics> | null
  channelType: string
  channelName?: string
}) => {
  const [channel, setChannel] = useState<Channel | null>(null)

  useEffect(() => {
    if (!streamChatClient || !streamChatClient.userID) {
      return
    }

    setChannel(
      streamChatClient.channel(channelType, 'channel-id-' + streamChatClient.userID, {
        name: channelName,
        members: [streamChatClient.userID]
      })
    )

    return () => {
      setChannel(null)
    }
  }, [channelName, channelType, streamChatClient])

  return channel
}
