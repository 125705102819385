import { Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useAuthorizedFeature } from 'hooks'
import { LocationPaths } from 'location.types'
import { startCase } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { CancelMessageIcon } from 'screens/Chat/CancelMessageIcon'
import { UserResource } from 'userResource.types'
import { TWELVE_HOURS_TIME_FORMAT } from 'utility/timeFormats'
import { HealthPortalFeature, ICoachConversationScheduledMessage } from 'types'
import { ClickableText } from './styles'

export const ScheduledMessage = ({ message }: { message: ICoachConversationScheduledMessage }) => {
  const navigate = useNavigate()
  const [chatAuthorized, chatManagementAuthorized] = useAuthorizedFeature([
    HealthPortalFeature.Chat,
    HealthPortalFeature.ChatManagement
  ])

  const navigateToChat = () => {
    navigate(`${LocationPaths.Users}/${message.coachAssignment.user.id}/${UserResource.Chat}`)
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="overline" color="text.secondary">
          {format(new Date(message.sendAt), TWELVE_HOURS_TIME_FORMAT)}
        </Typography>
        {chatAuthorized ? (
          <ClickableText variant="subtitle2" onClick={navigateToChat}>
            {message.coachAssignment.user.fullName}
          </ClickableText>
        ) : (
          <Typography variant="subtitle2">{message.coachAssignment.user.fullName}</Typography>
        )}
        <Typography variant="h6">·</Typography>
        <Typography variant="caption">{startCase(message.kind)}</Typography>
      </Stack>
      {chatManagementAuthorized && <CancelMessageIcon message={message} />}
    </Stack>
  )
}
