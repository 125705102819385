import { gql } from '@apollo/client'

export const AllUserProperties = gql`
  query allUserProperties {
    allUserProperties {
      userProperties {
        id
        kind
        value
        userId
      }
    }
  }
`
