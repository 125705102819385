import { List, ListItemButton, ListItemText } from '@mui/material'
import { EditorItem } from './types'

interface ILinkedItemsProps<T> {
  items: T[]
  selectedItem: T | null
  onSelect: (knowledge: T) => void
}

export function LinkedItems<T extends EditorItem>({
  items,
  selectedItem,
  onSelect
}: ILinkedItemsProps<T>) {
  if (items.length === 0) {
    return null
  }
  return (
    <List component="nav" sx={{ flex: 1 }}>
      {items.map((item) => (
        <ListItemButton
          key={item.id}
          selected={item.id === selectedItem?.id}
          onClick={() => onSelect(item)}
        >
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))}
    </List>
  )
}
