import VisibilityIcon from '@mui/icons-material/Visibility'
import { Button } from '@mui/material'
import { GridRowClassNameParams, GridRowParams } from '@mui/x-data-grid'
import { DataGrid as ServerDataGrid } from 'components'
import { GridVars } from 'context'
import { LocationPaths } from 'location.types'
import { startCase } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { IAccountRequestGridDynamicFilter } from 'screens/AccountRequests/hooks/useAccountRequestsTabs'
import { theme } from 'theme'
import { UserResource } from 'userResource.types'
import { getStripeRowsClassName } from 'utility/dataGrid'
import { useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import { getCommonListQueryVariablesWithDefaults } from 'utility/dataGrid/getCommonListQueryVariablesWithDefaults'
import {
  IAccountRequestPayloadUnionPresenterType,
  OpsAccountRequestKind,
  useAllAccountRequestsQuery
} from 'types'
import { columns } from './constants'

export interface IDataGridProps {
  actionButtons?: (
    accountRequestId: string,
    accountRequestKind: OpsAccountRequestKind,
    accountRequestPayload: IAccountRequestPayloadUnionPresenterType
  ) => JSX.Element[]
  gridVars: GridVars
  stateKey: string
  defaultFilter: IAccountRequestGridDynamicFilter
  columnsMap: string[]
}

export const DataGrid = ({
  gridVars,
  actionButtons = () => [],
  stateKey,
  defaultFilter,
  columnsMap
}: IDataGridProps) => {
  const reactiveVars = useReactiveGridVars(gridVars)
  const navigate = useNavigate()

  const { data, loading, error, refetch } = useAllAccountRequestsQuery({
    variables: {
      ...getCommonListQueryVariablesWithDefaults(reactiveVars, [defaultFilter])
    }
  })

  const rowCount = data?.allAccountRequests.totalCount

  const rows = data?.allAccountRequests.accountRequests || []

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 400,
    type: 'actions',
    getActions: (params: GridRowParams) => [
      ...actionButtons(params.row.id, params.row.kind, params.row.payload),
      <Button
        key={`view-billing-dashboard-${params.row.id}`}
        variant="outlined"
        sx={{ color: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
        onClick={() =>
          navigate(`${LocationPaths.Users}/${params.row.user.id}/${UserResource.BillingDashboard}`)
        }
      >
        <VisibilityIcon />
      </Button>
    ]
  }

  const columnsWithActions = [...columns, actions]
  const filteredActions = columnsWithActions.filter((column) => columnsMap.includes(column.field))

  const getRowClassName = (params: GridRowClassNameParams) => {
    const defaultRowClassName = getStripeRowsClassName(params)

    if (params.row.kind === OpsAccountRequestKind.MiscRequest) {
      return `accent${startCase(defaultRowClassName)}`
    }

    return defaultRowClassName
  }

  return (
    <ServerDataGrid
      rowHeight="auto"
      loading={loading}
      rows={rows}
      rowCount={rowCount}
      columns={filteredActions}
      getRowClassName={getRowClassName}
      error={error}
      gridVars={gridVars}
      refresh={refetch}
      stateKey={stateKey}
    />
  )
}
