import {
  Editor,
  Element as SlateElement,
  Point,
  Range,
  Transforms,
  TextUnit,
  BaseElement
} from 'slate'
import { ShortcutsEditor } from './types'

const LIST_ITEM = 'list-item'

const newBulletedList = () => ({
  type: 'bulleted-list',
  children: []
})

const insertBulletedText = (editor: ShortcutsEditor, insertText: (text: string) => void) => {
  return (text: string) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n)
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range) + text.slice(0, -1)

      if (beforeText === '') {
        Transforms.select(editor, range)

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor)
        }

        const newProperties = { type: LIST_ITEM }
        Transforms.setNodes<SlateElement>(editor, newProperties as Partial<BaseElement>, {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n)
        })

        if (path.length === 1) {
          Transforms.wrapNodes(editor, newBulletedList(), {
            match: (n) =>
              !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === LIST_ITEM
          })
        }
      }
    }

    insertText(text)
  }
}

const customDeleteBackward = (
  editor: ShortcutsEditor,
  deleteBackward: (unit: TextUnit) => void
) => {
  return (...args: any) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n)
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)
        const end = Editor.end(editor, path)

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          (block as any).type === LIST_ITEM &&
          Point.equals(selection.anchor, start) &&
          Point.equals(selection.anchor, end) &&
          path.every((index) => index === 0)
        ) {
          const newProperties = {
            type: 'paragraph'
          }
          Transforms.setNodes(editor, newProperties as Partial<BaseElement>)

          return
        }
      }

      // @ts-ignore
      deleteBackward(...args)
    }
  }
}

export const withBulletedListLayout = (editor: ShortcutsEditor) => {
  const { deleteBackward, insertText } = editor

  editor.insertText = insertBulletedText(editor, insertText)
  editor.deleteBackward = customDeleteBackward(editor, deleteBackward)

  return editor
}
