import { Transforms } from 'slate'
import { deserializeFromHtml, replaceNewLineFollowedByNoBreakJoiner } from './io'
import { ShortcutsEditor } from './types'

export const withHtml = (editor: ShortcutsEditor) => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = (element: any) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const html = data.getData('text/html')

    if (html) {
      const filteredHtml = replaceNewLineFollowedByNoBreakJoiner(html)
      const parsed = new DOMParser().parseFromString(filteredHtml, 'text/html')
      const fragment = deserializeFromHtml(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }

    insertData(data)
  }

  return editor
}
