import { Box, Chip, styled } from '@mui/material'
import { LineClampedTypography } from 'components'
import { theme } from 'theme'

export const TitleCellTypography = styled(LineClampedTypography)({
  fontSize: '13px',
  fontWeight: 500,
  textDecoration: 'underline',
  textDecorationColor: '#D1D1D1'
})

export const LinkedItemsCellBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',
  width: '100%'
})

export const TitleCellChip = styled(Chip)({
  flexGrow: 0,
  flexShrink: 1,
  flexBasis: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  margin: '2px 0',
  padding: 0,
  height: 'auto',
  lineHeight: '18px',
  border: 'none',

  '& .MuiChip-icon': {
    margin: '3px 4px 0 0',
    fontSize: '13px',
    color: '#9B9B9B'
  },

  '& .MuiChip-label': {
    margin: 0,
    padding: 0
  }
})

export const LinkedSourceChip = styled(Chip)({
  flexGrow: 0,
  flexShrink: 1,
  flexBasis: '30%',
  alignItems: 'flex-start',
  lineHeight: '18px',
  height: 'auto',

  margin: '4px 8px 4px 0',
  padding: '2px 4px',
  backgroundColor: 'white',
  borderRadius: '11px',
  borderColor: theme.palette['theme-primary']['level-4'],

  '& .MuiChip-icon': {
    marginTop: '3px',
    fontSize: '13px',
    transform: 'rotate(-45deg)'
  }
})
