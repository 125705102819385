import { useAuthorizedFeature } from 'hooks'
import { useCurrentAdminQuery, HealthPortalFeature, UserRole, useCurrentUserQuery } from 'types'

export const useCanEditCoachProfile = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()
  const isAuthorizedToEditCoachProfile = useAuthorizedFeature(
    HealthPortalFeature.UsersEditCoachProfile
  )

  const currentUserIsCoach =
    currentUser && [UserRole.Nutritionist, UserRole.NutritionistManager].includes(currentUser.role)

  const currentAdminIsCoach =
    currentAdmin &&
    [UserRole.Nutritionist, UserRole.NutritionistManager].includes(currentAdmin.role)

  const isAdminImpersonating = currentAdmin?.id !== currentUser?.id

  const isAdminNutritionistManager =
    currentAdmin?.role === UserRole.NutritionistManager ? true : !isAdminImpersonating

  return (
    currentUserIsCoach &&
    currentAdminIsCoach &&
    isAuthorizedToEditCoachProfile &&
    isAdminNutritionistManager
  )
}
