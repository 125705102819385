import { ReactNode, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import { CollapseButton } from 'components/CollapseButton'

interface IGroupedGridProps {
  children: ReactNode | ReactNode[]
  field: string
  value: string
  count?: number
}

const GroupedGrid = (props: IGroupedGridProps) => {
  const { children, field, value, count } = props

  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => setExpanded(!expanded)

  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" mb={2}>
            <Stack mr={1}>
              <Typography color="text.primary" variant="caption">
                {field}
              </Typography>
              <Chip label={value} />
            </Stack>
            {count && (
              <>
                <Typography mr="4px" color="text.primary" variant="caption">
                  Count:
                </Typography>
                <Typography color="text.primary" variant="subtitle2">
                  {count}
                </Typography>
              </>
            )}
          </Stack>
        }
        action={<CollapseButton expanded={expanded} handleExpandClick={handleExpandClick} />}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ marginLeft: 2, marginRight: 2 }} />
        <CardContent>
          <Stack height={600}>{children}</Stack>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default GroupedGrid
