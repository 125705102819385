import { getGridSingleSelectOperators } from '@mui/x-data-grid'
import { CoachFilter } from 'screens/AdminPanel/CoachAssignmentDataGrid/CoachFilter'

export const coachFilterOperators = () =>
  getGridSingleSelectOperators()
    .filter((operator) => operator.value === 'is')
    .map((operator) => ({
      ...operator,
      InputComponent: CoachFilter
    }))
