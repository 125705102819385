import { theme } from 'theme'
import { IEventStatus } from './types'

export const EventStatuses: { [key: string]: IEventStatus } = {
  confirmed: {
    value: 'Confirmed',
    backgroundColor: theme.palette['brand-primary']['main'],
    borderColor: theme.palette['brand-primary']['main'],
    active: true
  },
  pendingConfirmed: {
    value: 'Pending Confirmed',
    backgroundColor: theme.palette.warning.light,
    borderColor: theme.palette.warning.light,
    active: true
  },
  cancelled: {
    value: 'Cancelled',
    backgroundColor: theme.palette['theme-primary']['level-1'],
    borderColor: theme.palette.danger.main,
    active: false
  },
  rescheduled: {
    value: 'Rescheduled',
    backgroundColor: theme.palette['theme-primary']['level-1'],
    borderColor: theme.palette.danger.main,
    active: false
  }
}
