import { createContext, useContext, useMemo } from 'react'
import { format, timezoneTimeToUtc } from 'utility/timeZone'
import { useCurrentUserQuery } from 'types'

const DEFAULT_TIMEZONE = 'America/Los_Angeles'

const defaultValues = {
  timeZone: DEFAULT_TIMEZONE,
  formatWithTimeZone: format(DEFAULT_TIMEZONE),
  timezoneTimeToUtc: timezoneTimeToUtc(DEFAULT_TIMEZONE)
}

const TimeZoneContext = createContext(defaultValues)
export const useTimeZone = () => useContext(TimeZoneContext)

export const TimeZoneProvider = ({ children }: { children: JSX.Element }) => {
  const { data } = useCurrentUserQuery()

  const value = useMemo(() => {
    const timeZone = data?.currentUser?.timeZone || defaultValues.timeZone
    return {
      timeZone,
      formatWithTimeZone: format(timeZone),
      timezoneTimeToUtc: timezoneTimeToUtc(timeZone)
    }
  }, [data?.currentUser?.timeZone])

  return <TimeZoneContext.Provider value={value}>{children}</TimeZoneContext.Provider>
}
