import { useRef } from 'react'
import { LinkableItemAutocomplete } from 'screens/Knowledge/Editor'
import {
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  IKnowledgeAnswer,
  KnowledgeAnswerDynamicFilterField,
  useAllKnowledgeAnswersLazyQuery
} from 'types'

interface ISearchAnswersProps {
  selectedAnswer: IKnowledgeAnswer | null
  linkedAnswers: IKnowledgeAnswer[]
  onSelect: (answer: IKnowledgeAnswer) => void
}

export const SearchAnswers = ({ selectedAnswer, linkedAnswers, onSelect }: ISearchAnswersProps) => {
  const searchValue = useRef('')

  const [searchAnswers, { data, loading }] = useAllKnowledgeAnswersLazyQuery({
    variables: {
      dynamicFilters: {
        items: [
          {
            field: KnowledgeAnswerDynamicFilterField.Title,
            operation: DynamicFilterItemOperation.ICont,
            value: searchValue.current
          },
          {
            field: KnowledgeAnswerDynamicFilterField.Value,
            operation: DynamicFilterItemOperation.ICont,
            value: searchValue.current
          }
        ],
        operator: DynamicFilterOperator.Or
      }
    }
  })

  const options = (data?.allKnowledgeAnswers.knowledgeAnswers ?? []) as IKnowledgeAnswer[]

  return (
    <LinkableItemAutocomplete
      selectedItem={selectedAnswer}
      linkedItems={linkedAnswers}
      options={options}
      search={searchAnswers}
      searchValue={searchValue}
      loading={loading}
      onSelect={onSelect}
    />
  )
}
