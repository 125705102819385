import { Avatar, IconButton } from '@mui/material'
import { useMenu } from 'hooks'
import { nameInitials } from 'utility/nameInitials'
import { useCurrentUserQuery } from 'types'
import { UserMenu } from './UserMenu'

export const UserProfile = () => {
  const { menuAnchorEl, menuState, openMenu, closeMenu } = useMenu()

  const { data } = useCurrentUserQuery()
  const user = data?.currentUser
  const avatar = user?.avatar || ''

  return (
    <>
      <IconButton onClick={openMenu} size="small" sx={{ marginLeft: 1 }}>
        <Avatar
          src={avatar}
          alt={user?.fullName}
          sx={{ width: 32, height: 32, cursor: 'pointer', fontSize: '1rem' }}
        >
          {nameInitials(user?.fullName)}
        </Avatar>
      </IconButton>

      <UserMenu anchorEl={menuAnchorEl} state={menuState} close={closeMenu} />
    </>
  )
}
