import { useEffect } from 'react'
import { useBugsnagMember } from 'hooks/analytics/bugsnag/useBugsnagMember'
import { IUserMin, useCurrentAdminQuery } from 'types'

export const useBugsnagAdmin = () => {
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()

  const { setMember } = useBugsnagMember()

  useEffect(() => {
    if (currentAdmin) {
      const user = {
        email: currentAdmin.email,
        fullName: `${currentAdmin.firstName} ${currentAdmin.lastName}`,
        id: currentAdmin.id
      } as IUserMin
      setMember(user)
    }
  }, [currentAdmin, setMember])
}
