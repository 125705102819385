import { FC } from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { EventsType } from 'components/Events'
import { EventTypes } from 'event.types'
import { camelCase, isEmpty } from 'lodash'
import { theme } from 'theme'
import { IJournalEntry, INote } from 'types'
import {
  EventAvatar,
  EventCardHeader,
  EventTitle,
  MealIngredientsList,
  ScoreMetricList,
  GlucoseMetricList
} from '.'
import { getEventData } from './helpers/getEventData'
import { JournalEntryToolbar } from './JournalEntryToolbar'
import { MealToolbar } from './MealToolbar'
import { NoteToolbar } from './NoteToolbar'
import { BaseNutrition } from './Nutrition/BaseNutrition'
import { WellnessMeasurementList } from './WellnessMeasurementList'

export const EventCard: FC<EventsType> = (event) => {
  const { title, __typename } = event

  const mealPhotoUrl = 'avatar' in event ? event.avatar : null
  const nutrition = 'nutrition' in event ? event.nutrition : null

  const parsedNutrition = nutrition?.reduce((acc, entry) => {
    acc[camelCase(entry.key)] = entry.value
    return acc
  }, {} as { [key: string]: number })
  const { description, ingredients, statistics, measurements } = getEventData(event)

  const eventIngredients = __typename === 'Meal' && (
    <Grid item xs={4}>
      <MealIngredientsList ingredients={ingredients || []} />
    </Grid>
  )

  const metricsLists = statistics && (
    <>
      <Grid item xs={3}>
        <GlucoseMetricList event={event} statistics={statistics} />
      </Grid>
      <Grid item xs={2}>
        <ScoreMetricList statistics={statistics} />
      </Grid>
    </>
  )

  const mealNutrition = !isEmpty(parsedNutrition) && (
    <Grid item xs={3}>
      <BaseNutrition nutrition={parsedNutrition} />
    </Grid>
  )

  const noteEvent = event as INote
  const noteToolbar = __typename === 'Note' && noteEvent.createdBy === 'Coach' && (
    <NoteToolbar event={noteEvent} />
  )

  const mealToolbar = event.__typename === EventTypes.Meal && <MealToolbar event={event} />

  const journalEntryToolbar = __typename === 'JournalEntry' && (
    <JournalEntryToolbar event={event as unknown as IJournalEntry} />
  )

  const journalEntryMeasurements = measurements && (
    <Grid item xs={3}>
      <WellnessMeasurementList measurements={measurements} />
    </Grid>
  )

  const rightAdornment = noteToolbar || mealToolbar || journalEntryToolbar

  return (
    <Card
      sx={{
        margin: 0.5,
        width: '100%',
        backgroundColor: theme.palette.grey[100]
      }}
    >
      <EventCardHeader
        eventTitle={<EventTitle title={title} event={event} description={description} />}
        eventAvatar={<EventAvatar event={event} mealPhotoUrl={mealPhotoUrl} />}
        action={rightAdornment}
      />
      {eventIngredients || statistics || measurements ? (
        <CardContent
          sx={{
            paddingTop: 0,
            paddingRight: 3,
            '&:last-child': { paddingBottom: 1 }
          }}
        >
          <Grid container columnSpacing={2}>
            {eventIngredients}
            {metricsLists}
            {mealNutrition}
            {journalEntryMeasurements}
          </Grid>
        </CardContent>
      ) : null}
    </Card>
  )
}
