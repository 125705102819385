import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import { Badge } from '@mui/material'
import { FeatureFlags } from 'featureFlags.types'
import { useFeatureFlag } from 'hooks'
import { COACH_NOTIFICATION_DATA } from 'screens/AdminPanel/Notifications/constants/notificationData'
import { useAllHealthPortalNotificationsQuery } from 'types'
import { useEffectiveNotificationKinds } from './Notifications/hooks/useEffectiveNotificationKinds'

const NOTIFICATIONS_POLLING_INTERVAL = 300_000

export const AdminPanelSidebarIcon = () => {
  const { allNotificationKinds } = useEffectiveNotificationKinds()
  const isTicketCommentSupportEnabled = useFeatureFlag(FeatureFlags.TicketCommentSupport)
  const { data: healthPortalNotificationData } = useAllHealthPortalNotificationsQuery({
    variables: {
      forReview: true, // limits the scope of dietitian notifications to the daily ones
      kinds: allNotificationKinds
    },
    pollInterval: NOTIFICATIONS_POLLING_INTERVAL
  })

  const healthPortalNotifications =
    healthPortalNotificationData?.allHealthPortalNotifications?.healthPortalNotifications || []
  const notificationCount = healthPortalNotifications.filter((notification) => {
    const condition = !notification.completedAt

    if (isTicketCommentSupportEnabled) {
      return condition
    }
    return condition && notification.kind in COACH_NOTIFICATION_DATA
  }).length

  return (
    <Badge badgeContent={notificationCount} color="primary">
      <AdminPanelSettingsOutlinedIcon />
    </Badge>
  )
}
