import React, { BaseSyntheticEvent } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid'

interface IDialogEditCellEditor {
  onChange: (event: BaseSyntheticEvent) => void
}

interface IDialogEditCellProps {
  rowId: number
  field: string
  value: string
  title: string
  required: boolean
  error: boolean
  children?: React.FC<IDialogEditCellEditor>
}

export const DialogEditCell = ({
  rowId,
  field,
  value,
  title,
  required = false,
  error = false,
  children
}: IDialogEditCellProps) => {
  const apiRef = useGridApiContext()

  const handleChangeRawText = (event: BaseSyntheticEvent) => {
    const value = event.target.value // The new value entered by the user
    apiRef.current.setEditCellValue({ id: rowId, field, value })
  }

  const handleSave = () => {
    apiRef.current.stopCellEditMode({ id: rowId, field })
  }

  const handleCancel = () => {
    apiRef.current.stopCellEditMode({ id: rowId, field, ignoreModifications: true })
  }

  const disabled = error || (required && (value === undefined || value === null || value === ''))

  return (
    <>
      {value}
      <Dialog maxWidth="xs" fullWidth open onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children?.({ onChange: handleChangeRawText })}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit" variant="contained" disabled={disabled} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
