import { DesktopDatePicker, LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { format, isValid, subYears } from 'date-fns'
import { IModalProps } from 'hooks'
import { startCase } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import { MM_DD_YYYY, YYYY_MM_DD } from 'utility/timeFormats'
import { phoneNumberFn, requiredFn, validateFilledInput } from 'utility/validators'
import { useCurrentUserQuery, UserKind, useUpdateUserMutation } from 'types'

interface IEditProfileForm {
  firstName: string
  lastName: string
  kind: string
  sex: string
  phoneNumber: string
  dateOfBirth: string
}

// stated in our terms of use: https://www.nutrisense.io/terms
const MIN_MEMBER_AGE = 18

enum Sex {
  Male = 'Male',
  Female = 'Female'
}

export const EditProfileDialog = ({ closeModal, modalState }: IModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const confirm = useConfirm()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IEditProfileForm>({
    mode: 'onTouched',
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      kind: currentUser?.kind,
      sex: currentUser?.sex || undefined,
      phoneNumber: currentUser?.phoneNumber || undefined,
      dateOfBirth: currentUser?.dateOfBirth || undefined
    }
  })

  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation({
    onError: (error) =>
      snackBarVisibleVar({
        open: true,
        message: error.message
      }),
    onCompleted: () => closeModal()
  })

  if (!currentUser) {
    return null
  }

  const updateUserHandler = async (formData: IEditProfileForm) => {
    try {
      await confirm({ description: 'Make sure prescription data matches before editing' })
    } catch {
      return
    }
    await updateUser({ variables: formData })
  }

  return (
    <Dialog open={modalState} onClose={closeModal}>
      <DialogTitle>Edit Member Profile</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(updateUserHandler)}>
          <TextField
            label="First Name"
            margin="dense"
            fullWidth
            variant="standard"
            error={!!errors.firstName}
            {...register('firstName', { required: true, validate: requiredFn })}
          />
          <TextField
            label="Last Name"
            margin="dense"
            fullWidth
            variant="standard"
            error={!!errors.lastName}
            {...register('lastName', { required: true, validate: requiredFn })}
          />
          <FormControl variant="standard" margin="dense" fullWidth>
            <InputLabel id="kind-label">Type</InputLabel>
            <Controller
              control={control}
              name="kind"
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <Select labelId="kind-label" onChange={onChange} value={value} ref={ref}>
                    <MenuItem value={undefined}>
                      <em>Select type</em>
                    </MenuItem>
                    {Object.values(UserKind).map((value) => (
                      <MenuItem key={value} value={value}>
                        {startCase(value)}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            />
          </FormControl>
          <FormControl variant="standard" margin="dense" fullWidth>
            <InputLabel id="sex-label">Sex</InputLabel>
            <Controller
              control={control}
              name="sex"
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <Select labelId="sex-label" onChange={onChange} value={value} ref={ref}>
                    <MenuItem value={undefined}>
                      <em>Select item below</em>
                    </MenuItem>
                    {Object.values(Sex).map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            />
          </FormControl>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { value } }) => {
                return (
                  <MaskedInput
                    mask="999-999-9999"
                    maskChar=""
                    value={value}
                    {...register('phoneNumber', {
                      validate: (value) =>
                        validateFilledInput(value.replace(/-/g, ''), phoneNumberFn)
                    })}
                  >
                    {
                      // ignoring TS error due to types package mismatch - https://stackoverflow.com/questions/72114032/cannot-use-react-input-mask-with-a-children-component-in-react
                      // @ts-ignore
                      (inputProps: any) => (
                        <TextField
                          {...inputProps}
                          margin="dense"
                          fullWidth
                          variant="standard"
                          placeholder="Phone Number"
                          error={!!errors.phoneNumber}
                        />
                      )
                    }
                  </MaskedInput>
                )
              }}
            />
          </FormControl>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { onChange, ref, value } }) => {
              return (
                <DesktopDatePicker
                  label="Date of Birth"
                  inputFormat={MM_DD_YYYY}
                  renderInput={(params) => <TextField margin="dense" ref={ref} {...params} />}
                  value={value ? format(Date.parse(value), MM_DD_YYYY) : null}
                  maxDate={subYears(new Date(), MIN_MEMBER_AGE)}
                  onChange={(newDate) => {
                    if (!newDate || !isValid(newDate)) {
                      return
                    }

                    const formattedDate = format(newDate, YYYY_MM_DD)
                    onChange(formattedDate)
                  }}
                />
              )
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <LoadingButton loading={updateUserLoading} onClick={handleSubmit(updateUserHandler)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
