import { SelectSetting, UserSettingsSection } from '.'

export const DashboardGraph = () => {
  const rows = [
    {
      label: 'Dashboard',
      cells: [
        <SelectSetting
          key="dashboardGraphOptions"
          clientConfigKey="dashboardGraphOptions"
          settingKey="dashboardGraph"
        />
      ]
    }
  ]

  return <UserSettingsSection header="Dashboard Graph" rows={rows} />
}
