import { Box } from '@mui/material'
import styled from '@mui/styled-engine'

export const ToolbarStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 12,
  paddingRight: 12,
  paddingLeft: 12,
  paddingBottom: 12
})
