import React from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Card } from '@mui/material'
import { APP_TOOLBAR_HEIGHT } from 'components'
import { MEMBER_BIO_HEIGHT } from 'components/MemberBio/MemberBio'
import { format } from 'date-fns'
import { startCase } from 'lodash'
import { ISO_DATE_FORMAT } from 'utility/timeFormats'
import { useUserInsurancePolicyQuery } from 'types'
import { APPOINTMENT_DETAILS_HEIGHT } from './AppointmentDetails'
import { ScrollableContent, HistoryItem } from './styles'

type IInsurancePolicyHistoryProps = {
  userId: string | undefined
}

const scrollableHeight = `calc(100vh - ${APP_TOOLBAR_HEIGHT}px - ${MEMBER_BIO_HEIGHT}px - ${APPOINTMENT_DETAILS_HEIGHT}px - 384px)`

export const InsurancePolicyHistory = ({ userId }: IInsurancePolicyHistoryProps) => {
  const { data } = useUserInsurancePolicyQuery({
    variables: { userId: userId! },
    skip: !userId
  })

  const states = [...(data?.userInsurancePolicy?.states || [])].sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  )

  return (
    <Card>
      <ScrollableContent sx={{ height: scrollableHeight }}>
        {states.map((state) => (
          <HistoryItem key={state.id} variant="body1">
            {format(new Date(state.createdAt), ISO_DATE_FORMAT)} - {startCase(state.kind)}{' '}
            {state.reason && `(${state.reason})`}
          </HistoryItem>
        ))}
      </ScrollableContent>
    </Card>
  )
}
