import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { startCase } from 'lodash'
import { formatDate } from 'utility/dataGrid'
import { CoachAssignmentSupportType, ICoachAssignment } from 'types'

export const CoachAssignmentsTable = ({ assignments }: { assignments: ICoachAssignment[] }) => {
  const isVideoCall =
    assignments.length && assignments[0].supportType === CoachAssignmentSupportType.VideoCall

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Coach</TableCell>
            <TableCell>Assigned At</TableCell>
            {isVideoCall && <TableCell>Call At</TableCell>}
            <TableCell>Deactivated At</TableCell>
            <TableCell>State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignments.map((assignment) => (
            <TableRow key={assignment.id}>
              <TableCell component="th" scope="row">
                {assignment.coach?.fullName}
              </TableCell>
              <TableCell>{formatDate({ value: assignment.assignedAt })}</TableCell>
              {isVideoCall && (
                <TableCell>{formatDate({ value: assignment.appointment?.date || '' })}</TableCell>
              )}
              <TableCell>{formatDate({ value: assignment.deactivatedAt })}</TableCell>
              <TableCell align="center">{startCase(assignment.state)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
