import React, { BaseSyntheticEvent } from 'react'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import NotesIcon from '@mui/icons-material/Notes'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import SpeedIcon from '@mui/icons-material/Speed'
import { Avatar, Popover } from '@mui/material'
import { EventsType } from 'components/Events'
import { colorMap } from 'screens/UserDetails/Graph/helpers'
import { Maybe } from 'types'
import { MealPhoto } from '.'

interface IEventAvatar {
  event: EventsType
  mealPhotoUrl: Maybe<string> | undefined
}

const EVENT_TYPE_ICON_MAP = {
  Note: <NotesIcon />,
  JournalEntry: <NotesIcon />,
  Meal: <RestaurantIcon />,
  Activity: <FitnessCenterIcon />,
  Measurement: <SpeedIcon />
}

export const EventAvatar = ({ event, mealPhotoUrl }: IEventAvatar) => {
  const { __typename } = event

  const eventColor = colorMap(__typename)

  const [photoPopoverAnchorEl, setMealPhotoPopoverAnchorEl] = React.useState<HTMLElement | null>(
    null
  )

  const handleMealAvatarClick = (event: BaseSyntheticEvent) => {
    setMealPhotoPopoverAnchorEl(event.currentTarget)
  }

  const handleMealPhotoClose = () => {
    setMealPhotoPopoverAnchorEl(null)
  }

  const open = Boolean(photoPopoverAnchorEl)
  const id = open ? 'photo-popover' : undefined

  return (
    <>
      {mealPhotoUrl ? (
        <Avatar onClick={handleMealAvatarClick} sx={{ cursor: 'pointer' }} src={mealPhotoUrl} />
      ) : (
        <Avatar
          sx={{
            bgcolor: 'white',
            color: eventColor,
            borderColor: eventColor,
            borderWidth: 0.125,
            borderStyle: 'solid'
          }}
        >
          {__typename && EVENT_TYPE_ICON_MAP[__typename]}
        </Avatar>
      )}
      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        anchorEl={photoPopoverAnchorEl}
        onClose={handleMealPhotoClose}
      >
        <MealPhoto url={mealPhotoUrl} />
      </Popover>
    </>
  )
}
