import isUrl from 'is-url'
import { isValidPhoneNumber } from 'libphonenumber-js'

export const requiredFn = (value: string) => (value ? undefined : 'This field is required.')

export const PATTERN_EMAIL = new RegExp(
  /^([0-9a-zA-Z]+[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])(?!con$)[a-zA-Z]{2,6}$/
)

const PATTERN_NUMBER = new RegExp(/^[0-9]*$/)

const PATTERN_ZIP_CODE = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/

export const numberFn = (number: string) =>
  number && !PATTERN_NUMBER.test(number) ? 'Must be valid number.' : undefined

export const phoneNumberFn = (number: string) => {
  return number && !isValidPhoneNumber(number, 'US') ? 'Must be valid number.' : undefined
}

export const validateFilledInput = (
  value: string | undefined | null,
  validatorFn: (value: string) => undefined | string
) => {
  if (!value) {
    return undefined
  }

  return validatorFn(value)
}

export const emailFn = (email: string) =>
  email && !PATTERN_EMAIL.test(email) ? 'Must be valid email.' : undefined

export const zipCodeFn = (zipCode: string) =>
  zipCode && !PATTERN_ZIP_CODE.test(zipCode) ? 'Must be valid zip code.' : undefined

export const urlFn = (url: string) => (url && !isUrl(url) ? 'Must be a valid URL.' : undefined)

export const composeValidators =
  (validators: Array<(value: string) => undefined | string>) => (value: string) =>
    validators.reduce<undefined | string>(
      (error, validator) => error || validator(value),
      undefined
    )
