export enum COLUMNS {
  '#' = 'index',
  NAME = 'user.fullName',
  EMAIL = 'user.email',
  SENSOR_EXPIRED = 'user.sensorInfo.lastSensor.expired',
  COACH_ASSIGNMENT_STATE = 'state',
  COACH_ASSIGNMENT_KIND = 'kind',
  COACH_ASSIGNMENT_SUPPORT_TYPE = 'supportType',
  COACH = 'coach.fullName',
  USER_TYPE = 'user.kind',
  DIETITIAN_SUBSCRIPTION_STATUS = 'user.lastDietitianSubscription.stripeStatus',
  APPROVAL_REQUEST_STATE = 'lastPrescriptionApprovalRequest.state',
  DIETITIAN_REQUEST = 'user.userProperties.dietitianRequest',
  COMMUNICATION_FREQUENCY = 'user.userProperties.communicationFrequency',
  HEALTH_GOAL = 'user.userProperties.healthGoal',
  ONE_MONTH_ACTIVE_FEEDBACK_DIETITIAN = 'user.userProperties.oneMonthActiveFeedbackDietitian',
  PRIMARY_GOAL = 'user.primaryGoal',
  TEST_GROUP = 'user.userProperties.testGroup',
  NUTRITIONIST_GROUP = 'user.userProperties.nutritionistGroup',
  ASSIGNED_AT = 'assignedAt',
  DEACTIVATED_AT = 'deactivatedAt',
  COACH_END_DATE = 'user.ongoingDietitianSubscription.cancelAt',
  PRODUCT = 'user.ongoingDietitianSubscription.primaryProduct',
  SENSOR_FIRST_ACTIVATION_DATE = 'user.sensorInfo.firstStartTime',
  SENSOR_EXPIRATION_DATE = 'user.sensorInfo.lastSensor.expirationDate',
  SENSOR_COUNT = 'user.sensorInfo.totalCount',
  SENSOR_REPLACEMENT_COUNT = 'user.sensorInfo.replacementRequestsCount',
  DIETITIAN_SENSOR_COUNT_NOTES = 'user.userProperties.dietitianSensorCountNotes',
  CORE_SUBSCRIPTION_STATUS = 'user.lastCoreSubscription.stripeStatus',
  CORE_SUBSCRIPTION_CHARGE_STATUS = 'user.lastCoreSubscription.latestInvoice.latestCharge.status',
  CORE_SUBSCRIPTION_CHARGE_CREATED = 'user.lastCoreSubscription.latestInvoice.latestCharge.created',
  SCHEDULED_VIDEO_CALL_STATUS = 'user.userProperties.scheduledVideoCallStatus'
}
