import { ShortcutsEditor } from './types'

export const withMentions = (editor: ShortcutsEditor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element: any) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = (element: any) => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor
}
