import { format as formatWithTimezone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const format = (timeZone: string) => (date: Date | string | number, dateFormat: string) => {
  const zonedTime = utcToZonedTime(date, timeZone)

  return formatWithTimezone(zonedTime, dateFormat, { timeZone })
}

export const timezoneTimeToUtc = (timeZone: string) => (date: Date | string | number) =>
  zonedTimeToUtc(date, timeZone)

export const getLocale = () =>
  navigator.languages.length > 0 ? navigator.languages[0] : navigator.language
