import React from 'react'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { Loader } from 'components'
import { ICoachConversationScheduledMessage } from 'types'

type IScheduledMessageStatusProps = {
  loading: boolean
  scheduledMessage: ICoachConversationScheduledMessage | null
  label: string
}

const formattedSentAt = (sentAt: string) => `Sent at ${new Date(sentAt).toLocaleString('en-US')}`
export const ScheduledMessageStatus = ({
  loading,
  scheduledMessage,
  label
}: IScheduledMessageStatusProps) => {
  return (
    <Tooltip
      title={scheduledMessage?.sentAt && formattedSentAt(scheduledMessage.sentAt)}
      placement="left"
    >
      <Stack direction="row" alignItems="center">
        {loading && <Loader styles={{ zoom: 0.4, width: 'auto' }} />}
        {!loading && <Box>{scheduledMessage?.sentAt ? '✅' : '❌'}</Box>}
        <Typography paddingLeft={1}>{label}</Typography>
      </Stack>
    </Tooltip>
  )
}
