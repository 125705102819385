import { ReactNode } from 'react'
import { Box } from '@mui/material'

interface ITabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

export const TabPanel = ({ children, value, index, ...other }: ITabPanelProps) => {
  return value === index ? (
    <Box sx={{ height: '100%', overflow: 'auto' }} {...other}>
      {children}
    </Box>
  ) : null
}
