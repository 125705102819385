import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#558D20'
    },
    'brand-primary': {
      light: '#CCED8E',
      main: '#75BC29',
      dark: '#558D20'
    },
    'brand-primary-transparent': {
      'transparent-8': 'rgba(140, 197, 65, 0.08)',
      'transparent-16': 'rgba(140, 197, 65, 0.16)',
      'transparent-24': 'rgba(140, 197, 65, 0.24)',
      'transparent-32': 'rgba(140, 197, 65, 0.32)'
    },
    'theme-primary': {
      'level-1': '#FFFFFF',
      'level-2': '#F5F5F5',
      'level-3': '#EBEBEB',
      'level-4': '#E9E9E9'
    },
    'theme-primary-transparent': {
      'transparent-8': 'rgba(255, 255, 255, 0.08)',
      'transparent-16': 'rgba(255, 255, 255, 0.16)',
      'transparent-24': 'rgba(255, 255, 255, 0.24)',
      'transparent-32': 'rgba(255, 255, 255, 0.32)'
    },
    'theme-alternative': {
      'level-1': '#18181B',
      'level-2': '#101113',
      'level-3': '#0C0D0E'
    },
    'theme-alternative-transparent': {
      'transparent-8': 'rgba(24, 24, 27, 0.08)',
      'transparent-16': 'rgba(24, 24, 27, 0.16)',
      'transparent-24': 'rgba(24, 24, 27, 0.24)',
      'transparent-32': 'rgba(24, 24, 27, 0.32)'
    },
    icon: {
      primary: '#093030',
      secondary: '#9398A0',
      alternative: '#FFFFFF',
      accent: '#75BC29'
    },
    'text-color': {
      primary: '#093030',
      secondary: '#7A7E85',
      alternative: '#FFFFFF',
      accent: '#75BC29',
      link: '#136EF5'
    },
    'disabled-button': {
      primary: '#D4D4D4',
      alternative: 'rgba(255, 255, 255, 0.08)',
      'alternative-border': 'rgba(147, 152, 160, 1)'
    },
    'border-highlight': {
      'primary-1': '#F5F5F5',
      'primary-2': 'rgba(24, 24, 27, 0.16)',
      'alternative-1': 'rgba(255, 255, 255, 0.16)'
    },
    success: {
      light: '#CCED8E',
      main: '#75BC29',
      dark: '#558D20'
    },
    'success-transparent': {
      light: 'rgba(0, 214, 143, 0.08)',
      main: 'rgba(0, 214, 143, 0.16)',
      dark: 'rgba(0, 214, 143, 0.24)'
    },
    info: {
      light: '#94CBFF',
      main: '#2F9BE8',
      dark: '#0057C2'
    },
    'info-transparent': {
      light: 'rgba(0, 149, 255, 0.08)',
      main: 'rgba(0, 149, 255, 0.16)',
      dark: 'rgba(0, 149, 255, 0.24)'
    },
    warning: {
      light: '#FFE59E',
      main: '#FF8D2A',
      dark: '#B86E00'
    },
    'warning-transparent': {
      light: 'rgba(255, 170, 0, 0.08)',
      main: 'rgba(255, 170, 0, 0.16)',
      dark: 'rgba(255, 170, 0, 0.24)'
    },
    danger: {
      light: '#FF968E',
      main: '#FF4757',
      dark: '#B81D5B'
    },
    'danger-transparent': {
      light: 'rgba(255, 61, 113, 0.08)',
      main: 'rgba(255, 61, 113, 0.16)',
      dark: 'rgba(255, 61, 113, 0.24)'
    },
    events: {
      Meal: '#FF3D71',
      Note: '#C80AFE',
      Activity: '#0095FF',
      Measurement: '#FFAA00'
    },
    file: {
      jpg: '#F67280',
      jpeg: '#C06C84',
      png: '#45ADA8',
      txt: '#F8B195',
      md: '#99B898'
    },
    graph: {
      'transparent-brand-primary': 'rgba(140, 197, 0.64)',
      'transparent-chart-secondary-background': 'rgba(255, 235, 0.56)',
      calories: '#FF3D71',
      protein: '#0095FF',
      carbs: '#8CC541',
      fat: '#FFAA00'
    }
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 0
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiSelect: {
      defaultProps: {
        inputProps: {
          sx: {
            '&:focus': {
              backgroundColor: 'white'
            }
          }
        }
      }
    }
  }
})
