import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Grid, IconButton } from '@mui/material'
import { saveEventDialogVar, deleteNoteDialogVar } from 'context'
import { INote } from 'types'

interface INoteToolbar {
  event: INote
}

export const NoteToolbar = ({ event }: INoteToolbar) => {
  const handleOpenDeleteNoteModal = () => {
    deleteNoteDialogVar({ open: true, event: event })
  }

  const handleOpenUpdateNoteModal = () => {
    saveEventDialogVar({ open: true, event: event, graphDataPoint: undefined })
  }

  return (
    <Grid item xs={12} display="flex" justifyContent="end">
      <IconButton size="small" onClick={handleOpenUpdateNoteModal}>
        <EditIcon />
      </IconButton>
      <IconButton size="small" onClick={handleOpenDeleteNoteModal}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  )
}
