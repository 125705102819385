import { Grid, Stack } from '@mui/material'
import { AppToolbar, CommonRightAdornment } from 'components'
import { Outlet } from 'react-router-dom'

export const AdminPanelLayout = () => {
  return (
    <Stack height="100%">
      <AppToolbar rightAdornment={<CommonRightAdornment />} />
      <Grid container spacing={2} flex={1} overflow="auto">
        <Outlet />
      </Grid>
    </Stack>
  )
}
