import { FormControl, MenuItem, Select } from '@mui/material'
import { useSaveUserSetting } from 'hooks'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { startCase } from 'lodash'
import { IClientConfigQuery, IUserFragment, useClientConfigQuery, useCurrentUserQuery } from 'types'

type Option = {
  text: string
  value: number | string
}

type ClientConfigKey = keyof IClientConfigQuery['clientConfig']['healthSettings']

interface ISelectSetting {
  clientConfigKey?: ClientConfigKey
  options?: Option[]
  settingKey: keyof IUserFragment['settings']
}

export const SelectSetting = ({ clientConfigKey, settingKey, options }: ISelectSetting) => {
  const { track } = useSegmentInteraction()
  const saveUserSetting = useSaveUserSetting(() => track(`${startCase(settingKey)} field updated`))
  const { data: clientConfigData } = useClientConfigQuery()

  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const settings = currentUser?.settings

  const selectOptions = clientConfigKey
    ? (clientConfigData?.clientConfig.healthSettings[clientConfigKey] as Option[])
    : options

  const currentValue = settings?.[settingKey]

  return (
    <FormControl variant="standard">
      {/* wait for the options to be available, otherwise MUI will trigger a warning */}
      {selectOptions && (
        <Select
          size="small"
          value={currentValue}
          sx={{ fontSize: 12, '& .MuiSelect-icon': { fontSize: 18 } }}
          onChange={(event) => {
            return saveUserSetting({ [settingKey]: event.target.value })
          }}
        >
          {selectOptions.map(({ value, text }: { value: number; text: string }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  )
}
