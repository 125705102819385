import { BaseSyntheticEvent, ReactElement } from 'react'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import { round } from 'lodash'
import { IMetricEntry } from '.'

export interface IMetricListProps {
  title: string
  metricEntries: IMetricEntry[]
  data: Record<string, number>
  units?: Record<string, string>
  slotRight?: ReactElement
  onHeaderClick?: (event: BaseSyntheticEvent) => void
}

export const MetricList = ({
  title,
  metricEntries,
  data,
  units,
  slotRight,
  onHeaderClick
}: IMetricListProps) => {
  const entry = ({ label, key }: IMetricEntry) => {
    const entryValue = round(data?.[key] * 100) / 100
    const entryUnit = entryValue && units ? units?.[key] : null
    return (
      <ListItem disablePadding sx={{ justifyContent: 'space-between' }} key={key}>
        <Typography color="initial" variant="caption">
          {label}
        </Typography>
        <Box>
          <Typography fontWeight="bold" component="span" variant="caption" mr={0.2}>
            {isNaN(entryValue) ? '-' : entryValue}
          </Typography>
          {entryUnit && (
            <Typography fontWeight="bold" component="span" variant="caption">
              {entryUnit}
            </Typography>
          )}
        </Box>
      </ListItem>
    )
  }

  return (
    <List>
      <ListItem
        onClick={onHeaderClick}
        disablePadding
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          ...(onHeaderClick && { cursor: 'pointer' })
        }}
      >
        <Typography fontWeight="bold">{title}</Typography>
        {slotRight}
      </ListItem>
      <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
      {metricEntries.map(entry)}
    </List>
  )
}
