import { registerSW } from 'virtual:pwa-register'

export const registerServiceWorker = () => {
  const intervalMS = 15 * 1_000

  const updateSW = registerSW({
    onRegisteredSW(serviceWorkerUrl, registration) {
      registration &&
        setInterval(async () => {
          if (registration.installing || !navigator) return

          if ('connection' in navigator && !navigator.onLine) return

          const resp = await fetch(serviceWorkerUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache'
            }
          })

          if (resp?.status === 200) await registration.update()
        }, intervalMS)
    },
    onNeedRefresh() {
      generatePrompt()
    }
  })

  window.addEventListener('beforeunload', () => {
    updateSW(true)
  })
}

export const generatePrompt = () => {
  const promptContainer = document.createElement('div')

  promptContainer.style.display = 'flex'
  promptContainer.style.position = 'fixed'
  promptContainer.style.right = '16px'
  promptContainer.style.bottom = '16px'
  promptContainer.style.padding = '16px 16px'
  promptContainer.style.backgroundColor = 'rgba(255, 244, 230, 1)'
  promptContainer.style.borderRadius = '5px'

  const warningIcon = document.createElement('div')
  warningIcon.innerHTML =
    '<svg xmlns="http://www.w3.org/2000/svg"  style="fill: rgb(255, 130, 37); margin-right: 8px;"  enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M12,5.99L19.53,19H4.47L12,5.99 M12,2L1,21h22L12,2L12,2z"/><polygon points="13,16 11,16 11,18 13,18"/><polygon points="13,10 11,10 11,15 13,15"/></g></g></g></svg>'

  const promptContentContainer = document.createElement('div')

  const title = document.createElement('b')
  title.innerText = 'New HP version available'

  const message = document.createElement('div')
  message.innerHTML = 'Please, <b>refresh</b> this page to load the new version'

  promptContentContainer.append(title, message)
  promptContainer.append(warningIcon, promptContentContainer)

  const portalRoot = document.getElementById('portalRoot')!
  portalRoot.appendChild(promptContainer)
}
