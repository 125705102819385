import { useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { Paper, Typography } from '@mui/material'
import { DataGrid as ServerDataGrid, DataGridHandle } from 'components'
import gridVars from 'context/billingDashboard/charges'
import { FlexBoxStyled } from 'utility/box'
import { getStripeRowsClassName } from 'utility/dataGrid'
import { IBillingDashboardAllChargesQuery, useCurrentUserQuery } from 'types'
import { columns, DATA_GRID_STATE_KEY } from './constants'

interface IChargesProps {
  data: IBillingDashboardAllChargesQuery | undefined
  loading: boolean
  error: ApolloError | undefined
  refetch: any
}

export const Charges = ({ data, loading, error, refetch }: IChargesProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const dataGridRef = useRef<DataGridHandle>(null)

  const rowCount = data?.allCharges.totalCount
  const charges = data?.allCharges.charges || []

  return (
    <Paper>
      <FlexBoxStyled flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" mt={2} ml={2}>
          Charges
        </Typography>
        <FlexBoxStyled flexDirection="row" mt={2} mr={2}>
          <Typography variant="h6" mr={1}>
            Credit Balance:
          </Typography>
          <Typography variant="h6">${currentUser?.stripeCreditBalance || 0.0}</Typography>
        </FlexBoxStyled>
      </FlexBoxStyled>
      <ServerDataGrid
        ref={dataGridRef}
        rows={charges}
        columns={columns}
        loading={loading}
        error={error}
        gridVars={gridVars}
        refresh={refetch}
        rowCount={rowCount}
        getRowClassName={getStripeRowsClassName}
        stateKey={DATA_GRID_STATE_KEY}
        autoHeight
        noToolbar
      />
    </Paper>
  )
}
