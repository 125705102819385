export interface ISession {
  startedAt: Date | null
  endedAt: Date
  userId: string | undefined
}

const DIETITIAN_SESSIONS_KEY = 'DIETITIAN_SESSIONS'

export const storedSessions = () => {
  const sessions = JSON.parse(localStorage.getItem(DIETITIAN_SESSIONS_KEY) || '[]')
  // after parsing dates are no more of type Date but String
  sessions.forEach((session: ISession) => {
    if (session.startedAt) session.startedAt = new Date(session.startedAt)
    if (session.endedAt) session.endedAt = new Date(session.endedAt)
  })
  return sessions
}

export const clearStoredSessions = () => {
  return localStorage.removeItem(DIETITIAN_SESSIONS_KEY)
}

export const storeSession = ({ startedAt, endedAt, userId }: ISession) => {
  let sessions = storedSessions()
  sessions.push({ startedAt, endedAt, userId })
  localStorage.setItem(DIETITIAN_SESSIONS_KEY, JSON.stringify(sessions))
}
