import { Button } from '@mui/material'
import {
  getGridStringOperators,
  GridPreProcessEditCellProps,
  GridValueSetterParams
} from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { previewImageUrlVar } from 'context/approvalRequests/previewImageUrl'
import { startCase } from 'lodash'
import {
  formatDate,
  formatDateTime,
  formatStartCase,
  renderAsCheck,
  renderAsTextWithTooltip,
  renderAsUserHomePageLink
} from 'utility/dataGrid'
import {
  AddressState,
  IUser,
  IAddress,
  IInvoice,
  ICharge,
  IFulfillmentConfiguration,
  IApprovalRequest,
  UserApprovalRequestState,
  ApprovalRequestsDynamicFilterField,
  ApprovalRequestSortField,
  IProductMin,
  UserSensorKind,
  UserApprovalRequestKind
} from 'types'

type IApprovalRequestRow = { [K in keyof IApprovalRequest as K]?: IApprovalRequest[K] } & {
  [K in keyof IAddress as `user.address.${K}`]?: IAddress[K]
} & {
  [K in keyof IInvoice as `user.lastCoreSubscription.latestInvoice.${K}`]?: IInvoice[K]
} & {
  [K in keyof IUser as `user.${K}`]?: IUser[K]
} & {
  [K in keyof ICharge as `user.lastCoreSubscription.latestInvoice.latestCharge.${K}`]?: ICharge[K]
} & {
  [K in keyof IFulfillmentConfiguration as `user.fulfillmentConfiguration.${K}`]?: IFulfillmentConfiguration[K]
} & {
  [K in keyof IProductMin as `user.lastCoreSubscription.primaryProduct.${K}`]?: IProductMin[K]
}

const US_ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const columns: (IDataGridColumn & {
  field: keyof IApprovalRequestRow | 'index'
})[] = [
  {
    field: 'user.id',
    headerName: 'User ID',
    width: 100,
    type: 'number',
    valueGetter: ({ row }) => row.user.id
  },
  {
    field: 'user.fullName',
    headerName: 'Name',
    width: 250,
    type: 'string',
    editable: true,
    valueGetter: ({ row }) => row.user.fullName,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, user: { ...row.user } }
      rowCopy.user.fullName = value
      return rowCopy
    },
    renderCell: (params) => renderAsUserHomePageLink(params, (row) => row.user.id)
  },
  {
    field: 'user.governmentIdCard',
    headerName: 'Health - Identification',
    width: 200,
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.user.governmentIdCard,
    renderCell: ({ value }) => {
      if (!value) return

      const handlePreviewClick = () => {
        previewImageUrlVar(value)
      }

      return (
        <Button variant="text" color="success" onClick={handlePreviewClick}>
          preview
        </Button>
      )
    }
  },
  {
    field: 'user.email',
    headerName: 'Email',
    width: 250,
    valueGetter: ({ row }) => row.user.email,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, user: { ...row.user } }
      rowCopy.user.email = value
      return rowCopy
    },
    editable: true
  },
  {
    field: 'kind',
    headerName: 'Approval Kind',
    type: 'singleSelect',
    valueOptions: Object.values([
      UserApprovalRequestKind.Prescription,
      UserApprovalRequestKind.Research,
      UserApprovalRequestKind.Glp
    ]).map((value) => {
      return { label: startCase(value), value: value }
    }),
    valueFormatter: formatStartCase,
    width: 250
  },
  {
    field: 'state',
    headerName: 'Approval State',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(UserApprovalRequestState).map((value) => {
      return { label: startCase(value), value: value }
    }),
    width: 150,
    valueFormatter: formatStartCase,
    valueGetter: ({ row }) => row.state
  },
  {
    field: 'user.lastAnsweredHealthSurveyQuestionKey',
    headerName: 'Last Answered HQ Question',
    width: 250,
    valueFormatter: formatStartCase,
    valueGetter: ({ row }) => row.user.lastAnsweredHealthSurveyQuestionKey,
    renderCell: (params) => renderAsTextWithTooltip(params, true),
    filterable: false,
    sortable: false,
    groupable: true
  },
  {
    field: 'user.lastAnsweredMedicalHistorySurveyQuestionKey',
    headerName: 'Last Answered MH Question',
    width: 250,
    valueFormatter: formatStartCase,
    valueGetter: ({ row }) => row.user.lastAnsweredMedicalHistorySurveyQuestionKey,
    renderCell: (params) => renderAsTextWithTooltip(params, true),
    filterable: false,
    sortable: false,
    groupable: true
  },
  {
    field: 'createdAt',
    headerName: 'Approval State Changed At',
    type: 'dateTime',
    width: 200,
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row.createdAt
  },
  {
    field: 'user.dexcomSensorPromptUiState',
    headerName: 'Sensor Selection Pending',
    sortable: false,
    width: 200,
    type: 'boolean',
    renderCell: renderAsCheck,
    valueGetter: ({ row }) => !!row.user.dexcomSensorPromptUiState
  },
  {
    field: 'user.lastCoreSubscription.latestInvoice.latestCharge.created',
    headerName: 'Payment Charge Date',
    width: 150,
    type: 'date',
    backendSortName:
      ApprovalRequestSortField.UserLastCoreSubscriptionLatestInvoiceLatestChargeCreated,
    backendFilterName:
      ApprovalRequestsDynamicFilterField.UserLastCoreSubscriptionLatestInvoiceLatestChargeCreated,
    valueFormatter: formatDate,
    valueGetter: ({ row }) => row.user.lastCoreSubscription?.latestInvoice?.latestCharge?.created
  },
  {
    field: 'user.fulfillmentConfiguration.sensorKind',
    headerName: 'Sensor Kind',
    width: 150,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(UserSensorKind),
    valueGetter: ({ row }) =>
      row['user.fulfillmentConfiguration.sensorKind'] ||
      row.user.fulfillmentConfiguration?.sensorKind,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      return {
        ...row,
        user: {
          ...row.user,
          fulfillmentConfiguration: {
            ...row.user.fulfillmentConfiguration,
            sensorKind: value
          }
        }
      }
    }
  },
  {
    field: 'user.lastCoreSubscription.primaryProduct.title',
    headerName: 'Product',
    width: 250,
    backendSortName: ApprovalRequestSortField.UserLastCoreSubscriptionPrimaryProduct,
    backendFilterName: ApprovalRequestsDynamicFilterField.UserLastCoreSubscriptionPrimaryProduct,
    valueGetter: ({ row }) => row.user.lastCoreSubscription.primaryProduct.title,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
  },
  {
    field: 'user.dateOfBirth',
    headerName: 'DOB',
    width: 100,
    type: 'date',
    valueGetter: ({ row }) => row.user.dateOfBirth,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, user: { ...row.user } }
      rowCopy.user.dateOfBirth = value
      return rowCopy
    },
    editable: true
  },
  {
    field: 'user.age',
    headerName: 'Age',
    width: 100,
    filterable: false,
    valueGetter: ({ row }) => row.user.age,
    sortable: false
  },
  {
    field: 'user.phoneNumber',
    headerName: 'Phone Number',
    width: 150,
    valueGetter: ({ row }) => row.user.phoneNumber,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, user: { ...row.user } }
      rowCopy.user.phoneNumber = value
      return rowCopy
    },
    editable: true
  },
  {
    field: 'user.address.street',
    headerName: 'Address - Street',
    width: 250,
    editable: true,
    valueGetter: ({ row }) => row.user.address?.street,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      return {
        ...row,
        user: {
          ...row.user,
          address: {
            ...row.user.address,
            street: value
          }
        }
      }
    }
  },
  {
    field: 'user.address.street2',
    headerName: 'Address - Street 2',
    width: 200,
    editable: true,
    valueGetter: ({ row }) => row['user.address.street2'] || row.user.address?.street2,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      return {
        ...row,
        user: {
          ...row.user,
          address: {
            ...row.user.address,
            street2: value
          }
        }
      }
    }
  },
  {
    field: 'user.address.city',
    headerName: 'Address - City',
    width: 200,
    editable: true,
    valueGetter: ({ row }) => row['user.address.city'] || row.user.address?.city,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      return {
        ...row,
        user: {
          ...row.user,
          address: {
            ...row.user.address,
            city: value
          }
        }
      }
    }
  },
  {
    field: 'user.address.state',
    headerName: 'Address - State',
    width: 150,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(AddressState).sort(),
    valueGetter: ({ row }) => row['user.address.state'] || row.user.address?.state,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      return {
        ...row,
        user: {
          ...row.user,
          address: {
            ...row.user.address,
            state: value
          }
        }
      }
    }
  },
  {
    field: 'user.address.zipCode',
    headerName: 'Address - Zip Code',
    width: 150,
    editable: true,
    valueGetter: ({ row }) => row['user.address.zipCode'] || row.user.address?.zipCode,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      return {
        ...row,
        user: {
          ...row.user,
          address: {
            ...row.user.address,
            zipCode: value
          }
        }
      }
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const cellValue = params.props.value
      const hasError = !US_ZIP_CODE_REGEX.test(cellValue) || cellValue.toString() === '00000'
      return { ...params.props, error: hasError }
    }
  },
  {
    field: 'user.healthReject',
    headerName: 'Health - Reject',
    width: 200,
    filterable: false,
    valueGetter: ({ row }) => row.user.healthReject,
    sortable: false
  }
]
