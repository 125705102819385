import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { ApolloCache, gql, useReactiveVar } from '@apollo/client'
import { Button, DialogActions, DialogContent } from '@mui/material'
import { IEventListItem } from 'components/Events/types'
import { snackBarVisibleVar, saveEventDialogVar } from 'context'
import { useUpsertNoteMutation } from 'types'
import { Header } from './Header'
import { useEventDate, useGlucoseValue } from './helpers'
import { AddNoteInputStyled } from './styles'

const EMPTY_NOTE = ''
const ON_ERROR_MESSAGE = 'Note save failed. Contact the support'

export const SaveNote = () => {
  const { graphDataPoint, event } = useReactiveVar(saveEventDialogVar)
  const noteValueFromEvent = (event && 'body' in event && event?.body) || EMPTY_NOTE
  const [noteValue, setNoteValue] = useState(noteValueFromEvent)

  const formattedDate = useEventDate(event!, graphDataPoint!)
  const glucoseValue = useGlucoseValue(event!, graphDataPoint!)

  useEffect(() => {
    setNoteValue(noteValueFromEvent)
  }, [noteValueFromEvent])

  const handleClose = () => {
    saveEventDialogVar({ open: false })
    setNoteValue(EMPTY_NOTE)
  }

  const handleInputChange = (event: BaseSyntheticEvent) => setNoteValue(event.target.value)

  const handleCacheUpdate = (cache: ApolloCache<IEventListItem>, { data: createNote }: any) => {
    cache.modify({
      fields: {
        allHistory(existingHistory: IEventListItem[]) {
          const newNoteRef = cache.writeFragment({
            data: createNote,
            fragment: gql`
              fragment NewNoteUpdate on Note {
                id
                body
              }
            `
          })
          return [...existingHistory, newNoteRef]
        }
      }
    })
  }

  const [createNoteMutation] = useUpsertNoteMutation({
    update: handleCacheUpdate,
    onCompleted: handleClose,
    onError: () => snackBarVisibleVar({ open: true, message: ON_ERROR_MESSAGE }),
    refetchQueries: ['allEvents']
  })

  const handleSave = () => {
    const body = noteValue
    let id = null
    let time = null

    if (event) {
      time = event.occurredAt
      id = event.id
    } else {
      if (!graphDataPoint) return
      time = new Date(graphDataPoint?.time).toString()
    }

    createNoteMutation({ variables: { id, time, body } })
  }

  return (
    <>
      <DialogContent sx={{ paddingBottom: '24px' }}>
        <Header formattedDate={formattedDate} glucoseValue={glucoseValue} />
        <AddNoteInputStyled
          value={noteValue}
          onChange={handleInputChange}
          minRows={3}
          maxRows={15}
          placeholder="Type your note here"
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CLOSE</Button>
        <Button onClick={handleSave} disabled={!noteValue}>
          SAVE
        </Button>
      </DialogActions>
    </>
  )
}
