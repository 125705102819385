import { ChatAutoComplete, SendButton, useMessageInputContext } from 'stream-chat-react'
import {
  CustomMessageInputContainer,
  CustomMessageInputSendContainer,
  CustomMessageInputTextareaContainer
} from './styles'
import { useIsProcessing } from './useIsProcessing'

export const CustomMessageInput = () => {
  const { maxRows = 3, handleSubmit } = useMessageInputContext()
  const isProcessing = useIsProcessing()

  return (
    <CustomMessageInputContainer>
      <CustomMessageInputTextareaContainer>
        <ChatAutoComplete rows={maxRows} />
      </CustomMessageInputTextareaContainer>
      <CustomMessageInputSendContainer>
        <SendButton sendMessage={handleSubmit} disabled={isProcessing} />
      </CustomMessageInputSendContainer>
    </CustomMessageInputContainer>
  )
}
