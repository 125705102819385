import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { compact } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import {
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation
} from 'types'

interface IChangeSubscriptionBillingCycleForm {
  subscriptionId: string
  billingCycleAnchor: Date
  priority: OpsAccountRequestPriority
  note: string
}

export const ChangeSubscriptionBillingCycleModal = ({
  closeModal,
  modalState
}: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { handleSubmit, control, register, reset, formState } =
    useForm<IChangeSubscriptionBillingCycleForm>({
      mode: 'onTouched',
      defaultValues: {
        subscriptionId: '',
        billingCycleAnchor: new Date(),
        priority: OpsAccountRequestPriority.Regular,
        note: ''
      }
    })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessChangeSubscriptionBillingCycle
  )

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser || !currentUser.ongoingCoreSubscription?.id) {
    return null
  }

  const onClose = () => {
    reset()
    closeModal()
  }

  const subscriptions = compact([
    currentUser.ongoingCoreSubscription,
    currentUser.ongoingDietitianSubscription
  ])

  const changeSubscriptionBillingCycleHandler = async (
    formData: IChangeSubscriptionBillingCycleForm
  ) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.ChangeSubscriptionBillingCycle,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.ChangeSubscriptionBillingCycle,
          changeSubscriptionBillingCycle: {
            subscriptionId: currentUser!.ongoingCoreSubscription!.id,
            billingCycleAnchor: formData.billingCycleAnchor
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const innerForm = (
    <>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Select Subscription</InputLabel>
        <Controller
          name="subscriptionId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('subscriptionId', { required: true })}
              error={!!formState.errors.subscriptionId}
              label="Select Subscription"
            >
              <MenuItem value="" disabled>
                <em>Select Subscription</em>
              </MenuItem>
              {subscriptions.map((subscription) => (
                <MenuItem key={subscription.id} value={subscription.id}>
                  {subscription.primaryProduct.title}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <Controller
          control={control}
          name="billingCycleAnchor"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => {
            return (
              <DesktopDatePicker
                label="New Billing Cycle Anchor"
                value={value}
                onChange={onChange}
              />
            )
          }}
        />
      </FormControl>
    </>
  )
  return (
    <AccountRequestModal<IChangeSubscriptionBillingCycleForm>
      register={register}
      modalTitle="Change Subscription Billing Cycle Anchor"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(changeSubscriptionBillingCycleHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
