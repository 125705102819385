import { useReactiveVar } from '@apollo/client'
import { Box, Chip } from '@mui/material'
import { Loader } from 'components/Loader'
import { condensedEventCardsVar } from 'context'
import { ListItemTypename } from 'listItem.types'
import { CondensedCard as CondensedEventCard } from './CondensedCard'
import { EventCard } from './EventCard'
import { IEventListItem } from './types'

interface IEventListItemProp {
  event: IEventListItem
  eventsListHeight?: number
}

export const EventListItem = ({ event, eventsListHeight }: IEventListItemProp) => {
  const { __typename: typename } = event
  const condensed = useReactiveVar(condensedEventCardsVar)

  if (typename === ListItemTypename.DateDelimiter) {
    return <Chip sx={{ borderRadius: '10%' }} label={event.date} />
  }

  if (typename === ListItemTypename.LastItem) {
    return <Box height={eventsListHeight} />
  }

  if (typename === ListItemTypename.Loader)
    return (
      <Box height={60}>
        <Loader />
      </Box>
    )

  return condensed ? <CondensedEventCard {...event} /> : <EventCard {...event} />
}
