import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import { IMPERIAL_UNIT_SYSTEM, IMPERIAL_UNITS, METRIC_UNITS, UNIT_SYSTEM_TYPE } from 'context'
import { theme } from 'theme'
import { SECTION_STAT_KEY_TO_UNIT, STAT_KEY_TO_NAME, StatSections } from 'utility/statistic'
import { IRangeUnit, IStatistic } from 'types'

const RATING_TO_COLOR = {
  good: theme.palette.success.main,
  ok: theme.palette.warning.main,
  bad: theme.palette.error.main
}

const getValueRating = (value: number, ranges: IRangeUnit[]) => {
  const range = ranges.find((range) => {
    if (!range.from) return value <= (range.to || 0)
    if (!range.to) return value >= range.from

    return value >= range.from && value <= range.to
  })

  return range?.rating
}

export const getValueColor = (stat: IStatistic, ranges: IRangeUnit[]) => {
  if (!ranges) return

  const rating = getValueRating(stat.value, ranges)

  if (!rating || stat.meta.section !== StatSections.Glucose) return

  return RATING_TO_COLOR[rating as keyof typeof RATING_TO_COLOR]
}

export const getValueChangeText = (stat: IStatistic, unit: string) => {
  const { value, valuePrev } = stat
  const valueChange = value - valuePrev

  if (valueChange === 0) return 'No change'

  const sign = valueChange > 0 ? '+' : ''
  return `${sign}${Number(valueChange.toFixed(2)).toLocaleString()} ${unit} vs past period`
}

export const getStatTitle = (stat: IStatistic) => {
  return STAT_KEY_TO_NAME[stat.meta.key as keyof typeof STAT_KEY_TO_NAME] || stat.title
}

export const getTrendArrow = (stat: IStatistic) => {
  const { value, valuePrev } = stat

  if (!value) return null
  if (value === valuePrev) return null

  return value > valuePrev ? ArrowDropUpOutlinedIcon : ArrowDropDownOutlinedIcon
}

export const getStatUnit = (stat: IStatistic, unitSystem: UNIT_SYSTEM_TYPE) => {
  const value: string = (SECTION_STAT_KEY_TO_UNIT as any)[stat.meta.section][stat.meta.key]

  if (stat.meta.section !== StatSections.Glucose || value !== IMPERIAL_UNITS) {
    return value
  }

  return unitSystem === IMPERIAL_UNIT_SYSTEM ? IMPERIAL_UNITS : METRIC_UNITS
}
