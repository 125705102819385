import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const EmojiLevel3 = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        fill="#F8F6F1"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 18.25a3 3 0 100-6 3 3 0 000 6zM26.5 18.25a3 3 0 100-6 3 3 0 000 6z"
        fill="#11494F"
      />
      <path
        d="M12.5 25H27"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default EmojiLevel3
