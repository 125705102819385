import { useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Chip, IconButton, Tooltip } from '@mui/material'
import { EmptyFieldChip } from 'components/EmptyFieldChip'
import { findIndex, isNil } from 'lodash'
import { useClientConfigQuery, useCurrentUserQuery, useUpdateSensorMutation } from 'types'

const NO_ACTIVE_SENSOR = 'No Active Sensor'
const SENSOR_ADJUSTMENT_INCREASE = 1
const SENSOR_ADJUSTMENT_DECREASE = -1

interface ISensorCalibrationChip {
  editable?: boolean
  onClick?: () => void
}

export const SensorCalibrationChip = ({
  editable = false,
  onClick = () => {}
}: ISensorCalibrationChip) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { clientConfig } = {} } = useClientConfigQuery()
  const [updateSensor] = useUpdateSensorMutation({
    refetchQueries: ['currentUser']
  })
  const baselineAdjustment = currentUser?.sensor?.baselineAdjustment

  const options = clientConfig?.sensorSettings.baselineAdjustmentOptions

  const currentAdjustmentIndex = useMemo(
    () => findIndex(options, { value: baselineAdjustment }),
    [options, baselineAdjustment]
  )

  const currentAdjustment = options?.[currentAdjustmentIndex]

  const submitUpdateSensor = async (newAdjustment = 0) => {
    await updateSensor({ variables: { baselineAdjustment: newAdjustment } })
  }

  const changeAdjustment = async (direction: number) => {
    const nextAdjustmentValue = options?.[currentAdjustmentIndex + direction].value
    submitUpdateSensor(nextAdjustmentValue)
  }

  const leftActionDecreaseCalibration = (
    <IconButton
      sx={{ padding: 0 }}
      size="small"
      onClick={() => changeAdjustment(SENSOR_ADJUSTMENT_DECREASE)}
    >
      <RemoveIcon sx={{ fontSize: 16 }} />
    </IconButton>
  )

  const rightActionIncreaseCalibration = (
    <IconButton sx={{ padding: 0 }} size="small">
      <AddIcon sx={{ fontSize: 16 }} />
    </IconButton>
  )

  const editableCalibrationOptions = editable
    ? {
        icon: leftActionDecreaseCalibration,
        deleteIcon: rightActionIncreaseCalibration,
        onDelete: () => changeAdjustment(SENSOR_ADJUSTMENT_INCREASE)
      }
    : {}

  return !options || options.length === 0 || isNil(baselineAdjustment) ? (
    <EmptyFieldChip
      size="small"
      label={NO_ACTIVE_SENSOR}
      sx={{ marginLeft: 1 }}
      onClick={onClick}
    />
  ) : (
    <Tooltip title="Sensor calibration">
      <Chip
        size="small"
        clickable
        onClick={onClick}
        sx={{
          fontWeight: 400,
          marginLeft: 1,
          '& .MuiChip-deleteIconSmall': {
            color: 'text.secondary'
          }
        }}
        {...editableCalibrationOptions}
        label={`${currentAdjustment?.text || 0}`}
      />
    </Tooltip>
  )
}
