import { FormControl, InputAdornment, TextField } from '@mui/material'

import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import {
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation
} from 'types'
import { centsAmountFromDollarStringAmount } from './utils'

interface ICreditForm {
  amount: string
  priority: OpsAccountRequestPriority
  note: string
}

export const CreditModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { handleSubmit, control, register, reset, formState } = useForm<ICreditForm>({
    mode: 'onTouched',
    defaultValues: {
      amount: '',
      priority: OpsAccountRequestPriority.Regular,
      note: ''
    }
  })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessCredit
  )

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser) {
    return null
  }

  const creditHandler = async (formData: ICreditForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.Credit,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.Credit,
          credit: {
            centsAmount: centsAmountFromDollarStringAmount(formData.amount)
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const innerForm = (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <Controller
        name="amount"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            customInput={TextField}
            variant="outlined"
            fullWidth
            label="Amount"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            error={!!formState.errors.amount}
            {...rest}
          />
        )}
      />
    </FormControl>
  )
  return (
    <AccountRequestModal<ICreditForm>
      register={register}
      modalTitle="Credit"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(creditHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
