export const ON_ERROR_MESSAGE = 'Meal save failed. Contact the support'

export const CATEGORIES = [
  'breakfast',
  'dessert',
  'dinner',
  'drink',
  'lunch',
  'snack',
  'supplement'
]

export const MACROS = [
  {
    label: 'Calories',
    key: 'calories' as const,
    unit: 'kcal'
  },
  {
    label: 'Proteins',
    key: 'protein' as const,
    unit: 'g'
  },
  {
    label: 'Carbs',
    key: 'total_carb' as const,
    unit: 'g'
  },
  {
    label: 'Fat',
    key: 'total_fat' as const,
    unit: 'g'
  }
]
