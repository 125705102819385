import React, { useEffect } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useReactiveVar } from '@apollo/client'
import { Grid, Typography } from '@mui/material'
import { WithCopyToClipboardAction } from 'components'
import { selectedAppointmentIdVar } from 'context/nutritionistHome/selectedAppointmentIdVar'
import {
  IAppointment,
  IInsurancePolicy,
  IInsurancePolicyState,
  InsuranceBerryStreetAppointmentMeetingStatus,
  useAppointmentLazyQuery
} from 'types'
import { LinkStyled, PaddedCard } from './styles'
import {
  appointmentDescription,
  appointmentTimeDescription,
  callsUsedDescription,
  insurancePolicyStateDescription
} from './utils'

export const APPOINTMENT_DETAILS_HEIGHT = 245

type IAppointmentDetailsProps = {
  pastAppointments: IAppointment[]
}

export const AppointmentDetails = ({ pastAppointments }: IAppointmentDetailsProps) => {
  const selectedAppointmentId = useReactiveVar(selectedAppointmentIdVar)
  const [getAppointment, { data: { appointment } = { appointment: null }, loading }] =
    useAppointmentLazyQuery()
  const callsUsed = pastAppointments.filter(
    (appointment) =>
      appointment.meetingStatus === InsuranceBerryStreetAppointmentMeetingStatus.Occurred
  ).length

  useEffect(() => {
    if (selectedAppointmentId) {
      getAppointment({ variables: { id: selectedAppointmentId } })
    }
  }, [selectedAppointmentId, getAppointment])

  if (!selectedAppointmentId || !appointment || loading)
    return <PaddedCard sx={{ height: `${APPOINTMENT_DETAILS_HEIGHT}px` }} />

  const { insurancePolicy, externalVideochatUrl, startVideochatUrl } = appointment

  return (
    <PaddedCard sx={{ height: `${APPOINTMENT_DETAILS_HEIGHT}px`, overflowY: 'scroll' }}>
      <Typography variant="h5">
        {appointmentTimeDescription(appointment as IAppointment)}
      </Typography>
      <Grid container spacing={2} paddingTop={2}>
        <Grid item xs={4}>
          📞 {appointmentDescription(appointment as IAppointment)} video call
        </Grid>
        <Grid item xs={8}>
          {insurancePolicyStateDescription(insurancePolicy?.lastState as IInsurancePolicyState)}
        </Grid>
        <Grid item xs={4}>
          {externalVideochatUrl && (
            <WithCopyToClipboardAction value={externalVideochatUrl}>
              📹{' '}
              <LinkStyled target="_blank" href={externalVideochatUrl}>
                Member Zoom call link
              </LinkStyled>
            </WithCopyToClipboardAction>
          )}
        </Grid>
        <Grid item xs={8}>
          {insurancePolicy && (
            <>🗓️ {callsUsedDescription(callsUsed, insurancePolicy as IInsurancePolicy)}</>
          )}
        </Grid>
        <Grid item xs={4}>
          {startVideochatUrl && (
            <WithCopyToClipboardAction value={startVideochatUrl}>
              📹{' '}
              <LinkStyled target="_blank" href={startVideochatUrl}>
                Nutritionist Zoom call link
              </LinkStyled>
            </WithCopyToClipboardAction>
          )}
        </Grid>
        <Grid item xs={10}>
          {appointment.agenda && (
            <>
              <Typography variant="body1">Agenda</Typography>
              <Typography variant="body2">{appointment.agenda}</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </PaddedCard>
  )
}
