import { HTMLAttributes } from 'react'
import { Badge, Box, Chip, Tooltip } from '@mui/material'
import { IAllCoachesOption } from 'screens/AdminPanel/CoachAssignmentDataGrid/ChangeCoach/ChangeCoach'
import { theme } from 'theme'

export interface IChangeCoachOptionProps {
  muiProps: HTMLAttributes<HTMLLIElement | HTMLDivElement>
  user: IAllCoachesOption
  rowNumber: number
}

export const ChangeCoachOption = ({
  muiProps,
  user: {
    coachAssignmentInfo: { activeAssignmentsCount, pendingAssignmentsCount },
    fullName
  },
  rowNumber
}: IChangeCoachOptionProps) => {
  const rowBaseStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '10px'
  }

  const rowDirtyStyles = {
    ...rowBaseStyles,
    backgroundColor: theme.palette['info-transparent'].light
  }

  let tooltipText = `${activeAssignmentsCount - (pendingAssignmentsCount || 0)} existing`
  if (pendingAssignmentsCount) tooltipText += ` + ${pendingAssignmentsCount} pending`

  return (
    <Box {...muiProps} style={pendingAssignmentsCount ? rowDirtyStyles : rowBaseStyles}>
      <Box sx={{ marginX: 1 }}>{`${rowNumber}.  ${fullName}`}</Box>
      <Badge color="error" badgeContent={pendingAssignmentsCount}>
        <Tooltip title={tooltipText}>
          <Chip label={activeAssignmentsCount} />
        </Tooltip>
      </Badge>
    </Box>
  )
}
