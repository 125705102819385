import { SelectSetting, ToggleSetting } from '.'
import { UserSettingsSection } from './UserSettingsSection'

export const Reminders = () => {
  const rows = [
    {
      label: 'New App Version',
      cells: [<ToggleSetting key="appVersionReminder" settingKey="appVersionReminder" />]
    },
    {
      label: 'Scan Reminders',
      cells: [<ToggleSetting key="reminders" settingKey="reminders" />]
    },
    {
      label: 'Scan Reminder Interval',
      cells: [
        <SelectSetting
          clientConfigKey="scanReminderOptions"
          key="scanReminder"
          settingKey="scanReminder"
        />
      ]
    }
  ]

  return <UserSettingsSection header="Reminders" rows={rows} />
}
