import { useState } from 'react'
import { Chip, Typography } from '@mui/material'
import { DEFAULT_GOALS } from 'utility/defaults'
import { IUserFragment, useClientConfigQuery, useCurrentUserQuery } from 'types'
import { MacroGoalsDailySlider, ToggleSetting, UserSettingsSection } from '.'

export const MACRO_GOALS_SETTINGS = {
  CALORIES_MAX: 5000,
  CARBS_MAX: 750,
  FAT_MAX: 750,
  PROTEIN_MAX: 300
}

type MacroGoalsDailyType = IUserFragment['settings']['macroGoalsDaily']

type MacroChange = {
  [key: string]: number
}

const getInitialMacroGoals = (macroGoalsDaily: MacroGoalsDailyType | undefined) => {
  const calories = macroGoalsDaily?.calories || DEFAULT_GOALS.calories
  let netCarbs = macroGoalsDaily?.netCarbs || DEFAULT_GOALS.netCarbs
  const carbs = macroGoalsDaily?.carbs || DEFAULT_GOALS.carbs
  const protein = macroGoalsDaily?.protein || DEFAULT_GOALS.protein
  const fat = macroGoalsDaily?.fat || DEFAULT_GOALS.fat

  if (carbs < netCarbs) {
    netCarbs = carbs
  }

  return { calories, carbs, fat, protein, netCarbs }
}
export const Nutrition = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const settings = currentUser?.settings

  const { data: clientConfigData } = useClientConfigQuery()

  const macroGoalsSettings = clientConfigData?.clientConfig.healthSettings.macroGoalSettings

  const caloriesMax = macroGoalsSettings?.caloriesMax || MACRO_GOALS_SETTINGS.CALORIES_MAX
  const proteinMax = macroGoalsSettings?.proteinMax || MACRO_GOALS_SETTINGS.PROTEIN_MAX
  const carbsMax = macroGoalsSettings?.carbsMax || MACRO_GOALS_SETTINGS.CARBS_MAX
  const fatMax = macroGoalsSettings?.fatMax || MACRO_GOALS_SETTINGS.FAT_MAX

  let showNetCarbs = settings?.showNetCarbs
  const macroGoalsDaily = settings?.macroGoalsDaily

  const [macroGoals, setMacroGoals] = useState(() => getInitialMacroGoals(macroGoalsDaily))

  const { calories, carbs, fat, protein, netCarbs } = macroGoals

  const onMacroGoalsChange = (change: MacroChange) => {
    setMacroGoals((prevMacroGoals) => ({
      ...prevMacroGoals,
      ...change
    }))
  }

  const proteinPercent = ((protein * 4) / calories) * 100
  const carbsPercent = ((carbs * 4) / calories) * 100
  const fatPercent = ((fat * 9) / calories) * 100
  const totalPercent = proteinPercent + carbsPercent + fatPercent
  const rows = [
    {
      label: 'Use Net Carbs',
      cells: [<ToggleSetting settingKey="showNetCarbs" key="showNetCarbs" />]
    },
    {
      label: 'Calories',
      cells: [
        <MacroGoalsDailySlider
          value={calories}
          max={caloriesMax}
          macroKey="calories"
          key="calories"
          onChange={onMacroGoalsChange}
        />
      ]
    },
    {
      label: 'Protein',
      cells: [
        <MacroGoalsDailySlider
          value={protein}
          max={proteinMax}
          macroKey="protein"
          key="protein"
          percent={proteinPercent}
          onChange={onMacroGoalsChange}
        />
      ]
    },
    {
      label: 'Carbs',
      cells: [
        <MacroGoalsDailySlider
          value={carbs}
          max={carbsMax}
          percent={carbsPercent}
          macroKey="carbs"
          key="carbs"
          onChange={onMacroGoalsChange}
        />
      ]
    },
    {
      label: 'Net Carbs',
      cells: [
        showNetCarbs ? (
          <MacroGoalsDailySlider
            value={netCarbs}
            max={carbs}
            macroKey="netCarbs"
            key="netCarbs"
            onChange={onMacroGoalsChange}
          />
        ) : (
          <Chip label="disabled" size="small" />
        )
      ]
    },
    {
      label: 'Fat',
      cells: [
        <MacroGoalsDailySlider
          value={fat}
          max={fatMax}
          percent={fatPercent}
          macroKey="fat"
          key="fat"
          onChange={onMacroGoalsChange}
        />
      ]
    }
  ]

  return (
    <UserSettingsSection
      header="Nutrition"
      rows={rows}
      headerSlotRight={
        <Typography color="initial" variant="caption">
          {totalPercent.toFixed(2)}% Allocated
        </Typography>
      }
    />
  )
}
