import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Tooltip } from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { useConfirm } from 'material-ui-confirm'
import {
  CoachesConversationScheduledMessageState,
  ICoachConversationScheduledMessage,
  useUpdateCoachConversationScheduledMessageMutation
} from 'types'

export const CancelMessageIcon = ({ message }: { message: ICoachConversationScheduledMessage }) => {
  const confirm = useConfirm()
  const [updateCoachMessage] = useUpdateCoachConversationScheduledMessageMutation({
    onError: (e) => {
      snackBarVisibleVar({
        open: true,
        message: e.message
      })
    },
    onCompleted: () => {
      snackBarVisibleVar({
        open: true,
        severity: 'success',
        message: 'Message Canceled'
      })
    },
    refetchQueries: ['allCoachMessages']
  })

  const cancelMessage = async () => {
    try {
      await confirm({
        description: 'Are you sure you want to cancel this message?'
      })
    } catch {
      return
    }

    await updateCoachMessage({
      variables: {
        scheduledMessageId: message.id,
        state: CoachesConversationScheduledMessageState.Canceled
      }
    })
  }

  return (
    <Tooltip title="Cancel Message" placement="left">
      <IconButton aria-label="cancel" onClick={cancelMessage}>
        <CloseIcon fontSize="small" color="error" />
      </IconButton>
    </Tooltip>
  )
}
