import { useRef } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import { IUserMin, useAllUsersAutocompleteLazyQuery, useCurrentUserQuery } from 'types'
import { getLabel } from './helpers/getLabel'

export interface ISearchUserProps {
  onUserSelect: (user: IUserMin) => void
  placeholderText?: string
}

export const SearchUser = ({ onUserSelect, placeholderText }: ISearchUserProps) => {
  const searchValue = useRef('')
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser

  const [searchUser, { data, loading }] = useAllUsersAutocompleteLazyQuery({
    variables: { query: searchValue.current }
  })

  const options = data?.allUsersAutocomplete.users ?? []

  const { handleInputChange } = useDebouncedSearch(searchUser, searchValue)

  const handleChange = (_: any, user: (typeof options)[number]) => {
    onUserSelect(user)
  }

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      options={options}
      isOptionEqualToValue={(userA, userB) => userA?.id === userB?.id}
      loading={loading}
      getOptionLabel={getLabel}
      clearOnEscape
      noOptionsText="No member was found =("
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={placeholderText || getLabel(user as IUserMin)}
        />
      )}
    />
  )
}
