import { Box, ListItem, styled } from '@mui/material'

export const ListItemBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  paddingTop: 0
})

export const ListItemStyled = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  borderLeftWidth: 5,
  borderLeftStyle: 'solid'
})
