import { gql } from '@apollo/client'
import { CoreAppointmentFields } from 'gql/fragments/appointment'

export const Appointment = gql`
  ${CoreAppointmentFields}

  query appointment($id: ID!) {
    appointment(id: $id) {
      ...CoreAppointmentFields
    }
  }
`
