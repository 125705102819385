import { useRef, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { Alert } from '@mui/material'
import { IModalProps } from 'hooks'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import { FlexBoxStyled } from 'utility/box'
import {
  IOrganization,
  useAllOrganizationsLazyQuery,
  useCurrentUserQuery,
  useUpdateUserOrganizationMutation,
  UserRole
} from 'types'

export const OrganizationDialog = ({ closeModal, modalState }: IModalProps) => {
  const searchValue = useRef('')
  const { data: currentUserData } = useCurrentUserQuery()
  const user = currentUserData?.currentUser
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>()
  const [failedMessage, setFailedMessage] = useState<string | null>()
  const [warningMessage, setWarningMessage] = useState<string | null>()

  const [searchOrganization, { data, loading }] = useAllOrganizationsLazyQuery({
    variables: { name: searchValue.current }
  })
  const { handleInputChange } = useDebouncedSearch(searchOrganization, searchValue)
  const options = data?.allOrganizations.organizations ?? []

  const handleChange = (_: any, organization: (typeof options)[number]) => {
    if (organization) {
      setSelectedOrganization(organization)
      setWarningMessage(getWarningMessage(organization))
    }
  }

  const getWarningMessage = (organization: IOrganization) => {
    let message

    // TODO: Do not rely on role for this messaging
    if (user?.role !== UserRole.User && organization.isNutrisense) {
      message =
        'This member is an admin. Changing their organization will result in them being able to view the data of any member'
    } else if (user?.role !== UserRole.User) {
      message = `This member is an admin. Changing their organization will result in them being able to view the data of any member in ${organization.name}.`
    } else {
      message = `Any admin in ${organization.name} will be able to view the data of the member.`
    }

    return message
  }

  const [updateUserOrganization, { loading: updateUserLoading }] =
    useUpdateUserOrganizationMutation({
      onError: (error: ApolloError) =>
        setFailedMessage(`Update organization failed: ${error.message}`),
      onCompleted: () => closeModalAndResetVars()
    })

  const handleUpdateUserOrganization = async () => {
    if (!user || !selectedOrganization) {
      return
    }

    if (selectedOrganization) {
      await updateUserOrganization({
        variables: { id: user.id, organizationId: selectedOrganization.id }
      })
      setSelectedOrganization(undefined)
    }
  }

  const closeModalAndResetVars = () => {
    setFailedMessage(null)
    setWarningMessage(null)
    setSelectedOrganization(undefined)
    closeModal()
  }

  return (
    <Dialog open={modalState} fullWidth onClose={closeModalAndResetVars}>
      <DialogTitle>Change an organization</DialogTitle>
      <DialogContent dividers>
        <FlexBoxStyled flexDirection="column" alignItems="center">
          <Grid container direction="column" spacing={3} padding={3} paddingTop={1}>
            {failedMessage && (
              <Grid item>
                <Alert severity="error">{failedMessage}</Alert>
              </Grid>
            )}
            {warningMessage && (
              <Grid item>
                <Alert severity="warning">{warningMessage}</Alert>
              </Grid>
            )}
            <Grid item>
              <Typography>Select an organization:</Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                fullWidth
                disableClearable
                clearOnEscape
                options={options}
                loading={loading}
                noOptionsText="No organization was found =("
                onChange={handleChange}
                onInputChange={handleInputChange}
                getOptionLabel={(org) => org.name}
                isOptionEqualToValue={(orgA, orgB) => orgA?.id === orgB?.id}
                getOptionDisabled={(org) => org.id === user?.organization?.id}
                renderInput={(params) => (
                  <TextField {...params} size="small" placeholder={user?.organization?.name} />
                )}
              />
            </Grid>
          </Grid>
        </FlexBoxStyled>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <LoadingButton
          loading={updateUserLoading}
          onClick={handleUpdateUserOrganization}
          disabled={!selectedOrganization}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
