import { Button } from '@mui/material'
import {
  getGridStringOperators,
  GridPreProcessEditCellProps,
  GridValueSetterParams
} from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { previewImageUrlVar } from 'context/approvalRequests/previewImageUrl'
import { startCase } from 'lodash'
import {
  formatDate,
  formatDateTime,
  formatStartCase,
  renderAsCheck,
  renderAsTextWithTooltip,
  renderAsUserHomePageLink
} from 'utility/dataGrid'
import {
  AddressState,
  IUser,
  IAddress,
  IInvoice,
  ICharge,
  IFulfillmentConfiguration,
  IApprovalRequest,
  UserApprovalRequestState,
  UserDynamicFilterField,
  UserSortField,
  IProductMin,
  UserSensorKind
} from 'types'

type IHqApprovalsUser = { [K in keyof IUser as K]?: IUser[K] } & {
  [K in keyof IAddress as `address.${K}`]?: IAddress[K]
} & {
  [K in keyof IInvoice as `lastCoreSubscription.latestInvoice.${K}`]?: IInvoice[K]
} & {
  [K in keyof IApprovalRequest as `lastPrescriptionApprovalRequest.${K}`]?: IApprovalRequest[K]
} & {
  [K in keyof ICharge as `lastCoreSubscription.latestInvoice.latestCharge.${K}`]?: ICharge[K]
} & {
  [K in keyof IFulfillmentConfiguration as `fulfillmentConfiguration.${K}`]?: IFulfillmentConfiguration[K]
} & {
  [K in keyof IFulfillmentConfiguration as `fulfillmentConfiguration.${K}`]?: IFulfillmentConfiguration[K]
} & {
  [K in keyof IProductMin as `lastCoreSubscription.primaryProduct.${K}`]?: IProductMin[K]
}

const US_ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const columns: (IDataGridColumn & {
  field: keyof IHqApprovalsUser | 'index'
})[] = [
  {
    field: 'id',
    headerName: 'User ID',
    width: 100,
    type: 'number'
  },
  {
    field: 'fullName',
    headerName: 'Name',
    width: 250,
    type: 'string',
    editable: true,
    renderCell: renderAsUserHomePageLink
  },
  {
    field: 'governmentIdCard',
    headerName: 'Health - Identification',
    width: 200,
    filterable: false,
    sortable: false,
    renderCell: ({ value }) => {
      if (!value) return

      const handlePreviewClick = () => {
        previewImageUrlVar(value)
      }

      return (
        <Button variant="text" color="success" onClick={handlePreviewClick}>
          preview
        </Button>
      )
    }
  },
  { field: 'email', headerName: 'Email', width: 250, editable: true },
  {
    field: 'lastPrescriptionApprovalRequest.state',
    headerName: 'Approval State',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(UserApprovalRequestState).map((value) => {
      return { label: startCase(value), value: value }
    }),
    width: 150,
    valueFormatter: formatStartCase,
    valueGetter: ({ row }) => row.lastPrescriptionApprovalRequest?.state,
    backendFilterName: UserDynamicFilterField.ApprovalRequestsState,
    backendSortName: UserSortField.ApprovalRequestsState
  },
  {
    field: 'lastAnsweredHealthSurveyQuestionKey',
    headerName: 'Last Answered HQ Question',
    width: 250,
    valueFormatter: formatStartCase,
    renderCell: (params) => renderAsTextWithTooltip(params, true),
    filterable: false,
    sortable: false,
    groupable: true
  },
  {
    field: 'lastPrescriptionApprovalRequest.createdAt',
    headerName: 'Approval State Changed At',
    type: 'dateTime',
    width: 200,
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row.lastPrescriptionApprovalRequest?.createdAt,
    backendFilterName: UserDynamicFilterField.ApprovalRequestsCreatedAt,
    backendSortName: UserSortField.ApprovalRequestsCreatedAt
  },
  {
    field: 'dexcomSensorPromptUiState',
    headerName: 'Sensor Selection Pending',
    sortable: false,
    width: 200,
    type: 'boolean',
    renderCell: renderAsCheck,
    valueGetter: ({ row }) => !!row.dexcomSensorPromptUiState
  },
  {
    field: 'lastCoreSubscription.latestInvoice.latestCharge.created',
    headerName: 'Payment Charge Date',
    width: 150,
    type: 'date',
    valueFormatter: formatDate,
    valueGetter: ({ row }) => row.lastCoreSubscription?.latestInvoice?.latestCharge?.created
  },
  {
    field: 'fulfillmentConfiguration.sensorKind',
    headerName: 'Sensor Kind',
    width: 150,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(UserSensorKind),
    valueGetter: ({ row }) =>
      row['fulfillmentConfiguration.sensorKind'] || row.fulfillmentConfiguration?.sensorKind,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, fulfillmentConfiguration: { ...row.fulfillmentConfiguration } }
      rowCopy.fulfillmentConfiguration.sensorKind = value
      return rowCopy
    }
  },
  {
    field: 'lastCoreSubscription.primaryProduct.title',
    headerName: 'Product',
    width: 250,
    backendSortName: UserSortField.LastCoreSubscriptionPrimaryProduct,
    backendFilterName: UserDynamicFilterField.LastCoreSubscriptionPrimaryProduct,
    valueGetter: ({ row }) => row.lastCoreSubscription.primaryProduct.title,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
  },
  { field: 'dateOfBirth', headerName: 'DOB', width: 100, type: 'date', editable: true },
  { field: 'age', headerName: 'Age', width: 100, filterable: false, sortable: false },
  { field: 'phoneNumber', headerName: 'Phone Number', width: 150, editable: true },
  {
    field: 'address.street',
    headerName: 'Address - Street',
    width: 250,
    editable: true,
    valueGetter: ({ row }) => row.address?.street,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, address: { ...row.address } }
      rowCopy.address.street = value
      return rowCopy
    }
  },
  {
    field: 'address.street2',
    headerName: 'Address - Street 2',
    width: 200,
    editable: true,
    valueGetter: ({ row }) => row['address.street2'] || row.address?.street2,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, address: { ...row.address } }
      rowCopy.address.street2 = value
      return rowCopy
    }
  },
  {
    field: 'address.city',
    headerName: 'Address - City',
    width: 200,
    editable: true,
    valueGetter: ({ row }) => row['address.city'] || row.address?.city,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, address: { ...row.address } }
      rowCopy.address.city = value
      return rowCopy
    }
  },
  {
    field: 'address.state',
    headerName: 'Address - State',
    width: 150,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.values(AddressState).sort(),
    valueGetter: ({ row }) => row['address.state'] || row.address?.state,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, address: { ...row.address } }
      rowCopy.address.state = value
      return rowCopy
    }
  },
  {
    field: 'address.zipCode',
    headerName: 'Address - Zip Code',
    width: 150,
    editable: true,
    valueGetter: ({ row }) => row['address.zipCode'] || row.address?.zipCode,
    valueSetter: ({ row, value }: GridValueSetterParams) => {
      const rowCopy = { ...row, address: { ...row.address } }
      rowCopy.address.zipCode = value
      return rowCopy
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const cellValue = params.props.value
      const hasError = !US_ZIP_CODE_REGEX.test(cellValue) || cellValue.toString() === '00000'
      return { ...params.props, error: hasError }
    }
  },
  {
    field: 'healthReject',
    headerName: 'Health - Reject',
    width: 200,
    filterable: false,
    sortable: false
  }
]
