import { Box, Card, Chip } from '@mui/material'
import { Button, styled, Typography } from '@mui/material'
import { theme } from 'theme'

// Represents count of spacings: Sensor Top spacing + chart top spacing + stats top spacing
// + eventsListVar top spacing
const VERTICAL_SPACINGS_COUNT = 4
export const DEFAULT_SPACING = 1
// will be 5 * 8 in px = 32px
export const VERTICAL_PADDINGS_PX = theme.spacing(VERTICAL_SPACINGS_COUNT * DEFAULT_SPACING)
export const SCROLL_HEIGHT_OFFSET = 10

export const StrongTypographyStyled = styled(Typography)({
  fontWeight: 700
})

export const ToolbarButton = styled(Button)((props: { active?: number }) => ({
  color: theme.palette.icon[props.active ? 'primary' : 'secondary']
}))

export const CardWithHeight = styled(Card)((props: { height: string }) => ({
  height: props.height
}))

export const ChipStyled = styled(Chip)({
  borderRadius: 10
})

export const ChipLabel = styled(Typography)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 10
})

export const MarkDownEditorAsTextField = styled(Box)({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  padding: '14px 14px 10px',
  fontSize: 14,
  p: {
    margin: 0
  },
  '&:hover': {
    borderColor: theme.palette['theme-alternative']['level-1']
  }
})
