import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { compareAsc, parseISO } from 'date-fns'
import { useAuthorizedFeature } from 'hooks'
import { compact } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import {
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation
} from 'types'

interface IUncancelSubscriptionForm {
  subscriptionId: string
  priority: OpsAccountRequestPriority
  note: string
}

export const UncancelSubscriptionModal = ({
  closeModal,
  modalState
}: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { handleSubmit, control, register, reset, formState } = useForm<IUncancelSubscriptionForm>({
    mode: 'onTouched',
    defaultValues: {
      subscriptionId: '',
      priority: OpsAccountRequestPriority.Regular,
      note: ''
    }
  })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessUncancelSubscription
  )

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser) {
    return null
  }

  const onClose = () => {
    reset()
    closeModal()
  }

  const subscriptions = compact(
    [currentUser.ongoingCoreSubscription, currentUser.ongoingDietitianSubscription].filter(
      (subscription) => {
        if (!subscription?.cancelAt) {
          return false
        }

        // returns 1 if dateA is after dateB
        // returns 0 if equal
        // returns -1 if dateA is before dateB
        const timestampComparison = compareAsc(
          parseISO(subscription?.cancelAt),
          parseISO(subscription?.commitmentEndAt)
        )

        const isCanceledAtCommitmentEnd = timestampComparison === 0

        const hasNoCommitmentEnd = !subscription?.commitmentEndAt

        // selects subscriptions where canceledAt is after commitmentEndAt
        const canceledAfterCommitmentEnd = timestampComparison === 1

        return isCanceledAtCommitmentEnd || canceledAfterCommitmentEnd || hasNoCommitmentEnd
      }
    )
  )

  if (subscriptions.length === 0) {
    return null
  }

  const updateSubscriptionHandler = async (formData: IUncancelSubscriptionForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.UncancelSubscription,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.UncancelSubscription,
          uncancelSubscription: {
            subscriptionId: formData.subscriptionId
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const innerForm = (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <InputLabel>Select Subscription</InputLabel>
      <Controller
        name="subscriptionId"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            {...register('subscriptionId', { required: true })}
            error={!!formState.errors.subscriptionId}
            label="Select Subscription"
          >
            <MenuItem value="" disabled>
              <em>Select Subscription</em>
            </MenuItem>
            {subscriptions.map((subscription) => (
              <MenuItem key={subscription.id} value={subscription.id}>
                {subscription.primaryProduct.title}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  )
  return (
    <AccountRequestModal<IUncancelSubscriptionForm>
      register={register}
      modalTitle="Uncancel Subscription"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(updateSubscriptionHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
