import { ApolloCache } from '@apollo/client'
import { snackBarVisibleVar } from 'context'
import {
  useLinkKnowledgeQuestionMutation,
  useUnlinkKnowledgeQuestionMutation,
  CoreKnowledgeQuestionFieldsFragmentDoc,
  IKnowledgeQuestion,
  ILinkKnowledgeQuestionMutation
} from 'types'

type LinkedKnowledgeQuestion = ILinkKnowledgeQuestionMutation['linkKnowledgeQuestion']

const handleCacheUpdate = (
  cache: ApolloCache<IKnowledgeQuestion>,
  updatedQuestion?: LinkedKnowledgeQuestion
) => {
  if (!updatedQuestion) {
    return
  }

  cache.updateFragment(
    {
      id: cache.identify(updatedQuestion),
      fragment: CoreKnowledgeQuestionFieldsFragmentDoc,
      overwrite: true
    },
    (_data) => updatedQuestion
  )
}

interface IQuestionLinking {
  onLinkingUpdated: (answers: LinkedKnowledgeQuestion['knowledgeAnswers']) => void
}

export const useQuestionLinking = ({ onLinkingUpdated }: IQuestionLinking) => {
  const [linkQuestion, { loading: linking }] = useLinkKnowledgeQuestionMutation({
    onError: (error) => {
      snackBarVisibleVar({ open: true, message: error.message })
    },
    update: (cache, { data }) => handleCacheUpdate(cache, data?.linkKnowledgeQuestion),
    onCompleted: ({ linkKnowledgeQuestion: { knowledgeAnswers } }) => {
      onLinkingUpdated(knowledgeAnswers)
    }
  })

  const [unlinkQuestion, { loading: unlinking }] = useUnlinkKnowledgeQuestionMutation({
    onError: (error) => {
      snackBarVisibleVar({ open: true, message: error.message })
    },
    update: (cache, { data }) => handleCacheUpdate(cache, data?.unlinkKnowledgeQuestion),
    onCompleted: ({ unlinkKnowledgeQuestion: { knowledgeAnswers } }) => {
      onLinkingUpdated(knowledgeAnswers)
    }
  })

  return {
    linkQuestion,
    unlinkQuestion,
    loading: linking || unlinking
  }
}
