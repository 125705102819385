import { Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import styled from 'styled-components'

export const EventCardTitleStyled = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical'
})

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '60vw'
  }
})
