import { ICurrentAdminQuery, IUser, Maybe } from 'types'

export const getPerformedByFullName = (
  performedBy?: Maybe<IUser>,
  currentAdmin?: ICurrentAdminQuery['currentAdmin']
) => {
  if (performedBy && performedBy.id === currentAdmin?.id) return 'You'

  return performedBy?.fullName
}
