import { Box, Slider, Stack, Typography } from '@mui/material'
import { useSaveUserSetting } from 'hooks'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { startCase } from 'lodash'
import { IMacroGoalsDaily } from 'types'

const STEPS = {
  calories: 50,
  protein: 5,
  carbs: 5,
  netCarbs: 5,
  fat: 5
}

type MacroChange = {
  [key: string]: number
}

interface IMacroGoalsDailySlider {
  value: number
  max: number
  macroKey: keyof IMacroGoalsDaily
  percent?: number
  onChange: (change: MacroChange) => void
}

export const MacroGoalsDailySlider = ({
  value,
  max,
  percent,
  macroKey,
  onChange
}: IMacroGoalsDailySlider) => {
  const { track } = useSegmentInteraction()
  const saveUserSettings = useSaveUserSetting(() =>
    track(`${startCase(macroKey)} has been updated`)
  )

  const handleChangeCommitted = (_event: Event, sliderValue: number) => {
    saveUserSettings({ macroGoalsDaily: { [macroKey]: sliderValue } })
  }

  const handleChange = (_event: Event, sliderValue: number) => onChange({ [macroKey]: sliderValue })

  return (
    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="overline" color="text.disabled">
        <Box width="45px">{percent || percent === 0 ? `${percent.toFixed(2)}%` : ''}</Box>
      </Typography>
      <Slider
        value={value}
        max={max}
        min={0}
        onChangeCommitted={handleChangeCommitted}
        onChange={handleChange}
        step={STEPS[macroKey]}
        size="small"
      />
      <Typography variant="overline">{value}</Typography>
    </Stack>
  )
}
