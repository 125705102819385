import { Circle } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import DangerousIcon from '@mui/icons-material/Dangerous'
import EditIcon from '@mui/icons-material/Edit'
import { Box, CircularProgress, Tooltip } from '@mui/material'

interface ISaveStatusProps {
  fontSize?: number
  loading: boolean
  pending: boolean
  error: boolean | undefined
  called: boolean
}

const NO_CHANGES_TOOLTIP = 'No changes in this field'
const LOADING_TOOLTIP = 'Sending changes to the server'
const ERROR_TOOLTIP = 'Error saving your changes'
const SAVED_TOOLTIP = 'Changes were saved'
const PENDING = 'There are pending edits to save'
const CIRCLE_SCALE_FACTOR = 0.6

export const SaveStatus = ({
  fontSize = 18,
  loading,
  pending,
  error,
  called: finished
}: ISaveStatusProps) => {
  const renderIcon = () => {
    let tooltipTitle = NO_CHANGES_TOOLTIP
    let icon = (
      // circle icon looks too big comparing with other icons.
      <Circle sx={{ fontSize: fontSize * CIRCLE_SCALE_FACTOR, opacity: 0.5 }} color="disabled" />
    )

    if (loading) {
      tooltipTitle = LOADING_TOOLTIP
      icon = <CircularProgress size={fontSize} />
    } else if (pending) {
      tooltipTitle = PENDING
      icon = <EditIcon sx={{ fontSize }} color="primary" />
    } else if (error) {
      tooltipTitle = ERROR_TOOLTIP
      icon = <DangerousIcon sx={{ fontSize }} color="error" />
    } else if (finished) {
      tooltipTitle = SAVED_TOOLTIP
      icon = <CheckIcon sx={{ fontSize }} color="success" />
    }

    return (
      <Tooltip title={tooltipTitle}>
        <Box display="flex">{icon}</Box>
      </Tooltip>
    )
  }

  return renderIcon()
}
