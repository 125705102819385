import { ReactNode } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import { EditorItem } from './types'

interface IEditorProps<T> extends Omit<GridRenderEditCellParams, 'row'> {
  title: string
  row: T
  items: T[]
  children: ReactNode
}

export function Editor<T extends EditorItem>({ title, row, field, children }: IEditorProps<T>) {
  const apiRef = useGridApiContext()

  const handleDone = () => {
    apiRef.current.stopCellEditMode({ id: row?.id, field, ignoreModifications: true })
  }

  return (
    <Dialog fullWidth open maxWidth="lg" onClose={handleDone}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container height={600} spacing={4}>
          {children}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDone}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}
