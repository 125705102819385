// Apply these styles to truncate string contents after maxLines
//
export const lineClampStyles = (maxLines: number): Record<string, any> => ({
  display: '-webkit-box',
  overflow: 'hidden',
  whiteSpace: 'normal',
  maxLines,
  lineClamp: maxLines,
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: 'vertical'
})
