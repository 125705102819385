import { makeVar } from '@apollo/client'
import { AlertColor, SnackbarOrigin } from '@mui/material'

export interface ISnackBarContext extends Partial<SnackbarOrigin> {
  open: boolean
  severity?: AlertColor
  message?: string
  handleClose?: () => void
}

export const DEFAULT_SNACKBAR_CONTEXT: ISnackBarContext = {
  open: false,
  severity: 'info',
  message: '',
  handleClose: () => {}
}

export const snackBarVisibleVar = makeVar<ISnackBarContext>(DEFAULT_SNACKBAR_CONTEXT)
