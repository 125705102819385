import { useState } from 'react'
import { Typography } from '@mui/material'
import { IGridDynamicFilter } from 'components/DataGrid/types'
import { snackBarVisibleVar } from 'context'
import escalatedRequestsGridVars from 'context/accountRequests/escalatedRequestsGridVars'
import failedRequestsGridVars from 'context/accountRequests/failedRequestsGridVars'
import processedRequestsGridVars from 'context/accountRequests/processedRequestsGridVars'
import rejectedRequestsGridVars from 'context/accountRequests/rejectedRequestsGridVars'
import reviewedRequestsGridVars from 'context/accountRequests/reviewedRequestsGridVars'
import {
  ESCALATED_REQUESTS_DATA_GRID_STATE_KEY,
  PROCESSED_REQUESTS_DATA_GRID_STATE_KEY,
  REJECTED_REQUESTS_DATA_GRID_STATE_KEY,
  FAILED_REQUESTS_DATA_GRID_STATE_KEY,
  REVIEWED_REQUESTS_DATA_GRID_STATE_KEY,
  ESCALATED_DEFAULT_FILTER,
  FAILED_DEFAULT_FILTER,
  PROCESSED_DEFAULT_FILTER,
  REJECTED_DEFAULT_FILTER,
  REVIEWED_DEFAULT_FILTER,
  ESCALATED_COLUMNS_MAP,
  REVIEWED_COLUMNS_MAP,
  FAILED_COLUMNS_MAP,
  PROCESSED_COLUMNS_MAP,
  REJECTED_COLUMNS_MAP
} from 'screens/AccountRequests/constants'
import { DataGrid } from 'screens/AccountRequests/DataGrid'
import { useEscalatedRequestsActionButtons } from 'screens/AccountRequests/hooks/useEscalatedRequestsActionButtons'
import { useProcessedRequestsActionButtons } from 'screens/AccountRequests/hooks/useProcessedRequestsActionButtons'
import { useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import { getCommonListQueryVariablesWithDefaults } from 'utility/dataGrid/getCommonListQueryVariablesWithDefaults'
import {
  OpsAccountRequestStatus,
  useAllAccountRequestsQuery,
  useExportAccountRequestsDataMutation,
  useUpdateAccountRequestMutation
} from 'types'

export interface IAccountRequestGridDynamicFilter extends IGridDynamicFilter {
  value: OpsAccountRequestStatus
}

export const useAccountRequestsTabs = () => {
  const [selectedTab, setSelectedTab] = useState(OpsAccountRequestStatus.Escalated)
  const escalatedGridVars = useReactiveGridVars(escalatedRequestsGridVars)
  const processedGridVars = useReactiveGridVars(processedRequestsGridVars)

  const [exportAccountRequestData] = useExportAccountRequestsDataMutation({
    onError: () =>
      snackBarVisibleVar({
        open: true,
        severity: 'error',
        message: 'There was an error exporting account request data. Please try again later.'
      }),
    onCompleted: () =>
      snackBarVisibleVar({
        open: true,
        severity: 'success',
        message: 'Account request data is being prepared. You will receive an email shortly.'
      })
  })

  const { data: escalatedData, refetch: refetchEscalatedRequests } = useAllAccountRequestsQuery({
    variables: {
      ...getCommonListQueryVariablesWithDefaults<IAccountRequestGridDynamicFilter>(
        escalatedGridVars,
        [ESCALATED_DEFAULT_FILTER]
      )
    }
  })

  const { data: processedData, refetch: refetchProcessedRequests } = useAllAccountRequestsQuery({
    variables: {
      ...getCommonListQueryVariablesWithDefaults<IAccountRequestGridDynamicFilter>(
        processedGridVars,
        [PROCESSED_DEFAULT_FILTER]
      )
    }
  })

  const [updateAccountRequest] = useUpdateAccountRequestMutation({
    onCompleted: () => {
      refetchEscalatedRequests()
      refetchProcessedRequests()
    },
    refetchQueries: ['AllAccountRequests']
  })

  const { totalCount: escalatedTotalCount } = escalatedData?.allAccountRequests || {}
  const { totalCount: processedTotalCount } = processedData?.allAccountRequests || {}

  const escalatedRequestsActionButtons = useEscalatedRequestsActionButtons(updateAccountRequest)
  const processedRequestsActionButtons = useProcessedRequestsActionButtons(updateAccountRequest)

  return {
    tabs: [
      {
        label: (
          <Typography>
            Escalated {escalatedTotalCount ? `(${escalatedTotalCount})` : null}
          </Typography>
        ),
        content: (
          <DataGrid
            defaultFilter={ESCALATED_DEFAULT_FILTER}
            gridVars={escalatedRequestsGridVars}
            stateKey={ESCALATED_REQUESTS_DATA_GRID_STATE_KEY}
            actionButtons={escalatedRequestsActionButtons}
            columnsMap={ESCALATED_COLUMNS_MAP}
          />
        ),
        onTabSelected: () => setSelectedTab(OpsAccountRequestStatus.Escalated)
      },
      {
        label: (
          <Typography>
            Processed {processedTotalCount ? `(${processedTotalCount})` : null}
          </Typography>
        ),
        content: (
          <DataGrid
            defaultFilter={PROCESSED_DEFAULT_FILTER}
            gridVars={processedRequestsGridVars}
            stateKey={PROCESSED_REQUESTS_DATA_GRID_STATE_KEY}
            actionButtons={processedRequestsActionButtons}
            columnsMap={PROCESSED_COLUMNS_MAP}
          />
        ),
        onTabSelected: () => setSelectedTab(OpsAccountRequestStatus.Processed)
      },
      {
        label: 'Reviewed',
        content: (
          <DataGrid
            defaultFilter={REVIEWED_DEFAULT_FILTER}
            gridVars={reviewedRequestsGridVars}
            stateKey={REVIEWED_REQUESTS_DATA_GRID_STATE_KEY}
            columnsMap={REVIEWED_COLUMNS_MAP}
          />
        ),
        onTabSelected: () => setSelectedTab(OpsAccountRequestStatus.Reviewed)
      },
      {
        label: 'Rejected',
        content: (
          <DataGrid
            defaultFilter={REJECTED_DEFAULT_FILTER}
            gridVars={rejectedRequestsGridVars}
            stateKey={REJECTED_REQUESTS_DATA_GRID_STATE_KEY}
            columnsMap={REJECTED_COLUMNS_MAP}
          />
        ),
        onTabSelected: () => setSelectedTab(OpsAccountRequestStatus.Rejected)
      },
      {
        label: 'Failed',
        content: (
          <DataGrid
            defaultFilter={FAILED_DEFAULT_FILTER}
            gridVars={failedRequestsGridVars}
            stateKey={FAILED_REQUESTS_DATA_GRID_STATE_KEY}
            columnsMap={FAILED_COLUMNS_MAP}
          />
        ),
        onTabSelected: () => setSelectedTab(OpsAccountRequestStatus.Failed)
      }
    ],
    selectedTab,
    exportAccountRequestData
  }
}
