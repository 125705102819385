import { getCommonListQueryVariables, ReactiveGridVars } from 'utility/dataGrid/commonVariables'
import { IUser } from 'types'

const userFilter = (user: IUser) => ({
  field: 'user_id',
  operation: 'eq',
  value: user.id
})

export const getCommonListQueryVariablesForUser = (
  reactiveGridVars: ReactiveGridVars,
  user: IUser | null | undefined
) => {
  if (!user) return getCommonListQueryVariables(reactiveGridVars)

  return getCommonListQueryVariables({
    ...reactiveGridVars,
    dynamicFilters: [...reactiveGridVars.dynamicFilters, userFilter(user)]
  })
}
