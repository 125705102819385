import { ApolloLink, NextLink, Operation } from '@apollo/client'

export const USER_ID_HEADER = 'X-USER-ID-HEADER'

const OPERATIONS_WITHOUT_USER_ID = ['signinUser', 'allUsersAutocomplete']
class UserIdLink extends ApolloLink {
  public userId: string | undefined
  request(operation: Operation, forward: NextLink) {
    operation.setContext((ctx: Record<string, any>) => {
      const { headers = {} } = ctx
      if (this.userId && !OPERATIONS_WITHOUT_USER_ID.includes(operation.operationName)) {
        headers[USER_ID_HEADER] = this.userId
      }

      return {
        headers
      }
    })
    return forward(operation)
  }
}

export const userIdLink = new UserIdLink()
