import React from 'react'
import { Card, CardContent } from '@mui/material'
import { NutrisenseLogo } from 'components'
import { ImmersiveScreenContainerStyled } from '.'
import { ImmersiveLogoStyled } from './styles'

interface IImmersiveScreen {
  children: React.ReactNode
}

export const ImmersiveScreen = ({ children }: IImmersiveScreen) => {
  return (
    <ImmersiveScreenContainerStyled>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <ImmersiveLogoStyled>
            <NutrisenseLogo />
          </ImmersiveLogoStyled>
          {children}
        </CardContent>
      </Card>
    </ImmersiveScreenContainerStyled>
  )
}
