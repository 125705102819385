export const DEFAULT_GOALS = {
  calories: 2000,
  protein: 75,
  carbs: 150,
  fat: 75,
  netCarbs: 110
}

export const IMPERIAL_GLUCOSE_LOW_THRESHOLD = 70
export const IMPERIAL_GLUCOSE_HIGH_THRESHOLD = 140

export const METRIC_GLUCOSE_LOW_THRESHOLD = 3.9
export const METRIC_GLUCOSE_HIGH_THRESHOLD = 7.8

export const DEFAULT_USER_SETTINGS = {
  healthKitSync: true,
  googleFitSync: true,
  ketoMojoSync: false,
  reminders: true,
  showNetCarbs: true,
  scanReminder: 8,
  glucoseLowThreshold: IMPERIAL_GLUCOSE_LOW_THRESHOLD,
  glucoseHighThreshold: IMPERIAL_GLUCOSE_HIGH_THRESHOLD,
  ketoneLowThreshold: 1,
  ketoneHighThreshold: 5,
  dashboardGraph: 'glucose',
  unitSystem: 'imperial',
  fastingStart: 20,
  fastingEnd: 8,
  macroGoalsDaily: DEFAULT_GOALS,
  appVersionReminder: true
}

export const SESSION_INACTIVE_RESCUE_TIME = 30_000
