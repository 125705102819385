import { Box } from '@mui/material'
import styled from 'styled-components'
import { FlexboxProps } from 'styled-system'
import { FlexBoxStyled } from 'utility/box'

export const ImmersiveScreenContainerStyled = styled(FlexBoxStyled)<FlexboxProps>`
  flex-direction: column;
  margin: auto;
  height: 100vh;
  align-items: center;
  padding-top: 200px;
  max-width: 500px;
`

export const ImmersiveLogoStyled = styled(Box)<FlexboxProps>`
  padding-top: 20px;
  margin: auto;
  width: 60%;
`
