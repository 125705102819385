import { KnowledgeResource } from 'knowledgeResource.types'
import { useNavigate } from 'react-router-dom'
import { IKnowledgeAnswer } from 'types'
import { AnswerTitleChip } from './AnswerTitleChip'
import { LinkedItemsCellBox } from './styles'
import { getLinkToKnowledgeItem } from './utils'

interface ILinkedAnswersCellProps {
  answers: IKnowledgeAnswer[]
}

export const LinkedAnswersCell = ({ answers }: ILinkedAnswersCellProps) => {
  const navigate = useNavigate()

  const handleClick = (answer: IKnowledgeAnswer) => {
    navigate(getLinkToKnowledgeItem(KnowledgeResource.Answers, answer))
  }

  return (
    <LinkedItemsCellBox>
      {answers.map((answer) => (
        <AnswerTitleChip key={answer.id} title={answer.title} onClick={() => handleClick(answer)} />
      ))}
    </LinkedItemsCellBox>
  )
}
