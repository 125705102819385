import { useMemo } from 'react'
import { Annotation, Chart, LineAdvance, Point, ShapeAttrs } from 'bizcharts'
import { getChartData } from 'components/Events/EventCard/helpers/getChartData'
import { IMPERIAL_UNIT_SYSTEM, useTimeZone, useUnitSystem } from 'context'
import { getUnixTime } from 'date-fns'
import { first, last, sortBy } from 'lodash'
import { theme } from 'theme'
import { TWELVE_HOURS_TIME_FORMAT } from 'utility/timeFormats'
import { IEntryGlucose } from 'types'

const GRAPH_HEIGHT = 140

const TIME_TEXT_DELTA = 0.2

const LAST_MEASUREMENT_KEY = 'lastMeasurement'
const PEAK_MEASUREMENT_KEY = 'peakMeasurement'
const FIRST_MEASUREMENT_KEY = 'firstMeasurement'
const DUAL_MEASUREMENT_KEY = 'dualMeasurementKey'

const TIME_TEXT_STYLE = {
  fontSize: 12,
  textAlign: 'center'
}

const POINTS_SIZE = {
  [PEAK_MEASUREMENT_KEY]: 6,
  [FIRST_MEASUREMENT_KEY]: 4,
  [LAST_MEASUREMENT_KEY]: 4,
  [DUAL_MEASUREMENT_KEY]: 6
}

const POINT_PEAK_COLOR = 'rgb(254, 64, 113)'
const POINT_BORDER_COLOR = 'black'

const POINTS_STYLES = {
  [PEAK_MEASUREMENT_KEY]: {
    fill: POINT_PEAK_COLOR,
    stroke: POINT_BORDER_COLOR
  },
  [FIRST_MEASUREMENT_KEY]: {
    fill: theme.palette.graph['transparent-brand-primary'],
    stroke: POINT_BORDER_COLOR
  },
  [LAST_MEASUREMENT_KEY]: {
    fill: theme.palette.graph['transparent-brand-primary'],
    stroke: POINT_BORDER_COLOR
  },
  [DUAL_MEASUREMENT_KEY]: {
    fill: theme.palette.graph['transparent-brand-primary'],
    stroke: POINT_PEAK_COLOR,
    lineWidth: 2
  }
}

const getPointSize = (name: keyof typeof POINTS_SIZE) => {
  if (!name) return 0
  return POINTS_SIZE[name]
}

const getPointStyle = (name: keyof typeof POINTS_SIZE) => {
  return POINTS_STYLES[name]
}

const LINE_STYLE = {
  stroke: theme.palette.graph['transparent-brand-primary'],
  opacity: 1
}

export const TwoHourGraph = ({
  meal
}: {
  meal:
    | { occurredAt: string }
    | {
        glucose: IEntryGlucose
        occurredAt: string
      }
}) => {
  const { unitSystem } = useUnitSystem()

  const { formatWithTimeZone } = useTimeZone()

  const isImperial = unitSystem === IMPERIAL_UNIT_SYSTEM
  const precision = isImperial ? 0 : 1

  const { activityData, centeredChartData, startTimeTick, endTimeTick } = getChartData(meal)

  const pointsData = activityData.filter((point) => !point.fake)

  const firstPoint = first(pointsData)
  if (firstPoint) firstPoint.name = FIRST_MEASUREMENT_KEY

  const lastMeasurementPoint = last(pointsData)
  if (lastMeasurementPoint) lastMeasurementPoint.name = LAST_MEASUREMENT_KEY

  const peakMeasurementPoint = useMemo(() => last(sortBy(pointsData, 'y')), [pointsData])

  if (peakMeasurementPoint) {
    // sometimes the peak data point can be the same as the first (if the first is the highest)
    // or last (if the last is the highest) measurement.
    // In these cases, we use the DUAL_MEASUREMENT_KEY name to identify it and shows
    // a custom bullet with specific styles.
    peakMeasurementPoint.name = !peakMeasurementPoint.name
      ? PEAK_MEASUREMENT_KEY
      : DUAL_MEASUREMENT_KEY
  }

  const minPoint = useMemo(() => first(sortBy(centeredChartData, 'y')), [centeredChartData])

  const pointsLabel = {
    [FIRST_MEASUREMENT_KEY]: {
      content: firstPoint?.y.toFixed(precision)
    },
    [LAST_MEASUREMENT_KEY]: {
      content: lastMeasurementPoint?.y.toFixed(precision)
    },
    [PEAK_MEASUREMENT_KEY]: {
      content: peakMeasurementPoint?.y.toFixed(precision)
    },
    [DUAL_MEASUREMENT_KEY]: {
      content: peakMeasurementPoint?.y.toFixed(precision)
    }
  }

  const getPointLabel = (name: keyof typeof pointsLabel) => {
    if (!name) return { content: '' }
    return pointsLabel[name]
  }

  const chartData = centeredChartData.map((value) => ({ ...value, x: getUnixTime(value.x) }))

  const timeTextY =
    minPoint?.y && peakMeasurementPoint?.y
      ? minPoint?.y - (peakMeasurementPoint?.y - minPoint?.y) * TIME_TEXT_DELTA
      : 'min'

  return chartData.length > 0 ? (
    <Chart padding={[30]} autoFit height={GRAPH_HEIGHT} data={chartData} pure>
      <LineAdvance
        area
        position="x*y"
        style={{ opacity: 1, stroke: theme.palette.graph['transparent-brand-primary'] }}
        color="white"
      />
      <Point
        position="x*y"
        size={['name', getPointSize]}
        style={['name', getPointStyle]}
        label={['name', getPointLabel]}
      />
      <Annotation.Line
        start={[startTimeTick.x, 'min']}
        end={[startTimeTick.x, startTimeTick.y]}
        style={LINE_STYLE}
      />
      <Annotation.Text
        position={[startTimeTick.x, timeTextY]}
        content={formatWithTimeZone(startTimeTick.time, TWELVE_HOURS_TIME_FORMAT)}
        style={TIME_TEXT_STYLE as ShapeAttrs}
      />
      <Annotation.Line
        start={[endTimeTick.x, 'min']}
        end={[endTimeTick.x, endTimeTick.y]}
        style={LINE_STYLE}
      />
      <Annotation.Text
        position={[endTimeTick.x, timeTextY]}
        content={formatWithTimeZone(endTimeTick.time, TWELVE_HOURS_TIME_FORMAT)}
        style={TIME_TEXT_STYLE as ShapeAttrs}
      />
      <Annotation.RegionFilter
        top={false}
        start={[startTimeTick.x, 'min']}
        end={[endTimeTick.x, 'max']}
        color={theme.palette.graph['transparent-brand-primary']}
      />
    </Chart>
  ) : null
}
