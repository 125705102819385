import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { startCase } from 'lodash'
import { IKnowledgeTag } from 'types'

export const columns: (IDataGridColumn & {
  field?: keyof IKnowledgeTag | 'index' | 'actions'
})[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    type: 'number',
    valueFormatter: (params) => params.value
  },
  {
    field: 'value',
    headerName: 'Tag',
    editable: true,
    width: 300,
    type: 'string',
    valueFormatter: (params) => startCase(params.value)
  }
]

export const editableTagColumns = columns.filter(({ editable }) => editable)
