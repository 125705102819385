import { ApolloCache } from '@apollo/client'
import { snackBarVisibleVar } from 'context'
import {
  useLinkKnowledgeAnswerMutation,
  useUnlinkKnowledgeAnswerMutation,
  CoreKnowledgeAnswerFieldsFragmentDoc,
  IKnowledgeAnswer,
  ILinkKnowledgeAnswerMutation
} from 'types'

type LinkedKnowledgeAnswer = ILinkKnowledgeAnswerMutation['linkKnowledgeAnswer']

const handleCacheUpdate = (
  cache: ApolloCache<IKnowledgeAnswer>,
  updatedAnswer?: LinkedKnowledgeAnswer
) => {
  if (!updatedAnswer) {
    return
  }

  cache.updateFragment(
    {
      id: cache.identify(updatedAnswer),
      fragment: CoreKnowledgeAnswerFieldsFragmentDoc,
      overwrite: true
    },
    (_data) => updatedAnswer
  )
}

interface IAnswerLinking {
  onLinkingUpdated: (sources: LinkedKnowledgeAnswer['knowledgeSources']) => void
}

export const useAnswerLinking = ({ onLinkingUpdated }: IAnswerLinking) => {
  const [linkAnswer, { loading: linking }] = useLinkKnowledgeAnswerMutation({
    onError: (error) => {
      snackBarVisibleVar({ open: true, message: error.message })
    },
    update: (cache, { data }) => handleCacheUpdate(cache, data?.linkKnowledgeAnswer),
    onCompleted: ({ linkKnowledgeAnswer: { knowledgeSources } }) => {
      onLinkingUpdated(knowledgeSources)
    }
  })

  const [unlinkAnswer, { loading: unlinking }] = useUnlinkKnowledgeAnswerMutation({
    onError: (error) => {
      snackBarVisibleVar({ open: true, message: error.message })
    },
    update: (cache, { data }) => handleCacheUpdate(cache, data?.unlinkKnowledgeAnswer),
    onCompleted: ({ unlinkKnowledgeAnswer: { knowledgeSources } }) => {
      onLinkingUpdated(knowledgeSources)
    }
  })

  return {
    linkAnswer,
    unlinkAnswer,
    loading: linking || unlinking
  }
}
