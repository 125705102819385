import { BillingProduct, CoachAssignmentKind } from 'types'

export const COACH_ASSIGNMENT_DATA_GRID_STATE_KEY =
  'nutrisense:adminPanel:coachAssignmentDataGrid:state'

export const FORMATTED_COACH_ASSIGNMENTS: { [key in CoachAssignmentKind]: string } = {
  [CoachAssignmentKind.AnnualMembership]: 'Annual Membership',
  [CoachAssignmentKind.CoachingPlan]: 'Coaching Plan',
  [CoachAssignmentKind.Fixed_8Weeks]: '8 Weeks Fixed',
  [CoachAssignmentKind.Influencer]: 'Influencer',
  [CoachAssignmentKind.NonRecurring]: 'Non-recurring',
  [CoachAssignmentKind.Recurring_12Weeks]: '12 Weeks Recurring',
  [CoachAssignmentKind.Regular]: 'Regular',
  [CoachAssignmentKind.NoCoreSubscription]: 'No Core Subscription'
}

export const FORMATTED_DIETITIAN_PRODUCTS = {
  [BillingProduct.NutrisenseDietitian]: 'Unlimited Nutrition Coaching',
  [BillingProduct.NutrisenseDietitianLimited]: 'Complimentary Nutrition Coaching',
  [BillingProduct.NutritionCoachFocused]: 'Nutrition Coaching Program'
}
