import { useState } from 'react'
import { ChatPanel } from './ChatPanel'
import { SourcesPanel } from './SourcesPanel'
import { Modal } from './styles'

interface IChatbotAssistantProps {
  open: boolean
  onClose: () => void
}

export const ChatbotAssistant = ({ open, onClose }: IChatbotAssistantProps) => {
  const [messageId, setMessageId] = useState<number>()
  const [expanded, setExpanded] = useState(false)

  const handleClose = () => {
    setMessageId(undefined)
    onClose()
  }

  return (
    <Modal open={open} expanded={expanded}>
      <ChatPanel
        expanded={expanded}
        setExpanded={setExpanded}
        onShowSources={(id) => setMessageId(id)}
        onClose={handleClose}
      />
      {!!messageId && (
        <SourcesPanel messageId={messageId} onClose={() => setMessageId(undefined)} />
      )}
    </Modal>
  )
}
