import React, { useState } from 'react'

export const useMenu = () => {
  const [menuAnchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuState = Boolean(menuAnchorEl)
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return { menuAnchorEl, menuState, openMenu, closeMenu }
}
