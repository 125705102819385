import { BaseSyntheticEvent, useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PageviewIcon from '@mui/icons-material/Pageview'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { KnowledgeResource } from 'knowledgeResource.types'
import { Link } from 'react-router-dom'
import { openExternalLink } from 'utility'
import { IKnowledgeSource } from 'types'
import { LinkedItemsCellBox, LinkedSourceChip } from './styles'
import { getLinkToKnowledgeItem } from './utils'

interface ILinkedSourcesCellProps {
  sources: IKnowledgeSource[]
}

export const LinkedSourcesCell = ({ sources }: ILinkedSourcesCellProps) => {
  const [selectedSource, setSelectedSource] = useState<IKnowledgeSource>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: BaseSyntheticEvent, source: IKnowledgeSource) => {
    setAnchorEl(event.currentTarget)
    setSelectedSource(source)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedSource(undefined)
  }

  const handleOpenLink = () => {
    openExternalLink(selectedSource?.url)
    handleClose()
  }

  const sourceLink = getLinkToKnowledgeItem(KnowledgeResource.Sources, selectedSource)

  return (
    <LinkedItemsCellBox>
      {sources.map((source) => (
        <LinkedSourceChip
          key={source.id}
          label={source.title}
          variant="outlined"
          icon={<LinkIcon />}
          onClick={(e) => handleClick(e, source)}
        />
      ))}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleOpenLink}>
          <ListItemIcon>
            <OpenInNewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Open link in new tab</ListItemText>
        </MenuItem>
        <MenuItem component={Link} to={sourceLink}>
          <ListItemIcon>
            <PageviewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View in Health Portal</ListItemText>
        </MenuItem>
      </Menu>
    </LinkedItemsCellBox>
  )
}
