import escapeHtml from 'escape-html'
import { Node as SlateNode, Text } from 'slate'
import { jsx } from 'slate-hyperscript'
import { TypedDescendant } from './types'

export const serializeToJson = (value: TypedDescendant[]): string => {
  return JSON.stringify(value)
}

export const serializeToText = (value: TypedDescendant[]): string =>
  value
    .map((descendant) => {
      if (descendant.type === 'bulleted-list') {
        return serializeToText((descendant as any).children)
      }
      if (descendant.type === 'image') {
        return descendant.url
      }
      return SlateNode.string(descendant)
    })
    .join('\n')

export const deserialize = (value: string): TypedDescendant[] => {
  try {
    const parsed = JSON.parse(value)
    if (typeof parsed === 'object') return parsed
    return simpleStringToParagraphs(value)
  } catch (e) {
    return simpleStringToParagraphs(value)
  }
}

export const deserializeToText = (value: string): string => {
  try {
    const parsed = JSON.parse(value)
    if (typeof parsed === 'number') {
      return String(value)
    }
    return serializeToText(parsed)
  } catch (e) {
    return value
  }
}

const simpleStringToParagraphs = (value: string) => value.split('\n').map(newParagraph)

const newParagraph = (text: string): TypedDescendant => {
  return {
    type: 'paragraph',
    children: [{ text }]
  }
}

export const deserializeFromHtml = (el: HTMLElement | ChildNode, markAttributes = {}): any => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes: any = { ...markAttributes }

  // text nodes attributes
  switch (el.nodeName) {
    case 'B':
    case 'STRONG':
      nodeAttributes.bold = true
      break
    case 'I':
    case 'EM':
      nodeAttributes.italic = true
      break
    case 'U':
      nodeAttributes.underline = true
  }

  const children = Array.from(el.childNodes).flatMap((node) =>
    deserializeFromHtml(node, nodeAttributes)
  )

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: (el as HTMLElement).getAttribute('href') },
        children
      )
    case 'IMG':
      return jsx(
        'element',
        { type: 'image', url: (el as HTMLElement).getAttribute('src') },
        children
      )
    default:
      return children
  }
}

export const serializeToHtml = (node: any, forceEmptyParagraph: boolean = false) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if ((node as any).bold) {
      string = `<strong>${string}</strong>`
    }
    if ((node as any).italic) {
      string = `<em>${string}</em>`
    }
    if ((node as any).underline) {
      string = `<u>${string}</u>`
    }
    return string
  }

  const children = node.children.map((n: any) => serializeToHtml(n, forceEmptyParagraph)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${forceEmptyParagraph ? children || '<br>' : children}</p>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'image':
      return `<img src="${escapeHtml(node.url)}" />`
    default:
      return children
  }
}

// slate editor does not parse ZWNBSP (zero-width no-break space) correctly
// so it misleads nutritionists to think that there will be breaks between lines
// this is a workaround to replace new line followed with ZWNBSP with a normal space
export const replaceNewLineFollowedByNoBreakJoiner = (rawHtml: string) => {
  // eslint-disable-next-line no-control-regex
  return rawHtml.replace(/\u000A\uFEFF/g, ' ')
}

export const parseAndDeserializeFromHtml = (value?: string) => {
  if (!value) return deserialize('')

  const effectiveValue = replaceNewLineFollowedByNoBreakJoiner(value)
  const html = new DOMParser().parseFromString(effectiveValue, 'text/html').body

  return deserializeFromHtml(html)
}
