import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Button } from '@mui/material'
import { AccountRequestCancelSubscriptionSubtypes } from 'screens/AccountRequests/constants'
import { useCanApproveOrRejectAccountRequest } from 'screens/AccountRequests/hooks/useCanApproveOrRejectAccountRequest'
import { theme } from 'theme'
import {
  IAccountRequestPayloadUnionPresenterType,
  ICancelSubscriptionResponsePayload,
  OpsAccountRequestKind,
  OpsAccountRequestStatus
} from 'types'

export const useEscalatedRequestsActionButtons = (updateAccountRequest: (options: any) => void) => {
  const canApproveOrRejectAccountRequest = useCanApproveOrRejectAccountRequest()

  return (
    accountRequestId: string,
    accountRequestKind: OpsAccountRequestKind,
    accountRequestPayload: IAccountRequestPayloadUnionPresenterType
  ) => {
    let accountRequestSubtype

    if (accountRequestKind === OpsAccountRequestKind.CancelSubscription) {
      accountRequestSubtype = (accountRequestPayload as ICancelSubscriptionResponsePayload)
        .immediateCancellation
        ? AccountRequestCancelSubscriptionSubtypes.Immediate
        : AccountRequestCancelSubscriptionSubtypes.CommitmentEnd
    }
    const canApproveOrReject = canApproveOrRejectAccountRequest({
      accountRequestKind,
      accountRequestSubtype
    })

    const buttons = [
      <Button
        key="reject"
        variant="outlined"
        color="error"
        sx={{
          color: theme.palette.error.light,
          borderColor: theme.palette.error.light
        }}
        onClick={() =>
          updateAccountRequest({
            variables: { accountRequestId, status: OpsAccountRequestStatus.Rejected }
          })
        }
      >
        <CloseOutlinedIcon />
      </Button>
    ]

    if (accountRequestKind === OpsAccountRequestKind.MiscRequest) {
      buttons.unshift(
        <Button
          key="processed"
          variant="outlined"
          sx={{
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light
          }}
          onClick={() =>
            updateAccountRequest({
              variables: { accountRequestId, status: OpsAccountRequestStatus.Processed }
            })
          }
        >
          Mark as Processed
        </Button>
      )
    } else {
      buttons.unshift(
        <Button
          key="Approve"
          variant="outlined"
          sx={{
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light
          }}
          onClick={() =>
            updateAccountRequest({
              variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
            })
          }
          startIcon={<CheckOutlinedIcon />}
        >
          Approve
        </Button>
      )
    }

    return canApproveOrReject ? buttons : []
  }
}
