import { makeVar } from '@apollo/client'
import { IDateFilterVar } from 'context/types'
import { format } from 'date-fns'
import { YYYY_MM_DD } from 'utility/timeFormats'

const today = format(new Date(), YYYY_MM_DD)

export const dateFilterVar = makeVar<IDateFilterVar>({
  startDate: today,
  endDate: today
})
