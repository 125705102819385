import { useSegmentPage } from './segment'

export const useAnalytics = () => {
  const { trackPage: segmentTracKPage } = useSegmentPage()

  const trackPage = (nextPage: string) => {
    segmentTracKPage(nextPage)
  }

  return { trackPage }
}
