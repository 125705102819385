import { ReactNode } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Tooltip } from '@mui/material'
import { useClipboard } from 'hooks/useClipboard'
import { StackStyled } from './styles'

interface IWithCopyToClipboardAction {
  children: ReactNode
  value?: string
}

export const WithCopyToClipboardAction = ({ children, value = '' }: IWithCopyToClipboardAction) => {
  const { tooltipTitle, handleClick } = useClipboard(value)

  return (
    <StackStyled direction="row" gap={1}>
      {children}
      <Tooltip title={tooltipTitle} placement="right-end">
        <ContentCopyIcon aria-label="copy-to-clipboard" onClick={handleClick} />
      </Tooltip>
    </StackStyled>
  )
}
