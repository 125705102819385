import { useState } from 'react'
import { Box, Slider, Stack, Typography } from '@mui/material'
import { useSaveUserSetting } from 'hooks'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { DEFAULT_USER_SETTINGS } from 'utility/defaults'
import { useCurrentUserQuery } from 'types'
import { UserSettingsSection } from './UserSettingsSection'

export const FASTING_WINDOW_HOUR_MAP = [
  '12am',
  '01am',
  '02am',
  '03am',
  '04am',
  '05am',
  '06am',
  '07am',
  '08am',
  '09am',
  '10am',
  '11am',
  '12pm',
  '01pm',
  '02pm',
  '03pm',
  '04pm',
  '05pm',
  '06pm',
  '07pm',
  '08pm',
  '09pm',
  '10pm',
  '11pm',
  '12am',
  '01am',
  '02am',
  '03am',
  '04am',
  '05am',
  '06am',
  '07am',
  '08am',
  '09am',
  '10am',
  '11am',
  '12pm',
  '01pm',
  '02pm',
  '03pm',
  '04pm',
  '05pm',
  '06pm',
  '07pm',
  '08pm',
  '09pm',
  '10pm',
  '11pm',
  '12am'
]

const FASTING_MIN = 0
const FASTING_MAX = 48

export const FastingWindow = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const settings = currentUser?.settings

  const currentFastingStart = settings?.fastingStart || DEFAULT_USER_SETTINGS.fastingStart
  const currentFastingEnd = settings?.fastingEnd || DEFAULT_USER_SETTINGS.fastingEnd

  const [fastingWindow, setFastingWindow] = useState([currentFastingStart, currentFastingEnd])

  const newFastingStart = fastingWindow[0]
  const newFastingEnd = fastingWindow[1]

  const handleOnChange = (_event: Event, newFastingWindow: number[]) =>
    setFastingWindow([...newFastingWindow])

  const { track } = useSegmentInteraction()
  const saveUserSettings = useSaveUserSetting(() => track('Fasting window has been updated'))

  const handleChangeCommitted = (_event: Event, newFastingWindow: number[]) => {
    saveUserSettings({ fastingStart: newFastingWindow[0], fastingEnd: newFastingWindow[1] })
  }

  const rows = [
    {
      label: 'Fasting Window',
      cells: [
        <Stack
          key="fasting-window"
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="overline" color="text.disabled">
            <Box>{FASTING_WINDOW_HOUR_MAP[fastingWindow[0]]}</Box>
          </Typography>
          <Slider
            size="small"
            min={FASTING_MIN}
            max={FASTING_MAX}
            value={fastingWindow}
            onChange={handleOnChange}
            onChangeCommitted={handleChangeCommitted}
          />
          <Typography variant="overline" color="text.disabled">
            <Box>{FASTING_WINDOW_HOUR_MAP[fastingWindow[1]]}</Box>
          </Typography>
        </Stack>
      ]
    }
  ]

  return (
    <UserSettingsSection
      header="Fasting"
      rows={rows}
      headerSlotRight={
        <Typography color="initial" variant="caption">
          {newFastingEnd - newFastingStart} hours
        </Typography>
      }
    />
  )
}
