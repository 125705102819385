import { makeVar } from '@apollo/client'
import { INote } from 'types'

export const deleteNoteDialogVar = makeVar<{
  open: boolean
  event?: Pick<INote, 'id'> | undefined
}>({
  open: false,
  event: undefined
})
