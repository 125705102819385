export enum COLUMNS {
  '#' = 'index',
  NAME = 'fullName',
  EMAIL = 'email',
  COACH_ASSIGNMENT_KIND = 'lastCoachAssignment.kind',
  COACH_END_DATE = 'ongoingDietitianSubscription.cancelAt',
  PLAN_NICKNAME = 'ongoingDietitianSubscription.plans.nickname',
  DIETITIAN_SUBSCRIPTION_STATUS = 'lastDietitianSubscription.stripeStatus',
  SENSOR_COUNT = 'sensorInfo.totalCount',
  DIETITIAN_REQUEST = 'userProperties.dietitianRequest',
  HEALTH_GOAL = 'userProperties.healthGoal',
  ONE_MONTH_ACTIVE_FEEDBACK_DIETITIAN = 'userProperties.oneMonthActiveFeedbackDietitian',
  PRIMARY_GOAL = 'primaryGoal',
  TEST_GROUP = 'userProperties.testGroup',
  STRIPE_ID = 'stripeId',
  SCHEDULED_VIDEO_CALL_STATUS = 'userProperties.scheduledVideoCallStatus'
}
