import { useEffect, useState, ReactNode, BaseSyntheticEvent } from 'react'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Box } from '@mui/material'
import { Loader, SectionHeader } from 'components'
import { deserializeToText } from 'components/MarkdownEditor'
import { snackBarVisibleVar } from 'context'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { startCase } from 'lodash'
import { addElementToAllUserProperties } from 'utility/cache/addElementToAllUserProperties'
import { IUserProperty, useUpdateUserPropertyMutation } from 'types'
import { SaveStatus } from './SaveStatus'
import {
  SlotRightStyled,
  UserPropertyTextFieldWrapperStyled,
  UserPropertyTextFieldStyled
} from './styles'
import { UserPropertyEditDialog } from './UserPropertyEditDialog'

interface IUserPropertyEditProps {
  userProperty: IUserProperty
  userId?: string
  title?: string
  slim?: boolean
  topAdornment?: ReactNode | null
  multiline?: boolean
}

export const UserPropertyEdit = ({
  userProperty,
  title,
  slim,
  userId,
  topAdornment,
  multiline = true
}: IUserPropertyEditProps) => {
  const [dialogEditOpen, setDialogEditOpen] = useState(false)

  const [editing, setEditing] = useState(false)

  const { track } = useSegmentInteraction()

  const [initialValue, setInitialValue] = useState<string | null>(null)

  const [currentValue, setCurrentValue] = useState<string | null>(null)

  const [updateUserProperty, { loading, error, called }] = useUpdateUserPropertyMutation({
    onError: (_: Error) =>
      snackBarVisibleVar({
        severity: 'error',
        open: true,
        message: 'Nutritionist data field update failed. Please try again later.'
      }),
    onCompleted: () => {
      track(`${startCase(userProperty.kind)} field updated`)
      setInitialValue(currentValue)
    },
    update: (_cache, { data }) => {
      if (!data) return
      if (userProperty.id) return

      addElementToAllUserProperties(data)
    }
  })

  useEffect(() => {
    const value = deserializeToText(userProperty.value)
    setInitialValue(value)
    setCurrentValue(value)
  }, [userProperty])

  const label = title || startCase(userProperty.kind)

  const save = () => {
    setEditing(false)
    if (!initialValue && !currentValue) return
    if (initialValue === currentValue) return
    const updateParams = {
      variables: {
        kind: userProperty.kind,
        value: currentValue! as string,
        userId: userId
      }
    }

    updateUserProperty(updateParams)
  }

  const handleChange = (event: BaseSyntheticEvent) => {
    if (!editing) {
      setEditing(true)
    }
    setCurrentValue(event.target.value)
  }

  const slotRight = (
    <SlotRightStyled>
      <SaveStatus loading={loading} called={called} error={!!error} pending={editing} />
    </SlotRightStyled>
  )

  const sectionHeaderCommonProps = {
    label,
    slotRight
  }

  const userPropertyTextField =
    currentValue !== null ? (
      <UserPropertyTextFieldWrapperStyled>
        <UserPropertyTextFieldStyled
          onBlur={save}
          placeholder="empty"
          value={currentValue as string}
          onChange={handleChange}
          multiline={multiline}
        />
      </UserPropertyTextFieldWrapperStyled>
    ) : (
      <Loader />
    )

  if (slim)
    return (
      <>
        <Box onClick={() => setDialogEditOpen(true)} style={{ cursor: 'pointer', width: '100%' }}>
          {currentValue || <span>&nbsp;</span>}
        </Box>
        {topAdornment}
        <UserPropertyEditDialog
          open={dialogEditOpen}
          onClose={() => setDialogEditOpen(false)}
          content={
            <>
              <SectionHeader {...sectionHeaderCommonProps} />
              {userPropertyTextField}
            </>
          }
        />
      </>
    )

  return (
    <Box>
      <SectionHeader
        onTextHeaderClick={() => setDialogEditOpen(true)}
        slotLeft={<OpenInFullIcon sx={{ fontSize: 12 }} />}
        {...sectionHeaderCommonProps}
      />
      {topAdornment}
      {userPropertyTextField}

      <UserPropertyEditDialog
        open={dialogEditOpen}
        onClose={() => setDialogEditOpen(false)}
        content={
          <>
            <SectionHeader {...sectionHeaderCommonProps} />
            {userPropertyTextField}
          </>
        }
      />
    </Box>
  )
}
