import { Paper } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'
import { spacingHalf, spacingOne } from 'utility/spacings'

export const SectionHeaderStyled = styled(Paper)({
  backgroundColor: theme.palette['theme-primary']['level-1'],
  top: 0,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  marginBottom: spacingOne,
  marginTop: spacingOne,
  justifyContent: 'space-between',
  paddingLeft: spacingHalf,
  paddingRight: spacingOne,
  boxShadow: 'none'
})
