import { BaseSyntheticEvent, useState } from 'react'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { IEntryGlucose } from 'types'
import { MetricList } from './MetricList'
import { TwoHourGraphPopover } from './TwoHourGraph'

const GLUCOSE_METRICS = [
  { label: 'Peak', key: 'glucoseMax' },
  { label: 'AUC', key: 'aucScore' },
  { label: 'Delta', key: 'glucoseDelta' },
  { label: '2h Delta', key: 'glucose2hrDelta' }
]

interface IGlucoseMetricList {
  statistics: any
  event:
    | { occurredAt: string }
    | {
        glucose: IEntryGlucose
        occurredAt: string
      }
}

export const GlucoseMetricList = ({ event, statistics }: IGlucoseMetricList) => {
  const [twoHourGraphAnchorEl, setTwoHourGraphAnchorEl] = useState<HTMLElement | null>(null)
  const { track } = useSegmentInteraction()

  const showTwoHourGraph = (event: BaseSyntheticEvent) => {
    track('Show 2h graph popover')
    setTwoHourGraphAnchorEl(event.currentTarget)
  }

  const hideTwoHourGraph = () => {
    setTwoHourGraphAnchorEl(null)
  }

  return (
    <>
      <MetricList
        title="Glucose"
        onHeaderClick={showTwoHourGraph}
        slotRight={<ShowChartIcon sx={{ color: 'orange' }} />}
        data={statistics}
        metricEntries={GLUCOSE_METRICS}
      />

      <TwoHourGraphPopover
        event={event}
        anchorEl={twoHourGraphAnchorEl}
        onClose={hideTwoHourGraph}
      />
    </>
  )
}
