import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const StyledCalendar = styled(Paper)({
  height: '100%',
  overflowY: 'scroll',
  padding: '32px 16px',
  '& .rbc-toolbar, .rbc-time-header, .rbc-event-label': {
    display: 'none'
  },
  '& .rbc-time-view': {
    border: 'none'
  },
  '& .rbc-time-content': {
    borderTop: 'none',
    overflowY: 'inherit'
  },
  '& .rbc-time-content > * + * > *': {
    borderLeft: 'none'
  },
  '& .rbc-day-slot .rbc-time-slot': {
    borderTop: 'none'
  },
  '& .rbc-timeslot-group': {
    borderBottom: 'none',
    minHeight: '50px'
  },
  '& .rbc-day-slot > .rbc-timeslot-group': {
    borderTop: `1px solid ${theme.palette['disabled-button']['primary']}`
  },
  '& .rbc-label': {
    position: 'relative',
    top: '-12px',
    padding: '0 8px 0 0'
  },
  '& .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus': {
    outline: 'none'
  },
  '& .rbc-event': {
    borderRadius: 0
  },
  '& .rbc-current-time-indicator': {
    left: -64,
    right: -16,
    height: 2
  }
})

export const EventContent = styled(Box)((props: { active?: number }) => ({
  color: props.active ? theme.palette['text-color'].primary : theme.palette.danger.main
}))

export const EventTitle = styled(Typography)((props: { active?: number }) => ({
  textDecoration: props.active ? 'none' : 'line-through',
  textDecorationThickness: '5%'
}))

export const ClickableBox = styled(Box)({
  cursor: 'pointer',
  display: 'inline'
})

export const DatePickerStyled = styled(Box)({
  display: 'inline',
  '& .MuiFormControl-root': {
    opacity: 0,
    width: 0,
    height: 0
  }
})

export const PaddedPaper = styled(Paper)({
  padding: 16,
  height: '100%'
})

export const PaddedCard = styled(Card)({
  padding: 16
})

export const PaddedBox = styled(Box)({
  padding: '24px 0 16px'
})

export const LinkStyled = styled(Link)({
  color: theme.palette['text-color']['link']
})

export const ScrollableContent = styled(CardContent)({
  padding: 0,
  overflowY: 'scroll'
})

export const HistoryItem = styled(Typography)({
  paddingTop: 8
})

export const GreyButton = styled(Button)({
  color: theme.palette.getContrastText(theme.palette['disabled-button'].primary),
  backgroundColor: theme.palette['disabled-button'].primary,
  '&:hover': {
    backgroundColor: theme.palette['disabled-button']['alternative-border']
  }
})

export const MeetingStatusSelectStyled = styled(Select)({
  minWidth: 220
})

export const ActualDurationTextFieldStyled = styled(TextField)({
  width: 250
})
