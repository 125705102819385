import { useCallback, useEffect } from 'react'
import { useBugsnagInit } from 'hooks/analytics/bugsnag/useBugsnagInit'
import { useImpersonatedCurrentUser } from 'hooks/useImpersonatedCurrentUser'
import { IUserMin } from 'types'

export const useBugsnagMember = () => {
  const { data: { currentUser } = {} } = useImpersonatedCurrentUser()

  const { BugsnagClient } = useBugsnagInit()

  const setMember = useCallback(
    (user: { id: string; fullName: string; email: string }) => {
      const { id, fullName, email } = user
      BugsnagClient?.setUser(id, email, fullName)
    },
    [BugsnagClient]
  )

  useEffect(() => {
    if (currentUser) {
      const user = {
        email: currentUser.email,
        fullName: currentUser.fullName,
        id: currentUser.id
      } as IUserMin

      setMember(user)
    }
  }, [currentUser, setMember])

  // TODO: issue - we're overriding impersonated member with admin
  return { setMember }
}
