import { makeVar } from '@apollo/client'
import { IGraphDataPoint } from 'screens/UserDetails/Graph/types'
import { IAllEventsMealFragment, IJournalEntry, INote } from 'types'

export const saveEventDialogVar = makeVar<{
  open: boolean
  graphDataPoint?: IGraphDataPoint | undefined
  event?: IJournalEntry | INote | IAllEventsMealFragment | undefined
}>({
  open: false,
  graphDataPoint: undefined,
  event: undefined
})
