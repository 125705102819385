import { HTMLAttributes, useMemo } from 'react'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid'
import { client } from 'config/apolloClient'
import { ChangeCoachOption } from 'screens/AdminPanel/CoachAssignmentDataGrid/ChangeCoach/ChangeCoachOption'
import { getAllCoachFilters } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/allCoachesFilters'
import { computeDirtyAssignmentOptions } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/computeDirtyAssignmentOptions'
import { getLastCoachAssignment } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/getLastCoachAssignment'
import { getPreviousCoach } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/getPreviousCoach'
import {
  CoachAssignmentState,
  CoachAssignmentSupportType,
  ICoachAssignment,
  IQueryType,
  IUser,
  useAdminPanelAllCoachesQuery
} from 'types'

export interface IChangeCoachProps {
  refetchQueryName?: keyof IQueryType
  updateUnsavedCoachAssignment?: (
    id: string,
    userId: string,
    newCoach: IUser,
    supportType: CoachAssignmentSupportType
  ) => void
  unsavedCoachAssignments: {
    [key: string]: {
      userId: string
      supportType: CoachAssignmentSupportType
      coachId: string
      coachFullName: string
    }
  }
}

export interface IChangeCoachGridInjectedProps {
  rowId: number
  userId: string
  supportType: CoachAssignmentSupportType
  field: string
  existingCoachAssignments: ICoachAssignment[]
}

export type IAllCoachesOption = {
  id: string
  fullName: string
  coachAssignmentInfo: {
    activeAssignmentsCount: number
    pendingAssignmentsCount?: number
  }
}

export const ChangeCoach = ({
  rowId,
  userId,
  supportType,
  field,
  existingCoachAssignments,
  refetchQueryName,
  updateUnsavedCoachAssignment,
  unsavedCoachAssignments
}: IChangeCoachProps & IChangeCoachGridInjectedProps) => {
  const apiRef = useGridApiContext()

  const lastCoachAssignment = getLastCoachAssignment(existingCoachAssignments)
  const { coach: previousCoach } = getPreviousCoach(existingCoachAssignments)

  const handleChange = async (_: any, newCoach: IUser) => {
    if (!updateUnsavedCoachAssignment) return

    apiRef.current.stopCellEditMode({ id: rowId, field })
    updateUnsavedCoachAssignment(rowId.toString(), userId, newCoach, supportType)
  }

  const refetchData = async () => {
    if (refetchQueryName) {
      await client.refetchQueries({
        include: [refetchQueryName]
      })
    }
  }

  const { data, loading } = useAdminPanelAllCoachesQuery({
    variables: getAllCoachFilters(),
    fetchPolicy: 'network-only'
  })

  const options: IAllCoachesOption[] = useMemo(
    () => data?.allUsers.users ?? [],
    [data?.allUsers.users]
  )

  const dirtyOptions: IAllCoachesOption[] = useMemo(
    () => computeDirtyAssignmentOptions(options, unsavedCoachAssignments),
    [options, unsavedCoachAssignments]
  )

  return (
    <>
      {previousCoach && lastCoachAssignment?.state === CoachAssignmentState.Pending && (
        <Chip
          sx={{ marginX: 1 }}
          onClick={() => handleChange(undefined, previousCoach)}
          size="small"
          label={previousCoach.fullName}
        />
      )}
      <Autocomplete
        autoSelect
        fullWidth
        options={dirtyOptions}
        loading={loading}
        getOptionLabel={(user: IAllCoachesOption) => user.fullName}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, user: IAllCoachesOption) => {
          const optionIndex = dirtyOptions.findIndex((coach) => coach.id === user.id)
          return (
            <ChangeCoachOption
              key={`coach_option_${user.id}`}
              muiProps={props}
              user={user}
              rowNumber={optionIndex + 1}
            />
          )
        }}
        ListboxProps={{ style: { minHeight: '95vh' } }} // needed for setting the popper height; setting it in componentsProps gets overwritten somehow
        componentsProps={{
          popper: {
            placement: 'auto-start',
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  padding: 20
                }
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 8]
                }
              }
            ],
            style: {
              width: 375
            }
          },
          paper: {
            style: {
              backgroundColor: '#FAFAFA',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }
          }
        }}
        onOpen={refetchData} // covers use case where NM's use more than one tab to complete members assignments
        clearOnEscape
        noOptionsText="No Nutritionist was found"
        onChange={handleChange}
        renderInput={({ inputProps, ...rest }) => {
          return <TextField {...rest} inputProps={{ ...inputProps }} />
        }}
      />
    </>
  )
}
