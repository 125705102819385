import { startCase } from 'lodash'
import { SubscriptionStatus } from 'types'

export const SubscriptionStatusToMaskLookup = {
  [SubscriptionStatus.Trialing]: 'complimentary'
}

export const formatWithSubscriptionStatusMask = (value: string | undefined) => {
  if (!value) {
    return ''
  }

  const maskedValue =
    SubscriptionStatusToMaskLookup[value as keyof typeof SubscriptionStatusToMaskLookup] || value
  return startCase(maskedValue)
}
