import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { uniqBy } from 'lodash'
import { LinkedAnswersCell } from 'screens/Knowledge/LinkedAnswersCell'
import { LinkedQuestionsCell } from 'screens/Knowledge/LinkedQuestionsCell'
import { IKnowledgeAnswer, IKnowledgeSource, KnowledgeSourceDynamicFilterField } from 'types'

const sourceColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeSource
})[] = [
  {
    field: 'title',
    headerName: 'Source',
    sortable: false,
    editable: true,
    width: 300,
    type: 'string',
    valueFormatter: (params) => params.value
  },
  {
    field: 'url',
    headerName: 'URL',
    editable: true,
    width: 300,
    type: 'string',
    valueFormatter: (params) => params.value
  }
]

export const associationColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeSource | 'knowledgeQuestions'
})[] = [
  {
    field: 'knowledgeAnswers',
    backendFilterName: KnowledgeSourceDynamicFilterField.AnswersTitle,
    headerName: 'Answers',
    sortable: false,
    width: 360,
    renderCell: ({ value }) => <LinkedAnswersCell answers={value} />
  },
  {
    field: 'knowledgeQuestions',
    backendFilterName: KnowledgeSourceDynamicFilterField.AnswersQuestionsValue,
    headerName: 'Questions',
    sortable: false,
    width: 360,
    valueGetter: ({ row }) => {
      const answers: IKnowledgeAnswer[] = row.knowledgeAnswers
      return uniqBy(
        answers.flatMap((answer) => answer.knowledgeQuestions),
        'id'
      )
    },
    renderCell: ({ value }) => <LinkedQuestionsCell questions={value} />
  }
]

export const hiddenColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeSource
})[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    width: 100,
    valueFormatter: (params) => params.value
  }
]

export const columns = [...sourceColumns, ...associationColumns, ...hiddenColumns]

export const editableSourceColumns = sourceColumns.filter(({ editable }) => editable)
