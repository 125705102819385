import { CSSProperties } from 'react'
import { Box, CircularProgress } from '@mui/material'

export interface ILoaderProps {
  styles?: CSSProperties
}

export const Loader = ({ styles }: ILoaderProps) => (
  <Box
    sx={{
      zoom: 0.7,
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      ...styles
    }}
  >
    <CircularProgress />
  </Box>
)
