import { useEffect, useState } from 'react'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import { AppEnv, env } from 'config'

export const useSegment = () => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)

  useEffect(() => {
    if (env.REACT_APP_ENV === AppEnv.Production && (!analytics || !analytics.initialized)) {
      const loadAnalytics = async () => {
        let [response] = await AnalyticsBrowser.load({
          writeKey: env.REACT_APP_SEGMENT_API_KEY || ''
        })

        setAnalytics(response)
      }

      loadAnalytics()
    }
  }, [analytics])

  return { analytics }
}
