import { LineClampedBox } from 'components'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { EditTitleField } from 'screens/Knowledge/Answers/EditTitleField'
import { LinkedSourcesEditCell } from 'screens/Knowledge/Answers/LinkedSourcesEditCell'
import { AnswerTitleChip } from 'screens/Knowledge/AnswerTitleChip'
import { LinkedQuestionsCell } from 'screens/Knowledge/LinkedQuestionsCell'
import { LinkedSourcesCell } from 'screens/Knowledge/LinkedSourcesCell'
import { IKnowledgeAnswer, KnowledgeAnswerDynamicFilterField } from 'types'

export const answerColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeAnswer
})[] = [
  {
    field: 'title',
    headerName: 'Title',
    editable: true,
    width: 300,
    renderCell: ({ value }) => <AnswerTitleChip title={value} />
  },
  {
    field: 'value',
    headerName: 'Answer',
    type: 'string',
    editable: true,
    width: 420,
    renderCell: ({ value }) => <LineClampedBox maxLines={3}>{value}</LineClampedBox>,
    renderEditCell: (props) => <EditTitleField {...props} />
  }
]

export const associationColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeAnswer
})[] = [
  {
    field: 'knowledgeQuestions',
    backendFilterName: KnowledgeAnswerDynamicFilterField.QuestionsValue,
    headerName: 'Questions',
    sortable: false,
    width: 300,
    renderCell: ({ value }) => <LinkedQuestionsCell questions={value} />
  },
  {
    field: 'knowledgeSources',
    backendFilterName: KnowledgeAnswerDynamicFilterField.SourcesTitle,
    headerName: 'Sources',
    sortable: false,
    editable: true,
    width: 300,
    renderCell: ({ value }) => <LinkedSourcesCell sources={value} />,
    renderEditCell: (props) => <LinkedSourcesEditCell {...props} />
  }
]

export const hiddenColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeAnswer
})[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    width: 100,
    valueFormatter: (params) => params.value
  }
]

export const columns = [...answerColumns, ...associationColumns, ...hiddenColumns]

export const editableAnswerColumns = answerColumns.filter(({ editable }) => editable)
