import { ButtonProps } from '@mui/material'
// issue in mui-nested-menu, that's why we're importing from index
import { NestedDropdown, MenuItemData } from 'mui-nested-menu/index'
import { ReplacementRequestErrorType, ReplacementRequestReplacementReason } from 'types'

export const FORMATTED_REPLACEMENT_REQUEST_ERROR_TYPE = {
  [ReplacementRequestErrorType.Malfunction]: 'Malfunction',
  [ReplacementRequestErrorType.ReturnToSender]: 'Return to sender',
  [ReplacementRequestErrorType.UserError]: 'User Error',
  [ReplacementRequestErrorType.Unknown]: 'Unknown'
}

enum UserErrorType {
  BloodOrMedicalRelated = 'Blood or Medical-related',
  UserErrorWithHardware = 'User error with hardware',
  BandagePatch = 'Bandage/Patch'
}

enum MalfunctionErrorType {
  Hardware = 'Hardware',
  DataSoftware = 'Data/software'
}

enum ReturnToSenderErrorType {
  Shipping = 'Shipping'
}

enum ReplacementPaymentType {
  Paid = 'Paid',
  Free = 'Free'
}

export const FORMATTED_REPLACEMENT_REQUEST_REPLACEMENT_REASON = {
  [ReplacementRequestReplacementReason.ActivationError_0Reading]: 'Activation Error - 0 Reading',
  [ReplacementRequestReplacementReason.ActivationErrorActivationLoop]:
    'Activation Error - Activation Loop',
  [ReplacementRequestReplacementReason.ActivationErrorNoScans]: 'Activation Error - No Scans',
  [ReplacementRequestReplacementReason.ApplicationErrorDeploymentEarlyDeployment]:
    'Application Error Deployment - Early Deployment',
  [ReplacementRequestReplacementReason.ApplicationErrorDeploymentSensorStuck]:
    'Application Error Deployment - Sensor Stuck',
  [ReplacementRequestReplacementReason.ApplicationErrorNeedleBent]:
    'Application Error - Needle Bent',
  [ReplacementRequestReplacementReason.ApplicationErrorNeedleFellOut]:
    'Application Error - Needle Fell Out',
  [ReplacementRequestReplacementReason.ApplicationErrorNeedleStuck]:
    'Application Error - Needle Stuck',
  [ReplacementRequestReplacementReason.ApplicationErrorSensorPlacement]:
    'Application Error - Sensor Placement',
  [ReplacementRequestReplacementReason.ApplicationErrorUnknown]: 'Application Error (Unknown)',
  [ReplacementRequestReplacementReason.CalibrationErrorAbnormalData]:
    'Calibration Error - Abnormal Data',
  [ReplacementRequestReplacementReason.CalibrationErrorHighValues]:
    'Calibration Error - High Values',
  [ReplacementRequestReplacementReason.CalibrationErrorLowValues]: 'Calibration Error - Low Values',
  [ReplacementRequestReplacementReason.DamageSportsActivity]: 'Damage - Sports/Activity',
  [ReplacementRequestReplacementReason.DamageWaterSwimmingSauna]: 'Damage - Water/Swimming/Sauna',
  [ReplacementRequestReplacementReason.DamagedInTransit]: 'Damaged in Transit',
  [ReplacementRequestReplacementReason.DexcomSensorFailure]: 'Dexcom Sensor Failure',
  [ReplacementRequestReplacementReason.DiscomfortDrewBlood]: 'Discomfort - Drew Blood',
  [ReplacementRequestReplacementReason.DiscomfortGeneralPain]: 'Discomfort - General pain',
  [ReplacementRequestReplacementReason.DiscomfortSkinIrritation]: 'Discomfort - Skin Irritation',
  [ReplacementRequestReplacementReason.FellOffAdhesiveMalfunction]:
    'Fell Off - Adhesive Malfunction',
  [ReplacementRequestReplacementReason.FellOffBumped]: 'Fell Off - Bumped',
  [ReplacementRequestReplacementReason.FellOffChanging]: 'Fell Off - Changing',
  [ReplacementRequestReplacementReason.FellOffDueToPatch]: 'Fell Off - Due to Patch',
  [ReplacementRequestReplacementReason.FellOffShowering]: 'Fell Off - Showering',
  [ReplacementRequestReplacementReason.FellOffSleeping]: 'Fell Off - Sleeping',
  [ReplacementRequestReplacementReason.FellOffUnknown]: 'Fell Off - Unknown',
  [ReplacementRequestReplacementReason.FellOffWorkingOut]: 'Fell Off - Working Out',
  [ReplacementRequestReplacementReason.HealthMriXRayImaging]: 'Health - MRI/X-ray/Imaging',
  [ReplacementRequestReplacementReason.HealthSurgery]: 'Health - Surgery',
  [ReplacementRequestReplacementReason.MissingLost]: 'Missing/Lost',
  [ReplacementRequestReplacementReason.Other]: 'Other',
  [ReplacementRequestReplacementReason.ProductEarlyDecline]: 'Product - Early Decline',
  [ReplacementRequestReplacementReason.ProductSensorMissing]: 'Product - Sensor Missing',
  [ReplacementRequestReplacementReason.Refused]: 'Refused',
  [ReplacementRequestReplacementReason.RemovedWOTroubleshooting]: 'Removed w/o Troubleshooting',
  [ReplacementRequestReplacementReason.Retention]: 'Retention',
  [ReplacementRequestReplacementReason.ScanningError_0Reading]: 'Scanning Error - 0 Reading',
  [ReplacementRequestReplacementReason.ScanningErrorNfc]: 'Scanning Error (NFC)',
  [ReplacementRequestReplacementReason.SentExpiredSensor]: 'Sent Expired Sensor',
  [ReplacementRequestReplacementReason.Stolen]: 'Stolen',
  [ReplacementRequestReplacementReason.Unclaimed]: 'Unclaimed',
  [ReplacementRequestReplacementReason.WrongAddress]: 'Wrong Address'
}

export const buildMenuItemsData = ({
  label,
  onSelect
}: {
  label?: string
  onSelect?: ({
    errorType,
    replacementReason
  }?: {
    errorType: ReplacementRequestErrorType
    replacementReason: string
  }) => void
} = {}): MenuItemData => {
  const buildItems =
    (errorType: ReplacementRequestErrorType) =>
    (replacementReason: ReplacementRequestReplacementReason) => {
      return {
        label: FORMATTED_REPLACEMENT_REQUEST_REPLACEMENT_REASON[replacementReason],
        callback: () => {
          onSelect?.({ errorType, replacementReason })
        }
      }
    }

  const buildUserErrorItems = buildItems(ReplacementRequestErrorType.UserError)

  const buildMalfunctionItems = buildItems(ReplacementRequestErrorType.Malfunction)

  const buildReturnToSenderItems = buildItems(ReplacementRequestErrorType.ReturnToSender)

  return {
    label,
    items: [
      {
        label: FORMATTED_REPLACEMENT_REQUEST_ERROR_TYPE[ReplacementRequestErrorType.UserError],
        items: [
          {
            label: UserErrorType.BloodOrMedicalRelated,
            items: [
              {
                label: ReplacementPaymentType.Free,
                items: [
                  ReplacementRequestReplacementReason.DiscomfortDrewBlood,
                  ReplacementRequestReplacementReason.DiscomfortGeneralPain,
                  ReplacementRequestReplacementReason.DiscomfortSkinIrritation,
                  ReplacementRequestReplacementReason.HealthSurgery
                ].map(buildUserErrorItems)
              },
              {
                label: ReplacementPaymentType.Paid,
                items: [ReplacementRequestReplacementReason.HealthMriXRayImaging].map(
                  buildUserErrorItems
                )
              }
            ]
          },
          {
            label: UserErrorType.UserErrorWithHardware,
            items: [
              {
                label: ReplacementPaymentType.Paid,
                items: [
                  ReplacementRequestReplacementReason.ApplicationErrorDeploymentEarlyDeployment,
                  ReplacementRequestReplacementReason.ApplicationErrorSensorPlacement,
                  ReplacementRequestReplacementReason.DamageSportsActivity,
                  ReplacementRequestReplacementReason.DamageWaterSwimmingSauna,
                  ReplacementRequestReplacementReason.FellOffBumped,
                  ReplacementRequestReplacementReason.FellOffChanging,
                  ReplacementRequestReplacementReason.FellOffShowering,
                  ReplacementRequestReplacementReason.FellOffSleeping,
                  ReplacementRequestReplacementReason.FellOffUnknown,
                  ReplacementRequestReplacementReason.FellOffWorkingOut,
                  ReplacementRequestReplacementReason.RemovedWOTroubleshooting
                ].map(buildUserErrorItems)
              }
            ]
          },
          {
            label: UserErrorType.BandagePatch,
            items: [
              {
                label: ReplacementPaymentType.Paid,
                items: [ReplacementRequestReplacementReason.FellOffDueToPatch].map(
                  buildUserErrorItems
                )
              }
            ]
          }
        ]
      },
      {
        label: FORMATTED_REPLACEMENT_REQUEST_ERROR_TYPE[ReplacementRequestErrorType.Malfunction],
        items: [
          {
            label: MalfunctionErrorType.Hardware,
            items: [
              {
                label: ReplacementPaymentType.Free,
                items: [
                  ReplacementRequestReplacementReason.ApplicationErrorDeploymentSensorStuck,
                  ReplacementRequestReplacementReason.ApplicationErrorNeedleFellOut,
                  ReplacementRequestReplacementReason.ApplicationErrorNeedleStuck,
                  ReplacementRequestReplacementReason.ApplicationErrorNeedleBent,
                  ReplacementRequestReplacementReason.DexcomSensorFailure,
                  ReplacementRequestReplacementReason.FellOffAdhesiveMalfunction
                ].map(buildMalfunctionItems)
              }
            ]
          },
          {
            label: MalfunctionErrorType.DataSoftware,
            items: [
              {
                label: ReplacementPaymentType.Free,
                items: [
                  ReplacementRequestReplacementReason.ActivationError_0Reading,
                  ReplacementRequestReplacementReason.ActivationErrorActivationLoop,
                  ReplacementRequestReplacementReason.ActivationErrorNoScans,
                  ReplacementRequestReplacementReason.CalibrationErrorAbnormalData,
                  ReplacementRequestReplacementReason.CalibrationErrorHighValues,
                  ReplacementRequestReplacementReason.CalibrationErrorLowValues,
                  ReplacementRequestReplacementReason.ProductEarlyDecline,
                  ReplacementRequestReplacementReason.ScanningError_0Reading
                ].map(buildMalfunctionItems)
              }
            ]
          }
        ]
      },
      {
        label: FORMATTED_REPLACEMENT_REQUEST_ERROR_TYPE[ReplacementRequestErrorType.ReturnToSender],
        items: [
          {
            label: ReturnToSenderErrorType.Shipping,
            items: [
              {
                label: ReplacementPaymentType.Free,
                items: [
                  ReplacementRequestReplacementReason.ProductSensorMissing,
                  ReplacementRequestReplacementReason.DamagedInTransit,
                  ReplacementRequestReplacementReason.MissingLost,
                  ReplacementRequestReplacementReason.SentExpiredSensor
                ].map(buildReturnToSenderItems)
              },
              {
                label: ReplacementPaymentType.Paid,
                items: [
                  ReplacementRequestReplacementReason.Stolen,
                  ReplacementRequestReplacementReason.WrongAddress,
                  ReplacementRequestReplacementReason.Unclaimed,
                  ReplacementRequestReplacementReason.Refused
                ].map(buildReturnToSenderItems)
              }
            ]
          }
        ]
      }
    ]
  }
}

interface IErrorTypeSelectProps {
  label: string
  onSelect: ({
    errorType,
    replacementReason
  }: {
    errorType: ReplacementRequestErrorType
    replacementReason: ReplacementRequestReplacementReason
  }) => void
  ButtonProps?: ButtonProps
}

export function ErrorTypeSelect(props: IErrorTypeSelectProps) {
  const { label, onSelect, ButtonProps } = props
  return (
    <NestedDropdown
      menuItemsData={buildMenuItemsData({
        label,
        onSelect
      })}
      MenuProps={{ elevation: 3 }}
      ButtonProps={ButtonProps}
    />
  )
}
