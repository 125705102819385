import { pickBy, startCase } from 'lodash'
import { IAddress } from 'types'

export const getAddressText = (address: Partial<IAddress>): string =>
  Object.keys(sanitizedAddressFields(address))
    .reduce((acc, key) => {
      acc.push(`${startCase(key)}: ${address[key as keyof IAddress]}`)
      return acc
    }, [] as string[])
    .join('\n')

const sanitizedAddressFields = (address: Partial<IAddress>): Partial<IAddress> => {
  const keysToRemove = ['__typename', 'name']
  return pickBy(address || {}, (value: string, key: string) => {
    return value !== '' && !keysToRemove.includes(key)
  })
}
