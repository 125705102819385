import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { Loader } from 'components'
import { IKnowledgeAnswer } from 'types'
import { SourcesBrowser } from './SourcesBrowser'
import { PanelContentContainer, PanelHeader, PanelTitle, SourcesPanelContainer } from './styles'
import { useMessageAnswers } from './useMessageAnswers'

interface ISourcesPanelProps {
  messageId: number
  onClose: () => void
}

export const SourcesPanel = ({ messageId, onClose }: ISourcesPanelProps) => {
  const { answers, loading } = useMessageAnswers(messageId.toString())

  return (
    <SourcesPanelContainer>
      <PanelHeader>
        <PanelTitle>Knowledge Sources</PanelTitle>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </PanelHeader>
      <PanelContentContainer>
        {loading ? <Loader /> : <SourcesBrowser answers={answers as IKnowledgeAnswer[]} />}
      </PanelContentContainer>
    </SourcesPanelContainer>
  )
}
