import Bugsnag from '@bugsnag/js'
import { ChatConversationMessageSenderKind, IChatConversationMessage } from 'types'

export const getSenderFullName = (message: IChatConversationMessage) => {
  switch (message.senderKind) {
    case ChatConversationMessageSenderKind.User:
      return message.sender?.fullName
    case ChatConversationMessageSenderKind.Automated:
      return 'Automated Message'
    case ChatConversationMessageSenderKind.Unknown:
      return 'Unknown Sender'
    default:
      Bugsnag.notify('MessageEvent.getSenderFullName - unknown senderKind - ' + message.senderKind)
      return ''
  }
}
