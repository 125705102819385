import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from '@mui/material'
import { SidebarIcon } from './styles'

interface IChatbotAssistantSidebarItemProps {
  active: boolean
  onClick: () => void
}
export const ChatbotAssistantSidebarItem = ({
  active = false,
  onClick
}: IChatbotAssistantSidebarItemProps) => {
  return (
    <Tooltip title="Health Portal AI" placement="right-end">
      <SidebarIcon active={active} onClick={onClick}>
        <HelpOutlineIcon />
      </SidebarIcon>
    </Tooltip>
  )
}
