import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Stack } from '@mui/material'
import { cache } from 'config/apolloClient'
import { saveEventDialogVar, snackBarVisibleVar } from 'context'
import { EventTypes } from 'event.types'
import { useConfirm } from 'material-ui-confirm'
import { evictFromCacheById } from 'utility/cache/evictFromCacheById'
import {
  IAllEventsMealFragment,
  MealSource,
  useCurrentAdminQuery,
  useDeleteMealMutation
} from 'types'
import { GlucoseScoreAvatar } from './GlucoseScoreAvatar'

const ON_ERROR_MESSAGE = 'Unable to delete the meal. Please try again later.'

interface IMealToolbar {
  event: IAllEventsMealFragment
}
export const MealToolbar = ({ event }: IMealToolbar) => {
  const confirm = useConfirm()
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()

  const [deleteMealMutation] = useDeleteMealMutation({
    variables: { id: event?.id },
    onError: () => snackBarVisibleVar({ open: true, message: ON_ERROR_MESSAGE }),
    onCompleted: ({ deleteMeal: { id } }) => evictFromCacheById(cache, id, EventTypes.Meal),
    refetchQueries: ['allStats']
  })

  const handleDelete = () => {
    confirm({
      description: 'Are you sure you want to delete a meal?'
    })
      .then(() => {
        deleteMealMutation()
      })
      .catch(() => {})
  }

  return (
    <Stack direction="row" alignItems="center">
      {event.source === MealSource.HealthPortal && event.mealCreatedBy?.id === currentAdmin?.id && (
        <>
          <IconButton size="small" onClick={() => saveEventDialogVar({ open: true, event })}>
            <EditIcon />
          </IconButton>

          <IconButton size="small" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </>
      )}
      {event.statistics?.glucoseScore && (
        <GlucoseScoreAvatar glucoseScore={event.statistics?.glucoseScore} />
      )}
    </Stack>
  )
}
