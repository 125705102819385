import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import {
  formatDate,
  renderAsCheck,
  renderAsExternalLink,
  renderAsPossiblyEmptyField
} from 'utility/dataGrid'
import { IInvoice, ISubscription, IUser } from 'types'

type IFinancialsInvoicesUser = { [K in keyof IInvoice as K]?: IInvoice[K] } & {
  [K in keyof ISubscription as `subscription.${K}`]?: ISubscription[K]
} & {
  [K in keyof IUser as `user.${K}`]?: IUser[K]
}

export const columns: (IDataGridColumn & { field?: keyof IFinancialsInvoicesUser })[] = [
  {
    field: 'downloadUrl',
    headerName: 'Action',
    width: 150,
    type: 'string',
    description: 'Download Invoice',
    renderCell: renderAsExternalLink('Download Invoice')
  },
  {
    field: 'stripeId',
    headerName: 'Stripe Id',
    width: 250,
    type: 'string',
    description: 'Stripe Id'
  },
  {
    field: 'created',
    headerName: 'Created',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    description: 'Created',
    valueFormatter: formatDate
  },
  {
    field: 'subscription.stripeId',
    headerName: 'Stripe Subscription Id',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    groupable: true,
    renderCell: renderAsPossiblyEmptyField,
    valueGetter: ({ row }) => row.subscription?.stripeId,
    description: 'Stripe Subscription Id'
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 100,
    type: 'number',
    description: 'Total'
  },
  {
    field: 'paidAt',
    headerName: 'Paid',
    width: 100,
    align: 'center',
    type: 'boolean',
    renderCell: renderAsCheck,
    groupable: true,
    description: 'Indicating if invoice is paid',
    valueGetter: (params) => !!params.value
  },
  {
    field: 'receiptNumber',
    headerName: 'Receipt Number',
    width: 150,
    type: 'string',
    description: 'Receipt Number'
  }
]
