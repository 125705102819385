import { useReactiveVar } from '@apollo/client'
import { Card, Drawer, Grid } from '@mui/material'
import { Loader } from 'components'
import { useGraphDateRange } from 'hooks'
import { StatKey } from 'stats.types'
import { IKeyValueStringArray, IStatistic, useAllStatsQuery } from 'types'
import { statsDrawerVisibleVar } from './context'
import { StatCard } from './StatCard'
import { StatsDrawerContent } from './StatsDrawerContent'

export const STATS_TYPES: IKeyValueStringArray[] = [
  {
    key: StatKey.Score,
    value: [
      'daily_average',
      'meals_average',
      'peak_average',
      'mean_average',
      'std_dev_average',
      'time_outside_range_average'
    ]
  },
  {
    key: StatKey.Glucose,
    value: [
      'time_within_range',
      'standard_deviation',
      'median',
      'mean',
      'min',
      'max',
      'sleep_average',
      'morning_average'
    ]
  },
  {
    key: StatKey.Ketones,
    value: ['time_within_range', 'median', 'mean', 'min', 'max']
  },
  {
    key: StatKey.Macros,
    value: ['calories', 'protein', 'carbs', 'fat']
  },
  { key: StatKey.Habits, value: [] }
]

const MAIN_STATS = {
  glucose: {
    standard_deviation: true,
    mean: true,
    max: true,
    morning_average: true,
    sleep_average: true,
    time_within_range: true
  }
}

export const Stats = () => {
  const { startDate, endDate } = useGraphDateRange()
  const { data, loading } = useAllStatsQuery({
    variables: { filter: { startDate, endDate, types: STATS_TYPES } }
  })

  const rawStats = data?.allStats.statistics || []

  const drawerOpen = useReactiveVar(statsDrawerVisibleVar)

  const mainStats = rawStats.filter((stat) => {
    const meta = stat.meta
    const mainStat = MAIN_STATS[meta.section as keyof typeof MAIN_STATS]
    return mainStat && mainStat[meta.key as keyof typeof mainStat]
  })

  const stats = mainStats?.map((stat) => {
    return <StatCard key={`${stat.meta.section}_${stat.meta.key}`} stat={stat as IStatistic} />
  })

  return (
    <Card sx={{ padding: '14px 16px', height: '80px' }}>
      <Grid direction="row" container>
        {loading ? <Loader /> : stats}
      </Grid>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => statsDrawerVisibleVar(false)}
        sx={{
          '& .MuiPaper-root': {
            width: 300
          }
        }}
      >
        <StatsDrawerContent stats={rawStats} />
      </Drawer>
    </Card>
  )
}
