import { Box, MenuItem, Stack } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const SidebarNavStyled = styled(Stack)({
  marginBottom: 0,
  paddingTop: 16,
  paddingBottom: 16,
  width: 60,
  height: '100%',
  backgroundColor: 'white'
})

export const SidebarTop = styled(Stack)({
  flexGrow: 1
})

export const SidebarBottom = styled(Stack)({
  flexShrink: 0,
  alignItems: 'center'
})

export const MenuItemStyled = styled(MenuItem)({
  '&:hover': {
    backgroundColor: theme.palette['brand-primary-transparent']['transparent-8']
  }
})

export const SidebarIndicator = styled(Box)({
  position: 'absolute',
  top: 10,
  right: 10,
  width: 8,
  height: 8,
  borderRadius: 8,
  background: theme.palette['danger'].main
})
