import { ReactElement } from 'react'
import { Box, Tooltip } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { theme } from 'theme'
import { MenuItemStyled, SidebarIndicator } from './styles'

interface ISidebarItem {
  children: ReactElement
  to: string
  tooltipTitle: string
  showIndicator?: boolean
  end?: boolean
  onClick?: () => void
}
export const SidebarItem = ({
  children,
  to,
  onClick,
  tooltipTitle,
  end,
  showIndicator
}: ISidebarItem) => {
  const getActiveLinkStyle = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? theme.palette['text-color'].accent : theme.palette['text-color'].secondary
  })

  return (
    <Tooltip title={tooltipTitle} placement="right-end">
      <NavLink to={to} onClick={onClick} style={getActiveLinkStyle} end={!!end}>
        <Box position="relative">
          <MenuItemStyled>{children}</MenuItemStyled>
          {showIndicator && <SidebarIndicator />}
        </Box>
      </NavLink>
    </Tooltip>
  )
}
