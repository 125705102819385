import Bugsnag from '@bugsnag/js'
import { GridState } from '@mui/x-data-grid'
import { pick } from 'lodash'
import { defaultState } from './defaultStates'

export const VERSION = 2
const STATE_FIELDS_TO_SAVE = ['filter', 'sorting', 'pagination', 'columns', 'density']

export const saveState = (stateKey: string, state: GridState) => {
  const statePayload = pick(state, STATE_FIELDS_TO_SAVE)
  const payload = { version: VERSION, state: statePayload }
  localStorage.setItem(stateKey, JSON.stringify(payload))
}

export const loadState = (stateKey: string) => {
  const payload = localStorage.getItem(stateKey)

  if (!payload) return defaultState(stateKey)

  try {
    const parsedPayload = JSON.parse(payload) as { version: number; state: GridState }

    if (parsedPayload.version !== VERSION) return defaultState(stateKey)

    return parsedPayload.state
  } catch (error: any) {
    Bugsnag.notify(error)
    return defaultState(stateKey)
  }
}
