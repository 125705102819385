import { Suspense } from 'react'
import { Stack, Toolbar, Tabs, Tab } from '@mui/material'
import { AuthorizedFeature, Loader } from 'components'
import { DppResource } from 'dppResource.types'
import { useAuthorizedFeature } from 'hooks'
import { LocationPaths } from 'location.types'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { HealthPortalFeature } from 'types'

const labelByDppResource: { [key in DppResource]?: string } = {
  [DppResource.EligibleUsers]: 'Eligible Members',
  [DppResource.Cohorts]: 'Cohorts',
  [DppResource.InsuranceParticipants]: 'Participant Submit',
  [DppResource.InsuranceSessions]: 'Session Submit',
  [DppResource.DppChat]: 'Chat'
}

export const DppLayout = () => {
  const location = useLocation()

  const [showDppAssignCohort, showDppSubmitReports, showDppChat] = useAuthorizedFeature([
    HealthPortalFeature.DppAssignCohort,
    HealthPortalFeature.DppSubmitReports,
    HealthPortalFeature.DppChat
  ])

  const availableDppResources = []
  if (showDppAssignCohort) {
    availableDppResources.push(DppResource.EligibleUsers)
  }
  availableDppResources.push(DppResource.Cohorts)
  if (showDppSubmitReports) {
    availableDppResources.push(DppResource.InsuranceParticipants)
    availableDppResources.push(DppResource.InsuranceSessions)
  }
  if (showDppChat) {
    availableDppResources.push(DppResource.DppChat)
  }

  const tabs = availableDppResources.map((resource) => (
    <Tab key={resource} label={labelByDppResource[resource]} component={NavLink} to={resource} />
  ))

  const tabValue = availableDppResources.findIndex((dppResource) =>
    location.pathname.match(new RegExp(`^${LocationPaths.Dpp}/${dppResource}`))
  )

  return (
    <AuthorizedFeature feature={HealthPortalFeature.DppViewCohorts}>
      <Stack height="100%">
        <Toolbar>
          <Tabs value={tabValue}>{tabs}</Tabs>
        </Toolbar>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Stack>
    </AuthorizedFeature>
  )
}
