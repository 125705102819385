import { useRef } from 'react'
import { LinkableItemAutocomplete } from 'screens/Knowledge/Editor'
import {
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  IKnowledgeSource,
  KnowledgeSourceDynamicFilterField,
  useAllKnowledgeSourcesLazyQuery
} from 'types'

interface ISearchSourcesProps {
  selectedSource: IKnowledgeSource | null
  linkedSources: IKnowledgeSource[]
  onSelect: (source: IKnowledgeSource) => void
}

export const SearchSources = ({ selectedSource, linkedSources, onSelect }: ISearchSourcesProps) => {
  const searchValue = useRef('')

  const [searchSources, { data, loading }] = useAllKnowledgeSourcesLazyQuery({
    variables: {
      dynamicFilters: {
        items: [
          {
            field: KnowledgeSourceDynamicFilterField.Title,
            operation: DynamicFilterItemOperation.ICont,
            value: searchValue.current
          },
          {
            field: KnowledgeSourceDynamicFilterField.Url,
            operation: DynamicFilterItemOperation.ICont,
            value: searchValue.current
          }
        ],
        operator: DynamicFilterOperator.Or
      }
    }
  })

  const options = (data?.allKnowledgeSources.knowledgeSources ?? []) as IKnowledgeSource[]

  return (
    <LinkableItemAutocomplete
      selectedItem={selectedSource}
      linkedItems={linkedSources}
      options={options}
      search={searchSources}
      searchValue={searchValue}
      loading={loading}
      onSelect={onSelect}
    />
  )
}
