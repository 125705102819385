import { Box, Chip, Typography } from '@mui/material'
import { getPreviousCoach } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/getPreviousCoach'
import { TableTooltip } from 'screens/AdminPanel/styles'
import { CoachAssignmentState, ICoachAssignment } from 'types'
import { CoachAssignmentsTable } from './CoachAssignmentsTable'

export interface ICoachCellProps {
  coachAssignments: ICoachAssignment[]
  value?: string
  supportType?: string
  formattedCellValue: string | undefined
}

export const CoachCell = ({
  coachAssignments,
  supportType,
  formattedCellValue
}: ICoachCellProps) => {
  if (!coachAssignments?.length) return null

  const filteredCoachAssignments = coachAssignments.filter(
    (assignment) => assignment.supportType === supportType
  )

  if (!filteredCoachAssignments.length) return null

  // needed for cases where the member is in inactive coach assignment state
  // but there was not coach assigned to this member
  // and there is no formatted value so the grid does not render empty chip
  if (!formattedCellValue && filteredCoachAssignments[0].state !== CoachAssignmentState.Pending)
    return null

  if (
    filteredCoachAssignments.length === 1 &&
    filteredCoachAssignments[0].state === CoachAssignmentState.Pending &&
    !formattedCellValue
  ) {
    return null
  }

  const { coach: previousCoach, coachAssignments: assignments } =
    getPreviousCoach(filteredCoachAssignments)
  const cellContent = (
    <TableTooltip title={<CoachAssignmentsTable assignments={assignments} />}>
      <Box>
        {previousCoach && (
          <>
            <Typography color="text.secondary" variant="caption">
              {previousCoach.fullName}
            </Typography>
            {formattedCellValue && ' | '}
          </>
        )}
        {formattedCellValue}
      </Box>
    </TableTooltip>
  )

  return <Chip variant="outlined" label={cellContent} />
}
