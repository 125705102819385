import { Box, Link, Typography } from '@mui/material'
import { ImmersiveScreen } from './ImmersiveScreen'

export const NotFound = () => {
  return (
    <ImmersiveScreen>
      <Box display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
        <Typography variant="h4">404 - Page not found</Typography>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
        <Link href="/">Go home</Link>
      </Box>
    </ImmersiveScreen>
  )
}
