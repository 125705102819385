import { ApolloClient, InMemoryCache, ServerParseError, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'
import { createUploadLink } from 'apollo-upload-client'
import { env } from 'config'
import { EndpointType } from 'endpoint.types'
import { LocationPaths } from 'location.types'
import { uniqBy } from 'lodash'
import { userIdLink } from './userIdLink'

export const uri = env.REACT_APP_API_ENDPOINT
const redirectToLoginErrorCodes = [401, 403]

const errorLink = onError(({ networkError }) => {
  if (
    networkError &&
    redirectToLoginErrorCodes.includes((networkError as ServerParseError).statusCode)
  ) {
    navigateToLogin()
  }
})

const PUBLIC_PATHS: string[] = [LocationPaths.Login, LocationPaths.ResetPasswordRequest]

const navigateToLogin = () => {
  if (PUBLIC_PATHS.includes(window.location.pathname)) return

  window.location.href = LocationPaths.Login
}

const buildMultiApiLink = new MultiAPILink({
  endpoints: {
    backend: uri!,
    courses: env.REACT_APP_COURSES_CMS_ENDPOINT
  },
  defaultEndpoint: 'backend',
  httpSuffix: '',
  createHttpLink: () => createUploadLink({ credentials: 'include' }),
  getContext: (endpoint, getCurrentContext) => {
    const previousContext = getCurrentContext()
    if (endpoint === EndpointType.BACKEND) {
      return {
        ...previousContext,
        headers: {
          ...previousContext.headers
        }
      }
    }

    if (endpoint === EndpointType.COURSES_CMS) {
      return {
        ...previousContext,
        headers: {
          authorization: `Bearer ${env.REACT_APP_COURSES_CMS_API_KEY}`
        }
      }
    }

    return previousContext
  }
})

export const client = new ApolloClient({
  link: from([userIdLink, errorLink, buildMultiApiLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allScans: {
            keyArgs: ['filter', ['startDate', 'endDate']],
            merge(existing, incoming) {
              if (!existing) return incoming
              if (!incoming) return existing
              return {
                __typename: 'MeasurementCollection',
                measurements: uniqBy([...existing.measurements, ...incoming.measurements], '__ref')
              }
            }
          },
          allEvents: {
            keyArgs: [
              'filter',
              ['orderBy', 'startDate', 'endDate', 'query', 'order', 'types'],
              'pagination',
              ['type']
            ],
            merge(existing, incoming) {
              if (!existing) return incoming
              if (!incoming) return existing

              return {
                __typename: 'EventsCollection',
                events: {
                  events: uniqBy(
                    [...(existing.events.events || []), ...(incoming.events.events || [])],
                    '__ref'
                  )
                }
              }
            }
          },
          allHealthPortalNotifications: {
            keyArgs: ['forReview', 'kinds'],
            merge(existing, incoming) {
              if (!existing) return incoming
              if (!incoming) return existing

              return {
                __typename: 'HealthPortalNotificationsCollection',
                healthPortalNotifications: uniqBy(
                  [
                    ...(existing.healthPortalNotifications || []),
                    ...(incoming.healthPortalNotifications || [])
                  ],
                  '__ref'
                )
              }
            }
          }
        }
      },
      Activity: {
        // It's necessary to compound the cache key because for Activities that
        // have a startDate differing from endDate (Sleep for example),
        // the backend can return different values for the occurredAt field.
        keyFields: ['id', 'occurredAt']
      },
      // Knowledge questions and answers may be (un)linked to answers or sources, respectively.
      // When said (un)linking is performed, we update the question/answer fragment based on the server resonse.
      // As such, the new answer/source list should overwrite (not be merged with) the existing answer/source list.
      KnowledgeQuestion: {
        fields: {
          knowledgeAnswers: {
            merge: false
          },
          knowledgeSources: {
            merge: false
          },
          knowledgeTags: {
            merge: false
          }
        }
      },
      KnowledgeAnswer: {
        fields: {
          knowledgeQuestions: {
            merge: false
          },
          knowledgeSources: {
            merge: false
          }
        }
      },
      KnowledgeSource: {
        fields: {
          knowledgeQuestions: {
            merge: false
          },
          knowledgeAnswers: {
            merge: false
          }
        }
      }
    }
  })
})

export const cache = client.cache
