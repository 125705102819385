import { makeVar } from '@apollo/client'
import { MealKind } from 'types'

export const MEAL_SUBTYPE_PREFIX = 'meals'

export const mealFilterSubtypes = [
  `${MEAL_SUBTYPE_PREFIX}.favorite_meals`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Breakfast}`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Lunch}`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Dinner}`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Dessert}`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Drink}`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Snack}`,
  `${MEAL_SUBTYPE_PREFIX}.${MealKind.Supplement}`
]

export const activityFilterSubtypes = [
  'activities.favorite_activities',
  'activities.sleep',
  'activities.fasting',
  'activities.exercise',
  'activities.meditation'
]

export const measurementFilterSubtypes = [
  'measurements.weight',
  'measurements.stress',
  'measurements.step_count',
  'measurements.blood_glucose',
  'measurements.blood_ketones',
  'measurements.breath_ketones',
  'measurements.blood_pressure',
  'measurements.exercise_time',
  'measurements.flights_climbed',
  'measurements.body_temperature',
  'measurements.cycling_distance',
  'measurements.running_distance',
  'measurements.swimming_distance',
  'measurements.body_fat_percentage'
]

export const noteFilterSubtypes = ['notes.my_notes', 'notes.coach_notes']

export const groupedEventFilterSubtypes = {
  activities: activityFilterSubtypes,
  meals: mealFilterSubtypes,
  measurements: measurementFilterSubtypes,
  notes: noteFilterSubtypes
}

export const eventFilterSubtypes = [
  ...activityFilterSubtypes,
  ...mealFilterSubtypes,
  ...measurementFilterSubtypes,
  ...noteFilterSubtypes
]

const initialFilters: string[] = []

export enum SortDirectionEnum {
  DESCENDING = 'descending',
  ASCENDING = 'ascending'
}

export enum OrderByFilterOption {
  Default = 'occurred_at',
  Score = 'score',
  Peak = 'peak',
  Exposure = 'exposure',
  Stability = 'stability',
  Recovery = 'recovery'
}

export const queryFilterVar = makeVar('')
export const orderFilterVar = makeVar<SortDirectionEnum>(SortDirectionEnum.DESCENDING)
export const typeFilterVar = makeVar(initialFilters)
export const orderByFilterVar = makeVar<OrderByFilterOption | string>(OrderByFilterOption.Default)

export const resetEventFilters = () => {
  queryFilterVar('')
  orderFilterVar(SortDirectionEnum.DESCENDING)
  typeFilterVar(initialFilters)
  orderByFilterVar(OrderByFilterOption.Default)
}
