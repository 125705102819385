import { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'

const WAIT_TIME = 300

const MIN_QUERY_LENGTH = 2

export const useDebouncedSearch = (query: any, searchValue: MutableRefObject<string>) => {
  const debounceSearch = useMemo(() => {
    return debounce(query, WAIT_TIME)
  }, [query])

  const handleInputChange = useCallback(
    (_: any, newValue: string) => {
      searchValue.current = newValue
      if (newValue.length > MIN_QUERY_LENGTH) debounceSearch()
    },
    [searchValue, debounceSearch]
  )

  useEffect(() => {
    return () => {
      debounceSearch.cancel()
    }
  }, [debounceSearch])

  return { handleInputChange }
}
