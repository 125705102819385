import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Box, Stack } from '@mui/material'
import { saveEventDialogVar, deleteNoteDialogVar } from 'context'
import { IJournalEntry } from 'types'
import { getWellnessScore } from './helpers/getWellnessScore'

interface IJournalEntryToolbar {
  event: IJournalEntry
}

const JournalScore = ({ score }: { score?: number | null }) => {
  const Emoji = getWellnessScore(score)
  if (!Emoji) return null

  return <Emoji style={{ width: 40, height: 40 }} />
}

export const JournalEntryToolbar = ({ event }: IJournalEntryToolbar) => {
  const handleOpenDeleteNoteModal = () => {
    deleteNoteDialogVar({ open: true, event: event })
  }

  const handleOpenUpdateNoteModal = () => {
    saveEventDialogVar({ open: true, event: event, graphDataPoint: undefined })
  }

  return (
    <Stack direction="row" alignItems="center">
      {event.createdBy === 'Coach' && (
        <>
          <IconButton size="small" onClick={handleOpenUpdateNoteModal}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={handleOpenDeleteNoteModal}>
            <DeleteIcon />
          </IconButton>
        </>
      )}
      {event.createdBy !== 'Coach' && (
        <Box sx={{ paddingRight: 2, height: '100%' }}>
          <JournalScore score={event.score} />
        </Box>
      )}
    </Stack>
  )
}
