import { Box, Tooltip } from '@mui/material'
import { UserPropertyEdit } from 'components/UserPropertyEdit'
import { client } from 'config/apolloClient'
import { AllUserProperties } from 'gql/queries/allUserProperties'
import { IUserProperty } from 'types'

interface IUserPropertyCell {
  value: IUserProperty
  userId: string
  kind: string
  multiline?: boolean
  tooltipLabel?: string
  title?: string
}

const defaultUserProperty = (kind: string) => ({ value: '', kind })

export const UserPropertyCell = ({
  value,
  kind,
  userId,
  multiline = true,
  tooltipLabel,
  title
}: IUserPropertyCell) => {
  let userProperty = value

  if (!userProperty) {
    userProperty = client
      .readQuery({ query: AllUserProperties })
      ?.allUserProperties?.userProperties?.find(
        (userProperty: IUserProperty) =>
          userProperty.userId === userId && userProperty.kind === kind
      )
  }

  return (
    <Tooltip title={tooltipLabel}>
      <Box sx={{ width: '100%' }}>
        <UserPropertyEdit
          slim
          userId={userId}
          userProperty={(userProperty || defaultUserProperty(kind)) as IUserProperty}
          topAdornment={null}
          multiline={multiline}
          title={title}
        />
      </Box>
    </Tooltip>
  )
}
