import { alpha, styled } from '@mui/material/styles'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { theme } from 'theme'

const ODD_OPACITY = 0.2

export const DataGridStyled = styled(DataGrid)({
  [`.${gridClasses.footerContainer}`]: {
    justifyContent: 'center'
  },
  [`.${gridClasses.columnSeparator}`]: {
    display: 'none'
  },
  [`&.${gridClasses.root}`]: {
    border: 'none'
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[100]
  },
  [`& .${gridClasses.row}.dirty`]: {
    backgroundColor: theme.palette['info-transparent'].light
  },
  [`& .${gridClasses.row}.accentOdd`]: {
    backgroundColor: theme.palette['warning-transparent'].light
  },
  [`& .${gridClasses.row}.accentEven`]: {
    backgroundColor: theme.palette['warning-transparent'].dark
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette['brand-primary'].light!, ODD_OPACITY)
    }
  },
  padding: '20px',
  backgroundColor: 'white'
})
