import { ApolloCache } from '@apollo/client'

export const evictFromCacheById = (
  cache: ApolloCache<any>,
  id: string | number,
  typename: string
) => {
  const normalizedId = cache.identify({ id, __typename: typename })
  cache.evict({ id: normalizedId })
  cache.gc()
}
