import { ReactNode, useEffect } from 'react'
import { AppBar, Stack } from '@mui/material'
import { useAuth } from 'context'
import { FeatureFlags } from 'featureFlags.types'
import { useFeatureFlag } from 'hooks'
import { FlexBoxStyled } from 'utility/box'
import { HealthPortalFeature, useCurrentUserQuery } from 'types'
import { NutritionistLeftAdornment } from './NutritionistLeftAdornment'
import { ToolbarStyled } from './styles'

export interface IAppToolBarProps {
  leftAdornment?: ReactNode
  centralAdornment?: ReactNode
  rightAdornment?: ReactNode
}

export const APP_TOOLBAR_HEIGHT = 64

export const AppToolbar = ({
  leftAdornment,
  centralAdornment,
  rightAdornment
}: IAppToolBarProps) => {
  const { data } = useCurrentUserQuery()
  const { isAuthorized } = useAuth()
  const nutritionistHomeEnabled = useFeatureFlag(FeatureFlags.NutritionistHome)
  const user = data?.currentUser

  useEffect(() => {
    document.title = user?.fullName ?? 'Nutritionist App'
  }, [user])

  return (
    <AppBar position="relative" sx={{ marginBottom: 1 }} color="inherit" elevation={0}>
      <ToolbarStyled>
        <Stack direction="row" spacing={0.5} alignItems="center">
          {leftAdornment}
          {nutritionistHomeEnabled && isAuthorized(HealthPortalFeature.NutritionistHome) && (
            <NutritionistLeftAdornment />
          )}
        </Stack>
        <FlexBoxStyled flex={1} />
        {centralAdornment}
        <FlexBoxStyled flex={1} />
        {rightAdornment}
      </ToolbarStyled>
    </AppBar>
  )
}
