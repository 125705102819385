import { uniqBy } from 'lodash'
import { useChatbotMessageQuery } from 'types'

export const useMessageAnswers = (id: string) => {
  const { data: messageData, loading } = useChatbotMessageQuery({ variables: { id } })

  const allAnswers = messageData?.chatbotMessage?.userMessage?.knowledgeQuestions?.flatMap(
    (question) => question.knowledgeAnswers
  )
  const answers = uniqBy(allAnswers, 'id')

  return {
    answers,
    loading
  }
}
