import { createContext, useEffect, useState } from 'react'
import { useAuthorizedFeature, useStreamChatClient } from 'hooks'
import { HealthPortalFeature, useCurrentAdminQuery } from 'types'

type SelectedInstructor = {
  id: string
  fullName: string
  avatar?: string | null
}

export const DppChatContext = createContext<{
  unreadMessagesCount: number
  client: ReturnType<typeof useStreamChatClient> | null
  selectedInstructor: SelectedInstructor | null
  setSelectedInstructor: (instructor: SelectedInstructor | null) => void
}>({
  unreadMessagesCount: 0,
  selectedInstructor: null,
  setSelectedInstructor: () => {},
  client: null
})

export const DPP_CHANNEL_TYPE = 'dpp'

export const DppChatProvider = ({ children }: { children: JSX.Element }) => {
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()

  const [selectedInstructor, setSelectedInstructor] = useState<SelectedInstructor | null>(null)

  const chatAuthorized = useAuthorizedFeature(HealthPortalFeature.DppChat)

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const client = useStreamChatClient({
    userId: selectedInstructor?.id || currentAdmin?.id,
    userName: selectedInstructor ? selectedInstructor.fullName : currentAdmin?.fullName,
    userAvatar: selectedInstructor ? selectedInstructor.avatar : currentAdmin?.avatar,
    shouldConnect: chatAuthorized
  })

  useEffect(() => {
    if (!client) {
      return
    }

    client.getUnreadCount(client.userID).then((response) => {
      setUnreadMessagesCount(
        response.channel_type.find((channel) => channel.channel_type === DPP_CHANNEL_TYPE)
          ?.channel_count || 0
      )
    })

    client.on((event) => {
      if (event.unread_channels !== undefined && event.channel_type === DPP_CHANNEL_TYPE) {
        setUnreadMessagesCount(event.unread_channels)
      }
    })
  }, [client])

  return (
    <DppChatContext.Provider
      value={{
        client,
        unreadMessagesCount,
        selectedInstructor,
        setSelectedInstructor
      }}
    >
      {children}
    </DppChatContext.Provider>
  )
}
