import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import { TitleCellChip, TitleCellTypography } from './styles'

interface IAnswerTitleChipProps {
  title: string
  onClick?: () => void
}

export const AnswerTitleChip = ({ title, onClick }: IAnswerTitleChipProps) => {
  return (
    <TitleCellChip
      label={<TitleCellTypography maxLines={2}>{title}</TitleCellTypography>}
      variant="outlined"
      icon={<FeedOutlinedIcon />}
      onClick={onClick}
    />
  )
}
