import {
  Box,
  List,
  Stack,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
  Chip
} from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'
import { lineClampStyles } from 'utility'

export const SidebarIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})((props: { active?: boolean }) => ({
  ...(props.active && {
    color: 'white',
    backgroundColor: theme.palette['text-color'].primary
  }),
  '&:hover': {
    backgroundColor: theme.palette['brand-primary-transparent']['transparent-8'],
    ...(props.active && {
      color: 'white',
      backgroundColor: theme.palette['text-color'].primary
    })
  }
}))

export const Modal = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'expanded'
})((props: { open: boolean; expanded: boolean }) => ({
  display: props.open ? 'flex' : 'none',
  flexDirection: 'row' as const,
  gap: '8px',
  position: 'fixed' as const,
  left: 54,
  bottom: 12,
  zIndex: 100,
  ...(props.expanded
    ? {
        top: 12,
        right: 54
      }
    : {
        height: '50vh'
      })
}))

export const PanelContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== 'expanded' })({
  gap: '16px',
  height: '100%',
  padding: '16px',
  backgroundColor: 'white',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: '#d7dddd',
  borderRadius: '16px'
})

export const ChatPanelContainer = styled(PanelContainer)((props: { expanded: boolean }) => ({
  ...(props.expanded ? { flexGrow: 1 } : { width: '40vw' })
}))

export const SourcesPanelContainer = styled(PanelContainer)({
  width: '45vw'
})

export const PanelHeader = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  flexShrink: 0
})

export const PanelTitle = styled(Typography)({
  flexGrow: 1,
  fontWeight: 700,
  fontSize: '15px'
})

export const PanelContentContainer = styled(Box)({
  flexGrow: 1,
  overflow: 'hidden'
})

export const SourcesBrowserContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '32px',
  height: '100%'
})

export const SourcesBrowserAnswerList = styled(List)({
  flexBasis: '33%',
  height: '100%',
  overflowY: 'scroll'
})

export const SourceBrowserAnswerListItem = styled(ListItemButton)({
  marginBottom: '8px',
  borderRadius: '12px',
  backgroundColor: theme.palette['theme-primary']['level-2']
})

export const SourceBrowserAnswerListText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '12px',
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '& .MuiListItemText-secondary': {
    fontSize: '12px',
    fontWeight: 400,

    ...lineClampStyles(2)
  }
})

export const SourceBrowserAnswerPreview = styled(Stack)({
  flexBasis: '67%',
  gap: '8px',
  overflowY: 'scroll'
})

export const SourceBrowserAnswerPreviewTitle = styled(Typography)({
  fontSize: '12px',
  fontWeight: 700
})

export const SourceBrowserAnswerPreviewBody = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400
})

export const SourceBrowserAnswerPreviewEmpty = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette['text-color'].secondary
})

export const SourceBrowserAnswerPreviewSourceChip = styled(Chip)({
  marginRight: '8px',
  marginTop: '4px',
  padding: '2px 6px',
  borderRadius: '11px',
  borderColor: theme.palette['theme-primary']['level-4']
})
