import { Stack, Typography } from '@mui/material'
import { Loader } from 'components'
import { Emoji } from 'components/Emoji'
import { format, isToday } from 'date-fns'
import { SectionTitle } from 'screens/AdminPanel/styles'
import { MMM_D } from 'utility/timeFormats'
import {
  CoachesConversationScheduledMessageState,
  CoachConversationScheduledMessageSortField,
  ICoachConversationScheduledMessage,
  SortDirection,
  useAllCoachConversationScheduledMessagesQuery
} from 'types'
import { ScheduledMessage } from './ScheduledMessage'
import { DashedDivider, DayText, ScrollableCard } from './styles'

const sorts = [
  {
    field: CoachConversationScheduledMessageSortField.SendAt,
    direction: SortDirection.Asc as SortDirection
  }
]

const groupByDays = (messages: ICoachConversationScheduledMessage[]) => {
  const dayToMessagesLookup = {} as { [key: string]: typeof messages }
  messages.forEach((message) => {
    const sendAtDate = new Date(message.sendAt)
    const day = isToday(sendAtDate) ? 'Today' : format(sendAtDate, MMM_D)
    if (!(day in dayToMessagesLookup)) {
      dayToMessagesLookup[day] = []
    }
    dayToMessagesLookup[day].push(message)
  })
  return dayToMessagesLookup
}

export const ScheduledMessages = () => {
  const { data, loading } = useAllCoachConversationScheduledMessagesQuery({
    variables: { states: [CoachesConversationScheduledMessageState.Pending], sorts: sorts }
  })
  const scheduledMessages =
    data?.allCoachConversationScheduledMessages.coachConversationScheduledMessages || []
  const dayToMessagesLookup = groupByDays(scheduledMessages as ICoachConversationScheduledMessage[])

  return (
    <Stack spacing={2}>
      <SectionTitle>
        <Emoji symbol="🗓️" label="Scheduled Messages" mr={1} />
        Scheduled Messages
      </SectionTitle>
      <ScrollableCard>
        {loading && <Loader />}
        {!loading && !scheduledMessages.length && <Typography>No Scheduled Messages</Typography>}
        <Stack>
          {Object.keys(dayToMessagesLookup).map((day) => (
            <Stack key={day}>
              <Stack direction="row" alignItems="center">
                <DayText variant="overline">{day}</DayText>
                <DashedDivider />
              </Stack>
              {dayToMessagesLookup[day].map((message: ICoachConversationScheduledMessage) => (
                <ScheduledMessage message={message} key={message.id} />
              ))}
            </Stack>
          ))}
        </Stack>
      </ScrollableCard>
    </Stack>
  )
}
