import { buildVars } from 'context/gridVars'
import {
  columns as answerColumns,
  DATA_GRID_STATE_KEY as ANSWER_DATA_GRID_STATE_KEY
} from 'screens/Knowledge/Answers/constants'
import {
  columns as questionColumns,
  DATA_GRID_STATE_KEY as QUESTION_DATA_GRID_STATE_KEY
} from 'screens/Knowledge/Questions/constants'
import {
  columns as sourceColumns,
  DATA_GRID_STATE_KEY as SOURCE_DATA_GRID_STATE_KEY
} from 'screens/Knowledge/Sources/constants'
import {
  columns as tagColumns,
  DATA_GRID_STATE_KEY as TAG_DATA_GRID_STATE_KEY
} from 'screens/Knowledge/Tags/constants'

export const questionGridVars = buildVars(questionColumns, QUESTION_DATA_GRID_STATE_KEY)

export const answerGridVars = buildVars(answerColumns, ANSWER_DATA_GRID_STATE_KEY)

export const sourceGridVars = buildVars(sourceColumns, SOURCE_DATA_GRID_STATE_KEY)

export const tagGridVars = buildVars(tagColumns, TAG_DATA_GRID_STATE_KEY)
