import { Paper } from '@mui/material'
import { Maybe } from 'types'

interface IMealPhoto {
  url: Maybe<string> | undefined
}

export const MealPhoto = ({ url }: IMealPhoto) => {
  return (
    <Paper sx={{ width: 'auto', height: 500, padding: 1 }} elevation={3}>
      {url && <img src={url} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
    </Paper>
  )
}
