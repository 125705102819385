import React, { forwardRef, useImperativeHandle } from 'react'
import { Button, Popover } from '@mui/material'

interface IDateRangePopover {
  label: string
  children: React.ReactElement
}

export interface IDateRangeAPI {
  handlePopoverClose: () => void
}

const DateRangePopover = forwardRef<IDateRangeAPI | undefined, IDateRangePopover>(
  ({ label, children }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    useImperativeHandle(ref, () => ({
      handlePopoverClose
    }))

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const isOpen = Boolean(anchorEl)

    return (
      <>
        <Button onClick={handlePopoverOpen}>{label}</Button>
        <Popover
          id="popover"
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handlePopoverClose}
        >
          {children}
        </Popover>
      </>
    )
  }
)

DateRangePopover.displayName = 'DateRangePopover'

export { DateRangePopover }
