import React from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Card } from '@mui/material'
import { APP_TOOLBAR_HEIGHT } from 'components'
import { MEMBER_BIO_HEIGHT } from 'components/MemberBio/MemberBio'
import { IAppointment } from 'types'
import { APPOINTMENT_DETAILS_HEIGHT } from './AppointmentDetails'
import { ScrollableContent, HistoryItem } from './styles'
import { appointmentDescription, historyAppointmentTimeDescription } from './utils'

type IUserAppointmentsHistoryProps = {
  pastAppointments: IAppointment[]
}

const scrollableHeight = `calc(100vh - ${APP_TOOLBAR_HEIGHT}px - ${MEMBER_BIO_HEIGHT}px - ${APPOINTMENT_DETAILS_HEIGHT}px - 384px)`

export const UserAppointmentsHistory = ({ pastAppointments }: IUserAppointmentsHistoryProps) => {
  return (
    <Card>
      <ScrollableContent sx={{ height: scrollableHeight }}>
        {pastAppointments.map((appointment) => (
          <HistoryItem key={appointment.id} variant="body1">
            {appointmentDescription(appointment as IAppointment)} Video Call:{' '}
            {historyAppointmentTimeDescription(appointment as IAppointment)}
          </HistoryItem>
        ))}
      </ScrollableContent>
    </Card>
  )
}
