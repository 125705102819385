import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { userIdLink } from 'config/userIdLink'
import { LocationPaths } from 'location.types'
import { useLocation, useParams } from 'react-router-dom'

export let currentUserId: string

export const useInvalidateQueriesOnUserChange = () => {
  const { id } = useParams<{ id: string }>()
  const { resetStore } = useApolloClient()
  const location = useLocation()

  userIdLink.userId = id
  const isUserPath = location.pathname.startsWith(LocationPaths.Users)

  useEffect(() => {
    if (!isUserPath || !id) return

    resetStore()
    currentUserId = id
  }, [id, resetStore, isUserPath])
}
