import { useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { ApolloError, useReactiveVar } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Box, InputAdornment, MenuItem, Stack, Typography } from '@mui/material'
import { Tabs } from 'components'
import { Loader } from 'components'
import { snackBarVisibleVar } from 'context'
import { selectedAppointmentIdVar } from 'context/nutritionistHome/selectedAppointmentIdVar'
import CustomEventTypes from 'hooks/analytics/customEventTypes'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { startCase } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import {
  ChatConversationMessageTemplateKind,
  CoachesConversationScheduledMessageState,
  IAppointment,
  ICoachConversationScheduledMessage,
  InsuranceBerryStreetAppointmentMeetingStatus,
  IUser,
  useAllCoachConversationScheduledMessagesLazyQuery,
  useSendAppointmentSmsReminderMutation
} from 'types'
import { useAppointment } from './hooks/useAppointment'
import { InsurancePolicyHistory } from './InsurancePolicyHistory'
import { ScheduledMessageStatus } from './ScheduledMessageStatus'
import { ActualDurationTextFieldStyled, MeetingStatusSelectStyled, PaddedPaper } from './styles'
import { UserAppointmentsHistory } from './UserAppointmentsHistory'

type IAppointmentActionsProps = {
  currentUser: IUser | null | undefined
  pastAppointments: IAppointment[]
}

const ALLOWED_MEETING_STATUSES = [
  InsuranceBerryStreetAppointmentMeetingStatus.Rescheduled,
  InsuranceBerryStreetAppointmentMeetingStatus.NoShow,
  InsuranceBerryStreetAppointmentMeetingStatus.Occurred
]

export const AppointmentActions = ({ currentUser, pastAppointments }: IAppointmentActionsProps) => {
  const selectedAppointmentId = useReactiveVar(selectedAppointmentIdVar)
  const [reminderDisabled, setReminderDisabled] = useState(false)
  const confirm = useConfirm()
  const { track } = useSegmentInteraction()
  const {
    appointment,
    loadingAppointment,
    updateAppointmentLoading,
    actualDuration,
    meetingStatus,
    callbacks: { updateDuration, handleDurationChange, handleMeetingStatusChange }
  } = useAppointment(selectedAppointmentId)
  const [getScheduledMessages, { data: scheduledMessagesResponse, loading }] =
    useAllCoachConversationScheduledMessagesLazyQuery()
  const [sendSmsReminderMutation, { loading: sendReminderLoading }] =
    useSendAppointmentSmsReminderMutation({
      onError: (error: ApolloError) => snackBarVisibleVar({ open: true, message: error.message }),
      onCompleted: () => {
        snackBarVisibleVar({
          open: true,
          severity: 'success',
          message: 'You have successfully sent SMS reminder.'
        })
        setReminderDisabled(true)
        track(CustomEventTypes.NutritionistHomeSmsReminderSent)
      }
    })

  useEffect(() => {
    if (!currentUser) return

    getScheduledMessages({
      variables: {
        userId: currentUser.id
      }
    })
  }, [currentUser, getScheduledMessages])

  useEffect(() => {
    const disabled = !appointment || !!appointment.meetingStatus
    setReminderDisabled(disabled)
  }, [appointment])

  if (!selectedAppointmentId || !appointment)
    return <PaddedPaper>{loadingAppointment ? <Loader /> : null}</PaddedPaper>

  const sendSmsReminder = async () => {
    try {
      await confirm({
        title: 'Are you sure you want to send an SMS reminder?',
        description: "The SMS reminder will reach the member's mobile phone in less than a minute."
      })
    } catch {
      return
    }
    await sendSmsReminderMutation({ variables: { id: selectedAppointmentId } })
  }

  const scheduledMessages =
    scheduledMessagesResponse?.allCoachConversationScheduledMessages
      .coachConversationScheduledMessages || []
  const videoInitialSent = scheduledMessages.find(
    (message) =>
      message.kind === ChatConversationMessageTemplateKind.VideoInitial &&
      message.state === CoachesConversationScheduledMessageState.Sent
  )
  const videoReminderSent = scheduledMessages.find(
    (message) =>
      message.kind === ChatConversationMessageTemplateKind.VideoReminder &&
      message.state === CoachesConversationScheduledMessageState.Sent &&
      message.coachAssignment.appointment?.id === selectedAppointmentId
  )

  const tabs = [
    {
      label: 'Appointments History',
      content: <UserAppointmentsHistory pastAppointments={pastAppointments} />
    },
    {
      label: 'Insurance Status History',
      content: <InsurancePolicyHistory userId={currentUser?.id} />
    }
  ]

  return (
    <PaddedPaper>
      <Typography variant="h6">Actions</Typography>
      <Stack spacing={2} paddingTop={2}>
        <ScheduledMessageStatus
          loading={loading}
          scheduledMessage={videoInitialSent as ICoachConversationScheduledMessage}
          label="Welcome note"
        />
        <ScheduledMessageStatus
          loading={loading}
          scheduledMessage={videoReminderSent as ICoachConversationScheduledMessage}
          label="Member reminder"
        />
        <Stack spacing={2} direction="row">
          <LoadingButton
            variant="contained"
            loading={sendReminderLoading}
            disabled={reminderDisabled}
            onClick={sendSmsReminder}
          >
            Send SMS Reminder
          </LoadingButton>

          <MeetingStatusSelectStyled
            onChange={handleMeetingStatusChange}
            displayEmpty
            value={startCase(meetingStatus || '')}
          >
            {ALLOWED_MEETING_STATUSES.map((status) => (
              <MenuItem key={status} value={startCase(status)}>
                {startCase(status)}
              </MenuItem>
            ))}
            <MenuItem key="emptyValue" value="">
              Select a meeting status
            </MenuItem>
          </MeetingStatusSelectStyled>
          <ActualDurationTextFieldStyled
            label="Actual Duration"
            value={actualDuration}
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">min</InputAdornment>
            }}
            onChange={handleDurationChange}
            onBlur={updateDuration}
          />

          {updateAppointmentLoading && (
            <Box component="span">
              <Loader />
            </Box>
          )}
        </Stack>
        <Tabs tabs={tabs} />
      </Stack>
    </PaddedPaper>
  )
}
