import { Box } from '@mui/system'
import { SearchUser } from 'components/SearchUser'
import { UserProfile } from 'components/UserProfile'
import { useBugsnagMember } from 'hooks/analytics/bugsnag/useBugsnagMember'
import { LocationPaths } from 'location.types'
import { useNavigate } from 'react-router-dom'
import { UserResource } from 'userResource.types'
import { IUserMin } from 'types'

export interface ICommonRightAdornmentProps {
  onUserChangeCallback?: () => void
  userResource?: UserResource
}

export const CommonRightAdornment = ({
  onUserChangeCallback,
  userResource = UserResource.Root
}: ICommonRightAdornmentProps) => {
  const { setMember: setBugsnagMember } = useBugsnagMember()
  const navigate = useNavigate()

  const onUserChange = (user: IUserMin) => {
    if (!user) return
    if (onUserChangeCallback) onUserChangeCallback()

    const { email, fullName, id } = user
    setBugsnagMember({ email, fullName, id })
    navigate(`${LocationPaths.Users}/${user.id}/${userResource}`)
  }

  return (
    <>
      <Box sx={{ width: 450 }}>
        <SearchUser onUserSelect={onUserChange} />
      </Box>
      <UserProfile />
    </>
  )
}
