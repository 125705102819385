import React, { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { Appointment } from 'gql/queries/appointments/appointment'
import { camelCase, upperFirst } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import {
  IAppointmentQuery,
  InsuranceBerryStreetAppointmentMeetingStatus,
  useAppointmentLazyQuery,
  useUpdateAppointmentMutation
} from 'types'

export const useAppointment = (selectedAppointmentId: string | null) => {
  const confirm = useConfirm()
  const [
    getAppointment,
    { data: { appointment } = { appointment: null }, loading: loadingAppointment }
  ] = useAppointmentLazyQuery()
  const [actualDuration, setActualDuration] = useState(appointment?.actualDuration || 0)
  const [meetingStatus, setMeetingStatus] = useState(appointment?.meetingStatus)

  const [updateAppointmentMutation, { loading: updateAppointmentLoading }] =
    useUpdateAppointmentMutation({
      onError: (error: ApolloError) => snackBarVisibleVar({ open: true, message: error.message }),
      onCompleted: () => {
        snackBarVisibleVar({
          open: true,
          severity: 'success',
          message: 'You have successfully updated the appointment.'
        })
      }
    })

  useEffect(() => {
    if (selectedAppointmentId && (selectedAppointmentId !== appointment?.id || !appointment)) {
      getAppointment({ variables: { id: selectedAppointmentId } })
    }
  }, [appointment, getAppointment, selectedAppointmentId])

  useEffect(() => {
    if (!appointment) return

    setMeetingStatus(appointment.meetingStatus)
    setActualDuration(appointment.actualDuration || 0)
  }, [appointment])

  if (!selectedAppointmentId) return { callbacks: {} }

  const updateDuration = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const nextActualDuration = parseInt(event.target.value, 10)
    if (isNaN(nextActualDuration)) return

    try {
      await confirm({
        description: 'Are you sure you want to change the appointment duration?'
      })
    } catch {
      return
    }

    await updateAppointmentMutation({
      variables: {
        id: selectedAppointmentId,
        actualDuration: nextActualDuration
      },
      update: (cache, { data }) => {
        if (!data?.updateAppointment.success) return

        const existingData: IAppointmentQuery | null = cache.readQuery({
          query: Appointment,
          variables: { id: selectedAppointmentId }
        })

        const newData = {
          appointment: {
            ...(existingData?.appointment || {}),
            actualDuration: nextActualDuration
          }
        }

        cache.writeQuery({
          query: Appointment,
          data: newData
        })
      }
    })
  }

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextActualDuration = parseInt(event.target.value, 10)

    setActualDuration(nextActualDuration)
  }

  const handleMeetingStatusChange = async (event: SelectChangeEvent<string>) => {
    const nextMeetingStatus =
      InsuranceBerryStreetAppointmentMeetingStatus[
        upperFirst(
          camelCase(event.target.value)
        ) as keyof typeof InsuranceBerryStreetAppointmentMeetingStatus
      ]

    if (!nextMeetingStatus) return

    try {
      await confirm({
        description: `Are you sure you want to mark this appointment as ${nextMeetingStatus}?`
      })
    } catch {
      return
    }

    setMeetingStatus(nextMeetingStatus)

    await updateAppointmentMutation({
      variables: {
        id: selectedAppointmentId,
        meetingStatus: nextMeetingStatus
      },
      update: (cache, { data }) => {
        if (!data?.updateAppointment.success) return

        const existingData: IAppointmentQuery | null = cache.readQuery({
          query: Appointment,
          variables: { id: selectedAppointmentId }
        })

        const newData = {
          appointment: {
            ...(existingData?.appointment || {}),
            meetingStatus: nextMeetingStatus
          }
        }

        cache.writeQuery({
          query: Appointment,
          data: newData
        })
      }
    })
  }

  return {
    appointment,
    updateAppointmentMutation,
    loadingAppointment,
    updateAppointmentLoading,
    actualDuration,
    meetingStatus,
    callbacks: {
      updateDuration,
      handleDurationChange,
      handleMeetingStatusChange
    }
  }
}
