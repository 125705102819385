import { Card } from '@mui/material'
import { EventAvatar, getEventData, EventCardHeader, EventTitle } from 'components/Events/EventCard'
import { MealToolbar } from 'components/Events/EventCard/MealToolbar'
import { NoteToolbar } from 'components/Events/EventCard/NoteToolbar'
import { EventsType } from 'components/Events/types'
import { EventTypes } from 'event.types'
import { theme } from 'theme'
import { INote } from 'types'

export const CondensedCard = (event: EventsType) => {
  const { title } = event

  const mealPhotoUrl = 'avatar' in event ? event.avatar : null

  const { description } = getEventData(event)

  const noteToolbar = event.__typename === EventTypes.Note && event.createdBy === 'Coach' && (
    <NoteToolbar event={event as INote} />
  )

  const mealToolbar = event.__typename === EventTypes.Meal && <MealToolbar event={event} />

  const rightAdornment = noteToolbar || mealToolbar

  return (
    <Card
      sx={{
        margin: 0.5,
        width: '100%',
        backgroundColor: theme.palette.grey[100]
      }}
    >
      <EventCardHeader
        eventTitle={<EventTitle title={title} event={event} description={description} />}
        eventAvatar={<EventAvatar event={event} mealPhotoUrl={mealPhotoUrl} />}
        action={rightAdornment}
      />
    </Card>
  )
}
