import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { MMM_DD_HH_MM_A } from 'utility/timeFormats'
import { CreatedAtDate } from './CreatedAtDate'
import { IEventElement } from './Event'
import { getPerformedByFullName } from './utils'

export const SnoozedEvent = ({ event, currentAdmin }: IEventElement) => {
  const snoozedUntilIso = event.snoozedUntil && parseISO(event.snoozedUntil)
  const fullName = getPerformedByFullName(event.performedBy, currentAdmin)
  const snoozedBy = event.performedBy ? `by ${fullName}` : 'automatically'

  return (
    <Typography color="text.secondary" variant="caption">
      <>The conversation was snoozed {snoozedBy}</>
      {snoozedUntilIso ? ` until ${format(snoozedUntilIso, MMM_DD_HH_MM_A)}` : ''}
      {' on '}
      <CreatedAtDate event={event} />
    </Typography>
  )
}
