import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { MentionOptionStyled, MentionOptionsPopupStyled } from 'components/MarkdownEditor/styles'
import { MentionElement, ShortcutsEditor } from 'components/MarkdownEditor/types'
import ReactDOM from 'react-dom'
import { Editor, Transforms, Range } from 'slate'
import { ReactEditor } from 'slate-react'
import { IUserMin } from 'types'

export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : ''
}

export const useSlateWithMentions = (editor: ShortcutsEditor, mentionOptions: IUserMin[]) => {
  const ref = useRef<HTMLDivElement>(null)
  const [target, setTarget] = useState<Range | undefined | null>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')

  const options = mentionOptions.filter((mention) =>
    mention.fullName.toLowerCase().startsWith(search.toLowerCase())
  )

  const insertMention = (editor: ShortcutsEditor, mentionedUser: IUserMin) => {
    const mention: MentionElement = {
      type: 'mention',
      mentionedUser: {
        name: mentionedUser.fullName,
        id: mentionedUser.id
      },
      children: [{ text: '' }]
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
  }

  const onKeyDown = useCallback(
    (event: any) => {
      if (target && options.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            setIndex(index >= options.length - 1 ? 0 : index + 1)
            break
          case 'ArrowUp':
            event.preventDefault()
            setIndex(index <= 0 ? options.length - 1 : index - 1)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, options[index])
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
    },
    [options, editor, index, target]
  )

  useEffect(() => {
    if (target && options.length > 0) {
      if (!ref.current) return
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()

      const listHeight = options.length * 24
      const bottomMargin = 24
      el.style.top = `${rect.top + window.scrollY - listHeight - bottomMargin}px`
      el.style.left = `${rect.left + window.scrollX}px`
    }
  }, [options, editor, index, search, target])

  const onChange = () => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange)
        setSearch(beforeMatch[1])
        setIndex(0)
        return
      }
    }

    setTarget(null)
  }

  const children = target && options.length > 0 && (
    <Portal>
      <MentionOptionsPopupStyled ref={ref}>
        {options.map((option, i) => {
          return (
            <MentionOptionStyled
              key={option.id}
              onClick={() => {
                Transforms.select(editor, target)
                insertMention(editor, option)
                setTarget(null)
              }}
              selected={i === index ? 1 : 0}
            >
              {option.fullName}
            </MentionOptionStyled>
          )
        })}
      </MentionOptionsPopupStyled>
    </Portal>
  )

  return { onKeyDown, onChange, children }
}
