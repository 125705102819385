import { useCallback, MouseEvent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import {
  gridFilteredSortedRowIdsSelector,
  GridFilterItemProps,
  useGridApiContext
} from '@mui/x-data-grid'

const CopyMenuItem = (props: GridFilterItemProps) => {
  const { onClick, column } = props

  const apiRef = useGridApiContext()

  const onColumnCopyClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onClick(event)

      const { field } = column
      const rowIds = gridFilteredSortedRowIdsSelector(apiRef)
      const values = rowIds.map((rowId) => {
        const cellParams = apiRef.current.getCellParams(rowId, field)
        return cellParams.value
      })
      const formattedValues = values.join('\n')
      navigator.clipboard.writeText(formattedValues)
    },
    [apiRef, column, onClick]
  )

  return <MenuItem onClick={onColumnCopyClick}>Copy column</MenuItem>
}

export default CopyMenuItem
