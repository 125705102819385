export enum FeatureFlags {
  CustomerSupportAdmin = 'customer_support_admin',
  EditProfile = 'edit_profile',
  FeatureAuthorization = 'feature_authorization',
  HqApprovals = 'hq_approvals',
  KnowledgeBaseAdmin = 'knowledge_base_admin',
  MemberNotes = 'member_notes',
  MobileAppPresentationMode = 'mobile_app_presentation_mode',
  NutritionManager = 'nutrition_manager',
  NutritionistHome = 'nutritionist_home',
  OpsAgent = 'ops_agent',
  OpsManager = 'ops_manager',
  OpsSupervisor = 'ops_supervisor',
  OpsTeamLead = 'ops_team_lead',
  TicketCommentSupport = 'ticket_comment_support',
  UserGoals = 'user_goals'
}
