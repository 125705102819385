import { Avatar, Stack, Typography } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const ChatConversationStackStyled = styled(Stack)(({ selected }: { selected: boolean }) => ({
  padding: `6px ${theme.spacing(1)}`,
  margin: theme.spacing(1),
  maxHeight: '90px',
  borderRadius: '4px',
  justifyContent: 'space-between',
  backgroundColor: selected ? '#7DA7DA4D' : '',
  '&:hover': {
    backgroundColor: !selected ? 'rgba(125, 167, 218, 0.1)' : '',
    cursor: 'pointer'
  }
}))

export const Dot = styled('span')({
  height: '10px',
  width: '10px',
  backgroundColor: '#7DA7DA',
  borderRadius: '50%',
  display: 'inline-block',
  marginTop: '10px'
})

export const SecondaryTypographyStyled = styled(Typography)({
  color: theme.palette['text-color'].secondary,
  fontSize: 13
})

export const LastMessageTypographyStyled = styled(SecondaryTypographyStyled)(
  ({ note }: { note?: number }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: theme.spacing(0.5),
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical' as const,
    borderLeft: note ? `medium solid ${theme.palette.success.light}` : '',
    paddingLeft: theme.spacing(1)
  })
)

export const AvatarStyled = styled(Avatar)({
  width: 32,
  height: 32,
  fontSize: 16
})
