import { Loader } from 'components'
import { Channel, StreamChat } from 'stream-chat'
import {
  Chat,
  Channel as ChannelComponent,
  EmptyStateIndicator,
  MessageInput,
  VirtualizedMessageList
} from 'stream-chat-react'
import {
  CustomMessage,
  CustomMessageInput,
  CustomMessageSystem,
  CustomReactionsList,
  CustomTriggerProvider,
  CustomTypingIndicator
} from './Custom'
import 'stream-chat-react/dist/css/v2/index.css'
import './styles.css'
import { ICustomActions } from './Custom/types'

interface IChatbotWindowProps {
  client: StreamChat | null
  channel: Channel | null
  customActions?: ICustomActions
  interactionEnabled: boolean
}

export const ChatbotWindow = ({
  client,
  channel,
  customActions,
  interactionEnabled
}: IChatbotWindowProps) => {
  if (!client) {
    return <Loader />
  }

  if (!channel) {
    return <EmptyStateIndicator listType="message" />
  }

  return (
    <Chat client={client}>
      <ChannelComponent
        channel={channel}
        MessageSystem={CustomMessageSystem}
        ReactionsList={CustomReactionsList}
        TriggerProvider={CustomTriggerProvider}
        TypingIndicator={CustomTypingIndicator}
      >
        <VirtualizedMessageList
          messageActions={[]}
          customMessageActions={customActions}
          Message={interactionEnabled ? CustomMessage : undefined}
        />
        {interactionEnabled && (
          <MessageInput noFiles disableMentions grow Input={CustomMessageInput} />
        )}
      </ChannelComponent>
    </Chat>
  )
}
