import { GridLinkOperator } from '@mui/x-data-grid'
import { getFilterModelQueryString } from 'components/DataGrid/QueryStringFilters'
import { KnowledgeResource } from 'knowledgeResource.types'
import { LocationPaths } from 'location.types'
import { IKnowledgeAnswer, IKnowledgeQuestion, IKnowledgeSource } from 'types'

export type KnowledgeItem = IKnowledgeAnswer | IKnowledgeQuestion | IKnowledgeSource

export const getLinkToKnowledgeItem = (
  resource: KnowledgeResource,
  item: KnowledgeItem | undefined
) => {
  if (!item) {
    return `${LocationPaths.Knowledge}/${resource}`
  }

  const queryString = getFilterModelQueryString({
    items: [{ columnField: 'id', operatorValue: '=', value: item.id }],
    linkOperator: GridLinkOperator.And
  })

  return `${LocationPaths.Knowledge}/${resource}?${queryString}`
}
