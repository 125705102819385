import { Divider, List, ListItem, Typography } from '@mui/material'
import { IMeasurement } from 'types'
import { getWellnessScore } from './helpers/getWellnessScore'

export const WellnessMeasurementList = ({
  measurements
}: {
  measurements: Pick<IMeasurement, 'id' | 'value' | 'title'>[]
}) => {
  if (!measurements || measurements.length === 0) return null
  return (
    <List>
      <ListItem disablePadding sx={{ justifyContent: 'space-between' }}>
        <Typography fontWeight="bold">Wellness measurements</Typography>
      </ListItem>
      <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
      {measurements.map((measurement) => {
        const Emoji = getWellnessScore(measurement.value)
        if (!Emoji) return null

        return (
          <ListItem
            sx={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'space-between' }}
            key={measurement.id}
          >
            <Typography variant="caption" fontWeight={400}>
              {measurement.title}
            </Typography>
            <Emoji style={{ float: 'right', width: 20, height: 20 }} />
          </ListItem>
        )
      })}
    </List>
  )
}
