import React from 'react'
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { Loader, WithCopyToClipboardAction } from 'components'
import { useUnitSystem } from 'context'
import { statsDrawerVisibleVar } from 'screens/UserDetails/Stats/context'
import {
  getStatTitle,
  getTrendArrow,
  getStatUnit,
  getValueChangeText,
  getValueColor
} from 'utility/statistic'
import { IRangeUnit, IStatistic, useClientConfigQuery } from 'types'
import { InlineTypographyStyled } from './styles'

interface IStatCardProps {
  stat: IStatistic
}

export const StatCard = ({ stat }: IStatCardProps) => {
  const { unitSystem } = useUnitSystem()
  const { value } = stat

  const { data: clientConfigData, loading } = useClientConfigQuery()
  const allRangeConfigs = clientConfigData?.clientConfig?.insights?.statistics || []
  const rangeConfig = allRangeConfigs.find(({ name }) => name === stat.meta.key)?.[unitSystem]

  const valueColor = getValueColor(stat as IStatistic, rangeConfig?.ranges as IRangeUnit[])
  const unit = getStatUnit(stat, unitSystem)
  const tooltipTitle = getValueChangeText(stat, unit)

  const TrendArrow = getTrendArrow(stat)

  return (
    <Grid item xs>
      {loading ? (
        <Loader />
      ) : (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <WithCopyToClipboardAction value={`${value}${unit}`}>
            <Typography noWrap sx={{ fontSize: 14, fontWeight: 500 }}>
              {getStatTitle(stat)}
            </Typography>
          </WithCopyToClipboardAction>
          <Tooltip title={tooltipTitle}>
            <Box
              onClick={() => {
                statsDrawerVisibleVar(true)
              }}
              sx={{ cursor: 'pointer' }}
            >
              {value === 0 ? (
                <InlineTypographyStyled fontSize={20}>N/A</InlineTypographyStyled>
              ) : (
                <Box>
                  <InlineTypographyStyled sx={{ color: valueColor, fontSize: 20, fontWeight: 600 }}>
                    {value}
                  </InlineTypographyStyled>
                  <InlineTypographyStyled sx={{ color: valueColor, fontSize: 14, fontWeight: 500 }}>
                    {unit}
                  </InlineTypographyStyled>
                  <InlineTypographyStyled sx={{ verticalAlign: 'middle' }}>
                    {TrendArrow && <TrendArrow fontSize="small" />}
                  </InlineTypographyStyled>
                </Box>
              )}
            </Box>
          </Tooltip>
        </Stack>
      )}
    </Grid>
  )
}
