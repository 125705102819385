import React from 'react'
import { Divider, List, ListSubheader, Typography } from '@mui/material'
import { StrongTypographyStyled } from 'styles'
import { theme } from 'theme'
import { statColor } from 'utility'
import { ListItemBoxStyled, ListItemStyled } from '.'
import { Stat } from './types'

interface IStatList {
  statSection: string
  statList: Stat[]
}

export const StatList = ({ statSection, statList }: IStatList) => (
  <List
    sx={{ width: '100%', paddingBottom: 0 }}
    subheader={
      <ListSubheader
        sx={{
          color: 'text.primary',
          textTransform: 'capitalize',
          backgroundColor: theme.palette.grey[200]
        }}
        id="nested-list-subheader"
      >
        {statSection}
      </ListSubheader>
    }
  >
    {statList &&
      statList.map((glucoseStat) => (
        <React.Fragment key={`${glucoseStat.meta.key}_${statSection}`}>
          <Divider />
          <ListItemStyled
            sx={{
              borderLeftColor: statColor(glucoseStat)
            }}
          >
            <ListItemBoxStyled>
              <Typography>{glucoseStat.title}</Typography>
              <StrongTypographyStyled>{glucoseStat.value}</StrongTypographyStyled>
            </ListItemBoxStyled>
            <ListItemBoxStyled>
              <Typography color="text.secondary">Last: {glucoseStat.valuePrev}</Typography>
              <Typography color="text.secondary">Change: {glucoseStat.change}%</Typography>
            </ListItemBoxStyled>
          </ListItemStyled>
        </React.Fragment>
      ))}
  </List>
)
