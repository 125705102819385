import { UserApprovalRequestState } from 'types'

export const QUICK_FILTERS_DEFINITIONS = [
  {
    name: 'Approvals',
    items: [
      {
        columnField: 'lastPrescriptionApprovalRequest.state',
        operatorValue: 'is',
        value: UserApprovalRequestState.PendingApproval
      }
    ]
  },
  {
    name: 'Rejections',
    items: [
      {
        columnField: 'lastPrescriptionApprovalRequest.state',
        operatorValue: 'is',
        value: UserApprovalRequestState.Rejected
      }
    ]
  }
]
