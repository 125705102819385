import { Box, Typography, styled } from '@mui/material'
import { lineClampStyles } from 'utility'

export const LineClampedTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxLines'
})((props: { maxLines: number }) => ({
  ...lineClampStyles(props.maxLines)
}))

export const LineClampedBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maxLines'
})((props: { maxLines: number }) => ({
  ...lineClampStyles(props.maxLines)
}))
