import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { IModalProps } from 'hooks'
import { Controller, useForm } from 'react-hook-form'
import { SensorKindProductName } from 'screens/Orders/constants'
import {
  UserSensorKind,
  useCurrentUserQuery,
  useUpdateFulfillmentConfigurationMutation
} from 'types'

interface IEditFulfillmentConfigurationForm {
  sensorKind: UserSensorKind
}

export const EditFulfillmentConfigurationDialog = ({ closeModal, modalState }: IModalProps) => {
  const { data: { currentUser } = {}, refetch } = useCurrentUserQuery()

  const { handleSubmit, control } = useForm<IEditFulfillmentConfigurationForm>({
    mode: 'onTouched',
    defaultValues: {
      sensorKind: currentUser?.fulfillmentConfiguration.sensorKind
    } as Partial<IEditFulfillmentConfigurationForm>
  })

  const [updateFulfillmentConfiguration, { loading }] = useUpdateFulfillmentConfigurationMutation({
    onError: (error) =>
      snackBarVisibleVar({
        open: true,
        message: error.message
      }),
    onCompleted: () => {
      closeModal()
      refetch()
    }
  })

  const updateFulfillmentConfigurationHandler = async (
    formData: IEditFulfillmentConfigurationForm
  ) => {
    if (!currentUser) {
      return
    }

    await updateFulfillmentConfiguration({
      variables: { userId: currentUser?.id, sensorKind: formData.sensorKind }
    })
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={modalState} onClose={closeModal}>
      <form onSubmit={handleSubmit(updateFulfillmentConfigurationHandler)}>
        <DialogTitle>Edit Member Fulfillment Configuration</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" margin="dense" fullWidth>
            <InputLabel required id="sensor-kind-label">
              Sensor Kind
            </InputLabel>
            <Controller
              control={control}
              name="sensorKind"
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <Select
                    labelId="sensor-kind-label"
                    label="Sensor Kind"
                    onChange={onChange}
                    value={value}
                    ref={ref}
                  >
                    {Object.values(UserSensorKind).map((sensorKind) => (
                      <MenuItem
                        key={sensorKind}
                        disabled={
                          value === UserSensorKind.DexcomG7
                            ? true
                            : sensorKind === UserSensorKind.DexcomG7
                        }
                        value={sensorKind}
                      >
                        {SensorKindProductName[sensorKind]}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <LoadingButton type="submit" loading={loading}>
            Change Sensor Kind
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
