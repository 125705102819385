import { CoachAssignmentKind, ISubscription } from 'types'

export const PAID_ADD_ON = 'Paid add-on'
export const PAID_ADD_ON_COMPLIMENTARY = 'Paid add-on complimentary'
export const NO_ADD_ON = 'No add-on'
export const STRIPE_STATUS_TRIALING = 'trialing'

export const tooltipLookup = {
  [PAID_ADD_ON]:
    "Member paid for additional nutritionist support. There isn't a date to finish the nutritionist support.",
  [PAID_ADD_ON_COMPLIMENTARY]:
    "Member is still on the complimentary period but already subscribed to additional nutritionist support. There isn't a date to finish the nutritionist support.",
  [NO_ADD_ON]: 'Member does not have nutritionist Add-on',
  [CoachAssignmentKind.Influencer]:
    'Some influencers do not have a nutritionist add-on subscription. In that case, you can use the "Payment Past Plan Type" column to check if the influencer has one or two sensors of nutritionist support.'
}

export const coachEndDateDescriptor = ({
  subscription,
  lastCoachAssignmentKind
}: {
  subscription?: ISubscription | null
  lastCoachAssignmentKind?: CoachAssignmentKind | null
}) => {
  if (lastCoachAssignmentKind === CoachAssignmentKind.Influencer) {
    return CoachAssignmentKind.Influencer
  }
  if (subscription && subscription.dietitianAddOn) {
    return subscription.stripeStatus === STRIPE_STATUS_TRIALING
      ? PAID_ADD_ON_COMPLIMENTARY
      : PAID_ADD_ON
  }
  return NO_ADD_ON
}
