import { Box, Button, IconButton, LinearProgress, Stack, Typography, styled } from '@mui/material'
import { theme } from 'theme'

export const CustomMessageInputContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center'
})

export const CustomMessageInputTextareaContainer = styled(Box)({
  flexGrow: 1,
  '& textarea': {
    flexGrow: 1,
    padding: '11px 16px 13px',
    width: '100%',
    maxHeight: '106px',
    borderWidth: 1,
    borderColor: theme.palette['theme-primary']['level-3'],
    borderRadius: '20px',
    resize: 'none'
  }
})

export const CustomMessageInputSendContainer = styled(Box)({
  flexShrink: 0,
  '& button': {
    flexShrink: 0,
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }
})

export const CustomMessageTextBubble = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'myMessage'
})((props: { myMessage: boolean }) => ({
  flexDirection: 'row',
  alignItems: 'flex-end',
  margin: '8px 0',
  borderWidth: '1px',
  ...(props.myMessage
    ? {
        padding: '8px 16px',
        borderRadius: '16px 16px 0 16px',
        backgroundColor: '#f2f4f5'
      }
    : {
        padding: '8px 8px 8px 16px',
        borderStyle: 'solid',
        borderRadius: '16px 16px 16px 0',
        borderColor: '#9daeae'
      }),
  '& p': {
    margin: '8px'
  }
}))

export const CustomMessageTextBubbleSources = styled(IconButton)({
  margin: '8px 0',
  borderRadius: '8px',
  color: 'white',
  width: '24px',
  height: '24px',
  backgroundColor: theme.palette.icon.primary,
  '&:hover': {
    backgroundColor: theme.palette.icon.secondary
  },
  '& .MuiSvgIcon-root': {
    fontSize: '14px'
  }
})

export const CopyButton = styled(Button)({
  padding: '0 8px',
  color: theme.palette['text-color'].primary,
  fontSize: '11px',
  '& .MuiButton-endIcon svg': {
    fontSize: '14px'
  }
})

export const TypingIndicatorContainer = styled(Stack)({
  padding: '16px 0 8px',
  gap: '4px'
})

export const TypingIndicatorMessage = styled(Typography)({
  fontSize: '12px',
  color: theme.palette['text-color'].secondary
})

export const TypingIndicatorProgress = styled(LinearProgress)({
  borderRadius: '2px'
})
