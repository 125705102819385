import { format } from 'date-fns'
import { parseUtcDate } from './dateParsers'
import { MM_DD_YYYY } from './timeFormats'

export const dateOfBirthFormatter = (dateOfBirth: string) => {
  const date = parseUtcDate(dateOfBirth)
  if (!date) return null

  return format(date, MM_DD_YYYY)
}
