import { useState } from 'react'
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'

import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { startCase } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import {
  BillingProduct,
  HealthPortalFeature,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useEligibleUpdateProductsQuery,
  useUpdateAccountRequestMutation
} from 'types'

interface IUpdateSubscriptionForm {
  nextProduct: BillingProduct | ''
  waiveFee: boolean
  priority: OpsAccountRequestPriority
  note: string
}

export const UpdateSubscriptionModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const [fee, setFee] = useState<number | undefined>(undefined)
  const { handleSubmit, control, register, reset, formState, watch } =
    useForm<IUpdateSubscriptionForm>({
      mode: 'onTouched',
      defaultValues: {
        nextProduct: '',
        waiveFee: false,
        priority: OpsAccountRequestPriority.Regular,
        note: ''
      }
    })
  const watchWaiveFee = watch('waiveFee')
  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessUpdateSubscription
  )

  const { data: eligibleUpdateProductsData } = useEligibleUpdateProductsQuery({
    variables: { userId: currentUser!.id }
  })

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser || !currentUser.ongoingCoreSubscription?.id) {
    return null
  }

  const updateProducts =
    eligibleUpdateProductsData?.eligibleUpdateProducts?.eligibleUpdateProducts || []
  const selectFee = (nextProduct: string) => {
    setFee(
      updateProducts
        .find((updateProduct) => updateProduct.product.key === nextProduct)
        ?.feeRules?.find(
          (feeRule) =>
            feeRule.paidCyclesCount === currentUser?.ongoingCoreSubscription?.paidMonthsCount
        )?.fee
    )
  }

  const updateSubscriptionHandler = async (formData: IUpdateSubscriptionForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.UpdateSubscription,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.UpdateSubscription,
          updateSubscription: {
            oldProduct: currentUser.ongoingCoreSubscription!.primaryProduct.key as BillingProduct,
            nextProduct: formData.nextProduct as BillingProduct,
            waiveFee: formData.waiveFee
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const innerForm = (
    <>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Select Plan</InputLabel>
        <Controller
          name="nextProduct"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('nextProduct', { required: true })}
              error={!!formState.errors.nextProduct}
              label="Select Plan"
            >
              <MenuItem value="" disabled>
                <em>Select Plan</em>
              </MenuItem>
              {updateProducts.map((updateProduct) => (
                <MenuItem
                  key={updateProduct.product.key}
                  value={updateProduct.product.key}
                  onClick={() => selectFee(updateProduct.product.key)}
                >
                  {startCase(updateProduct.product.title)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {fee && !watchWaiveFee && (
        <FormControl margin="dense" fullWidth>
          <Alert severity="info" variant="outlined">
            Member will be charged ${fee}.00
          </Alert>
        </FormControl>
      )}
      {fee && (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <Controller
            name="waiveFee"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                }
                label="Waive fee?"
              />
            )}
          />
        </FormControl>
      )}
    </>
  )

  return (
    <AccountRequestModal<IUpdateSubscriptionForm>
      register={register}
      modalTitle="Update Subscription"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(updateSubscriptionHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
