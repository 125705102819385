import { snackBarVisibleVar } from 'context'
import { IUpdateSettingsMutationVariables, useUpdateSettingsMutation } from 'types'

export const useSaveUserSetting = (onCompleted: () => void = () => undefined) => {
  const [updateSettings] = useUpdateSettingsMutation({
    onError: (_: Error) => {
      snackBarVisibleVar({
        open: true,
        message: 'User Settings update failed. Please try again later'
      })
    },
    onCompleted
  })

  const saveUserSetting = async (newSettings: IUpdateSettingsMutationVariables) => {
    updateSettings({ variables: newSettings })
  }

  return saveUserSetting
}
