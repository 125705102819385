import { cloneElement, forwardRef } from 'react'
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridColumnsMenuItem,
  GridFilterMenuItem,
  HideGridColMenuItem,
  SortGridMenuItems,
  useGridApiContext
} from '@mui/x-data-grid'
import CopyMenuItem from './CopyMenuItem'

const ColumnMenu = forwardRef<HTMLUListElement, GridColumnMenuProps>(function GridColumnMenu(
  props: GridColumnMenuProps,
  ref
) {
  // eslint-disable-next-line react/prop-types
  const { hideMenu, currentColumn } = props
  const apiRef = useGridApiContext()

  const defaultButtons = [
    <SortGridMenuItems key="sort" onClick={hideMenu} column={currentColumn!} />,
    <GridFilterMenuItem key="filter" onClick={hideMenu} column={currentColumn!} />,
    <HideGridColMenuItem key="hide" onClick={hideMenu} column={currentColumn!} />,
    <GridColumnsMenuItem key="grid" onClick={hideMenu} column={currentColumn!} />,
    <CopyMenuItem key="copy" onClick={hideMenu} column={currentColumn!} />
  ]

  const preProcessedButtons = apiRef.current.unstable_applyPipeProcessors(
    'columnMenu',
    defaultButtons,
    currentColumn
  )

  return (
    <GridColumnMenuContainer ref={ref} {...props}>
      {preProcessedButtons.map((button: any, index: number) =>
        cloneElement(button, { key: index, onClick: hideMenu, column: currentColumn })
      )}
    </GridColumnMenuContainer>
  )
})

export default ColumnMenu
