const LONG_TO_SHORT_VALUES = {
  second: 's',
  seconds: 's',
  minute: 'm',
  minutes: 'm',
  hour: 'h',
  hours: 'h',
  day: 'd',
  days: 'd',
  month: 'mo',
  months: 'mo',
  year: 'y',
  years: 'y'
}

export const shortenDistanceTime = (distance: string) => {
  const array = distance.split(' ', 2) as [string, keyof typeof LONG_TO_SHORT_VALUES]
  return `${array[0]}${LONG_TO_SHORT_VALUES[array[1]]}`
}
