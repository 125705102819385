import Check from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useMenu } from 'hooks'

export interface IGridToolbarQuickFiltersProps {
  quickFilters?: {
    name: string
    onClick: () => void
    isSelected: boolean
  }[]
  resetFilterModel: () => void
}

const GridToolbarQuickFilters = (props: IGridToolbarQuickFiltersProps) => {
  const { quickFilters, resetFilterModel } = props

  const { menuAnchorEl, menuState, openMenu, closeMenu } = useMenu()

  if (!quickFilters || quickFilters.length === 0) {
    return null
  }

  const isSelected = quickFilters.some(({ isSelected }) => isSelected)

  return (
    <>
      <Button size="small" variant={isSelected ? 'outlined' : 'text'} onClick={openMenu}>
        Quick Filters
      </Button>
      <Menu anchorEl={menuAnchorEl} open={menuState} onClose={closeMenu}>
        {isSelected && (
          <MenuItem
            onClick={() => {
              resetFilterModel()
              closeMenu()
            }}
          >
            <ListItemIcon>
              <ClearIcon color="error" />
            </ListItemIcon>
            Remove quick filter
          </MenuItem>
        )}
        {quickFilters.map((quickFilter) => (
          <MenuItem
            key={quickFilter.name}
            onClick={() => {
              quickFilter.onClick()
              closeMenu()
            }}
          >
            {quickFilter.isSelected && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {quickFilter.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default GridToolbarQuickFilters
