import { formatRelative } from 'date-fns'
import { enUS } from 'date-fns/locale'

export const formatRelativeWithEnhancedLocale = (isoDateString: string, baseDate = new Date()) => {
  const parsedDate = Date.parse(isoDateString)

  if (Number.isNaN(parsedDate)) return

  const formatRelativeLocale = {
    lastWeek: "'last' eeee 'at' p",
    yesterday: "'Yesterday at' p",
    today: "'Today at' p",
    tomorrow: "'Tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: 'P'
  }

  const locale = {
    ...enUS,
    formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token]
  }

  return formatRelative(new Date(parsedDate), baseDate, { locale })
}
