import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { IModalProps } from 'hooks'
import { Controller, useForm } from 'react-hook-form'
import { requiredFn, zipCodeFn } from 'utility/validators'
import { AddressCountry, AddressState, useCurrentUserQuery, useUpdateAddressMutation } from 'types'

interface IEditAddressForm {
  street: string
  street2: string
  city: string
  zipCode: string
  country: AddressCountry
  state: AddressState
}

const stateOptions = Object.values(AddressState)
const countryOptions = Object.values(AddressCountry)

export const EditAddressDialog = ({ closeModal, modalState }: IModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IEditAddressForm>({
    mode: 'onTouched',
    defaultValues: (currentUser?.address || {}) as Partial<IEditAddressForm>
  })

  const [updateAddress, { loading: updateAddressLoading }] = useUpdateAddressMutation({
    onError: (error) =>
      snackBarVisibleVar({
        open: true,
        message: error.message
      }),
    onCompleted: () => closeModal()
  })

  if (!currentUser) {
    return null
  }

  const updateAddressHandler = async (formData: IEditAddressForm) => {
    await updateAddress({ variables: formData })
  }

  return (
    <Dialog open={modalState} onClose={closeModal}>
      <DialogTitle>Edit Member Address</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(updateAddressHandler)}>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Controller
              control={control}
              name="country"
              rules={{ required: true, validate: requiredFn }}
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_e, value) => onChange(value)}
                    options={countryOptions}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        ref={ref}
                        error={!!errors.country}
                        variant="standard"
                        label="Country"
                      />
                    )}
                  />
                )
              }}
            />
          </FormControl>
          <TextField
            label="Street"
            margin="dense"
            fullWidth
            variant="standard"
            error={!!errors.street}
            {...register('street', { required: true, validate: requiredFn })}
          />
          <TextField
            label="Street 2"
            margin="dense"
            fullWidth
            variant="standard"
            error={!!errors.street2}
            {...register('street2')}
          />
          <FormControl variant="standard" margin="dense" fullWidth>
            <Controller
              control={control}
              name="state"
              rules={{ required: true, validate: requiredFn }}
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_e, value) => onChange(value)}
                    options={stateOptions}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        ref={ref}
                        error={!!errors.state}
                        variant="standard"
                        label="State"
                      />
                    )}
                  />
                )
              }}
            />
          </FormControl>
          <TextField
            label="City"
            margin="dense"
            fullWidth
            variant="standard"
            error={!!errors.city}
            {...register('city', { required: true, validate: requiredFn })}
          />
          <TextField
            label="Zip Code"
            margin="dense"
            fullWidth
            variant="standard"
            error={!!errors.zipCode}
            {...register('zipCode', { required: true, validate: zipCodeFn })}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <LoadingButton loading={updateAddressLoading} onClick={handleSubmit(updateAddressHandler)}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
