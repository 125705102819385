import { cache } from 'config/apolloClient'
import { AllUserProperties } from 'gql/queries/allUserProperties'
import { IAllUserPropertiesQuery, IUpdateUserPropertyMutation } from 'types'

export const addElementToAllUserProperties = (data: IUpdateUserPropertyMutation) => {
  cache.updateQuery(
    {
      query: AllUserProperties
    },
    (oldData: IAllUserPropertiesQuery) => {
      const newUserProperties = (oldData?.allUserProperties?.userProperties || []).concat({
        ...data.updateUserProperty
      })
      return {
        allUserProperties: {
          userProperties: newUserProperties
        }
      }
    }
  )
}
