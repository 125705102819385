import {
  GlucoseStats,
  JournalStats,
  KetoneStats,
  MacroStats,
  MeasurementStats,
  StatSections
} from './types'

export const STAT_KEY_TO_NAME = {
  standard_deviation: 'Variability',
  mean: 'Average',
  step_count: 'Steps'
}

export const SECTION_STAT_KEY_TO_UNIT = {
  [StatSections.Glucose]: {
    [GlucoseStats.TimeWithinRange]: '%',
    [GlucoseStats.StandardDeviation]: 'mg/dL',
    [GlucoseStats.Mean]: 'mg/dL',
    [GlucoseStats.Min]: 'mg/dL',
    [GlucoseStats.Max]: 'mg/dL',
    [GlucoseStats.SleepAverage]: 'mg/dL',
    [GlucoseStats.MorningAverage]: 'mg/dL'
  },
  [StatSections.Macros]: {
    [MacroStats.Calories]: 'kcal',
    [MacroStats.Protein]: 'g',
    [MacroStats.Carbs]: 'g',
    [MacroStats.Fat]: 'g'
  },
  [StatSections.Measurements]: {
    [MeasurementStats.BodyFatPercentage]: '%',
    [MeasurementStats.BodyTemperature]: '°F',
    [MeasurementStats.Weight]: 'pounds',
    [MeasurementStats.Stress]: 'stress',
    [MeasurementStats.BloodGlucose]: 'mg/dL',
    [MeasurementStats.BloodKetones]: 'mmol/L',
    [MeasurementStats.BreathKetones]: 'aces',
    [MeasurementStats.StepCount]: 'steps',
    [MeasurementStats.FlightsClimbed]: 'flights',
    [MeasurementStats.ExerciseTime]: 'minutes',
    [MeasurementStats.RunningDistance]: 'miles',
    [MeasurementStats.CyclingDistance]: 'miles'
  },
  [StatSections.Ketones]: {
    [KetoneStats.TimeWithinRange]: '%',
    [KetoneStats.Mean]: 'mmol/L',
    [KetoneStats.Min]: 'mmol/L',
    [KetoneStats.Max]: 'mmol/L'
  },
  [StatSections.Journal]: {
    [JournalStats.Appetite]: '',
    [JournalStats.Mood]: '',
    [JournalStats.Stress]: '',
    [JournalStats.Focus]: '',
    [JournalStats.Energy]: ''
  }
}
