import { FC, ReactElement } from 'react'
import { Box } from '@mui/system'
import { Loader } from 'components'
import { useAuth } from 'context'
import { useAnalytics } from 'hooks/analytics'
import { useLocation } from 'react-router-dom'
import { HealthPortalFeature } from 'types'

interface IPrivateRoute {
  feature: HealthPortalFeature
  children: ReactElement<any, any>
}

export const PrivateRoute: FC<IPrivateRoute> = ({ feature, children }) => {
  const { isAuthorized, loading } = useAuth()
  const { trackPage } = useAnalytics()
  const location = useLocation()

  trackPage(location.pathname)

  if (isAuthorized(feature) && !loading) {
    return children
  }

  return (
    <Box sx={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}
