import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useReactiveVar } from '@apollo/client'
import { userIdLink } from 'config/userIdLink'
import { selectedAppointmentIdVar } from 'context/nutritionistHome/selectedAppointmentIdVar'
import CustomEventTypes from 'hooks/analytics/customEventTypes'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { minBy } from 'lodash'
import { Calendar, Culture, DateLocalizer, Views } from 'react-big-calendar'
import { IAppointment, useCurrentUserLazyQuery } from 'types'
import { CalendarEvent } from './CalendarEvent'
import { StyledCalendar } from './styles'
import { IEvent } from './types'
import { dayPropGetter, eventPropGetter, localizer, toEvent } from './utils'

type INutritionistCalendarProps = {
  date: Date
  appointments: IAppointment[]
}

export const NutritionistCalendar = ({ appointments, date }: INutritionistCalendarProps) => {
  const [events, setEvents] = useState<IEvent[]>([])
  const [calendarKey, setCalendarKey] = useState(0)
  const { track } = useSegmentInteraction()
  const selectedAppointmentId = useReactiveVar(selectedAppointmentIdVar)
  const [getCurrentUser] = useCurrentUserLazyQuery({
    fetchPolicy: 'cache-and-network'
  })
  const { views, formats, components } = useMemo(
    () => ({
      views: [Views.DAY],
      formats: {
        timeGutterFormat: (date: Date, culture: Culture, localizer: DateLocalizer) =>
          localizer.format(date, 'HH:mm', culture)
      },
      components: {
        event: CalendarEvent
      }
    }),
    []
  )

  const onSelectEvent = useCallback(
    (event: IEvent) => {
      selectedAppointmentIdVar(event.id)
      userIdLink.userId = event.userId
      getCurrentUser()
    },
    [getCurrentUser]
  )

  useEffect(() => {
    if (selectedAppointmentId) track(CustomEventTypes.NutritionistHomeCalendarEventClicked)
  }, [selectedAppointmentId, track])

  useEffect(() => {
    if (appointments?.length) {
      const newEvents = appointments.map(toEvent)
      setEvents(newEvents)
    }
  }, [appointments])

  useEffect(() => {
    if (!events.length) return

    if (selectedAppointmentId) {
      const selectedEvent = events.find((event) => event.id === selectedAppointmentId)
      if (selectedEvent) {
        onSelectEvent(selectedEvent)
        return
      }
    }
    const now = new Date()
    const incomingEvents = events.filter((event) => event.end > now)
    onSelectEvent(minBy(incomingEvents.length ? incomingEvents : events, 'start')!)
  }, [events, onSelectEvent, selectedAppointmentId])

  useEffect(() => {
    // re-render calendar when date changes
    setCalendarKey((prev) => prev + 1)
  }, [date])

  useEffect(() => {
    document.querySelector('.rbc-event')?.scrollIntoView()
  }, [events])

  return (
    <StyledCalendar>
      <Calendar
        key={calendarKey}
        events={events}
        localizer={localizer}
        defaultDate={date}
        defaultView="day"
        views={views}
        dayLayoutAlgorithm="no-overlap"
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        formats={formats}
        components={components}
        enableAutoScroll
        onSelectEvent={onSelectEvent}
      />
    </StyledCalendar>
  )
}
