import { makeVar } from '@apollo/client'
import {
  GridFilterItem,
  GridFilterModel,
  GridLinkOperator,
  GridSortDirection,
  GridSortModel
} from '@mui/x-data-grid'
import { DEFAULT_PER_PAGE_VALUE, IDataGridColumn } from 'components/DataGrid/DataGrid'
import { getFiltersFromFilterItem } from 'components/DataGrid/helpers/getFiltersFromFilterItem'
import { getSortByFromSortItem } from 'components/DataGrid/helpers/getSortByFromSortItem'
import { loadState } from 'utility/dataGrid'
import { DynamicFilterItemOperation, DynamicFilterOperator } from 'types'

export const defaultFilterModel: GridFilterModel = {
  items: [],
  linkOperator: GridLinkOperator.And
}

export const buildVars = (columns: IDataGridColumn[], stateKey: string | undefined = undefined) => {
  const initialState = stateKey ? loadState(stateKey) : undefined

  const dynamicFilters = initialState?.filter?.filterModel?.items?.flatMap(
    (item: GridFilterItem) => getFiltersFromFilterItem(item, columns) ?? []
  )

  return {
    pageVar: makeVar(initialState?.pagination?.page || 1),
    perPageVar: makeVar(initialState?.pagination?.pageSize || DEFAULT_PER_PAGE_VALUE),
    sortByVar: makeVar(getSortByFromSortItem(initialState?.sorting?.sortModel?.[0], columns)),
    sortOrderVar: makeVar<GridSortDirection>(initialState?.sorting?.sortModel?.[0]?.sort),
    dynamicFiltersVar: makeVar<
      { field: string; operation: DynamicFilterItemOperation; value: string }[]
    >(dynamicFilters || []),
    dynamicFilterOperatorVar: makeVar<DynamicFilterOperator>(
      (initialState?.filter?.filterModel.linkOperator as unknown as DynamicFilterOperator) ||
        DynamicFilterOperator.And
    ),
    filterModelVar: makeVar<GridFilterModel>(
      initialState?.filter?.filterModel || defaultFilterModel
    ),
    sortModelVar: makeVar<GridSortModel>(initialState?.sorting?.sortModel || [])
  }
}

export type GridVars = ReturnType<typeof buildVars>
