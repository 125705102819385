import { useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { Button, Paper, Typography } from '@mui/material'
import { DataGrid as ServerDataGrid, DataGridHandle } from 'components'
import { snackBarVisibleVar } from 'context'
import gridVars from 'context/billingDashboard/invoices'
import { getStripeRowsClassName } from 'utility/dataGrid'
import {
  IBillingDashboardAllInvoicesQuery,
  useCreateSyncRequestMutation,
  useCurrentUserQuery
} from 'types'
import { columns, DATA_GRID_STATE_KEY } from './constants'

interface IInvoicesProps {
  data: IBillingDashboardAllInvoicesQuery | undefined
  loading: boolean
  error: ApolloError | undefined
  refetch: any
}

export const Invoices = ({ data, loading, error, refetch }: IInvoicesProps) => {
  const dataGridRef = useRef<DataGridHandle>(null)

  const rowCount = data?.allInvoices.totalCount
  const invoices = data?.allInvoices.invoices || []
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const [createSyncRequest] = useCreateSyncRequestMutation({
    onError: (error) =>
      snackBarVisibleVar({
        open: true,
        message: error.message
      }),
    onCompleted: () =>
      snackBarVisibleVar({
        severity: 'success',
        open: true,
        message: 'Refresh scheduled! Please reload this page in a few minutes to download invoices.'
      })
  })

  const handleRefresh = () => {
    if (currentUser) {
      createSyncRequest({
        variables: { userId: currentUser.id }
      })
    }
  }

  return (
    <Paper>
      <Typography variant="h6" mt={2} ml={2}>
        Invoices{' '}
        {invoices.length > 0 && <Button onClick={handleRefresh}>Refresh Expired Invoices</Button>}
      </Typography>
      <ServerDataGrid
        ref={dataGridRef}
        rows={invoices}
        columns={columns}
        loading={loading}
        error={error}
        gridVars={gridVars}
        refresh={refetch}
        rowCount={rowCount}
        getRowClassName={getStripeRowsClassName}
        stateKey={DATA_GRID_STATE_KEY}
        autoHeight
        noToolbar
      />
    </Paper>
  )
}
