import { Box, Popover, PopoverProps } from '@mui/material'
import { MetricList } from 'components/Events/EventCard/MetricList'
import { startCase } from 'lodash'
import { theme } from 'theme'
import { units } from './units'

export interface IDetailedNutritionPopoverProps {
  nutrition: Record<string, number>
}

const DETAILED_NUTRITION_ID = 'detailed-nutrition-popover'

export const DetailedNutritionPopover = ({
  nutrition,
  onClose,
  anchorEl
}: IDetailedNutritionPopoverProps & Partial<PopoverProps>) => {
  const entries = Object.keys(nutrition).map((key) => ({
    label: startCase(key),
    key
  }))

  const open = Boolean(anchorEl)
  return (
    <Popover
      id={DETAILED_NUTRITION_ID}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <Box
        sx={{
          minWidth: 400,
          padding: 1,
          maxHeight: 800,
          'ul > li:nth-of-type(even)': { backgroundColor: theme.palette.grey[200] }
        }}
      >
        <MetricList
          title="Detailed Nutrition"
          data={nutrition}
          metricEntries={entries}
          units={units}
        />
      </Box>
    </Popover>
  )
}
