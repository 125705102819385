import { useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { Paper, Typography } from '@mui/material'
import { DataGrid as ServerDataGrid, DataGridHandle } from 'components'
import gridVars from 'context/billingDashboard/subscriptions'
import { getStripeRowsClassName } from 'utility/dataGrid'
import { IBillingDashboardAllSubscriptionsQuery } from 'types'
import { columns, DATA_GRID_STATE_KEY } from './constants'

interface ISubscriptionsProps {
  data: IBillingDashboardAllSubscriptionsQuery | undefined
  loading: boolean
  error: ApolloError | undefined
  refetch: any
}

export const Subscriptions = ({ data, loading, error, refetch }: ISubscriptionsProps) => {
  const dataGridRef = useRef<DataGridHandle>(null)

  const rowCount = data?.allSubscriptions.totalCount
  const subscriptions = data?.allSubscriptions.subscriptions || []

  return (
    <Paper>
      <Typography variant="h6" mt={2} ml={2}>
        Subscriptions
      </Typography>
      <ServerDataGrid
        ref={dataGridRef}
        rows={subscriptions}
        columns={columns}
        loading={loading}
        error={error}
        gridVars={gridVars}
        refresh={refetch}
        rowCount={rowCount}
        getRowClassName={getStripeRowsClassName}
        stateKey={DATA_GRID_STATE_KEY}
        autoHeight
        noToolbar
      />
    </Paper>
  )
}
