import { Dialog, DialogContent, DialogTitle, Menu, MenuItem } from '@mui/material'
import { AuthorizedFeature } from 'components'
import { EditCoachProfileDialog } from 'components/CoachProfile/EditCoachProfileDialog'
import { useTimeTracker } from 'components/TimeTracker/TimeTrackerProvider'
import { useCanEditCoachProfile } from 'components/UserProfile/hooks/useCanEditCoachProfile'
import { useAuth } from 'context'
import { useModal } from 'hooks'
import { HealthPortalFeature } from 'types'
import { EditAddressDialog } from './EditAddressDialog'
import { EditFulfillmentConfigurationDialog } from './EditFulfillmentConfigurationDialog'
import { EditProfileDialog } from './EditProfileDialog'
import { OrganizationDialog } from './OrganizationDialog'
import { Profile } from './Profile'

interface IUserMenu {
  anchorEl: HTMLElement | null
  state: boolean
  close: () => void
}

export const UserMenu = ({ anchorEl, state, close }: IUserMenu) => {
  const {
    modalState: profileModalState,
    openModal: openProfileModal,
    closeModal: closeProfileModal
  } = useModal()
  const {
    modalState: organizationModalState,
    openModal: openOrganizationModal,
    closeModal: closeOrganizationModal
  } = useModal()
  const {
    modalState: editProfileModalState,
    openModal: openEditProfileModal,
    closeModal: closeEditProfileModal
  } = useModal()
  const {
    modalState: editAddressModalState,
    openModal: openEditAddressModal,
    closeModal: closeEditAddressModal
  } = useModal()
  const {
    modalState: editFulfillmentConfigurationModalState,
    openModal: openFulfillmentConfigurationModal,
    closeModal: closeFulfillmentConfigurationModal
  } = useModal()
  const {
    modalState: editCoachProfileModalState,
    openModal: editCoachProfileModal,
    closeModal: closeCoachProfileModal
  } = useModal()

  const { logout } = useAuth()
  const { endTrackingSession } = useTimeTracker()
  const canEditCoachProfile = useCanEditCoachProfile()

  return (
    <>
      <Menu open={state} anchorEl={anchorEl} onClose={close} onClick={close}>
        <MenuItem onClick={openProfileModal}>Member Profile</MenuItem>
        <AuthorizedFeature feature={HealthPortalFeature.UsersEditProfile}>
          <MenuItem onClick={openEditProfileModal}>Edit Member Profile</MenuItem>
        </AuthorizedFeature>
        <AuthorizedFeature feature={HealthPortalFeature.UsersEditAddress}>
          <MenuItem onClick={openEditAddressModal}>Edit Member Address</MenuItem>
        </AuthorizedFeature>
        <AuthorizedFeature feature={HealthPortalFeature.UsersEditFulfillmentConfiguration}>
          <MenuItem onClick={openFulfillmentConfigurationModal}>
            Edit Member Fulfillment Configuration
          </MenuItem>
        </AuthorizedFeature>
        <AuthorizedFeature feature={HealthPortalFeature.UsersEditOrganization}>
          <MenuItem onClick={openOrganizationModal}>Member Organization</MenuItem>
        </AuthorizedFeature>
        {canEditCoachProfile && (
          <MenuItem onClick={editCoachProfileModal}>Edit Coach Profile</MenuItem>
        )}
        <MenuItem
          onClick={() => {
            endTrackingSession(false)
            logout()
          }}
        >
          Sign out
        </MenuItem>
      </Menu>

      <Dialog open={profileModalState} onClose={closeProfileModal}>
        <DialogTitle>Member Profile</DialogTitle>
        <DialogContent>
          <Profile />
        </DialogContent>
      </Dialog>

      {editProfileModalState && (
        <EditProfileDialog closeModal={closeEditProfileModal} modalState={editProfileModalState} />
      )}

      {editAddressModalState && (
        <EditAddressDialog closeModal={closeEditAddressModal} modalState={editAddressModalState} />
      )}

      {editFulfillmentConfigurationModalState && (
        <EditFulfillmentConfigurationDialog
          closeModal={closeFulfillmentConfigurationModal}
          modalState={editFulfillmentConfigurationModalState}
        />
      )}

      {organizationModalState && (
        <OrganizationDialog
          closeModal={closeOrganizationModal}
          modalState={organizationModalState}
        />
      )}

      {editCoachProfileModalState && (
        <EditCoachProfileDialog
          closeModal={closeCoachProfileModal}
          modalState={editCoachProfileModalState}
        />
      )}
    </>
  )
}
