import { TitleCellChip, TitleCellTypography } from './styles'

interface IQuestionTitleChipProps {
  title: string
  onClick?: () => void
}

export const QuestionTitleChip = ({ title, onClick }: IQuestionTitleChipProps) => {
  return (
    <TitleCellChip
      label={<TitleCellTypography maxLines={2}>{title}</TitleCellTypography>}
      variant="outlined"
      onClick={onClick}
    />
  )
}
