import { PropsWithChildren } from 'react'
import { MessageInputContextProvider, useMessageInputContext } from 'stream-chat-react'

// Custom TriggerProvider implementation that disables all autocomplete triggers
// (e.g. typing a `:` character would normally open the emoji picker)
//
export const CustomTriggerProvider = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const currentContextValue = useMessageInputContext()

  const updatedContextValue = {
    ...currentContextValue,
    autocompleteTriggers: {}
  }

  return (
    <MessageInputContextProvider value={updatedContextValue}>
      {children}
    </MessageInputContextProvider>
  )
}
