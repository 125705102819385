import { GridRenderCellParams } from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { startCase } from 'lodash'
import { coachEndDateValue } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/coachEndDateValue'
import { coachFilterOperators } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/coachFilterOperators'
import { distinctSubscriptionStatusFilterOperators } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/distinctSubscriptionStatusFilterOperators'
import {
  ChangeCoach,
  CoachCell,
  IChangeCoachProps,
  UserPropertyCell
} from 'screens/AdminPanel/CoachAssignmentDataGrid/index'
import { COLUMNS } from 'screens/AdminPanel/CoachAssignmentDataGrid/types'
import { tooltipLookup } from 'utility/coachEndDateDescriptor'
import {
  formatDate,
  formatDateTime,
  formatStartCase,
  formatSubscriptionStatus,
  renderAsCheck,
  renderAsChip,
  renderAsChipWithTooltip,
  renderAsTextWithTooltip,
  renderAsUserHomePageLink,
  renderCoachAssignmentState,
  renderCoachEndDate
} from 'utility/dataGrid'
import { parseUtcDate } from 'utility/dateParsers'
import { formatWithSubscriptionStatusMask } from 'utility/formatWithSubscriptionStatusMask'
import {
  CoachAssignmentDynamicFilterField,
  CoachAssignmentKind,
  CoachAssignmentSortField,
  CoachAssignmentState,
  CoachAssignmentSupportType,
  ICharge,
  ICoachAssignment,
  ISensor,
  ISubscription,
  IUser,
  IUserSensorInfo,
  SubscriptionStatus,
  UserApprovalRequestState,
  UserGoalKind,
  UserKind,
  UserPropertyKind
} from 'types'
import { FORMATTED_COACH_ASSIGNMENTS, FORMATTED_DIETITIAN_PRODUCTS } from './constants'

type IAdminPanelCoachAssignment = { [K in keyof IUser as K]?: IUser[K] } & {
  [K in keyof IUserSensorInfo as `sensorInfo.${K}`]?: IUserSensorInfo[K]
} & { [K in keyof ISensor as `sensorInfo.lastSensor.${K}`]?: ISensor[K] } & {
  [K in keyof ISubscription as `ongoingDietitianSubscription.${K}`]?: ISubscription[K]
} & {
  [K in keyof IUser as `lastCoachAssignment.coach.${K}`]?: IUser[K]
} & {
  [K in keyof ICoachAssignment as `lastCoachAssignment.${K}`]?: ICoachAssignment[K]
} & {
  [K in keyof ISubscription as `lastCoreSubscription.${K}`]?: ISubscription[K]
} & {
  [K in keyof ICharge as `lastCoreSubscription.latestInvoice.latestCharge.${K}`]?: ICharge[K]
} & {
  [K in keyof ISubscription as `lastDietitianSubscription.${K}`]?: ISubscription[K]
}

export type IAdminPanelCoachAssignmentColumnsType = (IDataGridColumn & IAdminPanelCoachAssignment)[]

export const getCoachAssignmentViewColumns = (
  changeCoachInjectedProps: IChangeCoachProps
): IAdminPanelCoachAssignmentColumnsType => [
  {
    field: COLUMNS['#'],
    headerName: '#',
    type: 'number',
    width: 25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) =>
      params.api.getRowIndex(params.row.id) + 1
  },
  {
    field: COLUMNS.NAME,
    headerName: 'Name',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row?.user.fullName,
    renderCell: (params) => renderAsUserHomePageLink(params, (row) => row.user.id)
  },
  {
    field: COLUMNS.EMAIL,
    headerName: 'Email',
    valueGetter: ({ row }) => row?.user.email,
    width: 250
  },
  {
    field: COLUMNS.SCHEDULED_VIDEO_CALL_STATUS,
    headerName: 'Video Call Status',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.user?.userProperties?.scheduledVideoCallStatus?.value,
    renderCell: renderAsTextWithTooltip,
    backendFilterName: CoachAssignmentDynamicFilterField.UserScheduledVideoCallStatusValue,
    backendSortName: CoachAssignmentSortField.UserScheduledVideoCallStatusValue
  },
  {
    field: COLUMNS.SENSOR_EXPIRED,
    description: 'Sensor active',
    headerName: 'Sensor Active',
    type: 'boolean',
    groupable: true,
    align: 'center',
    width: 150,
    renderCell: renderAsCheck,
    valueGetter: ({ row }) => row?.user?.sensorInfo?.lastSensor?.expired === false
  },
  {
    field: COLUMNS.COACH_ASSIGNMENT_STATE,
    headerName: 'Nutritionist Assignment State',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.keys(CoachAssignmentState).map(
      (key: keyof typeof CoachAssignmentState) => {
        return { label: key, value: CoachAssignmentState[key] }
      }
    ),
    valueFormatter: ({ value }) => startCase(value || ''),
    width: 250,
    renderCell: renderCoachAssignmentState('user.lastDietitianSubscription')
  },
  {
    field: COLUMNS.COACH_ASSIGNMENT_KIND,
    headerName: 'Nutritionist Assignment Kind',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.keys(CoachAssignmentKind).map((key: keyof typeof CoachAssignmentKind) => {
      return { label: key, value: CoachAssignmentKind[key] }
    }),
    valueFormatter: ({ value }) =>
      FORMATTED_COACH_ASSIGNMENTS[value as keyof typeof FORMATTED_COACH_ASSIGNMENTS] ||
      startCase(value),
    width: 250,
    renderCell: renderAsChip
  },
  {
    field: COLUMNS.COACH_ASSIGNMENT_SUPPORT_TYPE,
    headerName: 'Support Type',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.keys(CoachAssignmentSupportType).map(
      (key: keyof typeof CoachAssignmentSupportType) => {
        return { label: key, value: CoachAssignmentSupportType[key] }
      }
    ),
    valueFormatter: ({ value }) => startCase(value),
    width: 250,
    renderCell: renderAsChip
  },
  {
    field: COLUMNS.COACH,
    backendFilterName: CoachAssignmentDynamicFilterField.CoachId,
    headerName: 'Nutritionist',
    groupable: true,
    editable: true,
    type: 'string',
    width: 250,
    valueFormatter: ({ value }) => startCase(value),
    valueGetter: ({ row }) =>
      changeCoachInjectedProps.unsavedCoachAssignments[row?.id]?.coachFullName ||
      row?.coach?.fullName,
    renderCell: ({ row, value }) => (
      <CoachCell
        coachAssignments={row?.user?.coachAssignments}
        supportType={row?.supportType}
        formattedCellValue={value}
      />
    ),
    renderEditCell: ({ row, field }) => (
      <ChangeCoach
        rowId={row?.id}
        userId={row?.user?.id}
        supportType={row?.supportType}
        field={field}
        existingCoachAssignments={row?.user?.coachAssignments?.filter(
          (assignment: ICoachAssignment) =>
            assignment.supportType === CoachAssignmentSupportType.AsyncChat
        )}
        {...changeCoachInjectedProps}
      />
    ),
    filterOperators: coachFilterOperators()
  },
  {
    field: COLUMNS.ASSIGNED_AT,
    headerName: 'Assigned At',
    width: 150,
    type: 'date',
    valueFormatter: formatDate
  },
  {
    field: COLUMNS.DEACTIVATED_AT,
    headerName: 'Deactivated At',
    width: 150,
    type: 'date',
    valueFormatter: formatDate
  },
  {
    field: COLUMNS.COACH_END_DATE,
    headerName: 'Nutritionist End Date',
    width: 150,
    type: 'date',
    valueGetter: coachEndDateValue,
    renderCell: renderCoachEndDate(tooltipLookup)
  },
  {
    field: COLUMNS.PRODUCT,
    headerName: 'Product',
    groupable: true,
    width: 270,
    type: 'singleSelect',
    valueOptions: Object.keys(FORMATTED_DIETITIAN_PRODUCTS).map(
      (key: keyof typeof FORMATTED_DIETITIAN_PRODUCTS) => {
        return { label: FORMATTED_DIETITIAN_PRODUCTS[key], value: key }
      }
    ),
    valueGetter: ({ row }) =>
      row.supportType === CoachAssignmentSupportType.AsyncChat
        ? row?.user?.ongoingDietitianSubscription?.primaryProduct?.title
        : null,
    valueFormatter: ({ value }) =>
      FORMATTED_DIETITIAN_PRODUCTS[value as keyof typeof FORMATTED_DIETITIAN_PRODUCTS] ||
      startCase(value),
    renderCell: renderAsChip,
    backendFilterName:
      CoachAssignmentDynamicFilterField.UserOngoingDietitianSubscriptionPlansProduct,
    backendSortName: CoachAssignmentSortField.UserOngoingDietitianSubscriptionPlansProduct
  },
  {
    field: COLUMNS.USER_TYPE,
    headerName: 'User Type',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(UserKind).sort(),
    width: 150,
    valueGetter: ({ row }) => row?.user.kind,
    renderCell: renderAsChipWithTooltip('Can be either Regular, Internal, Influencer or Investor'),
    valueFormatter: formatStartCase
  },
  {
    field: COLUMNS.DIETITIAN_SUBSCRIPTION_STATUS,
    headerName: 'Nutritionist Subscription Status',
    groupable: true,
    width: 270,
    type: 'singleSelect',
    valueOptions: Object.values(SubscriptionStatus).map((value) => {
      return { label: formatWithSubscriptionStatusMask(value), value: value }
    }),
    renderCell: renderAsChip,
    valueGetter: ({ row }) => row.user?.lastDietitianSubscription?.stripeStatus,
    valueFormatter: formatSubscriptionStatus,
    filterOperators: distinctSubscriptionStatusFilterOperators()
  },
  {
    field: COLUMNS.SENSOR_FIRST_ACTIVATION_DATE,
    headerName: 'Sensor First Activation Date',
    type: 'date',
    width: 200,
    valueGetter: ({ row }) => parseUtcDate(row?.user?.sensorInfo?.firstStartTime),
    valueFormatter: formatDate
  },
  {
    field: COLUMNS.SENSOR_EXPIRATION_DATE,
    headerName: 'Sensor Expiration Date',
    type: 'date',
    width: 200,
    valueGetter: ({ row }) => parseUtcDate(row?.user?.sensorInfo?.lastSensor?.expirationDate),
    valueFormatter: formatDate
  },
  {
    field: COLUMNS.SENSOR_COUNT,
    headerName: 'Sensor Count',
    groupable: true,
    width: 100,
    align: 'center',
    type: 'number',
    valueGetter: ({ row }) => row?.user?.sensorInfo?.totalCount
  },
  {
    field: COLUMNS.SENSOR_REPLACEMENT_COUNT,
    headerName: 'Sensor Replacement Count',
    groupable: true,
    width: 200,
    align: 'center',
    type: 'number',
    valueGetter: ({ row }) => row?.user?.sensorInfo?.replacementRequestsCount || 0
  },
  {
    field: COLUMNS.DIETITIAN_SENSOR_COUNT_NOTES,
    headerName: 'Nutritionist Sensor Count Notes',
    width: 300,
    type: 'string',
    align: 'center',
    valueGetter: ({ row }) => row?.user?.userProperties?.dietitianSensorCountNotes,
    renderCell: ({ row, value }) => {
      return (
        <UserPropertyCell
          value={value}
          userId={row.user.id}
          kind={UserPropertyKind.DietitianSensorCountNotes}
          title="Nutritionist Sensor Count Notes"
        />
      )
    },
    backendFilterName:
      CoachAssignmentDynamicFilterField.UserUserPropertiesDietitianSensorCountNotes,
    backendSortName: CoachAssignmentSortField.UserUserPropertiesDietitianSensorCountNotes
  },
  {
    field: COLUMNS.APPROVAL_REQUEST_STATE,
    headerName: 'Approval State',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(UserApprovalRequestState).map((value) => {
      return { label: startCase(value), value: value }
    }),
    width: 150,
    valueFormatter: formatStartCase,
    valueGetter: ({ row }) => row.user?.lastPrescriptionApprovalRequest?.state,
    backendFilterName: CoachAssignmentDynamicFilterField.UserApprovalRequestsState,
    backendSortName: CoachAssignmentSortField.UserApprovalRequestsState
  },
  {
    field: COLUMNS.DIETITIAN_REQUEST,
    headerName: 'Nutritionist Request',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.user?.userProperties?.dietitianRequest,
    renderCell: ({ row, value }) => {
      return (
        <UserPropertyCell
          value={value}
          userId={row.user.id}
          kind={UserPropertyKind.DietitianRequest}
          multiline={false}
          title="Nutritionist Request"
        />
      )
    },
    backendFilterName: CoachAssignmentDynamicFilterField.UserDietitianRequestValue,
    backendSortName: CoachAssignmentSortField.UserDietitianRequestValue
  },
  {
    field: COLUMNS.COMMUNICATION_FREQUENCY,
    headerName: 'Goals Communication',
    width: 200,
    groupable: true,
    type: 'string',
    renderCell: renderAsTextWithTooltip,
    valueGetter: ({ row }) => row.user?.userProperties?.communicationFrequency?.value,
    backendFilterName: CoachAssignmentDynamicFilterField.UserCommunicationFrequencyValue,
    backendSortName: CoachAssignmentSortField.UserCommunicationFrequencyValue
  },
  {
    field: COLUMNS.HEALTH_GOAL,
    headerName: 'Goals Main',
    width: 200,
    type: 'string',
    renderCell: renderAsTextWithTooltip,
    valueGetter: ({ row }) => row.user?.userProperties?.healthGoal?.value,
    backendFilterName: CoachAssignmentDynamicFilterField.UserHealthGoalValue,
    backendSortName: CoachAssignmentSortField.UserHealthGoalValue
  },
  {
    field: COLUMNS.ONE_MONTH_ACTIVE_FEEDBACK_DIETITIAN,
    headerName: 'One Month Active Feedback Nutritionist',
    width: 200,
    type: 'string',
    backendFilterName: CoachAssignmentDynamicFilterField.UserOneMonthActiveFeedbackDietitianValue,
    backendSortName: CoachAssignmentDynamicFilterField.UserOneMonthActiveFeedbackDietitianValue,
    renderCell: (params) => renderAsTextWithTooltip(params, true),
    valueGetter: ({ row }) => row.user?.userProperties?.oneMonthActiveFeedbackDietitian?.value
  },
  {
    field: COLUMNS.PRIMARY_GOAL,
    headerName: 'Primary Goal',
    groupable: true,
    width: 200,
    type: 'singleSelect',
    valueOptions: Object.keys(UserGoalKind).map((key: keyof typeof UserGoalKind) => {
      return { label: startCase(key), value: UserGoalKind[key] }
    }),
    valueGetter: ({ row }) => row.user.primaryGoal,
    valueFormatter: ({ value: goal }) => goal?.description || goal?.title,
    backendFilterName: CoachAssignmentDynamicFilterField.UserGoalKind,
    backendSortName: CoachAssignmentSortField.UserGoalKind
  },
  {
    field: COLUMNS.TEST_GROUP,
    backendFilterName: CoachAssignmentDynamicFilterField.UserTestGroupValue,
    backendSortName: CoachAssignmentSortField.UserTestGroupValue,
    groupable: true,
    headerName: 'Test Group',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.user?.userProperties?.testGroup,
    renderCell: ({ row, value }) => {
      return (
        <UserPropertyCell
          value={value}
          userId={row.user.id}
          kind={UserPropertyKind.TestGroup}
          multiline={false}
          tooltipLabel={`Member is assigned to the following test group: ${value?.value}`}
        />
      )
    }
  },
  {
    field: COLUMNS.NUTRITIONIST_GROUP,
    backendFilterName: CoachAssignmentDynamicFilterField.UserNutritionistGroupValue,
    backendSortName: CoachAssignmentSortField.UserNutritionistGroupValue,
    groupable: true,
    headerName: 'Groups',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.user?.userProperties?.nutritionistGroup,
    renderCell: ({ row, value }) => {
      return (
        <UserPropertyCell
          value={value}
          userId={row.user.id}
          kind={UserPropertyKind.NutritionistGroup}
          multiline={false}
          tooltipLabel={`Member is assigned to the following groups: ${value?.value}`}
        />
      )
    }
  },
  {
    field: COLUMNS.CORE_SUBSCRIPTION_STATUS,
    headerName: 'Core Subscription Status',
    width: 250,
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.values(SubscriptionStatus).map((value) => {
      return { label: formatWithSubscriptionStatusMask(value), value: value }
    }),
    renderCell: renderAsChip,
    valueGetter: ({ row }) => startCase(row?.user?.lastCoreSubscription?.stripeStatus),
    valueFormatter: formatSubscriptionStatus,
    filterOperators: distinctSubscriptionStatusFilterOperators()
  },
  {
    field: COLUMNS.CORE_SUBSCRIPTION_CHARGE_STATUS,
    headerName: 'Core Subscription Charge Status',
    width: 250,
    groupable: true,
    type: 'string',
    renderCell: renderAsChip,
    valueGetter: ({ row }) =>
      startCase(row?.user?.lastCoreSubscription?.latestInvoice?.latestCharge?.status)
  },
  {
    field: COLUMNS.CORE_SUBSCRIPTION_CHARGE_CREATED,
    headerName: 'Core Subscription Charge Created',
    width: 250,
    type: 'date',
    valueFormatter: formatDateTime,
    valueGetter: ({ row }) => row?.user?.lastCoreSubscription?.latestInvoice?.latestCharge?.created
  }
]

export const getFilteredColumns = ({
  coachAssignmentEnabled,
  changeCoachInjectedProps
}: {
  coachAssignmentEnabled: boolean
  changeCoachInjectedProps: IChangeCoachProps
}) => {
  let filteredColumns = getCoachAssignmentViewColumns(changeCoachInjectedProps)

  filteredColumns.find((column) => column.field === COLUMNS.COACH)!.editable =
    coachAssignmentEnabled

  filteredColumns.find((column) => column.field === COLUMNS.NUTRITIONIST_GROUP)!.editable =
    coachAssignmentEnabled

  return filteredColumns
}
