import React from 'react'
import { Box, Typography, TypographyProps } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

type PrivacyModeCloseLinkStyledProps = React.ComponentType<
  TypographyProps<'span', { component: 'span' }>
>

export const PrivacyModeWarningStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'white',
  flexDirection: 'column',
  zIndex: 1
})

export const PrivacyModeCloseLinkStyled: PrivacyModeCloseLinkStyledProps = styled(Typography)({
  marginRight: 4,
  marginLeft: 4,
  fontSize: 12,
  cursor: 'pointer',
  color: theme.palette['brand-primary'].dark
})

export const LoaderStyled = styled(Box)({ height: 72 })
