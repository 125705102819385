import { Box, Stack, TextField } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const Timeline = styled(Stack)({
  position: 'relative',
  margin: '0 auto',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 10,
    bottom: 30,
    width: 1,
    backgroundColor: theme.palette.grey[300],
    marginLeft: -4
  }
})

export const ActivityLogItemWrapper = styled(Box)({
  paddingLeft: 8,
  position: 'relative',
  backgroundColor: 'inherit',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: -8,
    backgroundColor: theme.palette.grey[300],
    border: `5px solid ${theme.palette.grey[300]}`,
    top: 10,
    borderRadius: '50%'
  }
})

export const TextFieldStyled = styled(TextField)({
  fontSize: '10px',
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette['text-color'].primary
  }
})
