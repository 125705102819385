import { Chip, styled } from '@mui/material'

const TAG_COLORS = [
  '#eddcd2',
  '#fff1e6',
  '#fde2e4',
  '#fad2e1',
  '#c5dedd',
  '#dbe7e4',
  '#f0efeb',
  '#d6e2e9',
  '#bcd4e6',
  '#99c1de',
  '#eae4e9',
  '#dfe7fd',
  '#bee1e6'
]

export const TagChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'id'
})((props: { id: string }) => ({
  padding: '2px 4px',
  height: '26px',
  borderRadius: '11px',
  backgroundColor: TAG_COLORS[Number(props.id) % TAG_COLORS.length]
}))
