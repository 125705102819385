import { Suspense, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Box, Grid, Stack } from '@mui/material'
import { Loader, SidebarNavigation, UserSettingsDialog } from 'components'
import { TimeTrackerProvider } from 'components/TimeTracker/TimeTrackerProvider'
import { userSettingsDialogVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { useInvalidateQueriesOnUserChange } from 'hooks/useInvalidateQueriesOnUserChange'
import ReactCursorPosition from 'react-cursor-position'
import { Outlet } from 'react-router-dom'
import { ChatbotAssistant } from 'screens/Chatbot/Assistant'
import { DEFAULT_SPACING } from 'styles'
import { SESSION_INACTIVE_RESCUE_TIME } from 'utility/defaults'
import { HealthPortalFeature } from 'types'

export const PrivatePageLayout = () => {
  useInvalidateQueriesOnUserChange()

  const userSettingsOpen = useReactiveVar(userSettingsDialogVisibleVar)
  const [isAssistantOpen, setIsAssistantOpen] = useState(false)
  const timeTrackerAuthorized = useAuthorizedFeature(HealthPortalFeature.HomeTimeTracker)

  const pageContent = (
    <>
      <Grid container sx={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <SidebarNavigation
            isAssistantOpen={isAssistantOpen}
            onToggleAssistant={() => setIsAssistantOpen(!isAssistantOpen)}
          />
        </Grid>
        <Grid flex={1} item>
          <Stack paddingRight={DEFAULT_SPACING} paddingLeft={DEFAULT_SPACING} height="100vh">
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </Stack>
        </Grid>
      </Grid>
      <UserSettingsDialog
        open={userSettingsOpen}
        onClose={() => userSettingsDialogVisibleVar(false)}
      />
      <ChatbotAssistant open={isAssistantOpen} onClose={() => setIsAssistantOpen(false)} />
    </>
  )

  return timeTrackerAuthorized ? (
    // <TimeTrackerProvider> needs to be first and direct child of <ReactCursorPosition> in order to pass down `isActive` prop
    <ReactCursorPosition hoverOffDelayInMs={SESSION_INACTIVE_RESCUE_TIME}>
      <TimeTrackerProvider>
        <Box sx={{ userSelect: 'text' }}>{pageContent}</Box>
      </TimeTrackerProvider>
    </ReactCursorPosition>
  ) : (
    pageContent
  )
}
