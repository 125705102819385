import { useCallback, useEffect } from 'react'
import { useImpersonatedCurrentUser } from 'hooks/useImpersonatedCurrentUser'
import { IUserMin } from 'types'
import { useSegment } from './useSegment'

export const useSegmentMember = () => {
  const { data: { currentUser } = {} } = useImpersonatedCurrentUser()

  const { analytics } = useSegment()

  const setMember = useCallback(
    (user: { id: string; fullName: string; email: string }) => {
      const { id, fullName, email } = user
      const traits = {
        name: fullName,
        email
      }
      analytics?.identify(id, traits)
    },
    [analytics]
  )

  useEffect(() => {
    if (currentUser) {
      const user = {
        email: currentUser.email,
        fullName: currentUser.fullName,
        id: currentUser.id
      } as IUserMin

      setMember(user)
    }
  }, [currentUser, setMember])

  // TODO: issue - we're overriding impersonated member with admin
  return { setMember }
}
