import { GridFilterItem, GridFilterModel, GridLinkOperator } from '@mui/x-data-grid'

export type CanonicalizedFilters = Record<string, string> & {
  filter_link?: GridLinkOperator
}

type FilterParamType = 'field' | 'operator' | 'value'

type ParsedFilterParam = { index: string | null; type: FilterParamType | null }

const FILTER_PARAM_TYPE_TO_GRID_FILTER_KEY: Record<FilterParamType, keyof GridFilterItem> = {
  field: 'columnField',
  operator: 'operatorValue',
  value: 'value'
}

const parseFilterParam = (filterParam: string) => {
  const matches = filterParam.match(/^filter_([0-9]+)_(field|operator|value)$/)

  if (!matches) {
    return { index: null, type: null }
  }

  const [_fullMatch, index, type] = matches
  return { index, type } as ParsedFilterParam
}

// Convert a data-grid filterModel object into a canonical form that can be used or compared with searchParams
//
export const canonicalizeFilterModel = (filterModel: GridFilterModel): CanonicalizedFilters => {
  const allItems = filterModel.items.reduce(
    (acc, item, index) => ({
      ...acc,
      [`filter_${index}_field`]: item.columnField,
      [`filter_${index}_operator`]: item.operatorValue,
      [`filter_${index}_value`]: item.value
    }),
    {}
  )

  const compactItems = Object.entries(allItems).filter(([_key, value]) => value !== undefined)
  const items = Object.fromEntries(compactItems)

  if (Object.keys(items).length === 0) {
    return {}
  }

  return {
    ...items,
    ...(filterModel.linkOperator && { filter_link: filterModel.linkOperator })
  }
}

// Convert url searchParams into a canonical form that can be used or compared with a filterModel
//
export const canonicalizeSearchParams = (searchParams: URLSearchParams): CanonicalizedFilters => {
  const canonicalized: Record<string, string> = {}
  const linkOperator = searchParams.get('filter_link')

  searchParams.forEach((value, key) => {
    const { index, type } = parseFilterParam(key)

    if (index !== null && type !== null) {
      canonicalized[`filter_${index}_${type}`] = value
    }
  })

  if (linkOperator && Object.keys(canonicalized).length > 0) {
    canonicalized.filter_link = linkOperator as GridLinkOperator
  }

  return canonicalized
}

// Convert a set of canonicalized filters back into a filterModel object that can be set on a data-grid
//
export const canonicalizedFiltersToModel = (canonicalizedFilters: CanonicalizedFilters) => {
  const { filter_link: linkOperator, ...filterParams } = canonicalizedFilters
  const indexedItems = Object.entries(filterParams).reduce((indexedItems, [key, value]) => {
    const { index, type } = parseFilterParam(key)

    if (index === null || type === null) {
      return indexedItems
    }

    const gridFilterKey = FILTER_PARAM_TYPE_TO_GRID_FILTER_KEY[type]

    indexedItems[index] = {
      ...indexedItems[index],
      [gridFilterKey]: value
    }
    return indexedItems
  }, {} as Record<string, GridFilterItem>)

  return {
    items: Object.values(indexedItems),
    linkOperator
  }
}
