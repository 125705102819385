import { EMPTY_EVENTS_TAG_KEY } from 'screens/UserDetails/Graph/constants'
import { IGraphDataPoint, IMeasurements } from 'screens/UserDetails/Graph/types'

export const parseGlucoseDatapoints = (measurements: IMeasurements): IGraphDataPoint[] => {
  return measurements.flatMap((measurement: IMeasurements[number]) => {
    const { __typename: typename } = measurement

    if (!typename) {
      return []
    }

    return [
      {
        time: new Date(measurement[`x${typename}` as keyof typeof measurement] as string).getTime(),
        value: measurement[`y${typename}` as keyof typeof measurement] as unknown as number,
        tag: EMPTY_EVENTS_TAG_KEY
      }
    ]
  })
}
