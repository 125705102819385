import { Alert, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { format } from 'date-fns'
import { useAuthorizedFeature } from 'hooks'
import { compact, startCase } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { MM_DD_YYYY } from 'utility/timeFormats'
import {
  OpsAccountRequestKindsCancelSubscriptionReason,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation,
  HealthPortalFeature
} from 'types'
import { ChipStyled } from './styles'

interface ICancelSubscriptionForm {
  subscriptionId: string
  immediateCancellation: 'true' | 'false' | ''
  waiveEarlyCancellationFee: 'true' | 'false' | ''
  waiveEarlyCancellationSurcharge: 'true' | 'false' | ''
  cancellationReason: OpsAccountRequestKindsCancelSubscriptionReason | ''
  priority: OpsAccountRequestPriority
  note: string
}

export const CancelSubscriptionModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { handleSubmit, control, register, reset, formState, watch } =
    useForm<ICancelSubscriptionForm>({
      mode: 'onTouched',
      defaultValues: {
        subscriptionId: '',
        immediateCancellation: '',
        waiveEarlyCancellationFee: '',
        waiveEarlyCancellationSurcharge: '',
        cancellationReason: '',
        priority: OpsAccountRequestPriority.Regular,
        note: ''
      }
    })
  const watchImmediateCancellation = watch('immediateCancellation')

  const [processStandardAuthorized, processImmediateAuthorized] = useAuthorizedFeature([
    HealthPortalFeature.SupportAccountRequestsProcessCancelSubscription,
    HealthPortalFeature.SupportAccountRequestsProcessCancelSubscriptionImmediate
  ])

  const processAuthorized =
    (watchImmediateCancellation !== 'true' && processStandardAuthorized) ||
    (watchImmediateCancellation === 'true' && processImmediateAuthorized)

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser) {
    return null
  }

  const onClose = () => {
    reset()
    closeModal()
  }

  const subscriptions = compact([
    currentUser.ongoingCoreSubscription,
    currentUser.ongoingDietitianSubscription
  ])

  const validateSubscriptionIsNotAlreadyCancelled = (subscription_id: string) => {
    const subscription = subscriptions.find((sub) => sub.id === subscription_id)

    return subscription?.cancelAt ? 'Subscription is already cancelled' : true
  }

  const cancelSubscriptionHandler = async (formData: ICancelSubscriptionForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.CancelSubscription,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.CancelSubscription,
          cancelSubscription: {
            subscriptionId: formData.subscriptionId,
            immediateCancellation: formData.immediateCancellation === 'true',
            waiveEarlyCancellationFee: formData.waiveEarlyCancellationFee === 'true',
            waiveEarlyCancellationSurcharge: formData.waiveEarlyCancellationSurcharge === 'true',
            cancellationReason:
              formData.cancellationReason as OpsAccountRequestKindsCancelSubscriptionReason
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const innerForm = (
    <>
      <Alert severity="warning">
        Member&apos;s video calls will remain active even after cancelling the subscription.
      </Alert>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Select Subscription</InputLabel>
        <Controller
          name="subscriptionId"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('subscriptionId', {
                validate: validateSubscriptionIsNotAlreadyCancelled,
                required: true
              })}
              error={!!formState.errors.subscriptionId}
              label="Select Subscription"
            >
              {formState.errors.subscriptionId && <p>{formState.errors.subscriptionId.message}</p>}
              <MenuItem value="" disabled>
                <em>Select Subscription</em>
              </MenuItem>
              {subscriptions.map((subscription) => (
                <MenuItem key={subscription.id} value={subscription.id}>
                  {subscription.primaryProduct.title}
                  {subscription.cancelAt ? (
                    <ChipStyled
                      size="small"
                      label={`(Cancellation Date: ${format(
                        new Date(subscription.cancelAt),
                        MM_DD_YYYY
                      )})`}
                    />
                  ) : (
                    ''
                  )}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Immediate Cancellation</InputLabel>
        <Controller
          name="immediateCancellation"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('immediateCancellation', { required: true })}
              error={!!formState.errors.immediateCancellation}
              label="Immediate Cancellation"
            >
              <MenuItem value="" disabled>
                <em>Immediate Cancellation</em>
              </MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Waive Early Cancellation Fee</InputLabel>
        <Controller
          name="waiveEarlyCancellationFee"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('waiveEarlyCancellationFee', { required: true })}
              error={!!formState.errors.waiveEarlyCancellationFee}
              label="Waive Early Cancellation Fee"
            >
              <MenuItem value="" disabled>
                <em>Waive Early Cancellation Fee</em>
              </MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Waive Early Cancellation Surcharge</InputLabel>
        <Controller
          name="waiveEarlyCancellationSurcharge"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('waiveEarlyCancellationSurcharge', { required: true })}
              error={!!formState.errors.waiveEarlyCancellationSurcharge}
              label="Waive Early Cancellation Surcharge"
            >
              <MenuItem value="" disabled>
                <em>Waive Early Cancellation Surcharge</em>
              </MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Cancellation Reason</InputLabel>
        <Controller
          name="cancellationReason"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('cancellationReason', { required: true })}
              error={!!formState.errors.cancellationReason}
              label="Cancellation Reason"
            >
              <MenuItem value="" disabled>
                <em>Cancellation Reason</em>
              </MenuItem>
              {Object.values(OpsAccountRequestKindsCancelSubscriptionReason).map((choice) => (
                <MenuItem key={choice} value={choice}>
                  {startCase(choice)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  )
  return (
    <AccountRequestModal<ICancelSubscriptionForm>
      register={register}
      modalTitle="Cancel Subscription"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(cancelSubscriptionHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
