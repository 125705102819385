import { SelectSetting } from '.'
import { UserSettingsSection } from './UserSettingsSection'

export const GlucoseThresholds = () => {
  const rows = [
    {
      label: 'Low',
      cells: [
        <SelectSetting
          key="glucoseLowOptions"
          clientConfigKey="glucoseLowOptions"
          settingKey="glucoseLowThreshold"
        />
      ]
    },
    {
      label: 'High',
      cells: [
        <SelectSetting
          key="glucoseHighOptions"
          clientConfigKey="glucoseHighOptions"
          settingKey="glucoseHighThreshold"
        />
      ]
    }
  ]

  return <UserSettingsSection header="Glucose Thresholds" rows={rows} />
}
