import { useRef, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Loader } from 'components'
import {
  MarkdownEditor,
  parseAndDeserializeFromHtml,
  serializeToHtml,
  TypedDescendant
} from 'components/MarkdownEditor'
import { snackBarVisibleVar } from 'context'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import { AvatarPlaceholder, MessageAvatar, MessageBody } from 'screens/Chat/styles'
import { shortenDistanceTime } from 'screens/Chat/utils'
import { nameInitials } from 'utility/nameInitials'
import { MMM_DD_HH_MM_A } from 'utility/timeFormats'
import { useUpdateChatConversationNoteMutation } from 'types'
import { IEventElement } from './Event'
import { MessageWrapper } from './styles'

const fontSize = 16

export const NoteEvent = ({ event, conversation }: IEventElement) => {
  const note = event.note!
  const createdBy = note.createdBy
  const createdByFullName = createdBy.fullName
  const createdAtIso = parseISO(note.createdAt)
  const distance = shortenDistanceTime(formatDistanceToNowStrict(createdAtIso))
  const [isEditing, setIsEditing] = useState(false)
  const noteValueRef = useRef<TypedDescendant[]>(parseAndDeserializeFromHtml(note.value))
  const setNoteValueRef = (value: TypedDescendant[]) => (noteValueRef.current = value)
  const [updateNoteMutation, { loading }] = useUpdateChatConversationNoteMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const updateNote = async () => {
    await updateNoteMutation({
      variables: { id: note.id, body: serializeToHtml({ children: noteValueRef.current }) }
    })
    setIsEditing(false)
  }

  return (
    <Stack>
      <MessageWrapper direction="row" event={event} conversation={conversation}>
        <Tooltip title={createdByFullName}>
          <MessageAvatar src={createdBy?.avatar || ''} alt={createdByFullName}>
            {nameInitials(createdByFullName)}
          </MessageAvatar>
        </Tooltip>
        {!isEditing && (
          <MessageBody
            dangerouslySetInnerHTML={{
              __html: serializeToHtml({ children: noteValueRef.current })
            }}
            note={1}
          />
        )}
        {isEditing && (
          <MessageBody note={1}>
            <MarkdownEditor
              initialValue={noteValueRef.current}
              onChange={setNoteValueRef}
              placeholder=""
              autoFocus
            />
          </MessageBody>
        )}
      </MessageWrapper>
      <MessageWrapper direction="row" event={event} conversation={conversation}>
        <AvatarPlaceholder />
        <Typography color="text.secondary" variant="caption">
          <Tooltip title={format(createdAtIso, MMM_DD_HH_MM_A)}>
            <Box component="span">{distance}</Box>
          </Tooltip>
        </Typography>
        {!loading && isEditing && (
          <IconButton size="small" onClick={updateNote}>
            <CheckIcon sx={{ fontSize }} />
          </IconButton>
        )}
        {!loading && isEditing && (
          <IconButton size="small" onClick={() => setIsEditing(false)}>
            <CloseIcon sx={{ fontSize }} color="error" />
          </IconButton>
        )}
        {!loading && !isEditing && (
          <IconButton size="small" onClick={() => setIsEditing(true)}>
            <EditIcon sx={{ fontSize }} />
          </IconButton>
        )}
        {loading && <Loader styles={{ zoom: 0.3, width: 'auto', paddingRight: 4 }} />}
      </MessageWrapper>
    </Stack>
  )
}
