import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { LocalizationProvider as LocalizationProviderLab } from '@mui/lab'
import AdapterDateFnsLab from '@mui/lab/AdapterDateFns'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { FlagProvider } from '@unleash/proxy-client-react'
import { Segment } from 'components'
import { Bugsnag } from 'components/Bugsnag'
import { client } from 'config/apolloClient'
import { TimeZoneProvider, UnitSystemProvider } from 'context'
import { AuthProvider } from 'context'
import { DppChatProvider } from 'context/dpp/ChatProvider'
import { ConfirmProvider } from 'material-ui-confirm'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { registerServiceWorker } from 'utility/registerServiceWorker'
import { App } from './App'
import { FeatureFlagUser } from './components/FeatureFlagUser'
import { env } from './config'
import { Unleash } from './config/unleashConfig'
import { theme } from './theme'

const container = document.getElementById('root')
const root = createRoot(container!)

const AppRoot = () => {
  useEffect(() => {
    // start unleash client
    Unleash.client.start()
    return () => {
      Unleash.client.stop()
    }
  }, [])
  return (
    <React.StrictMode>
      <Router>
        <ApolloProvider client={client}>
          <FlagProvider unleashClient={Unleash.client} startClient={false}>
            <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_CLIENT_ID}>
              <AuthProvider>
                <TimeZoneProvider>
                  <UnitSystemProvider>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={theme}>
                        <DppChatProvider>
                          <ConfirmProvider>
                            <LocalizationProviderLab dateAdapter={AdapterDateFnsLab}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <CssBaseline />
                                <Bugsnag />
                                <Segment />
                                <FeatureFlagUser />
                                <App />
                              </LocalizationProvider>
                            </LocalizationProviderLab>
                          </ConfirmProvider>
                        </DppChatProvider>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </UnitSystemProvider>
                </TimeZoneProvider>
              </AuthProvider>
            </GoogleOAuthProvider>
          </FlagProvider>
        </ApolloProvider>
      </Router>
    </React.StrictMode>
  )
}

root.render(<AppRoot />)

registerServiceWorker()
