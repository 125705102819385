import { Box, Stack } from '@mui/material'
import styled from '@mui/styled-engine'
import { ChatConversationEventKind, IChatConversation, IChatConversationEvent } from 'types'

export const EventWrapper = styled(Box)(
  ({
    event,
    conversation
  }: {
    event?: IChatConversationEvent
    conversation?: IChatConversation
  }) => ({
    display: 'flex',
    justifyContent:
      event?.kind === ChatConversationEventKind.Message
        ? conversation?.user?.id === event?.message?.sender?.id
          ? 'flex-start'
          : 'flex-end'
        : event?.kind === ChatConversationEventKind.Note
        ? 'flex-end'
        : 'center',
    padding: 20
  })
)

export const MessageWrapper = styled(Stack)(
  ({
    event,
    conversation
  }: {
    event: IChatConversationEvent
    conversation: IChatConversation
  }) => ({
    flexDirection:
      conversation.user.id === event.message?.sender?.id
        ? ('row' as const)
        : ('row-reverse' as const),
    alignItems: 'flex-end'
  })
)
