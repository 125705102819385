import React from 'react'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import { Button, Grid, Stack } from '@mui/material'
import { ACCOUNT_REQUEST_TITLE_MAP } from 'accountRequest.types'
import { APP_TOOLBAR_HEIGHT, AppToolbar, AuthorizedFeature, CommonRightAdornment } from 'components'
import { AccountRequestsMenu } from 'components/AccountRequestsMenu'
import { MemberBio } from 'components/MemberBio'
import { MEMBER_BIO_HEIGHT } from 'components/MemberBio/MemberBio'
import chargesGridVars from 'context/billingDashboard/charges'
import invoicesGridVars from 'context/billingDashboard/invoices'
import subscriptionsGridVars from 'context/billingDashboard/subscriptions'
import { DEFAULT_SPACING, StrongTypographyStyled } from 'styles'
import { theme } from 'theme'
import { useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import {
  HealthPortalFeature,
  IUser,
  useBillingDashboardAllChargesQuery,
  useBillingDashboardAllInvoicesQuery,
  useBillingDashboardAllSubscriptionsQuery,
  useCurrentUserQuery
} from 'types'
import { ActivityLog } from './ActivityLog/ActivityLog'
import { Charges } from './Charges'
import { SCREEN_HEADER_HEIGHT } from './constants'
import { getCommonListQueryVariablesForUser } from './helpers/getCommonListQueryVariablesForUser'
import { Invoices } from './Invoices'
import { ScrollableStack } from './styles'
import { Subscriptions } from './Subscriptions'

const VERTICAL_PADDINGS_PX = theme.spacing(2 * DEFAULT_SPACING)
const gridsHeight = `calc(100vh - ${APP_TOOLBAR_HEIGHT}px - ${SCREEN_HEADER_HEIGHT}px - ${MEMBER_BIO_HEIGHT}px - ${VERTICAL_PADDINGS_PX})`

export const BillingDashboard = () => {
  const { data: { currentUser } = {}, loading: currentUserLoading } = useCurrentUserQuery()

  const subscriptionsReactiveGridVars = useReactiveGridVars(subscriptionsGridVars)
  const chargesReactiveGridVars = useReactiveGridVars(chargesGridVars)
  const invoicesReactiveGridVars = useReactiveGridVars(invoicesGridVars)

  const {
    data: subscriptionsData,
    loading: subscriptionsLoading,
    error: subscriptionsError,
    refetch: refetchSubscriptions
  } = useBillingDashboardAllSubscriptionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: getCommonListQueryVariablesForUser(
      subscriptionsReactiveGridVars,
      currentUser as IUser
    )
  })

  const {
    data: chargesData,
    loading: chargesLoading,
    error: chargesError,
    refetch: refetchCharges
  } = useBillingDashboardAllChargesQuery({
    notifyOnNetworkStatusChange: true,
    variables: getCommonListQueryVariablesForUser(chargesReactiveGridVars, currentUser as IUser)
  })

  const {
    data: invoicesData,
    loading: invoicesLoading,
    error: invoicesError,
    refetch: refetchInvoices
  } = useBillingDashboardAllInvoicesQuery({
    notifyOnNetworkStatusChange: true,
    variables: getCommonListQueryVariablesForUser(invoicesReactiveGridVars, currentUser as IUser)
  })

  const refetchData = () => {
    refetchSubscriptions()
    refetchCharges()
    refetchInvoices()
  }

  return (
    <>
      <AppToolbar rightAdornment={<CommonRightAdornment />} />
      <Stack>
        <Stack direction="row" justifyContent="space-between" margin="8px 0">
          <StrongTypographyStyled variant="h4">Billing Dashboard</StrongTypographyStyled>
          <AuthorizedFeature feature={HealthPortalFeature.SupportAccountRequestsCreate}>
            <AccountRequestsMenu accountRequestTitleMap={ACCOUNT_REQUEST_TITLE_MAP}>
              <Button variant="contained" size="small" endIcon={<KeyboardArrowDownOutlined />}>
                Actions
              </Button>
            </AccountRequestsMenu>
          </AuthorizedFeature>
        </Stack>
        <Grid container spacing={DEFAULT_SPACING}>
          <Grid item xs={9}>
            <MemberBio showOpenDashboardIcon />
            <ScrollableStack spacing={DEFAULT_SPACING} height={gridsHeight} mt={DEFAULT_SPACING}>
              <Subscriptions
                data={subscriptionsData}
                loading={subscriptionsLoading || currentUserLoading}
                error={subscriptionsError}
                refetch={refetchSubscriptions}
              />
              <Charges
                data={chargesData}
                loading={chargesLoading || currentUserLoading}
                error={chargesError}
                refetch={refetchCharges}
              />
              <Invoices
                data={invoicesData}
                loading={invoicesLoading || currentUserLoading}
                error={invoicesError}
                refetch={refetchInvoices}
              />
            </ScrollableStack>
          </Grid>
          <Grid item xs={3}>
            <ActivityLog onRefetch={refetchData} />
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}
