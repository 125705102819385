import { Typography } from '@mui/material'
import { EventContent, EventTitle } from './styles'
import { IEvent } from './types'

const SHORT_DURATION = 30

export const CalendarEvent = ({ event }: { event: IEvent }) => {
  const { title, status } = event
  const active = status.active ? 1 : 0

  return (
    <EventContent active={active}>
      <EventTitle variant="body1" active={active}>
        {title}
        {event.durationMinutes <= SHORT_DURATION && ` (${status.value})`}
      </EventTitle>
      {event.durationMinutes > SHORT_DURATION && (
        <Typography variant="caption">{status.value}</Typography>
      )}
    </EventContent>
  )
}
