import { Tab, Tabs, Toolbar } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'

enum Paths {
  InvoiceOrders = '/invoice-orders',
  Orders = '/orders',
  Shipments = '/shipments'
}

export const OrdersAppBar = () => {
  const location = useLocation()

  const tabValue = Object.values(Paths).indexOf(location.pathname as Paths)

  return (
    <Toolbar>
      <Tabs value={tabValue}>
        <Tab label="Invoice Orders" component={NavLink} to={Paths.InvoiceOrders} />
        <Tab label="Orders" component={NavLink} to={Paths.Orders} />
        <Tab label="Shipments" component={NavLink} to={Paths.Shipments} />
      </Tabs>
    </Toolbar>
  )
}
