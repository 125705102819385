import { useState } from 'react'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import { Editor, EditorItems, EditorPreview } from 'screens/Knowledge/Editor'
import { LinkedSourcesCell } from 'screens/Knowledge/LinkedSourcesCell'
import { IKnowledgeSource } from 'types'
import { SearchSources } from './SearchSources'
import { useAnswerLinking } from './useAnswerLinking'

interface ILinkedSourcesEditCellProps extends Omit<GridRenderEditCellParams, 'row'> {
  row: IKnowledgeSource
}

export const LinkedSourcesEditCell = ({
  row,
  field,
  value,
  error
}: ILinkedSourcesEditCellProps) => {
  const apiRef = useGridApiContext()

  const { linkAnswer, unlinkAnswer, loading } = useAnswerLinking({
    onLinkingUpdated: (sources) => {
      apiRef.current.setEditCellValue({ id: row.id, field, value: sources })
    }
  })

  const [selectedSource, setSelectedSource] = useState<IKnowledgeSource | null>(null)
  const sources: IKnowledgeSource[] = value || []
  const selectedSourceLinked = sources.some((source) => source.id === selectedSource?.id)

  const handleUpdateLink = () => {
    if (!selectedSource) {
      return
    }

    if (selectedSourceLinked) {
      unlinkAnswer({
        variables: {
          id: row.id,
          knowledgeSourceId: selectedSource.id
        },
        onCompleted: () => setSelectedSource(null)
      })
    } else {
      linkAnswer({
        variables: {
          id: row.id,
          knowledgeSourceId: selectedSource.id
        }
      })
    }
  }

  return (
    <>
      <LinkedSourcesCell sources={sources} />
      <Editor
        title="Link a Source"
        items={sources}
        row={row}
        field={field}
        value={value}
        error={error}
      >
        <EditorItems
          title={row.title}
          items={sources}
          selectedItem={selectedSource}
          onSelect={(item: IKnowledgeSource) => setSelectedSource(item)}
        >
          <SearchSources
            selectedSource={selectedSource}
            linkedSources={sources}
            onSelect={setSelectedSource}
          />
        </EditorItems>
        <EditorPreview
          item={selectedSource}
          value={selectedSource?.url}
          linked={selectedSourceLinked}
          loading={loading}
          onUpdateLink={handleUpdateLink}
        />
      </Editor>
    </>
  )
}
