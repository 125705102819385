import React, { useMemo, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { APP_TOOLBAR_HEIGHT } from 'components'
import { Loader } from 'components/Loader'
import { useParams } from 'react-router-dom'
import { SCREEN_HEADER_HEIGHT } from 'screens/BillingDashboard/constants'
import { DEFAULT_SPACING, StrongTypographyStyled } from 'styles'
import { theme } from 'theme'
import {
  AccountRequestDynamicFilterField,
  AccountRequestSortField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  IAccountRequest,
  INote,
  NoteDynamicFilterField,
  NoteKind,
  NoteSortField,
  OpsAccountRequestStatus,
  SortDirection,
  useAllNotesQuery,
  useBillingDashboardAllAccountRequestsQuery
} from 'types'
import { ActivityLogItem, ILogItem } from './ActivityLogItem'
import { Timeline } from './styles'

const getAccountRequestsFilters = (userId: string) => ({
  items: [
    {
      field: AccountRequestDynamicFilterField.UserId,
      operation: DynamicFilterItemOperation.Eq,
      value: userId
    },
    {
      field: AccountRequestDynamicFilterField.Status,
      operation: DynamicFilterItemOperation.NotEq,
      value: OpsAccountRequestStatus.Rejected
    }
  ],
  operator: DynamicFilterOperator.And
})

const billingNotesFilters = {
  items: [
    {
      field: NoteDynamicFilterField.Kind,
      operation: DynamicFilterItemOperation.Eq,
      value: NoteKind.OpsAccountRequest
    }
  ],
  operator: DynamicFilterOperator.And
}

const accountRequestsSorts = [
  {
    field: AccountRequestSortField.ProcessedAt,
    direction: SortDirection.Desc
  }
]

const notesSorts = [
  {
    field: NoteSortField.OccurredAt,
    direction: SortDirection.Desc
  }
]

const newNote = {
  body: ''
}

const VERTICAL_PADDINGS_PX = theme.spacing(1 * DEFAULT_SPACING)
const CARD_HEADER_HEIGHT = 60
const height = `calc(100vh - ${APP_TOOLBAR_HEIGHT}px - ${SCREEN_HEADER_HEIGHT}px - ${CARD_HEADER_HEIGHT}px - ${VERTICAL_PADDINGS_PX})`

export const ActivityLog = ({ onRefetch }: { onRefetch: () => void }) => {
  const { id: userId } = useParams<{ id: string }>()
  const [addNewNote, setAddNewNote] = useState(false)

  const {
    data: accountRequestsData,
    loading: loadingAccountRequests,
    refetch: refetchAccountRequests
  } = useBillingDashboardAllAccountRequestsQuery({
    variables: {
      dynamicFilters: getAccountRequestsFilters(userId!),
      sorts: accountRequestsSorts
    }
  })

  const { data: notesData, loading: loadingNotes } = useAllNotesQuery({
    variables: {
      userId: userId!,
      dynamicFilters: billingNotesFilters,
      sorts: notesSorts
    }
  })
  const accountRequests = useMemo(
    () => accountRequestsData?.allAccountRequests?.accountRequests || [],
    [accountRequestsData]
  )
  const notes = useMemo(() => notesData?.allNotes?.notes || [], [notesData])
  const logItems = useMemo(() => {
    return [...(accountRequests as IAccountRequest[]), ...(notes as INote[])].sort(
      (a: ILogItem, b: ILogItem) => {
        const aOccurredAt = 'occurredAt' in a ? a.occurredAt : null
        const aTimestamp = 'processedAt' in a ? a.processedAt || a.escalatedAt : aOccurredAt
        const bOccurredAt = 'occurredAt' in b ? b.occurredAt : null
        const bTimestamp = 'processedAt' in b ? b.processedAt || b.escalatedAt : bOccurredAt
        return new Date(bTimestamp).getTime() - new Date(aTimestamp).getTime()
      }
    )
  }, [accountRequests, notes])
  const loading = loadingAccountRequests || loadingNotes

  const addNoteAction = (
    <Button onClick={() => setAddNewNote(true)} variant="outlined" size="small" key="add-note">
      <AddIcon /> Add Note
    </Button>
  )

  const refetchHandler = () => {
    refetchAccountRequests()
    onRefetch()
  }

  const refetchAction = (
    <Tooltip title="Refresh" key="refresh">
      <IconButton onClick={refetchHandler} aria-label="Refresh">
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  )

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" mb={2}>
            <Stack mr={1}>
              <StrongTypographyStyled color="text.primary" variant="subtitle1">
                Recent Activity
              </StrongTypographyStyled>
            </Stack>
          </Stack>
        }
        action={[addNoteAction, refetchAction]}
      />
      <CardContent sx={{ overflowY: 'scroll', maxHeight: height }}>
        {loading && <Loader />}
        {!loading && !logItems.length && !addNewNote && (
          <Typography textAlign="center" margin="auto">
            No Recent Activity
          </Typography>
        )}
        {!loading && (
          <Timeline spacing={2}>
            {addNewNote && (
              <ActivityLogItem
                logItem={newNote as INote}
                cancelNewNote={() => setAddNewNote(false)}
              />
            )}
            {logItems.map((logItem: ILogItem) => (
              <ActivityLogItem key={logItem.id} logItem={logItem} />
            ))}
          </Timeline>
        )}
      </CardContent>
    </Card>
  )
}
