import { useRef } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Stack, Typography } from '@mui/material'
import { Loader } from 'components/Loader'
import { snackBarVisibleVar } from 'context'
import { format } from 'date-fns'
import { noop } from 'lodash'
import { LLL_D_YYYY_HH_MM_A } from 'utility/timeFormats'
import {
  IAccountRequest,
  INote,
  NoteKind,
  useUpsertNoteMutation,
  useCurrentAdminQuery
} from 'types'
import { describeAccountRequest } from './describeAccountRequest'
import { ActivityLogItemWrapper, TextFieldStyled } from './styles'

export type ILogItem = IAccountRequest | INote

function extractDisplayFields(logItem: IAccountRequest | INote, currentAdmin: any) {
  const description = 'body' in logItem ? logItem.body : describeAccountRequest(logItem)
  const creator = 'creator' in logItem ? logItem.creator : null
  const activityBy = 'processedBy' in logItem ? logItem.processedBy || logItem.escalatedBy : creator
  const occurredAt = 'occurredAt' in logItem ? logItem.occurredAt : null
  const activityAt =
    'processedAt' in logItem
      ? logItem.processedAt || logItem.failedAt || logItem.escalatedAt
      : occurredAt
  const notCreatedByCurrentAdmin = !!logItem.id && currentAdmin?.id !== activityBy?.id
  const logItemDisabled = logItem.__typename === 'AccountRequest' || notCreatedByCurrentAdmin
  return { description, activityBy, activityAt, logItemDisabled }
}

export const ActivityLogItem = ({
  logItem,
  cancelNewNote
}: {
  logItem: ILogItem
  cancelNewNote?: any
}) => {
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [upsertNoteMutation, { loading: upsertNoteLoading }] = useUpsertNoteMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message }),
    onCompleted: cancelNewNote,
    refetchQueries: ['allNotes']
  })
  const upsertNote = () => {
    const currentValue = textFieldRef.current?.value
    currentValue &&
      currentValue !== (logItem as INote).body &&
      upsertNoteMutation({
        variables: { id: logItem?.id, body: currentValue, kind: NoteKind.OpsAccountRequest }
      })
  }
  const onBlur = logItem.id ? upsertNote : noop

  const { description, activityBy, activityAt, logItemDisabled } = extractDisplayFields(
    logItem,
    currentAdmin
  )

  return (
    <ActivityLogItemWrapper>
      {!logItem.id && (
        <Stack direction="row-reverse" justifyContent="space-between">
          {upsertNoteLoading && <Loader />}
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton size="small" color="primary" onClick={upsertNote}>
              <CheckIcon />
            </IconButton>
            <IconButton size="small" color="error" onClick={cancelNewNote}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}
      <Stack>
        <TextFieldStyled
          defaultValue={description}
          onBlur={onBlur}
          inputRef={textFieldRef}
          autoFocus={!logItem.id}
          placeholder="Type here..."
          fullWidth
          multiline
          variant="standard"
          disabled={logItemDisabled}
          InputProps={{
            disableUnderline: true,
            style: { fontSize: 14 }
          }}
        />
        {activityAt && (
          <Typography variant="caption" color="text.secondary">
            by {activityBy?.fullName} · {format(new Date(activityAt), LLL_D_YYYY_HH_MM_A)}
          </Typography>
        )}
      </Stack>
    </ActivityLogItemWrapper>
  )
}
