import { UnleashClient } from 'unleash-proxy-client'
import { env } from './env'

export const Unleash = {
  client: new UnleashClient({
    url: env.REACT_APP_UNLEASH_PROXY_URL || '',
    appName: 'nutrisense-web',
    clientKey: env.REACT_APP_UNLEASH_CLIENT_KEY || '',
    environment: env.REACT_APP_ENV,
    refreshInterval: 15
  })
}
