import { EventsType } from 'components/Events'

export const getEventData = (event: EventsType) => {
  let description = ''
  if (event.__typename === 'Note') {
    return { description: event.body }
  }
  if (event.__typename === 'JournalEntry') {
    return { description: event.body, measurements: event.measurements }
  }
  if (event.__typename === 'Meal') {
    const { description, ingredients, statistics } = event
    return { description, ingredients, statistics }
  }
  if (event.__typename === 'Activity' || event.__typename === 'Measurement') {
    description = event.description
  }

  return { description }
}
