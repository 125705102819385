import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const NutrisenseShortLogo = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 104 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6859 32.1906V58.7941H0V1.8026H10.6859V5.58719C10.6859 7.92473 10.4633 10.2623 9.46147 13.1564C9.43928 13.223 9.41659 13.29 9.39383 13.3573C9.07425 14.3022 8.74148 15.2861 9.57278 15.4939C10.2003 15.7031 10.533 15.0279 10.7556 14.5762C10.7699 14.5472 10.7838 14.519 10.7972 14.4921C13.0234 8.92653 20.2587 0.912109 30.4993 0.912109C43.6341 0.912109 51.6485 8.25866 51.6485 24.9554V58.7941H40.9626V27.1816C40.9626 13.9355 35.2857 10.2623 27.8279 10.2623C19.5908 10.2623 10.6859 18.6106 10.6859 32.1906ZM103.668 40.6503C103.668 52.2267 94.2061 59.6846 78.6225 59.6846C63.1502 59.6846 54.3566 51.5588 53.8001 38.9806H64.2633C64.3747 43.3218 67.9366 50.3344 79.0678 50.3344C89.9763 50.3344 92.9817 45.9932 92.9817 42.32C92.9817 37.1996 88.1953 35.6413 75.1719 34.3055C60.2561 32.7472 55.5811 25.9572 55.5811 18.4993C55.5811 9.92834 63.3729 0.912109 78.9565 0.912109C93.3156 0.912109 101.107 9.14916 101.887 19.8351H91.4233C91.0894 16.1618 88.6406 10.2623 78.6225 10.2623C69.6063 10.2623 66.267 13.9355 66.267 17.7201C66.267 21.3934 68.3819 23.8423 80.7374 25.178C96.0984 26.8477 103.668 31.3001 103.668 40.6503Z"
        fill="currentcolor"
      />
    </svg>
  </SvgIcon>
)
