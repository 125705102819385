import { useCallback, useEffect, useState } from 'react'
import { useSlateWithMentions } from 'components/MarkdownEditor/hooks/useSlateWithMentions'
import { withMentions } from 'components/MarkdownEditor/withMentions'
import { noop } from 'lodash'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact } from 'slate-react'
import { Element } from './Element'
import { Leaf } from './Leaf'
import { Placeholder } from './styles'
import { IMarkdownEditorProps, TypedDescendant } from './types'
import { onKeyDownHandler } from './utils'
import { withBulletedListLayout } from './withBulletedListLayout'
import { withHtml } from './withHtml'
import { withImages } from './withImages'
import { withLinks } from './withLinks'
import { withShortcuts } from './withShortcuts'

const DEFAULT_PLACEHOLDER = 'empty'

const renderElement = (props: any) => <Element {...props} />
const renderLeaf = (props: any) => <Leaf {...props} />

const defaultStyle = { outline: 'none' }

export const MarkdownEditor = ({
  initialValue,
  onChange = noop,
  onBlur = noop,
  placeholder = DEFAULT_PLACEHOLDER,
  bulleted = false,
  readOnly = false,
  autoFocus = false,
  mentionsOptions = [],
  children,
  style
}: IMarkdownEditorProps) => {
  const decorator = bulleted ? withBulletedListLayout : withShortcuts
  const [editor] = useState(
    decorator(withMentions(withLinks(withImages(withHtml(withReact(withHistory(createEditor())))))))
  )
  const [key, setKey] = useState(0)

  const {
    onKeyDown: onKeyDownWithMentions,
    onChange: onChangeWithMentions,
    children: childrenWithMentions
  } = useSlateWithMentions(editor, mentionsOptions)

  useEffect(() => {
    setKey((key) => key + 1)
  }, [initialValue])

  const onChangeWrapper = useCallback(
    (value: TypedDescendant[]) => {
      const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type)
      if (isAstChange) onChange(value)
      onChangeWithMentions?.()
    },
    [editor.operations, onChange, onChangeWithMentions]
  )

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={onChangeWrapper} key={key}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        onBlur={onBlur}
        onKeyDown={(event) => {
          onKeyDownWithMentions(event)
          onKeyDownHandler(editor)(event)
        }}
        readOnly={readOnly}
        autoFocus={autoFocus}
        placeholder={placeholder}
        renderPlaceholder={({ children, attributes }) => (
          <Placeholder component="span" {...attributes}>
            {children}
          </Placeholder>
        )}
        style={{ ...defaultStyle, ...style }}
      />
      <>
        {childrenWithMentions}
        {children}
      </>
    </Slate>
  )
}
