import { Typography } from '@mui/material'
import { CreatedAtDate } from './CreatedAtDate'
import { IEventElement } from './Event'
import { getPerformedByFullName } from './utils'

const UNKNOWN_NAME = 'unknown'

export const AssignmentEvent = ({ event, currentAdmin }: IEventElement) => {
  const performedByName = getPerformedByFullName(event.performedBy, currentAdmin) || UNKNOWN_NAME
  const assignedToName = getPerformedByFullName(event.assignedTo, currentAdmin) || UNKNOWN_NAME

  return (
    <Typography color="text.secondary" variant="caption">
      {`${performedByName} assigned this conversation to ${assignedToName} on `}
      <CreatedAtDate event={event} />
    </Typography>
  )
}
