import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { NutritionistNextCall } from 'components/NutritionistNextCall'
import { LocationPaths } from 'location.types'
import { useNavigate } from 'react-router-dom'

export const NutritionistLeftAdornment = () => {
  const navigate = useNavigate()

  return (
    <>
      <Tooltip title="Daily Appointments">
        <IconButton edge="start" onClick={() => navigate(`${LocationPaths.NutritionistHome}/`)}>
          <CalendarTodayOutlinedIcon />
        </IconButton>
      </Tooltip>
      <NutritionistNextCall />
    </>
  )
}
