export enum AppEnv {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  PreProduction = 'pre-production'
}

type IEnv = ImportMetaEnv & {
  REACT_APP_ENV?: `${AppEnv}`
  REACT_APP_COMMIT_HASH?: string
  REACT_APP_HEALTH_PORTAL_URL: string
  REACT_APP_BUGSNAG_API_KEY: string
  REACT_APP_MOBILE_APP_URL?: string
  REACT_APP_API_ENDPOINT?: string
  REACT_APP_UNLEASH_PROXY_URL?: string
  REACT_APP_UNLEASH_CLIENT_KEY?: string
  REACT_APP_GOOGLE_CLIENT_ID: string
  REACT_APP_COURSES_CMS_ENDPOINT: string
  REACT_APP_COURSES_CMS_API_KEY: string
  REACT_APP_STREAM_CHAT_API_KEY: string
}

export const env = import.meta.env as IEnv
