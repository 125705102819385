import { useReactiveVar } from '@apollo/client'
import { dateFilterVar, useTimeZone } from 'context'
import { parseGlucoseDatapoints } from 'screens/UserDetails/Graph/helpers'
import { IGraphDataPoint } from 'screens/UserDetails/Graph/types'
import { ISO_DATE_FORMAT, MMM_DD_HH_MM_A } from 'utility/timeFormats'
import { useAllChartsQuery } from 'types'

export const useGlucoseValue = (
  event: { occurredAt: string } | undefined,
  graphDataPoint: IGraphDataPoint
) => {
  const { startDate, endDate } = useReactiveVar(dateFilterVar)
  const { data: { allCharts } = {} } = useAllChartsQuery({
    variables: {
      filter: {
        types: [{ key: 'timeline', value: [] }],
        startDate,
        endDate
      }
    }
  })

  const glucoseDatapoints = parseGlucoseDatapoints(allCharts?.charts[0]?.values || [])

  const timeToGlucoseValueMap = new Map(
    glucoseDatapoints.map((datapoint) => {
      return [datapoint.time, datapoint.value]
    })
  )

  return (
    (event && timeToGlucoseValueMap.get(new Date(event?.occurredAt).getTime())) ||
    graphDataPoint?.value ||
    ''
  )
}

export const getEventDate = (
  event: { occurredAt: string } | undefined,
  graphDataPoint: IGraphDataPoint
) => {
  return event?.occurredAt || graphDataPoint?.time
}

export const useEventDate = (
  event: { occurredAt: string } | undefined,
  graphDataPoint: IGraphDataPoint
) => {
  const { formatWithTimeZone } = useTimeZone()

  const date = getEventDate(event, graphDataPoint)
  return date ? formatWithTimeZone(date, MMM_DD_HH_MM_A) : ''
}

export const useEventUtcDate = (
  event: { occurredAt: string } | undefined,
  graphDataPoint: IGraphDataPoint
) => {
  const { formatWithTimeZone } = useTimeZone()

  const date = getEventDate(event, graphDataPoint)
  return date ? formatWithTimeZone(date, ISO_DATE_FORMAT) : ''
}
