import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { SectionHeader } from 'components'

interface IUserSettingsSectionRow {
  label: string
  cells: (string | React.ReactNode)[]
}

interface IUserSettingsSection {
  header: string
  headerSlotRight?: React.ReactNode
  rows: IUserSettingsSectionRow[]
}

export const UserSettingsSection = ({ header, headerSlotRight, rows }: IUserSettingsSection) => {
  return (
    <>
      <SectionHeader label={header} slotRight={headerSlotRight} />
      <TableContainer sx={{ overflowX: 'unset' }}>
        <Table size="small">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.label}>
                <TableCell width="35%">{row.label}</TableCell>
                {row.cells.map((cell, index) => (
                  <TableCell
                    key={index}
                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                    align={row.cells.length - 1 === index ? 'right' : 'center'}
                  >
                    {cell || '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
