import { GridSortItem } from '@mui/x-data-grid/models/gridSortModel'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { snakeCase } from 'lodash'

export const getSortByFromSortItem = (
  sortItem: GridSortItem | undefined,
  columns: IDataGridColumn[]
) => {
  if (!sortItem) return

  const columnDefinition = columns.find((column) => column.field === sortItem.field)
  return snakeCase(columnDefinition?.backendSortName || sortItem.field)
}
