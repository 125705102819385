import { getGridStringOperators, getGridSingleSelectOperators } from '@mui/x-data-grid'

const subscriptionStatusFilterOperators = () =>
  getGridStringOperators()
    .concat(getGridSingleSelectOperators())
    .filter((operator) =>
      ['is', 'not', 'isEmpty', 'isNotEmpty', 'isAnyOf'].includes(operator.value)
    )

export const distinctSubscriptionStatusFilterOperators = () => [
  ...new Map(
    subscriptionStatusFilterOperators().map((operator) => [operator.value, operator])
  ).values()
]
