import { SyntheticEvent, useRef } from 'react'
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField
} from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import { startCase } from 'lodash'
import {
  IKnowledgeQuestion,
  IKnowledgeTag,
  useAllKnowledgeTagsLazyQuery,
  KnowledgeTagDynamicFilterField,
  DynamicFilterItemOperation,
  DynamicFilterOperator
} from 'types'
import { TagChip } from './styles'
import { useQuestionTagging } from './useQuestionTagging'

interface ITagsCellProps extends Omit<GridRenderEditCellParams, 'row'> {
  row: IKnowledgeQuestion
  editable?: boolean
}

export const TagsCell = ({ row, field, value, editable = false }: ITagsCellProps) => {
  const apiRef = useGridApiContext()
  const searchValue = useRef('')
  const tags: IKnowledgeTag[] = value || []

  const {
    addTag,
    removeTag,
    loading: updating
  } = useQuestionTagging({
    onTaggingUpdated: (tags) => {
      apiRef.current.setEditCellValue({ id: row.id, field, value: tags })
    }
  })

  const [searchTags, { data, loading }] = useAllKnowledgeTagsLazyQuery({
    variables: {
      dynamicFilters: {
        items: [
          {
            field: KnowledgeTagDynamicFilterField.Value,
            operation: DynamicFilterItemOperation.ICont,
            value: searchValue.current
          }
        ],
        operator: DynamicFilterOperator.Or
      }
    }
  })

  const handleAdd = (tag: IKnowledgeTag) => {
    addTag({ variables: { id: row.id, knowledgeTagId: tag.id } })
  }

  const handleRemove = (tag: IKnowledgeTag) => {
    removeTag({ variables: { id: row.id, knowledgeTagId: tag.id } })
  }

  const handleChange = (
    _event: SyntheticEvent,
    _newTags: IKnowledgeTag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<IKnowledgeTag>
  ) => {
    switch (reason) {
      case 'selectOption':
        return handleAdd(details.option)
      case 'removeOption':
        return handleRemove(details.option)
    }
  }

  const { handleInputChange } = useDebouncedSearch(searchTags, searchValue)
  const options = data?.allKnowledgeTags.knowledgeTags || []

  return (
    <Autocomplete<IKnowledgeTag, true, true>
      multiple
      size="small"
      limitTags={2}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      readOnly={!editable || updating}
      getOptionLabel={(option: IKnowledgeTag) => startCase(option.value)}
      value={tags}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderTags={(tags, getTagProps) =>
        tags.map((tag, index) => (
          <TagChip
            {...getTagProps({ index })}
            key={tag.id}
            id={tag.id}
            label={startCase(tag.value)}
          />
        ))
      }
      fullWidth
      disableClearable
      popupIcon={editable ? undefined : null}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={editable ? 'Search for a tag' : ''}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          sx={{
            '& .MuiInputBase-input': {
              // Hide the input when not in editing mode so that tags are centered in their cell
              display: editable ? 'block' : 'none'
            }
          }}
        />
      )}
      sx={{ margin: '4px' }}
    />
  )
}
