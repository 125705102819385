import { GridFilterModel } from '@mui/x-data-grid'
import { canonicalizeFilterModel } from './utils'

export const getFilterModelQueryString = (filterModel: GridFilterModel) => {
  const { filter_link, ...items } = canonicalizeFilterModel(filterModel)

  const itemString = Object.entries(items)
    .flatMap(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

  return `${itemString}&filter_link=${filter_link}`
}
