import { useMemo, useState } from 'react'
import {
  Autocomplete,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { ChargeOption } from 'components/AccountRequestsMenu/modals/RefundModal/ChargeOption'
import { centsAmountFromDollarStringAmount } from 'components/AccountRequestsMenu/modals/utils'
import { snackBarVisibleVar } from 'context'
import chargesGridVars from 'context/billingDashboard/charges'
import { useAuthorizedFeature } from 'hooks'
import { startCase } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { getCommonListQueryVariablesForUser } from 'screens/BillingDashboard/helpers/getCommonListQueryVariablesForUser'
import { useReactiveGridVars } from 'utility/dataGrid/commonVariables'
import {
  OpsAccountRequestKindsCancelSubscriptionReason,
  OpsAccountRequestKind,
  OpsAccountRequestKindsRefundReason,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useUpdateAccountRequestMutation,
  IUser,
  useBillingDashboardAllChargesQuery,
  ICharge,
  ChargeDynamicFilterField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  HealthPortalFeature,
  useShowAccountRequestsConfigQuery
} from 'types'

export interface IRefundForm {
  chargeId: string
  amountInCents: string
  subscriptionCancellationRequired: boolean
  subscriptionCancellationReason: OpsAccountRequestKindsCancelSubscriptionReason
  refundReason: OpsAccountRequestKindsRefundReason
  priority: OpsAccountRequestPriority
  note: string
}

export const RefundModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const [selectedChargeId, setSelectedChargeId] = useState<string>('')
  const { handleSubmit, control, register, reset, formState, setValue } = useForm<IRefundForm>({
    mode: 'onTouched',
    defaultValues: {
      chargeId: '',
      amountInCents: '',
      subscriptionCancellationRequired: undefined,
      subscriptionCancellationReason: undefined,
      refundReason: undefined,
      priority: OpsAccountRequestPriority.Regular,
      note: ''
    }
  })

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessRefund
  )

  const chargesReactiveGridVars = useReactiveGridVars(chargesGridVars)
  const commonListQueryVars = getCommonListQueryVariablesForUser(
    chargesReactiveGridVars,
    currentUser as IUser
  )

  const { data: chargesData } = useBillingDashboardAllChargesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...commonListQueryVars,
      dynamicFilters: {
        items: [
          ...(commonListQueryVars.dynamicFilters?.items || []),
          {
            field: ChargeDynamicFilterField.Id,
            operation: DynamicFilterItemOperation.Eq,
            value: selectedChargeId
          }
        ],
        operator: DynamicFilterOperator.And
      }
    }
  })

  const { data: configData } = useShowAccountRequestsConfigQuery()
  const refundReasons = configData?.showAccountRequestsConfig?.refundReasons

  const sortedRefundReasons = useMemo(() => {
    if (!refundReasons) return []

    // Refund Reasons array is frozen, slice returns a not frozen copy
    return refundReasons.slice().sort((reasonA, reasonB) => {
      return reasonA.key.localeCompare(reasonB.key)
    })
  }, [refundReasons])

  const [isCancellationRequired, setIsCancellationRequired] = useState(false)

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser) {
    return null
  }

  const refundHandler = async (formData: IRefundForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.Refund,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.Refund,
          refund: {
            chargeId: formData.chargeId,
            amountInCents: centsAmountFromDollarStringAmount(formData.amountInCents),
            subscriptionCancellationRequired: isCancellationRequired,
            subscriptionCancellationReason: formData.subscriptionCancellationReason
              ? formData.subscriptionCancellationReason
              : undefined,
            refundReason: formData.refundReason
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const handleRefundChargeIdSelection = (_: any, charge: ICharge) => {
    setValue('chargeId', charge?.id || '', { shouldValidate: true })
  }

  const handleInputChange = (_: any, chargeId: string) => setSelectedChargeId(chargeId)
  const innerForm = (
    <>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <Controller
          name="chargeId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              options={chargesData?.allCharges?.charges ?? []}
              renderOption={(props, option) => (
                <ListItem {...props}>
                  <ChargeOption key={option.id} charge={option} />
                </ListItem>
              )}
              onChange={handleRefundChargeIdSelection}
              noOptionsText="No charges found"
              getOptionLabel={(option) => option.id || ''}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...register('chargeId', { required: true })}
                  {...params}
                  placeholder="Charge ID"
                  label="Charge ID"
                  error={!!formState.errors.chargeId}
                />
              )}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Subscription Cancellation Required</InputLabel>
        <Controller
          name="subscriptionCancellationRequired"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('subscriptionCancellationRequired', { required: true })}
              error={!!formState.errors.subscriptionCancellationRequired}
              label="Subscription Cancellation Required"
            >
              <MenuItem value="true" onClick={() => setIsCancellationRequired(true)}>
                Yes
              </MenuItem>
              <MenuItem value="false" onClick={() => setIsCancellationRequired(false)}>
                No
              </MenuItem>
            </Select>
          )}
        />
      </FormControl>
      {isCancellationRequired && (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>Subscription Cancellation Reason</InputLabel>
          <Controller
            name="subscriptionCancellationReason"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                {...register('subscriptionCancellationReason', {
                  required: isCancellationRequired
                })}
                error={!!formState.errors.subscriptionCancellationReason}
                label="Subscription Cancellation Reason"
              >
                {Object.values(OpsAccountRequestKindsCancelSubscriptionReason).map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {startCase(choice)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      )}
      <FormControl variant="outlined" margin="dense" fullWidth>
        <Controller
          name="amountInCents"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...rest } }) => (
            <NumericFormat
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              getInputRef={ref}
              customInput={TextField}
              variant="outlined"
              fullWidth
              label="Refund Amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              error={!!formState.errors.amountInCents}
              {...rest}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Refund Reason</InputLabel>
        <Controller
          name="refundReason"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('refundReason', { required: true })}
              error={!!formState.errors.refundReason}
              label="Refund Journey"
            >
              <MenuItem value={undefined} disabled>
                <em>Refund Reason</em>
              </MenuItem>
              {sortedRefundReasons.map((refundReason) => (
                <MenuItem key={refundReason.key} value={refundReason.key}>
                  <Stack>
                    {startCase(refundReason.key)}
                    <Typography variant="caption">{refundReason.description}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  )
  return (
    <AccountRequestModal<IRefundForm>
      register={register}
      modalTitle="Refund"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(refundHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      {innerForm}
    </AccountRequestModal>
  )
}
