import { ACCOUNT_REQUEST_TITLE_MAP } from 'accountRequest.types'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { startCase } from 'lodash'
import {
  formatDate,
  renderAccountRequestPayloadParams,
  renderAsPossiblyEmptyField,
  renderAsTextWithTooltip,
  renderAsUserHomePageLink
} from 'utility/dataGrid'
import {
  IAccountRequest,
  IAccountRequestPayloadUnionPresenterType,
  IUser,
  OpsAccountRequestKind,
  OpsAccountRequestPriority
} from 'types'

type IPaymentRequestRow = { [K in keyof IAccountRequest as K]?: IAccountRequest[K] } & {
  [K in keyof IUser as `user.${K}`]?: IUser[K]
} & {
  [K in keyof IAccountRequestPayloadUnionPresenterType as `payload.${string}`]?: IAccountRequestPayloadUnionPresenterType[K]
}

export const columns: (IDataGridColumn & { field: keyof IPaymentRequestRow })[] = [
  {
    field: 'user.fullName',
    headerName: 'User Full Name',
    width: 200,
    valueGetter: ({ row }) => row.user.fullName,
    renderCell: (params) => renderAsUserHomePageLink(params, (row) => row?.user?.id)
  },
  {
    field: 'user.email',
    headerName: 'Email',
    minWidth: 200,
    valueGetter: ({ row }) => {
      return row.user.email
    }
  },
  {
    field: 'escalatedAt',
    type: 'date',
    headerName: 'Submission Date',
    width: 150,
    valueFormatter: formatDate
  },
  {
    field: 'escalatedBy',
    headerName: 'Submitted by',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueFormatter: ({ value }) => startCase(value.fullName)
  },
  {
    field: 'processedAt',
    type: 'date',
    headerName: 'Execution Date',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: formatDate,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'processedBy',
    headerName: 'Executed by',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ value }) => startCase(value?.fullName),
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'rejectedAt',
    type: 'date',
    headerName: 'Rejected Date',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: formatDate,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'rejectedBy',
    headerName: 'Rejected by',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ value }) => startCase(value?.fullName),
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'reviewedAt',
    type: 'date',
    headerName: 'Review Date',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: formatDate,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'reviewedBy',
    headerName: 'Reviewed by',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ value }) => startCase(value?.fullName),
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'errorMessage',
    headerName: 'Error Message',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'payload.pauseReason',
    headerName: 'Pause Reason',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ row }) => row.payload.reason,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'payload.cancellationReason',
    headerName: 'Cancelation Reason',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ row }) => row.payload.cancellationReason,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'payload.refundReason.journey',
    headerName: 'Refund Journey',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ row }) => row.payload.refundReason?.journey,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'payload.refundReason.reasonOne',
    headerName: 'Refund Reason 1',
    width: 150,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ row }) => row.payload.refundReason?.reasonOne,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'payload.refundReason.reasonTwo',
    headerName: 'Refund Reason 2',
    width: 300,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ row }) => row.payload.refundReason?.reasonTwo,
    renderCell: renderAsPossiblyEmptyField
  },
  {
    field: 'priority',
    headerName: 'Priority',
    type: 'singleSelect',
    valueOptions: Object.keys(OpsAccountRequestPriority).map(
      (key: keyof typeof OpsAccountRequestPriority) => {
        return { label: key, value: OpsAccountRequestPriority[key] }
      }
    ),
    valueFormatter: ({ value }) => startCase(value),
    width: 160
  },
  {
    field: 'kind',
    headerName: 'Request Type',
    width: 200,
    type: 'singleSelect',
    valueOptions: Object.keys(OpsAccountRequestKind).map(
      (key: keyof typeof OpsAccountRequestKind) => {
        return {
          label: ACCOUNT_REQUEST_TITLE_MAP[OpsAccountRequestKind[key]],
          value: OpsAccountRequestKind[key]
        }
      }
    ),
    valueGetter: ({ row }) =>
      ACCOUNT_REQUEST_TITLE_MAP[row.kind as keyof typeof ACCOUNT_REQUEST_TITLE_MAP],
    valueFormatter: ({ value }) => startCase(value)
  },
  {
    field: 'payload',
    headerName: 'Values',
    width: 400,
    sortable: false,
    filterable: false,
    groupable: false,
    valueGetter: ({ row }) => row.payload,
    renderCell: renderAccountRequestPayloadParams
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 400,
    sortable: false,
    filterable: false,
    groupable: false,
    renderCell: (params) => renderAsTextWithTooltip(params, true)
  }
]
