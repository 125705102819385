import React, { useMemo } from 'react'
import { Card, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { Loader, WithCopyToClipboardAction } from 'components'
import { useUnitSystem } from 'context'
import { useGraphDateRange } from 'hooks'
import { upperCase } from 'lodash'
import { STATS_TYPES } from 'screens/UserDetails/Stats/Stats'
import { theme } from 'theme'
import {
  getStatTitle,
  getStatUnit,
  getTrendArrow,
  getValueChangeText,
  MacroStats,
  StatSections
} from 'utility/statistic'
import { IStatistic, useAllStatsQuery } from 'types'

const STAT_KEYS = [MacroStats.Calories, MacroStats.Protein, MacroStats.Carbs, MacroStats.Fat]

export const MacrosData = () => {
  const { unitSystem } = useUnitSystem()
  const { startDate, endDate } = useGraphDateRange()
  const { data, loading } = useAllStatsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { filter: { startDate, endDate, types: STATS_TYPES } }
  })

  const stats = useMemo(() => {
    const rawStats = data?.allStats.statistics || []

    return rawStats.filter(({ meta }) => {
      return meta.section === StatSections.Macros && STAT_KEYS.includes(meta.key as MacroStats)
    })
  }, [data])

  const getContent = () => {
    return (
      <Grid container spacing={4}>
        {stats.map((stat: IStatistic) => {
          const TrendArrow = getTrendArrow(stat)
          const value = stat.value.toLocaleString()
          const unit = getStatUnit(stat, unitSystem)
          const tooltipTitle = getValueChangeText(stat, unit)

          return (
            <Grid item xs key={stat.meta.key}>
              <Stack sx={{ alignItems: 'center' }}>
                <WithCopyToClipboardAction value={`${value}${unit}`}>
                  <Typography sx={{ color: theme.palette['text-color'].secondary, fontSize: 12 }}>
                    {upperCase(getStatTitle(stat))}
                  </Typography>
                </WithCopyToClipboardAction>
                <Tooltip title={tooltipTitle}>
                  <Typography noWrap fontWeight={500}>
                    {stat.value === 0 ? (
                      'N/A'
                    ) : (
                      <>
                        {value}
                        {unit}
                        {TrendArrow && (
                          <TrendArrow fontSize="small" sx={{ verticalAlign: 'middle' }} />
                        )}
                      </>
                    )}
                  </Typography>
                </Tooltip>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.grey[100],
        width: '450px',
        height: '64px',
        padding: '8px 16px'
      }}
    >
      {loading ? <Loader /> : getContent()}
    </Card>
  )
}
