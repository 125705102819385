import { useState } from 'react'

const DEFAULT_TOOLTIP_TITLE = 'Copy'

export const useClipboard = (value: string) => {
  const [tooltipTitle, setTooltipTitle] = useState(DEFAULT_TOOLTIP_TITLE)

  const handleClick = () => {
    navigator.clipboard.writeText(value)
    setTooltipTitle('Copied')
    setTimeout(() => setTooltipTitle(DEFAULT_TOOLTIP_TITLE), 2500)
  }

  return {
    tooltipTitle,
    handleClick
  }
}
