import { Box, Typography } from '@mui/material'

interface IHeader {
  formattedDate: string
  glucoseValue: number | string
}
export const Header = ({ formattedDate, glucoseValue }: IHeader) => {
  return (
    <>
      <Box>
        <Typography color="initial" component="span">
          Date:{' '}
        </Typography>
        <Typography color="initial" fontWeight={700} component="span">
          {formattedDate}
        </Typography>
      </Box>
      <Box>
        <Typography color="initial" component="span">
          Glucose Measurement:{' '}
        </Typography>
        <Typography color="initial" component="span" fontWeight={700}>
          {glucoseValue}
        </Typography>
      </Box>
    </>
  )
}
