import { useAuth } from 'context'
import { HealthPortalFeature } from 'types'

type IUseAuthorizedFeature = {
  (feature: HealthPortalFeature): boolean
  (feature: HealthPortalFeature[]): boolean[]
}

/**
 * Convenience hook for checking if the current admin is authorized to use a specific feature
 *
 * @param feature The HealthPortalFeature or array of features to check
 * @returns true if authorized; otherwise false
 */
export const useAuthorizedFeature: IUseAuthorizedFeature = (feature: unknown): any => {
  const { isAuthorized } = useAuth()

  if (feature instanceof Array) {
    return (feature as HealthPortalFeature[]).map(isAuthorized)
  }
  return isAuthorized(feature as HealthPortalFeature)
}
