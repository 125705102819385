import { Tooltip, Typography, TypographyProps } from '@mui/material'

interface IEmoji {
  label: string
  symbol: string
  fontSize?: number
  tooltipTitle?: string
}
export const Emoji = ({
  label,
  symbol,
  fontSize = 24,
  tooltipTitle = '',
  ...props
}: IEmoji & Partial<TypographyProps>) => {
  return (
    <Tooltip title={tooltipTitle} placement="left">
      <Typography
        {...props}
        role="img"
        component="span"
        aria-label={label}
        aria-hidden={false}
        sx={{ fontSize: fontSize }}
      >
        {symbol}
      </Typography>
    </Tooltip>
  )
}
