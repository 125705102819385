import { SyntheticEvent, useState, ReactNode } from 'react'
import { Box, Tab, Tabs as MuiTabs } from '@mui/material'
import { TabPanel } from '.'
import { TabsStyled } from './styles'

export interface ISidebarProps {
  tabs?: {
    content: ReactNode
    label: ReactNode
    onTabSelected?: () => void
  }[]
}

export const Tabs = ({ tabs = [] }: ISidebarProps) => {
  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <TabsStyled>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs value={value} onChange={handleChange}>
          {tabs.map(({ label, onTabSelected }, index) => (
            <Tab
              onClick={() => {
                onTabSelected?.()
              }}
              key={`${label}-${index}`}
              sx={{ textTransform: 'none', minWidth: `${100 / tabs.length}%` }}
              label={label}
            />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map(({ content, label }, index) => (
        <TabPanel key={`${label}-${index}`} value={value} index={index}>
          {content}
        </TabPanel>
      ))}
    </TabsStyled>
  )
}
