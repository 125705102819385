import { useEffect, useState } from 'react'
import { Client } from '@bugsnag/browser'
import Bugsnag from '@bugsnag/js'

import BugsnagPluginReact from '@bugsnag/plugin-react'
import { AppEnv, env } from 'config'
import { useCurrentAdminQuery, useCurrentUserQuery } from 'types'

export const useBugsnagInit = () => {
  const [BugsnagClient, setBugsnagClient] = useState<Client | undefined>(undefined)
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()

  useEffect(() => {
    if (env.REACT_APP_ENV !== AppEnv.Production) return

    if (Bugsnag?.isStarted() || !currentUser || !currentAdmin) {
      return
    }

    const client = Bugsnag.start({
      apiKey: env.REACT_APP_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      onError: (event) => {
        if (currentUser) {
          event.setUser(currentUser.id, currentUser.email, currentUser.fullName)
        }
        if (currentAdmin) {
          event.addMetadata('admin', currentAdmin)
        }
      }
    })

    setBugsnagClient(client)
  }, [currentUser, currentAdmin])

  return { BugsnagClient }
}
