import { Box, Stack, Typography, styled } from '@mui/material'

export const PreviewHeader = styled(Typography)({
  fontWeight: 500
})

export const PreviewTitle = styled(Typography)({
  marginBottom: 16,
  fontWeight: 500
})

export const PreviewBox = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  overflow: 'auto',
  padding: 16,
  backgroundColor: theme.palette['theme-primary']['level-2'],
  borderRadius: '4px'
}))

export const PreviewStack = styled(Stack)({
  flex: 1,
  minHeight: 0,
  overflow: 'hidden'
})

export const LinkedItemsBox = styled(Box)({
  height: '50%',
  border: '1px solid #eaeaea'
})
