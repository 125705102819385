import { getCommonListQueryVariables, ReactiveGridVars } from 'utility/dataGrid/commonVariables'

export const getCommonListQueryVariablesWithDefaults = <T>(
  reactiveGridVars: ReactiveGridVars,
  defaultFilters: T[]
) => {
  if (defaultFilters.length === 0) return getCommonListQueryVariables(reactiveGridVars)

  return getCommonListQueryVariables({
    ...reactiveGridVars,
    dynamicFilters: [...reactiveGridVars.dynamicFilters, ...defaultFilters]
  })
}
