import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { formatDate, renderAsCheck, renderAsPossiblyEmptyField } from 'utility/dataGrid'
import { ICharge, IInvoice, ISubscription, IUser } from 'types'

type IChargeGridRow = { [K in keyof ICharge as K]?: ICharge[K] } & {
  [K in keyof IInvoice as `invoice.${K}`]?: IInvoice[K]
} & {
  [K in keyof ISubscription as `invoice.subscription.${K}`]?: ISubscription[K]
} & {
  [K in keyof IUser as `user.${K}`]?: IUser[K]
}

export const columns: (IDataGridColumn & { field?: keyof IChargeGridRow })[] = [
  {
    field: 'id',
    headerName: 'Charge Id',
    type: 'string',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    description: 'Charge Id'
  },
  {
    field: 'stripeId',
    headerName: 'Stripe Charge Id',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    description: 'Stripe Charge Id'
  },
  {
    field: 'created',
    headerName: 'Created',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    description: 'Created',
    valueFormatter: formatDate
  },
  {
    field: 'invoice.stripeId',
    headerName: 'Stripe Invoice Id',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    groupable: true,
    renderCell: (params) => renderAsPossiblyEmptyField(params, null),
    valueGetter: ({ row }) => row.invoice?.stripeId,
    description: 'Stripe Invoice Id'
  },
  {
    field: 'invoice.subscription.stripeId',
    headerName: 'Stripe Subscription Id',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    groupable: true,
    renderCell: (params) => renderAsPossiblyEmptyField(params, null),
    valueGetter: ({ row }) => row.invoice?.subscription?.stripeId,
    description: 'Stripe Subscription Id'
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    width: 100,
    align: 'center',
    headerAlign: 'center',
    description: 'Amount'
  },
  {
    field: 'paid',
    headerName: 'Paid',
    type: 'boolean',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderAsCheck,
    groupable: true,
    description: 'Describes if charge was paid or not'
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 150,
    align: 'center',
    groupable: true,
    headerAlign: 'center',
    description: 'Charge Status'
  },
  {
    field: 'failureMessage',
    headerName: 'Failure Message',
    type: 'string',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderAsPossiblyEmptyField,
    description: 'Charge Failure Message'
  },
  {
    field: 'refunded',
    headerName: 'Refunded',
    type: 'boolean',
    width: 150,
    renderCell: renderAsCheck,
    align: 'center',
    headerAlign: 'center',
    groupable: true,
    description: 'Describes if the charge was refunded or not'
  },
  {
    field: 'amountRefunded',
    headerName: 'Amount Refunded',
    type: 'number',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    description: 'Amount Refunded'
  },
  {
    field: 'disputed',
    headerName: 'Disputed',
    type: 'boolean',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderAsCheck,
    groupable: true,
    description: 'Describes if charge was disputed or not'
  },
  {
    field: 'disputeStripeId',
    headerName: 'Dispute Id',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell: renderAsPossiblyEmptyField,
    description: 'Charge Dispute Id'
  }
]
