import { CardHeader, Stack, Typography } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const CardHeaderStyled = styled(CardHeader)({
  padding: '1rem',
  '.MuiCardHeader-avatar': {
    alignSelf: 'flex-start'
  }
})

export const StackWithIcons = styled(Stack)({
  paddingTop: 8,
  svg: {
    color: theme.palette.icon.secondary,
    fontSize: 15
  },
  p: {
    paddingRight: 8
  }
})

export const ItemStyled = styled(Typography)({
  fontSize: 13
})

export const SubtitleStyled = styled(ItemStyled)({
  color: theme.palette['text-color'].secondary,
  fontWeight: 600
})

export const TitleStyled = styled(Typography)({
  fontWeight: 700,
  fontSize: 32,
  lineHeight: 1
})

export const PartnerLogoStyled = styled('img')({
  maxHeight: 10
})
