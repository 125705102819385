import { Button, Box, Card, Checkbox } from '@mui/material'
import styled from '@mui/styled-engine'

export const NotificationCardStyled = styled(Card)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  paddingTop: 10,
  paddingBottom: 10,
  justifyContent: 'space-between',
  boxShadow: 'inset 0px -1px 0px rgba(0,0,0,0.12)',
  '&:hover': {
    backgroundColor: '#f8f8f8'
  }
})

export const CheckboxStyled = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#A3C278'
  }
})

export const SeeCompletedButtonStyled = styled(Button)({
  marginTop: 18
})

export const TitleMetadataStyled = styled('span')({
  display: 'inline-block',
  fontSize: 10,
  marginLeft: 10,
  whiteSpace: 'nowrap'
})

export const UserNameStyled = styled(Box)({
  cursor: 'pointer',
  color: ' rgba(0, 0, 0, 0.6)'
})
