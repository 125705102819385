import { KnowledgeResource } from 'knowledgeResource.types'
import { useNavigate } from 'react-router-dom'
import { IKnowledgeQuestion } from 'types'
import { QuestionTitleChip } from './QuestionTitleChip'
import { LinkedItemsCellBox } from './styles'
import { getLinkToKnowledgeItem } from './utils'

interface ILinkedQuestionsCellProps {
  questions: IKnowledgeQuestion[]
}

export const LinkedQuestionsCell = ({ questions }: ILinkedQuestionsCellProps) => {
  const navigate = useNavigate()

  const handleClick = (question: IKnowledgeQuestion) => {
    navigate(getLinkToKnowledgeItem(KnowledgeResource.Questions, question))
  }

  return (
    <LinkedItemsCellBox>
      {questions.map((question) => (
        <QuestionTitleChip
          key={question.id}
          title={question.value}
          onClick={() => handleClick(question)}
        />
      ))}
    </LinkedItemsCellBox>
  )
}
