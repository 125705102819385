import { useEffect, useState } from 'react'
import {
  Alert,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'

import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { useAuthorizedFeature } from 'hooks'
import { startCase } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat, numericFormatter } from 'react-number-format'
import {
  BillingProduct,
  HealthPortalFeature,
  IProduct,
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  OpsAccountRequestStatus,
  useCreateAccountRequestMutation,
  useCurrentUserQuery,
  useEligibleProductsLazyQuery,
  useUpdateAccountRequestMutation
} from 'types'
import { centsAmountFromDollarStringAmount } from './utils'

interface ICreditForm {
  product: BillingProduct | ''
  paid: 'true' | 'false' | ''
  discount: 'true' | 'false' | ''
  discountAmount: string
  priority: OpsAccountRequestPriority
  note: string
}

const ONE_OFF_ADDON_PRODUCTS = [
  BillingProduct.Cgm,
  BillingProduct.Patch,
  BillingProduct.WelcomeKit,
  BillingProduct.DmDietitianVideo_30Min,
  BillingProduct.NutrisenseDietitian
]

function getFormattedCharge(price: number, watchDiscount: string, watchDiscountAmount: string) {
  if (watchDiscount !== 'true') return price

  const centsValue = (price * 100 - centsAmountFromDollarStringAmount(watchDiscountAmount)) / 100
  const effectiveCentValue = centsValue < 0 ? 0 : centsValue
  return numericFormatter(String(effectiveCentValue), { thousandSeparator: true, decimalScale: 2 })
}

export const OneOffAddonModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const [price, setPrice] = useState<number | undefined>(undefined)
  const { handleSubmit, control, register, reset, formState, watch, setValue } =
    useForm<ICreditForm>({
      mode: 'onTouched',
      defaultValues: {
        product: '',
        paid: '',
        discount: '',
        discountAmount: '',
        priority: OpsAccountRequestPriority.Regular,
        note: ''
      }
    })
  const watchPaid = watch('paid')
  const watchDiscount = watch('discount')
  const watchDiscountAmount = watch('discountAmount')

  const processAuthorized = useAuthorizedFeature(
    HealthPortalFeature.SupportAccountRequestsProcessOneOffAddon
  )

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading: createLoading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [updateAccountRequest, { loading: updateLoading }] = useUpdateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  const [getEligibleProducts, { data: eligibleProductsData }] = useEligibleProductsLazyQuery()
  const eligibleProducts = eligibleProductsData?.eligibleProducts.products || []

  useEffect(() => {
    if (currentUser)
      getEligibleProducts({
        variables: { userId: currentUser.id, products: ONE_OFF_ADDON_PRODUCTS }
      })
  }, [currentUser, getEligibleProducts])

  if (!currentUser) {
    return null
  }

  const oneOffAddonHandler = async (formData: ICreditForm) => {
    const createResponse = await createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.OneOffAddon,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.OneOffAddon,
          oneOffAddon: {
            product: formData.product as BillingProduct,
            paid: formData.paid === 'true',
            discountCentsAmount: centsAmountFromDollarStringAmount(formData.discountAmount)
          }
        }
      }
    })

    const accountRequestId = createResponse.data?.createAccountRequest.id

    if (processAuthorized && accountRequestId) {
      await updateAccountRequest({
        variables: { accountRequestId, status: OpsAccountRequestStatus.Processing }
      })
    }

    onClose()
  }

  const onProductSelect = (product: IProduct) => {
    setPrice(product.price)
    if (!product.price) setValue('paid', 'false')
  }

  return (
    <AccountRequestModal<ICreditForm>
      register={register}
      modalTitle="One Off Addon"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(oneOffAddonHandler)}
      loading={createLoading || updateLoading}
      callToActionText={processAuthorized ? 'Submit' : 'Escalate'}
    >
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Select Product</InputLabel>
        <Controller
          name="product"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('product', { required: true })}
              error={!!formState.errors.product}
              label="Select Product"
            >
              <MenuItem value="" disabled>
                <em>Select Product</em>
              </MenuItem>
              {eligibleProducts.map((product) => (
                <MenuItem
                  key={product.key}
                  value={product.key}
                  onClick={() => onProductSelect(product as IProduct)}
                >
                  {startCase(product.title)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Paid or free?</InputLabel>
        <Controller
          name="paid"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              {...register('paid', { required: true })}
              error={!!formState.errors.paid}
              disabled={!price}
              label="Paid or free?"
            >
              <MenuItem value="true">Paid</MenuItem>
              <MenuItem value="false">Free</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      {!!price && watchPaid === 'true' && (
        <FormControl margin="dense" fullWidth>
          <Alert severity="info" variant="outlined">
            Member will be charged ${getFormattedCharge(price, watchDiscount, watchDiscountAmount)}
          </Alert>
        </FormControl>
      )}
      {watchPaid === 'true' && (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>Add Discount?</InputLabel>
          <Controller
            name="discount"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                {...register('discount', { required: true })}
                error={!!formState.errors.discount}
                label="Paid or free?"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      )}
      {watchPaid === 'true' && watchDiscount === 'true' && (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <Controller
            name="discountAmount"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...rest } }) => (
              <NumericFormat
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                getInputRef={ref}
                customInput={TextField}
                variant="outlined"
                fullWidth
                label="Discount"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                error={!!formState.errors.discountAmount}
                isAllowed={(values) => {
                  const { floatValue } = values
                  if (floatValue && price) return floatValue <= price
                  return true
                }}
                {...rest}
              />
            )}
          />
        </FormControl>
      )}
    </AccountRequestModal>
  )
}
