import { useEffect, useRef } from 'react'
import {
  AppointmentDynamicFilterField,
  AppointmentSortField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  SortDirection,
  useAllAppointmentsLazyQuery
} from 'types'

const getUserAppointmentsFilter = (userId: string) => ({
  items: [
    {
      field: AppointmentDynamicFilterField.UserId,
      operation: DynamicFilterItemOperation.Eq,
      value: userId
    },
    {
      field: AppointmentDynamicFilterField.MeetingAt,
      operation: DynamicFilterItemOperation.Lt,
      value: memoizedNow(userId)
    }
  ],
  operator: DynamicFilterOperator.And
})

const appointmentsSorts = [
  {
    field: AppointmentSortField.MeetingAt,
    direction: SortDirection.Desc
  }
]

let currentUserId: string | undefined
let currentNow: string

const memoizedNow = (userId: string | undefined) => {
  if (userId !== currentUserId) {
    currentUserId = userId
    currentNow = new Date().toISOString()
  }
  return currentNow
}

export const useUserPastAppointments = (userId: string | undefined) => {
  const [getAllAppointments, { data, loading }] = useAllAppointmentsLazyQuery()
  const loadingRef = useRef(false)

  useEffect(() => {
    if (userId && !loadingRef.current) {
      getAllAppointments({
        variables: {
          dynamicFilters: getUserAppointmentsFilter(userId),
          sorts: appointmentsSorts
        }
      })
    }
  }, [userId, getAllAppointments])

  useEffect(() => {
    loadingRef.current = loading
  }, [loading])

  const appointments = data?.allAppointments?.appointments || []

  return { appointments, loading }
}
