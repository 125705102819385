import { useEffect } from 'react'
import { IUserMin, useCurrentAdminQuery } from 'types'
import { useSegmentMember } from './useSegmentMember'

export const useSegmentAdmin = () => {
  const { data: currentAdminData } = useCurrentAdminQuery()
  const currentAdmin = currentAdminData?.currentAdmin

  const { setMember } = useSegmentMember()

  useEffect(() => {
    if (currentAdmin) {
      const user = {
        email: currentAdmin.email,
        fullName: `${currentAdmin.firstName} ${currentAdmin.lastName}`,
        id: currentAdmin.id
      } as IUserMin
      setMember(user)
    }
  }, [currentAdmin, setMember])
}
