export enum UserResource {
  Root = '',
  History = 'history',
  Questionnaire = 'questionnaire',
  Files = 'files',
  Shipments = 'shipments',
  Insights = 'insights',
  Chat = 'chat',
  Chatbot = 'chatbot',
  BillingDashboard = 'billing-dashboard'
}
