import { Typography } from '@mui/material'
import { CreatedAtDate } from './CreatedAtDate'
import { IEventElement } from './Event'
import { getPerformedByFullName } from './utils'

export const UnsnoozeEvent = ({ event, currentAdmin }: IEventElement) => {
  const fullName = getPerformedByFullName(event.performedBy, currentAdmin)

  return (
    <Typography color="text.secondary" variant="caption">
      {`The conversation was unsnoozed ${
        event.performedBy ? `by ${fullName}` : 'automatically'
      } on `}
      <CreatedAtDate event={event} />
    </Typography>
  )
}
