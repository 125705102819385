// cSpell:disable
export const units = {
  calories: 'kcal',
  totalFat: 'g',
  saturatedFat: 'g',
  monounsaturatedFat: 'g',
  polyunsaturatedFat: 'g',
  transFat: 'g',
  totalCarb: 'g',
  sugars: 'g',
  fiber: 'g',
  protein: 'g',
  cholesterol: 'mg',
  sodium: 'mg',
  potassium: 'mg',
  calcium: 'mg',
  iron: 'mg',
  magnesium: 'mg',
  phosphorus: 'mg',
  zinc: 'mg',
  copper: 'mg',
  manganese: 'mg',
  selenium: 'mcg',
  vitaminARae: 'mcg rae',
  vitaminAIu: 'IU',
  retinol: 'mcg',
  alphaCarotene: 'mcg',
  betaCarotene: 'mcg',
  betaCryptoxanthin: 'mcg',
  lycopene: 'mcg',
  thiamin: 'mg',
  riboflavin: 'mg',
  niacin: 'mg',
  pantothenicAcid: 'mg',
  vitaminB6: 'mg',
  folate: 'mcg dfe',
  folicAcid: 'mcg',
  vitaminB12: 'mcg',
  choline: 'mg',
  vitaminC: 'mg',
  vitaminD: 'mcg',
  vitaminE: 'mg',
  vitaminK: 'mcg',
  water: 'g',
  ash: 'g',
  caffeine: 'mg',
  alcohol: 'g',
  addedSugars: 'g',
  omega3: 'g',
  omega6: 'g',
  theobromine: 'mg',
  luteinZeaxanthin: 'mcg',
  fruit: 'servings',
  vegetable: 'servings',
  wholeGrain: 'servings',
  starch: 'g',
  sucrose: 'g',
  glucose: 'g',
  fructose: 'g',
  lactose: 'g',
  maltose: 'g',
  adjustedProtein: 'g',
  energyKj: 'kJ',
  galactose: 'g',
  fluoride: 'µg',
  vitaminDIu: 'IU',
  vitaminD2: 'µg',
  vitaminD3: 'µg',
  betaTocopherol: 'mg',
  gammaTocopherol: 'mg',
  deltaTocopherol: 'mg',
  alphaTocotrienol: 'mg',
  betaTocotrienol: 'mg',
  gammaTocotrienol: 'mg',
  deltaTocotrienol: 'mg',
  totalFolate: 'µg',
  menaquinone4: 'µg',
  dihydrophylloquinone: 'µg',
  folateFood: 'µg',
  betaine: 'mg',
  tryptophan: 'g',
  threonine: 'g',
  isoleucine: 'g',
  leucine: 'g',
  lysine: 'g',
  methionine: 'g',
  cystine: 'g',
  phenylalanine: 'g',
  tyrosine: 'g',
  valine: 'g',
  arginine: 'g',
  histidine: 'g',
  alanine: 'g',
  asparticAcid: 'g',
  glutamicAcid: 'g',
  glycine: 'g',
  proline: 'g',
  serine: 'g',
  hydroxyproline: 'g',
  addedVitaminE: 'mg',
  addedVitaminB12: 'µg',
  butyricAcid: 'g',
  caproicAcid: 'g',
  caprylicAcid: 'g',
  capricAcid: 'g',
  lauricAcid: 'g',
  myristic: 'g',
  palmitic: 'g',
  stearic: 'g',
  arachidic: 'g',
  oleic: 'g',
  linoleic: 'g',
  linolenic: 'g',
  fattyAcid204: 'g',
  docosahexaenoicAcid: 'g',
  behenic: 'g',
  myristoleic: 'g',
  palmitoleic: 'g',
  parinaricAcid: 'g',
  gadoleic: 'g',
  eicosapentaenoicAcid: 'g',
  docosenoicAcid: 'g',
  docosapentaenoicAcid: 'g',
  phytosterols: 'mg',
  stigmasterol: 'mg',
  campesterol: 'mg',
  betaSitosterol: 'mg',
  pentadecanoicAcid: 'g',
  margaric: 'g',
  lignoceric: 'g',
  fattyAcid161T: 'g',
  fattyAcid181T: 'g',
  fattyAcid221T: 'g',
  fattyAcid182TNfd: 'g',
  fattyAcid182I: 'g',
  fattyAcid182Tt: 'g',
  fattyAcid182Clas: 'g',
  nervonic: 'g',
  eicosadienoicAcid: 'g',
  fattyAcid161C: 'g',
  fattyAcid181C: 'g',
  fattyAcid182N6Cc: 'g',
  erucicCitoleic: 'g',
  gammaLinolenicAcid: 'g',
  heptadecenoicAcid: 'g',
  eicosatrienoicAcid: 'g',
  transFatMonoenoic: 'g',
  transFatPolyenoic: 'g',
  tridecanoicAcid: 'g',
  pentadecenoicAcid: 'g',
  alphaLinolenicAcid: 'g',
  fattyAcid203N3: 'g',
  fattyAcid203N6: 'g',
  arachidonicAcid: 'g',
  fattyAcid183I: 'g',
  fattyAcid215: 'g',
  fattyAcid224: 'g',
  fattyAcid18111T: 'g'
}
