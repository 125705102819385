import { startCase } from 'lodash'
import { OpsAccountRequestKind } from 'types'

export const ACCOUNT_REQUEST_TITLE_MAP = {
  ...Object.values(OpsAccountRequestKind)
    .sort()
    .reduce((acc, kind) => {
      acc[kind] = startCase(kind)
      return acc
    }, {} as { [key in OpsAccountRequestKind]: string }),
  [OpsAccountRequestKind.UpsertLimitedSupport]: 'Add Limited Support'
} as { [key in OpsAccountRequestKind]: string }
