import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { formatDate, renderAsCheck, renderAsChip } from 'utility/dataGrid'
import { ISubscription, IUser } from 'types'

type ISubscriptionGridRow = { [K in keyof ISubscription as K]?: ISubscription[K] } & {
  [K in keyof IUser as `user.${K}`]?: IUser[K]
}

export const columns: (IDataGridColumn & { field?: keyof ISubscriptionGridRow })[] = [
  {
    field: 'stripeId',
    headerName: 'Stripe Id',
    width: 250,
    type: 'string',
    description: 'Stripe Id'
  },
  {
    field: 'stripeStatus',
    headerName: 'Stripe Status',
    width: 150,
    type: 'string',
    renderCell: renderAsChip,
    groupable: true,
    description: 'Current Stripe Status'
  },
  {
    field: 'plans',
    headerName: 'Plan',
    width: 200,
    type: 'string',
    groupable: true,
    description: 'Plan Nickname',
    valueGetter: (params) =>
      params.value.map((plan: { nickname: string }) => plan.nickname).join(' | ')
  },
  {
    field: 'startAt',
    headerName: 'Start At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the subscription starts'
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the subscription record was created in our database'
  },
  {
    field: 'subscribedAt',
    headerName: 'Subscribed At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description:
      'Date the subscription record was created in StripeDate the subscription record was created in Stripe'
  },
  {
    field: 'trialEndAt',
    headerName: 'Trial End At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the complementary dietitian offering will conclude'
  },
  {
    field: 'currentPeriodEndAt',
    headerName: 'Current Period End At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: "Date of the next invoice which aligns with the member's billing cycle"
  },
  {
    field: 'paidMonthsCount',
    headerName: 'Paid Months Count',
    width: 170,
    type: 'number',
    description:
      'Number of months the member has had a successful charge as part of their subscription'
  },
  {
    field: 'paused',
    headerName: 'Paused',
    width: 100,
    align: 'center',
    type: 'boolean',
    renderCell: renderAsCheck,
    groupable: true,
    description: 'Indicating member is currently snoozed'
  },
  {
    field: 'pausedMonthsCount',
    headerName: 'Paused Months Count',
    width: 170,
    type: 'number',
    description: 'Number of months the member has snoozed their current subscription'
  },
  {
    field: 'resumesAt',
    headerName: 'Resumes At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description:
      "Date the subscription is scheduled to resume, note that this is not the same as the next invoice date which aligns with the member's billing cycle"
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the subscription was updated'
  },
  {
    field: 'endAt',
    headerName: 'End At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the subscription ended'
  },
  {
    field: 'canceledAt',
    headerName: 'Canceled At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the subscription was canceled'
  },
  {
    field: 'cancelAt',
    headerName: 'Cancel At',
    type: 'date',
    width: 150,
    valueFormatter: formatDate,
    description: 'Date the subscription is scheduled to be canceled'
  }
]
