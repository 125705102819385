import { Box, Card, Typography } from '@mui/material'
import styled from '@mui/styled-engine'
import { theme } from 'theme'

export const DashedDivider = styled(Box)({
  borderTopColor: theme.palette['theme-primary']['level-3'],
  borderTopStyle: 'dotted',
  height: 0,
  width: '100%'
})

export const ScrollableCard = styled(Card)({
  maxHeight: '30vh',
  padding: '16px 24px',
  overflowY: 'scroll'
})

export const ClickableText = styled(Typography)({
  cursor: 'pointer'
})

export const DayText = styled(Typography)({
  width: '65px'
})
