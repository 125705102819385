import { theme } from 'theme'

export function scoreColor(number: number, danger?: number | null, warning?: number | null) {
  if (!danger || !warning) return theme.palette.grey[300]

  if (number <= danger) {
    return theme.palette.error.main
  }
  if (number <= warning) {
    return theme.palette.warning.main
  }
  return theme.palette.success.main
}
