import React from 'react'
import { Dialog, DialogContent, DialogProps, DialogTitle } from '@mui/material'

interface IUserPropertyEditDialog {
  content: React.ReactNode
}

export const UserPropertyEditDialog = (
  props: IUserPropertyEditDialog & Omit<DialogProps, 'content'>
) => {
  const { content, ...dialogProps } = props
  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="md"
      sx={{ '& .MuiDialog-paper': { height: '80%' } }}
    >
      <DialogTitle sx={{ paddingBottom: 1 }} />
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}
