import React from 'react'
import { CardHeader } from '@mui/material'

interface IEventCardHeader {
  eventAvatar: React.ReactNode
  eventTitle: React.ReactNode
  action: React.ReactNode
}

export const EventCardHeader = ({ eventAvatar, eventTitle, action }: IEventCardHeader) => {
  return (
    <CardHeader
      avatar={eventAvatar}
      title={eventTitle}
      sx={{
        padding: 1.5,
        '& > .MuiCardHeader-avatar': { alignSelf: 'flex-start' }
      }}
      action={action}
    />
  )
}
