import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SyncIcon from '@mui/icons-material/Sync'
import SyncIconDisabled from '@mui/icons-material/SyncDisabled'
import { Chip, Tooltip } from '@mui/material'
import { useTimeZone } from 'context'
import { startCase } from 'lodash'
import { MMM_DD_HH_MM_A } from 'utility/timeFormats'
import { useCurrentUserQuery } from 'types'
import { UserSettingsSection } from './UserSettingsSection'

const SYNC_KEYS = [
  { settingsKey: 'healthKitSync', lastSyncKey: 'lastSyncedHealth' },
  { settingsKey: 'googleFitSync', lastSyncKey: 'lastSyncedLibreView' },
  { settingsKey: 'ketoMojoSync', lastSyncKey: 'lastSyncedKetoMojo' }
] as const

const SYNC_PREFIX = 'Sync'

const syncContent = (setting: boolean | undefined, syncTitle: string) =>
  setting ? (
    <Tooltip title={`${syncTitle} sync is enabled`}>
      <SyncIcon color="success" sx={{ fontSize: 22 }} />
    </Tooltip>
  ) : (
    <Tooltip title={`${syncTitle} sync is disabled`}>
      <SyncIconDisabled color="disabled" sx={{ fontSize: 22 }} />
    </Tooltip>
  )

const lastSyncContent = (lastSync: any, label: string) =>
  lastSync ? (
    <Tooltip title="Last time this integration was synced in the member's time zone">
      <Chip size="small" avatar={<AccessTimeIcon fontSize="small" />} label={label} />
    </Tooltip>
  ) : null

export const Integrations = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const settings = currentUser?.settings
  const { formatWithTimeZone } = useTimeZone()

  const rows = SYNC_KEYS.map((syncKey) => {
    const settingsKey = syncKey.settingsKey
    const syncTitle = startCase(settingsKey).replace(SYNC_PREFIX, '')
    const lastSync = settings?.integrationsSyncSettings[syncKey.lastSyncKey]

    const lastSyncCellContent = lastSyncContent(
      lastSync,
      formatWithTimeZone(new Date(lastSync), MMM_DD_HH_MM_A)
    )

    const syncCellContent = syncContent(settings?.[settingsKey], syncTitle)

    const row = {
      label: syncTitle,
      cells: [lastSyncCellContent, syncCellContent]
    }
    return row
  })

  return <UserSettingsSection header="Integrations" rows={rows} />
}
