import { createContext, useContext, useMemo } from 'react'
import { useCurrentUserQuery } from 'types'

export const METRIC_UNITS = 'mmol/L'
export const IMPERIAL_UNITS = 'mg/dL'
export const IMPERIAL_UNIT_SYSTEM = 'imperial'
export const METRIC_UNIT_SYSTEM = 'metric'

export type UNIT_SYSTEM_TYPE = typeof METRIC_UNIT_SYSTEM | typeof IMPERIAL_UNIT_SYSTEM
export type UNIT_SYSTEM_UNITS_TYPE = typeof METRIC_UNITS | typeof IMPERIAL_UNITS

export interface IUnitSystemContext {
  unitSystem: UNIT_SYSTEM_TYPE
  units: UNIT_SYSTEM_UNITS_TYPE
}

const defaultUnitSystemContext: IUnitSystemContext = {
  unitSystem: IMPERIAL_UNIT_SYSTEM,
  units: IMPERIAL_UNITS
}

const UnitSystemContext = createContext(defaultUnitSystemContext)
export const useUnitSystem = () => useContext(UnitSystemContext)

export const UnitSystemProvider = ({ children }: { children: JSX.Element }) => {
  const { data } = useCurrentUserQuery()

  const unitSystem = data?.currentUser?.settings?.unitSystem || defaultUnitSystemContext.unitSystem

  const unitSystemContext = useMemo(() => {
    const units = unitSystem === IMPERIAL_UNIT_SYSTEM ? IMPERIAL_UNITS : METRIC_UNITS
    return {
      unitSystem: unitSystem as UNIT_SYSTEM_TYPE,
      units: units as UNIT_SYSTEM_UNITS_TYPE
    }
  }, [unitSystem])

  return (
    <UnitSystemContext.Provider value={unitSystemContext}>{children}</UnitSystemContext.Provider>
  )
}
