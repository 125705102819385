import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton } from '@mui/material'
import { theme } from 'theme'

export interface ICollapseButtonProps {
  expanded: boolean
  handleExpandClick: () => void
  iconMarginRight?: number
}

export const CollapseButton = ({
  expanded,
  handleExpandClick,
  iconMarginRight = 2
}: ICollapseButtonProps) => {
  return (
    <IconButton sx={{ marginRight: iconMarginRight }} onClick={handleExpandClick}>
      <ExpandMoreIcon
        aria-expanded={expanded}
        aria-label="show more"
        sx={{
          transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
          })
        }}
      />
    </IconButton>
  )
}
