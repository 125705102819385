export enum ListItemTypename {
  LastItem = 'LastItem',
  DateDelimiter = 'DateDelimiter',
  Loader = 'Loader'
}

export interface IDateDelimiter {
  id: string
  __typename: `${ListItemTypename.DateDelimiter}`
  date: string
}

export interface ILastItem {
  id: 'last-item'
  __typename: `${ListItemTypename.LastItem}`
  date?: string
}

export interface ILoader {
  id: 'loader'
  __typename: `${ListItemTypename.Loader}`
}
