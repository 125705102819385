import { TypingIndicatorContainer, TypingIndicatorMessage, TypingIndicatorProgress } from './styles'
import { useIsProcessing } from './useIsProcessing'

export const CustomTypingIndicator = () => {
  const isProcessing = useIsProcessing()

  if (!isProcessing) {
    return null
  }

  return (
    <TypingIndicatorContainer>
      <TypingIndicatorMessage>Nora is thinking...</TypingIndicatorMessage>
      <TypingIndicatorProgress color="info" />
    </TypingIndicatorContainer>
  )
}
