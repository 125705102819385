import { useEffect } from 'react'
import { useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react'
import { useCurrentAdminQuery } from 'types'

export const FeatureFlagUser = () => {
  const { data: currentAdminData } = useCurrentAdminQuery()
  const currentAdmin = currentAdminData?.currentAdmin
  const updateUnleashContext = useUnleashContext()
  const { flagsReady } = useFlagsStatus()

  useEffect(() => {
    if (flagsReady && currentAdmin) {
      // use setTimeout to trigger unleash re-fetch with new context when it's ready
      // if we update context here without setTimeout, it doesn't re-fetch flags
      const timeout = setTimeout(() => {
        updateUnleashContext({
          userId: currentAdmin.id,
          properties: { userEmail: currentAdmin.email }
        })
      })

      return () => {
        clearTimeout(timeout)
      }
    }

    return
  }, [currentAdmin, flagsReady, updateUnleashContext])

  return null
}
