import { Grid } from '@mui/material'
import { Loader } from 'components/Loader'
import { useCurrentUserQuery } from 'types'
import {
  FastingWindow,
  GlucoseThresholds,
  Integrations,
  KetoneThresholds,
  Nutrition,
  Reminders,
  SensorCalibration,
  System,
  DashboardGraph
} from '.'

export const UserSettingsDialogContent = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  if (!currentUser) {
    return <Loader />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <GlucoseThresholds />
      </Grid>
      <Grid item xs={6}>
        <KetoneThresholds />
      </Grid>
      <Grid item xs={6}>
        <SensorCalibration />
      </Grid>
      <Grid item xs={6}>
        <DashboardGraph />
      </Grid>
      <Grid item xs={6}>
        <FastingWindow />
      </Grid>
      <Grid item xs={6}>
        <System />
      </Grid>
      <Grid item xs={6}>
        <Nutrition />
      </Grid>
      <Grid item xs={6}>
        <Integrations />
      </Grid>
      <Grid item xs={6}>
        <Reminders />
      </Grid>
    </Grid>
  )
}
