import { useReactiveVar } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { saveEventDialogVar, snackBarVisibleVar } from 'context'
import { startCase } from 'lodash'
import { useForm } from 'react-hook-form'
import { Header } from 'screens/UserDetails/SaveEventDialog/Header'
import {
  useEventDate,
  useEventUtcDate,
  useGlucoseValue
} from 'screens/UserDetails/SaveEventDialog/helpers'
import { IAllEventsMealFragment, MealKind, useUpsertMealMutation } from 'types'
import { CATEGORIES, MACROS, ON_ERROR_MESSAGE } from './constants'

interface IFormParams {
  category: string
  description: string
  calories: string
  protein: string
  total_carb: string
  total_fat: string
}

export const SaveMeal = () => {
  const { graphDataPoint, event } = useReactiveVar(saveEventDialogVar)
  const mealEvent = event as IAllEventsMealFragment
  const formattedDate = useEventDate(mealEvent, graphDataPoint!)
  const glucoseValue = useGlucoseValue(mealEvent, graphDataPoint!)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormParams>({
    mode: 'onTouched'
  })

  const handleClose = () => saveEventDialogVar({ open: false })

  const [upsertMealMutation, { loading }] = useUpsertMealMutation({
    onCompleted: handleClose,
    onError: () => snackBarVisibleVar({ open: true, message: ON_ERROR_MESSAGE }),
    refetchQueries: ['allEvents', 'allStats']
  })

  const mealTime = useEventUtcDate(mealEvent!, graphDataPoint!)
  const onFormSubmit = (values: IFormParams) => {
    const variables = {
      id: event?.id,
      favorite: false,
      time: mealTime,
      kind: values.category as MealKind,
      description: values.description || '',
      ingredients: [
        {
          description: 'Macros',
          servingAmount: 1,
          servingUnits: 'meal',
          calories: parseFloat(values.calories),
          nutrition: MACROS.map(({ key }) => ({ key, value: parseFloat(values[key]) }))
        }
      ]
    }
    upsertMealMutation({ variables })
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <DialogContent>
        <Header formattedDate={formattedDate} glucoseValue={glucoseValue} />

        <FormControl variant="outlined" margin="dense" fullWidth error={!!errors.category}>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            error={!!errors.category}
            label="Category"
            labelId="category-label"
            {...register('category', { required: true })}
            defaultValue={mealEvent?.kind}
          >
            {CATEGORIES.map((category) => (
              <MenuItem key={category} value={category}>
                {startCase(category)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          error={!!errors.description}
          label="Description"
          margin="dense"
          fullWidth
          defaultValue={mealEvent?.description}
          variant="outlined"
          type="text"
          {...register('description')}
        />

        <Typography mb={2} mt={2}>
          Macros
        </Typography>
        {MACROS.map(({ key, label, unit }) => {
          return (
            <TextField
              error={!!errors[key]}
              margin="dense"
              label={label}
              fullWidth
              variant="outlined"
              type="number"
              key={key}
              defaultValue={mealEvent?.nutrition?.find((row) => row.key === key)?.value}
              InputProps={{
                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                inputProps: { min: 0 }
              }}
              {...register(key, { required: true, min: 0 })}
            />
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <LoadingButton loading={loading} type="submit">
          SAVE
        </LoadingButton>
      </DialogActions>
    </form>
  )
}
