import { GridRowClassNameParams } from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { format } from 'date-fns'
import { YYYY_MM_DD } from 'utility/timeFormats'

export function getChanges<T extends Record<string, unknown>, RowType>(
  columns: IDataGridColumn[],
  newRow: RowType,
  oldRow: RowType,
  fieldPrefix = ''
): T {
  const changes = {} as T

  columns.forEach((column) => {
    const newValue = newRow[column.field as keyof RowType]
    const oldValue = oldRow[column.field as keyof RowType]

    if (column.type === 'number') {
      if ((newValue as number) !== (oldValue as number)) {
        // @ts-ignore
        changes[column.field.replace(fieldPrefix, '')] = newValue
      }
    } else if (column.type === 'dateTime') {
      if ((newValue as Date) !== (oldValue as Date)) {
        // @ts-ignore
        changes[column.field.replace(fieldPrefix, '')] = newValue
      }
    } else if (column.type === 'date') {
      if ((newValue as Date) !== (oldValue as Date)) {
        // @ts-ignore
        changes[column.field.replace(fieldPrefix, '')] = format(newValue, YYYY_MM_DD)
      }
    } else {
      if ((newValue as string | null)?.trim() !== (oldValue as string | null)?.trim()) {
        // @ts-ignore
        changes[column.field.replace(fieldPrefix, '')] = newValue?.trim() || null
      }
    }
  })

  return changes
}

export const getStripeRowsClassName = (params: GridRowClassNameParams) => {
  return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
}
