import { Box } from '@mui/material'
import styled from '@mui/styled-engine'

export const ProfileGridStyled = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '15px',
  alignItems: 'center',
  minWidth: 400,
  minHeight: 400
})
