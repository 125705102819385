import { ReactNode } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { IconButton } from '@mui/material'
import {
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  selectedGridRowsSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid'
import { MoreMenu } from 'components/MoreMenu'
import { IGetFields } from 'screens/Shipments/helpers/getFields'
import GridToolbarCopyFields from './GridToolbarCopyFields'
import GridToolbarGroup, { IGridToolbarGroupProps } from './GridToolbarGroup'
import GridToolbarQuickFilters, { IGridToolbarQuickFiltersProps } from './GridToolbarQuickFilters'
import GridToolbarSendPrescriptionsShipments from './GridToolbarSendPrescriptionsShipments'

export type IToolbarProps = IGridToolbarQuickFiltersProps &
  IGridToolbarGroupProps & {
    toolbarAdornments?: ReactNode | ReactNode[]
    showCopyPrescriptionsActions?: boolean
    showResendPrescriptionsAction?: boolean
    getMainFields?: IGetFields
    getGiftHealthPrescriptionFields?: IGetFields
    getTruepillPrescriptionFields?: IGetFields
    BulkUpdateSelectedRows?: ({
      selectedRows,
      bulkUpdate
    }: {
      selectedRows: Map<GridRowId, any>
      bulkUpdate: (ids: any[], params: { [key: string]: any }) => void
    }) => JSX.Element | null
    bulkUpdate?: (ids: any[], params: { [key: string]: any }) => void
    bulkUpdateVisible?: boolean
    refresh?: () => void
    additionalActions?: ReactNode | ReactNode[]
  }

function Toolbar(props: IToolbarProps) {
  const {
    quickFilters,
    groupField,
    setGroupField,
    availableGroupColumns,
    resetFilterModel,
    showResendPrescriptionsAction,
    getMainFields,
    BulkUpdateSelectedRows,
    bulkUpdate,
    bulkUpdateVisible,
    refresh,
    toolbarAdornments,
    getGiftHealthPrescriptionFields,
    getTruepillPrescriptionFields,
    additionalActions
  } = props

  const apiRef = useGridApiContext()

  const selectedRows = useGridSelector(apiRef, selectedGridRowsSelector)
  const showBulkUpdate = bulkUpdateVisible === undefined ? selectedRows.size > 1 : bulkUpdateVisible

  return (
    <GridToolbarContainer>
      {/*// @ts-ignore */}
      <GridToolbarColumnsButton />
      {/*// @ts-ignore */}
      <GridToolbarFilterButton />
      <GridToolbarQuickFilters quickFilters={quickFilters} resetFilterModel={resetFilterModel} />
      {showResendPrescriptionsAction && <GridToolbarSendPrescriptionsShipments />}
      <GridToolbarGroup
        groupField={groupField}
        setGroupField={setGroupField}
        availableGroupColumns={availableGroupColumns}
      />
      {/*// @ts-ignore */}
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      {selectedRows.size > 0 && (
        <>
          {getMainFields && (
            <GridToolbarCopyFields
              buildRow={getMainFields}
              selectedRows={selectedRows}
              title="Copy Main Fields"
            />
          )}
          {getGiftHealthPrescriptionFields && (
            <GridToolbarCopyFields
              uniqueRows
              buildRow={getGiftHealthPrescriptionFields}
              selectedRows={selectedRows}
              title="Copy Prescriptions Data (GiftHealth format)"
            />
          )}
          {getTruepillPrescriptionFields && (
            <GridToolbarCopyFields
              uniqueRows
              buildRow={getTruepillPrescriptionFields}
              selectedRows={selectedRows}
              title="Copy Prescriptions Data (Truepill format)"
            />
          )}
        </>
      )}
      {showBulkUpdate && BulkUpdateSelectedRows && bulkUpdate && (
        <BulkUpdateSelectedRows selectedRows={selectedRows} bulkUpdate={bulkUpdate} />
      )}
      {refresh && (
        <IconButton
          onClick={() => {
            refresh()
          }}
          aria-label="Refresh"
        >
          <RefreshIcon />
        </IconButton>
      )}
      {additionalActions && <MoreMenu>{additionalActions}</MoreMenu>}
      {toolbarAdornments}
    </GridToolbarContainer>
  )
}

export default Toolbar
