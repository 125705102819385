import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const EmojiLevel1 = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        fill="#FF8B83"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M12.4 27.991a9.019 9.019 0 0115.6 0M17 15a3.788 3.788 0 01-1.27 1.464A3.113 3.113 0 0114 17a3.113 3.113 0 01-1.73-.536A3.788 3.788 0 0111 15M29 15a3.788 3.788 0 01-1.27 1.464A3.113 3.113 0 0126 17a3.113 3.113 0 01-1.73-.536A3.788 3.788 0 0123 15"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default EmojiLevel1
