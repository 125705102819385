import { useSegment } from './useSegment'

export const useSegmentInteraction = () => {
  const { analytics } = useSegment()

  const track = (event: string, properties?: object) => {
    return analytics?.track(event, properties)
  }

  return { track }
}
