import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { uniqBy } from 'lodash'
import { LinkedAnswersCell } from 'screens/Knowledge/LinkedAnswersCell'
import { LinkedSourcesCell } from 'screens/Knowledge/LinkedSourcesCell'
import { LinkedAnswersEditCell } from 'screens/Knowledge/Questions/LinkedAnswersEditCell'
import { TagsCell } from 'screens/Knowledge/Questions/TagsCell'
import { TitleCellTypography } from 'screens/Knowledge/styles'
import { IKnowledgeAnswer, IKnowledgeQuestion, KnowledgeQuestionDynamicFilterField } from 'types'

export const questionColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeQuestion
})[] = [
  {
    field: 'value',
    headerName: 'Question',
    editable: true,
    width: 300,
    renderCell: ({ value }) => <TitleCellTypography maxLines={2}>{value}</TitleCellTypography>
  }
]

export const associationColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeQuestion | 'knowledgeSources'
})[] = [
  {
    field: 'knowledgeAnswers',
    backendFilterName: KnowledgeQuestionDynamicFilterField.AnswersTitle,
    headerName: 'Answers',
    sortable: false,
    editable: true,
    width: 420,
    renderCell: ({ value }) => <LinkedAnswersCell answers={value} />,
    renderEditCell: (props) => <LinkedAnswersEditCell {...props} />
  },
  {
    field: 'knowledgeSources',
    backendFilterName: KnowledgeQuestionDynamicFilterField.AnswersSourcesTitle,
    headerName: 'Sources',
    sortable: false,
    width: 300,
    valueGetter: ({ row }) => {
      const answers: IKnowledgeAnswer[] = row.knowledgeAnswers
      return uniqBy(
        answers.flatMap((answer) => answer.knowledgeSources),
        'id'
      )
    },
    renderCell: ({ value }) => <LinkedSourcesCell sources={value} />
  },
  {
    field: 'knowledgeTags',
    backendFilterName: KnowledgeQuestionDynamicFilterField.TagsValue,
    headerName: 'Tags',
    sortable: false,
    editable: true,
    width: 300,
    renderCell: (props) => <TagsCell {...props} />,
    renderEditCell: (props) => <TagsCell {...props} editable />
  }
]

export const hiddenColumns: (IDataGridColumn & {
  field?: keyof IKnowledgeQuestion
})[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    width: 100,
    valueFormatter: (params) => params.value
  }
]

export const columns = [...questionColumns, ...associationColumns, ...hiddenColumns]

export const editableQuestionColumns = questionColumns.filter(({ editable }) => editable)
