import { useApolloClient, useReactiveVar } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { deleteNoteDialogVar } from 'context'
import { snackBarVisibleVar } from 'context/ui'
import { EventTypes } from 'event.types'
import { theme } from 'theme'
import { evictFromCacheById } from 'utility/cache/evictFromCacheById'
import { useDeleteNoteMutation } from 'types'

const ON_ERROR_MESSAGE = 'Unable to delete the note. Please try again later.'

export const DeleteNoteDialog = () => {
  const { cache } = useApolloClient()
  const { open, event } = useReactiveVar(deleteNoteDialogVar)
  const closeDialog = () => {
    deleteNoteDialogVar({ open: false })
  }

  const [deleteNoteMutation] = useDeleteNoteMutation({
    variables: { id: event?.id },
    onError: () => snackBarVisibleVar({ open: true, message: ON_ERROR_MESSAGE }),
    onCompleted: ({ deleteNote: { id } }) => {
      evictFromCacheById(cache, id, EventTypes.Note)
      deleteNoteDialogVar({ open: false })
    }
  })

  const deleteNote = () => deleteNoteMutation()

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Delete the note</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this note ?</DialogContentText>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteNote} autoFocus sx={{ color: theme.palette.danger.main }}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
