import {
  EmojiLevel0,
  EmojiLevel1,
  EmojiLevel2,
  EmojiLevel3,
  EmojiLevel4,
  EmojiLevel5
} from 'icons/journal'

const EmojiScale = [EmojiLevel0, EmojiLevel1, EmojiLevel2, EmojiLevel3, EmojiLevel4, EmojiLevel5]

export const getWellnessScore = (score?: number | null) => {
  if (!score) return null

  return EmojiScale[score]
}
