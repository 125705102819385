import { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Typography } from '@mui/material'
import { Loader } from 'components/Loader'
import {
  LoaderStyled,
  PrivacyModeCloseLinkStyled,
  PrivacyModeWarningStyled
} from 'components/PrivacyModeWarning/styles'
import { privacyModeActiveVar } from 'context/ui/privacyModeContext'
import {
  CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY,
  PRESENTATION_WINDOW_COUNT_STORAGE_KEY,
  PRIVACY_MODE_LOCAL_STORAGE_KEY
} from 'hooks/usePrivacyMode'
import { LocationPaths } from 'location.types'
import { useConfirm } from 'material-ui-confirm'
import { useLocation } from 'react-router-dom'

export const PrivacyModeWarning = () => {
  const isActive = useReactiveVar(privacyModeActiveVar)
  const location = useLocation()
  const confirm = useConfirm()
  const [exitingPrivacyMode, setExitingPrivacyMode] = useState(false)

  const shouldNotShowWarning = !isActive || location.pathname === LocationPaths.Presentation

  useEffect(() => {
    const storageListener = (ev: StorageEvent) => {
      if (ev.key === CLEAR_PRIVACY_MODE_LOCAL_STORAGE_KEY) {
        setExitingPrivacyMode(ev.newValue === 'true')
      }
    }

    window.addEventListener('storage', storageListener)

    return () => window.removeEventListener('storage', storageListener)
  }, [])

  if (shouldNotShowWarning) return null

  const handleClosePrivacyMode = async () => {
    try {
      await confirm({
        description:
          'You are about to close Privacy Mode. Make sure no active presentation tabs or windows are opened before you proceed!'
      })
    } catch {
      return
    }

    localStorage.setItem(PRIVACY_MODE_LOCAL_STORAGE_KEY, 'false')
    localStorage.setItem(PRESENTATION_WINDOW_COUNT_STORAGE_KEY, '0')
    privacyModeActiveVar(false)
  }

  return (
    <PrivacyModeWarningStyled>
      <Typography fontSize={36}>Privacy mode is enabled</Typography>
      <Typography fontSize={12}>
        Click
        <PrivacyModeCloseLinkStyled component="span" onClick={handleClosePrivacyMode}>
          here
        </PrivacyModeCloseLinkStyled>
        to close this warning
      </Typography>
      <LoaderStyled>{exitingPrivacyMode && <Loader />}</LoaderStyled>
    </PrivacyModeWarningStyled>
  )
}
