import * as Apollo from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Exact, ICurrentUserQuery, useCurrentUserQuery } from 'types'

export const useImpersonatedCurrentUser = () => {
  const response = useCurrentUserQuery()
  const { id } = useParams<{ id: string }>()

  if (response.data && response.data.currentUser?.id === id) {
    return response
  }

  return { ...response, data: undefined } as Apollo.QueryResult<
    ICurrentUserQuery,
    Exact<{
      [key: string]: never
    }>
  >
}
