import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const EmojiLevel4 = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        fill="#EFD482"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 18.25a3 3 0 100-6 3 3 0 000 6zM26.5 18.25a3 3 0 100-6 3 3 0 000 6z"
        fill="#11494F"
      />
      <path
        d="M27.8 23.5a9.019 9.019 0 01-15.6 0"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default EmojiLevel4
