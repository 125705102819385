import { LinkStyled } from 'components/MarkdownEditor/styles'
import { IElement } from 'components/MarkdownEditor/types'
import { useSelected } from 'slate-react'
import { InlineChromiumBugfix } from './InlineChromiumBugfix'

export const LinkElement = ({ attributes, children, element }: IElement) => {
  const selected = useSelected()

  return (
    <LinkStyled {...attributes} href={element.url} active={selected ? 1 : 0}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </LinkStyled>
  )
}
