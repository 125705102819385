import React from 'react'
import { Box } from '@mui/material'
import { StrongTypographyStyled } from 'styles'
import { SectionHeaderStyled } from '.'

interface ISectionHeaderProps {
  slotRight?: React.ReactNode
  slotLeft?: React.ReactNode
  textColor?: string
  onTextHeaderClick?: () => void
  label?: string
}

export const SectionHeader = ({
  slotRight,
  slotLeft,
  onTextHeaderClick,
  label = ''
}: ISectionHeaderProps) => {
  return (
    <SectionHeaderStyled>
      <Box onClick={onTextHeaderClick} sx={{ cursor: onTextHeaderClick ? 'pointer' : '' }}>
        {slotLeft && (
          <Box marginRight={0.5} display="inline">
            {slotLeft}
          </Box>
        )}
        <StrongTypographyStyled fontSize={16} textTransform="none" variant="overline">
          {label}
        </StrongTypographyStyled>
      </Box>
      {slotRight}
    </SectionHeaderStyled>
  )
}
