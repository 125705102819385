import { DataGridProps, GridFilterItem } from '@mui/x-data-grid'
import { getFiltersFromFilterItem } from 'components/DataGrid/helpers/getFiltersFromFilterItem'
import { orderBy } from 'lodash'

type ExcludesNull = <T>(x: T | null) => x is T

export const isQuickFilterSelected = (
  filterItems: GridFilterItem[],
  quickFilterItems: GridFilterItem[],
  columns: DataGridProps['columns']
) => {
  const filters = filterItems
    .flatMap((item) => getFiltersFromFilterItem(item, columns))
    .filter(Boolean as any as ExcludesNull)
  const quickFilters = quickFilterItems
    .flatMap((item) => getFiltersFromFilterItem(item, columns))
    .filter(Boolean as any as ExcludesNull)

  if (filters.length !== quickFilters.length) {
    return false
  }

  const sortedFilters = orderBy(filters, ['field', 'operation'])
  const sortedQuickFilters = orderBy(quickFilters, ['field', 'operation'])

  for (let index = 0; index < filters.length; index += 1) {
    if (
      sortedFilters[index].field !== sortedQuickFilters[index].field ||
      sortedFilters[index].operation !== sortedQuickFilters[index].operation ||
      sortedFilters[index].value !== sortedQuickFilters[index].value
    ) {
      return false
    }
  }

  return true
}
