import {
  COACH_NOTIFICATION_DATA,
  NOTIFICATION_DATA,
  SUPPORT_TICKET_NOTIFICATION_DATA
} from 'screens/AdminPanel/Notifications/constants/notificationData'
import { HealthPortalNotificationKind } from 'types'

export const useEffectiveNotificationKinds = () => {
  return {
    allNotificationKinds: Object.keys(NOTIFICATION_DATA) as HealthPortalNotificationKind[],
    coachNotificationKinds: Object.keys(COACH_NOTIFICATION_DATA) as HealthPortalNotificationKind[],
    supportTicketNotificationKinds: Object.keys(
      SUPPORT_TICKET_NOTIFICATION_DATA
    ) as HealthPortalNotificationKind[]
  }
}
