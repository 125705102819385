import { ACCOUNT_REQUEST_TITLE_MAP } from 'accountRequest.types'
import { IAccountRequest, OpsAccountRequestStatus } from 'types'
import { describeAccountRequestPayload } from './describeAccountRequestPayload'
import { describeProcessedAccountRequest } from './describeProcessedAccountRequest'

export const UNPAUSE_SUBSCRIPTION_TIME_BUFFER_MINUTES = 5

export const describeAccountRequest = (accountRequest: IAccountRequest) => {
  if (accountRequest.status === OpsAccountRequestStatus.Processing)
    return `Processing ${ACCOUNT_REQUEST_TITLE_MAP[accountRequest.kind]} request...`
  if (accountRequest.status === OpsAccountRequestStatus.Escalated) {
    return `${
      ACCOUNT_REQUEST_TITLE_MAP[accountRequest.kind]
    } request escalated\n\n${describeAccountRequestPayload(accountRequest)}`
  }
  if (accountRequest.status === OpsAccountRequestStatus.Failed) {
    return `${ACCOUNT_REQUEST_TITLE_MAP[accountRequest.kind]} request failed:\n${
      accountRequest.errorMessage
    }\n\n${describeAccountRequestPayload(accountRequest)}`
  }
  return describeProcessedAccountRequest(accountRequest)
}
