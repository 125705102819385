import { Avatar, Box } from '@mui/material'
import { scoreColor } from 'utility'
import { useClientConfigQuery } from 'types'

interface IGlucoseScoreAvatarProps {
  glucoseScore: number
}

export const GlucoseScoreAvatar = ({ glucoseScore }: IGlucoseScoreAvatarProps) => {
  const { data: clientConfigResult } = useClientConfigQuery()

  const warning = clientConfigResult?.clientConfig.score.warning
  const danger = clientConfigResult?.clientConfig.score.danger
  return (
    <Box sx={{ paddingRight: 2 }}>
      <Avatar
        sx={{
          bgcolor: 'white',
          color: scoreColor(glucoseScore, danger, warning),
          borderColor: scoreColor(glucoseScore, danger, warning),
          borderWidth: 0.125,
          borderStyle: 'solid'
        }}
      >
        {glucoseScore}
      </Avatar>
    </Box>
  )
}
