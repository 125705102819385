import { TextField } from '@mui/material'
import { GridRenderEditCellParams } from '@mui/x-data-grid'
import { DialogEditCell } from 'components/DataGrid/DialogEditCell'

export const EditTitleField = ({ row, field, value, error }: GridRenderEditCellParams) => {
  return (
    <DialogEditCell
      required
      rowId={row?.id}
      value={value}
      error={error}
      field={field}
      title="Edit Answer"
    >
      {({ onChange }) => (
        <TextField
          required
          fullWidth
          multiline
          label="Title"
          defaultValue={value}
          error={error}
          variant="standard"
          margin="dense"
          onChange={onChange}
        />
      )}
    </DialogEditCell>
  )
}
