import { format } from 'date-fns'
import { coachEndDateDescriptor } from 'utility/coachEndDateDescriptor'
import { MMM_DD_YYYY } from 'utility/timeFormats'
import { CoachAssignmentState, IUser } from 'types'

const doFormat = (date: Date) => format(date, MMM_DD_YYYY)

export const formatDate = (dateString?: string | null) => {
  if (!dateString) return null
  return doFormat(new Date(dateString))
}

export const getCoachDates = (user: IUser | null | undefined) => {
  const coachStartDate = user?.ongoingDietitianSubscription?.startAt
  const coachResumesDate = user?.ongoingDietitianSubscription?.resumesAt
  const coachEndDate = user?.ongoingDietitianSubscription?.cancelAt
  let coachEndDateDescription = ''

  if (!coachEndDate) {
    coachEndDateDescription = coachEndDateDescriptor({
      subscription: user?.ongoingDietitianSubscription,
      lastCoachAssignmentKind:
        user?.lastAsyncChatAssignment?.state !== CoachAssignmentState.Inactive
          ? user?.lastAsyncChatAssignment?.kind
          : null
    })
  }

  return { coachStartDate, coachResumesDate, coachEndDate, coachEndDateDescription }
}
