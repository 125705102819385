import { Typography } from '@mui/material'
import { format, isToday } from 'date-fns'
import { TWELVE_HOURS_TIME_FORMAT } from 'utility/timeFormats'
import { useNextNutritionistAppointmentQuery } from 'types'

const NEXT_NUTRITIONIST_APPOINTMENT_POLL_INTERVAL = 600_000

export const NutritionistNextCall = () => {
  const { data: { nextNutritionistAppointment } = {} } = useNextNutritionistAppointmentQuery({
    pollInterval: NEXT_NUTRITIONIST_APPOINTMENT_POLL_INTERVAL
  })

  if (!nextNutritionistAppointment?.meetingAt) return

  const meetingAt = new Date(nextNutritionistAppointment.meetingAt)

  if (!isToday(meetingAt)) return

  return <Typography>Next call: {format(meetingAt, TWELVE_HOURS_TIME_FORMAT)}</Typography>
}
