import { useFlag } from '@unleash/proxy-client-react'
import { AppEnv, env } from 'config'
import { FeatureFlags } from 'featureFlags.types'

export const useFeatureFlag = (featureName: FeatureFlags) => {
  const enabled = useFlag(featureName)

  if (env.REACT_APP_ENV === AppEnv.PreProduction) return true

  return enabled
}
