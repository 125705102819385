import { ApolloCache } from '@apollo/client'
import { snackBarVisibleVar } from 'context'
import {
  useAddKnowledgeQuestionTagMutation,
  useRemoveKnowledgeQuestionTagMutation,
  CoreKnowledgeQuestionFieldsFragmentDoc,
  IKnowledgeQuestion,
  IAddKnowledgeQuestionTagMutation
} from 'types'

type TaggedKnowledgeQuestion = IAddKnowledgeQuestionTagMutation['addKnowledgeQuestionTag']

const handleCacheUpdate = (
  cache: ApolloCache<IKnowledgeQuestion>,
  updatedQuestion?: TaggedKnowledgeQuestion
) => {
  if (!updatedQuestion) {
    return
  }

  cache.updateFragment(
    {
      id: cache.identify(updatedQuestion),
      fragment: CoreKnowledgeQuestionFieldsFragmentDoc,
      overwrite: true
    },
    (_data) => updatedQuestion
  )
}

interface IQuestionTagging {
  onTaggingUpdated: (tags: TaggedKnowledgeQuestion['knowledgeTags']) => void
}

export const useQuestionTagging = ({ onTaggingUpdated }: IQuestionTagging) => {
  const [addTag, { loading: adding }] = useAddKnowledgeQuestionTagMutation({
    onError: (error) => {
      snackBarVisibleVar({ open: true, message: error.message })
    },
    update: (cache, { data }) => handleCacheUpdate(cache, data?.addKnowledgeQuestionTag),
    onCompleted: ({ addKnowledgeQuestionTag: { knowledgeTags } }) => {
      onTaggingUpdated(knowledgeTags)
    }
  })

  const [removeTag, { loading: removing }] = useRemoveKnowledgeQuestionTagMutation({
    onError: (error) => {
      snackBarVisibleVar({ open: true, message: error.message })
    },
    update: (cache, { data }) => handleCacheUpdate(cache, data?.removeKnowledgeQuestionTag),
    onCompleted: ({ removeKnowledgeQuestionTag: { knowledgeTags } }) => {
      onTaggingUpdated(knowledgeTags)
    }
  })

  return {
    addTag,
    removeTag,
    loading: adding || removing
  }
}
