import { COLUMNS } from 'screens/AdminPanel/CoachAssignmentDataGrid/types/coachAssignmentViewColumn.types'
import { UserApprovalRequestState, CoachAssignmentKind, CoachAssignmentState } from 'types'

export const COACH_ASSIGNMENT_GRID_QUICK_FILTERS_DEFINITIONS = [
  {
    name: 'Regular Member Assignment',
    items: [
      {
        id: 1,
        columnField: COLUMNS.COACH_ASSIGNMENT_KIND,
        operatorValue: 'isAnyOf',
        value: [
          CoachAssignmentKind.Regular,
          CoachAssignmentKind.Influencer,
          CoachAssignmentKind.NonRecurring,
          CoachAssignmentKind.AnnualMembership
        ]
      },
      {
        id: 2,
        columnField: COLUMNS.COACH_ASSIGNMENT_STATE,
        operatorValue: 'is',
        value: CoachAssignmentState.Pending
      }
    ]
  },
  {
    name: 'Nutritionist Member Assignment',
    items: [
      {
        id: 1,
        columnField: COLUMNS.COACH_ASSIGNMENT_KIND,
        operatorValue: 'is',
        value: CoachAssignmentKind.CoachingPlan
      },
      {
        id: 2,
        columnField: COLUMNS.COACH_ASSIGNMENT_STATE,
        operatorValue: 'is',
        value: CoachAssignmentState.Pending
      },
      {
        id: 3,
        columnField: COLUMNS.APPROVAL_REQUEST_STATE,
        operatorValue: 'equals',
        value: UserApprovalRequestState.Approved
      }
    ]
  }
]
