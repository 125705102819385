import { BaseSyntheticEvent, useState } from 'react'
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined'
import { MetricList } from 'components/Events/EventCard/MetricList'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { DetailedNutritionPopover } from './DetailedNutritionPopover'
import { units } from './units'

export interface IBaseNutrition {
  nutrition: Record<string, number> | undefined
}

const BASE_NUTRITION = [
  { label: 'Calories', key: 'calories' },
  { label: 'Total Fat', key: 'totalFat' },
  { label: 'Total Carb', key: 'totalCarb' },
  { label: 'Protein', key: 'protein' },
  { label: 'Cholesterol', key: 'cholesterol' }
]

export const BaseNutrition = ({ nutrition = {} }: IBaseNutrition) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { track } = useSegmentInteraction()

  const nutritionItemsKeys = Object.keys(nutrition)
  const baseKeys = new Set(BASE_NUTRITION.map((metric) => metric.key))
  const restKeys = nutritionItemsKeys.filter((key) => !baseKeys.has(key))
  const restNutrition = restKeys.reduce((acc, key) => {
    acc[key] = nutrition[key]
    return acc
  }, {} as { [key: string]: number })

  const showDetailedNutrition = (ev: BaseSyntheticEvent) => {
    track('Show detailed nutrition popover')
    setAnchorEl(ev.target)
  }

  const hideDetailedNutrition = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MetricList
        title="Nutrition"
        onHeaderClick={showDetailedNutrition}
        slotRight={<ClearAllOutlinedIcon sx={{ color: 'orange' }} />}
        data={nutrition}
        units={units}
        metricEntries={BASE_NUTRITION}
      />
      <DetailedNutritionPopover
        nutrition={restNutrition}
        anchorEl={anchorEl}
        onClose={hideDetailedNutrition}
      />
    </>
  )
}
