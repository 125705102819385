import { IAllCoachesOption } from 'screens/AdminPanel/CoachAssignmentDataGrid/ChangeCoach/ChangeCoach'

export const computeDirtyAssignmentOptions = (
  options: IAllCoachesOption[],
  unsavedCoachAssignments: { [key: string]: { coachId: string; coachFullName: string } } | undefined
) => {
  if (!unsavedCoachAssignments) {
    return options
  }
  const dirtyOptions = [...options]
  const touchedCoaches = Object.values(unsavedCoachAssignments)

  touchedCoaches.forEach((touchedCoach: { coachId: string; coachFullName: string }) => {
    const coachIndex = dirtyOptions.findIndex((coach) => coach.id === touchedCoach.coachId)
    if (coachIndex < 0) {
      return
    }

    const coach = dirtyOptions[coachIndex]

    dirtyOptions.splice(coachIndex, 1, {
      ...coach,
      coachAssignmentInfo: {
        ...coach.coachAssignmentInfo,
        activeAssignmentsCount: coach.coachAssignmentInfo.activeAssignmentsCount! + 1,
        pendingAssignmentsCount: (coach.coachAssignmentInfo.pendingAssignmentsCount || 0) + 1
      }
    } as IAllCoachesOption)
  })

  return dirtyOptions
}
