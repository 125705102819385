import { MutableRefObject, SyntheticEvent, useState } from 'react'
import { Autocomplete, AutocompleteInputChangeReason, TextField } from '@mui/material'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import { EditorItem } from './types'

const MAX_OPTION_LENGTH = 64

interface ILinkableItemAutocompleteProps<T> {
  selectedItem: T | null
  linkedItems: T[]
  options: T[]
  search: any // The lazy query to perform the autocomplete search
  searchValue: MutableRefObject<string>
  loading: boolean
  onSelect: (item: T) => void
}

export const LinkableItemAutocomplete = <T extends EditorItem>({
  selectedItem,
  linkedItems,
  options,
  search,
  searchValue,
  loading,
  onSelect
}: ILinkableItemAutocompleteProps<T>) => {
  const [inputValue, setInputValue] = useState('')

  const { handleInputChange: handleSearchValueChange } = useDebouncedSearch(search, searchValue)

  const handleInputValueChange = (
    e: SyntheticEvent,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'reset') {
      // The reason will be 'reset' when a user selects an option from the autocomplete dropdown.
      // Do not change the text field's input value when this happens.
      return
    }

    handleSearchValueChange(e, newValue)
    setInputValue(newValue)
  }

  const handleChange = (_: any, item: T) => {
    onSelect(item)
  }

  const optionsWithoutLinkedItems = (options: T[]) =>
    options.filter((option) => !linkedItems.some((item) => item.id === option.id))
  const value =
    optionsWithoutLinkedItems(options).find((item) => item.id === selectedItem?.id) ?? null

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(item: EditorItem) =>
        item.title.length > MAX_OPTION_LENGTH
          ? `${item.title.substring(0, MAX_OPTION_LENGTH)}...`
          : item.title
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      noOptionsText="No items found"
      filterOptions={optionsWithoutLinkedItems}
      open={!!inputValue && options.length > 0}
      clearOnBlur={false}
      disableCloseOnSelect
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputValueChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Value"
          variant="standard"
          placeholder="Search by title or content"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
