import { MessageProps } from 'stream-chat-react'

const DelimiterComponent = () => {
  return (
    <div className="str-chat__message--system">
      <div className="str-chat__message--system__text">
        <div className="str-chat__message--system__line" />
        <p>End</p>
        <div className="str-chat__message--system__line" />
      </div>
    </div>
  )
}

export const CustomMessageSystem = ({ message }: MessageProps) => {
  const { nutrisense_type } = message

  if (nutrisense_type === 'delimiter') {
    return <DelimiterComponent />
  }

  return null
}
