export enum StatSections {
  Glucose = 'glucose',
  Macros = 'macros',
  Measurements = 'measurements',
  Ketones = 'ketones',
  Journal = 'journal'
}

export enum GlucoseStats {
  TimeWithinRange = 'time_within_range',
  StandardDeviation = 'standard_deviation',
  Mean = 'mean',
  Min = 'min',
  Max = 'max',
  SleepAverage = 'sleep_average',
  MorningAverage = 'morning_average'
}

export enum MacroStats {
  Calories = 'calories',
  Protein = 'protein',
  Carbs = 'carbs',
  Fat = 'fat'
}

export enum MeasurementStats {
  BodyFatPercentage = 'body_fat_percentage',
  BodyTemperature = 'body_temperature',
  Weight = 'weight',
  Stress = 'stress',
  BloodGlucose = 'blood_glucose',
  BloodKetones = 'blood_ketones',
  BreathKetones = 'breath_ketones',
  StepCount = 'step_count',
  FlightsClimbed = 'flights_climbed',
  ExerciseTime = 'exercise_time',
  RunningDistance = 'running_distance',
  CyclingDistance = 'cycling_distance'
}

export enum KetoneStats {
  TimeWithinRange = 'time_within_range',
  Mean = 'mean',
  Min = 'min',
  Max = 'max'
}

export enum JournalStats {
  Mood = 'mood',
  Energy = 'energy',
  Focus = 'focus',
  Stress = 'stress',
  Appetite = 'appetite'
}
