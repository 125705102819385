import { useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import { Box, Tooltip } from '@mui/material'
import { openExternalLink } from 'utility'
import { IKnowledgeAnswer } from 'types'
import {
  SourceBrowserAnswerListItem,
  SourceBrowserAnswerListText,
  SourceBrowserAnswerPreview,
  SourceBrowserAnswerPreviewBody,
  SourceBrowserAnswerPreviewEmpty,
  SourceBrowserAnswerPreviewSourceChip,
  SourceBrowserAnswerPreviewTitle,
  SourcesBrowserAnswerList,
  SourcesBrowserContainer
} from './styles'

interface ISourcesBrowserProps {
  answers: IKnowledgeAnswer[]
}

export const SourcesBrowser = ({ answers }: ISourcesBrowserProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<IKnowledgeAnswer>(answers[0])

  return (
    <SourcesBrowserContainer>
      <SourcesBrowserAnswerList>
        {answers.map((answer) => (
          <SourceBrowserAnswerListItem
            key={answer.id}
            selected={answer.id === selectedAnswer?.id}
            onClick={() => setSelectedAnswer(answer)}
          >
            <SourceBrowserAnswerListText primary={answer.title} secondary={answer.value} />
          </SourceBrowserAnswerListItem>
        ))}
      </SourcesBrowserAnswerList>
      <SourceBrowserAnswerPreview>
        {!!selectedAnswer && (
          <>
            <SourceBrowserAnswerPreviewTitle>
              {selectedAnswer?.title}
            </SourceBrowserAnswerPreviewTitle>
            <Box>
              {selectedAnswer.knowledgeSources.length === 0 ? (
                <SourceBrowserAnswerPreviewEmpty>No linked sources</SourceBrowserAnswerPreviewEmpty>
              ) : (
                selectedAnswer.knowledgeSources.map((source) => (
                  <Tooltip key={source.id} title={source.url} arrow>
                    <SourceBrowserAnswerPreviewSourceChip
                      icon={<LinkIcon fontSize="small" />}
                      label={source.title}
                      size="small"
                      variant="outlined"
                      onClick={() => openExternalLink(source.url)}
                    />
                  </Tooltip>
                ))
              )}
            </Box>
            <SourceBrowserAnswerPreviewBody>{selectedAnswer?.value}</SourceBrowserAnswerPreviewBody>
          </>
        )}
      </SourceBrowserAnswerPreview>
    </SourcesBrowserContainer>
  )
}
