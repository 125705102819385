import { useRef } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid'
import { useDebouncedSearch } from 'hooks/useDebouncedSearch'
import { IUser, useAdminPanelAllCoachesLazyQuery } from 'types'
import { getCoachDynamicFilters } from './helpers/coachDynamicFilters'

const INPUT_PLACEHOLDER_KEY = 'nutrisense:adminPanel:coachFilter:inputPlaceholder'

export const CoachFilter = ({ item, applyValue }: GridFilterInputValueProps) => {
  const searchValue = useRef('')

  const handleChange = (_: any, coach: IUser) => {
    applyValue({
      ...item,
      value: coach?.id
    })
    localStorage.setItem(INPUT_PLACEHOLDER_KEY, coach?.fullName || '')
  }

  const [searchUsers, { data, loading }] = useAdminPanelAllCoachesLazyQuery({
    variables: getCoachDynamicFilters(searchValue.current)
  })
  const { handleInputChange } = useDebouncedSearch(searchUsers, searchValue)
  const options = data?.allUsers.users ?? []

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(user: IUser) => user.fullName}
      isOptionEqualToValue={(userA, userB) => userA?.id === userB?.id}
      loading={loading}
      noOptionsText="No Nutritionist was found"
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Value"
          variant="standard"
          placeholder={localStorage.getItem(INPUT_PLACEHOLDER_KEY) || ''}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  )
}
