export enum DppCohortSegments {
  View = ':viewCohortId',
  Session = 'sessions/:sessionId'
}

export enum DppResource {
  EligibleUsers = 'eligible-users',
  Cohorts = 'cohorts',
  CohortView = `cohorts/${DppCohortSegments.View}`,
  CohortViewSession = `cohorts/${DppCohortSegments.View}/${DppCohortSegments.Session}`,
  InsuranceParticipants = 'insurance-participants',
  InsuranceSessions = 'insurance-sessions',
  DppChat = 'chat'
}
