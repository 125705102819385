import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import { snackBarVisibleVar } from 'context/ui'
import { useResendMissingPrescriptionsShipmentsMutation } from 'types'

const GridToolbarSendPrescriptionsShipments = () => {
  const [sendShipments, { loading }] = useResendMissingPrescriptionsShipmentsMutation({
    onError: () => {
      snackBarVisibleVar({ open: true, message: 'Error, please try again.' })
    },
    onCompleted: () => {
      snackBarVisibleVar({ open: true, message: 'Shipments are sent to provider' })
    }
  })

  const onClick = () => {
    sendShipments()
  }

  return (
    <Box mr={1}>
      <LoadingButton
        color="warning"
        size="small"
        variant="contained"
        loading={loading}
        onClick={onClick}
      >
        Send Shipments
      </LoadingButton>
    </Box>
  )
}

export default GridToolbarSendPrescriptionsShipments
