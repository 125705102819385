import DownloadIcon from '@mui/icons-material/Download'
import { Box, Button, Stack, Typography } from '@mui/material'
import { AppToolbar, CommonRightAdornment, Tabs } from 'components'
import { useAccountRequestsTabs } from 'screens/AccountRequests/hooks/useAccountRequestsTabs'

export const AccountRequests = () => {
  const { tabs, selectedTab, exportAccountRequestData } = useAccountRequestsTabs()

  return (
    <>
      <AppToolbar rightAdornment={<CommonRightAdornment />} />
      <Stack
        spacing={2}
        sx={{
          display: 'flex',
          height: '100%'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Account Requests Dashboard
          </Typography>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => exportAccountRequestData({ variables: { status: selectedTab } })}
          >
            Export
          </Button>
        </Box>
        <Tabs tabs={tabs} />
      </Stack>
    </>
  )
}
