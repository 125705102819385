import isUrl from 'is-url'
import { ShortcutsEditor } from './types'
import { isImageUrl, wrapLink } from './utils'

export const withLinks = (editor: ShortcutsEditor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element: any) => element.type === 'link' || isInline(element)

  editor.insertText = (text) => {
    if (text && isUrl(text) && !isImageUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text) && !isImageUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}
