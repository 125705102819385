import styled from 'styled-components'
import {
  space,
  layout,
  color,
  flexbox,
  LayoutProps,
  ColorProps,
  SpaceProps,
  FlexboxProps,
  border,
  BorderProps,
  shadow,
  ShadowProps
} from 'styled-system'

export const BoxStyled = styled.div<
  LayoutProps & ColorProps & SpaceProps & BorderProps & ShadowProps
>`
  ${space}
  ${layout}
    ${color}
    ${border}
    ${shadow}
`

export const FlexBoxStyled = styled(BoxStyled)<FlexboxProps>`
  ${flexbox}
  display: flex;
`
