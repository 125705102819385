import { useAuth } from 'context'
import { AccountRequestCancelSubscriptionSubtypes } from 'screens/AccountRequests/constants'
import { HealthPortalFeature, OpsAccountRequestKind } from 'types'

export const useCanReviewAccountRequest = () => {
  const { isAuthorized } = useAuth()

  return ({
    accountRequestKind,
    accountRequestSubtype = undefined
  }: {
    accountRequestKind: OpsAccountRequestKind
    accountRequestSubtype?: AccountRequestCancelSubscriptionSubtypes
  }) => {
    if (accountRequestKind === OpsAccountRequestKind.CancelSubscription && accountRequestSubtype) {
      switch (accountRequestSubtype) {
        case AccountRequestCancelSubscriptionSubtypes.CommitmentEnd:
          return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewCancelSubscription)
        case AccountRequestCancelSubscriptionSubtypes.Immediate:
          return isAuthorized(
            HealthPortalFeature.SupportAccountRequestsReviewCancelSubscriptionImmediate
          )
        default:
          return false
      }
    }

    switch (accountRequestKind) {
      case OpsAccountRequestKind.PauseSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewPauseSubscription)
      case OpsAccountRequestKind.UnpauseSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewUnpauseSubscription)
      case OpsAccountRequestKind.UncancelSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewUncancelSubscription)
      case OpsAccountRequestKind.ChangeSubscriptionBillingCycle:
        return isAuthorized(
          HealthPortalFeature.SupportAccountRequestsReviewChangeSubscriptionBillingCycle
        )
      case OpsAccountRequestKind.UpdateSubscription:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewUpdateSubscription)
      case OpsAccountRequestKind.Refund:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewRefund)
      case OpsAccountRequestKind.OneOffAddon:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewOneOffAddon)
      case OpsAccountRequestKind.MiscRequest:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewMiscRequest)
      case OpsAccountRequestKind.DeleteDuplicateAccount:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewDeleteDuplicateAccount)
      case OpsAccountRequestKind.Credit:
        return isAuthorized(HealthPortalFeature.SupportAccountRequestsReviewCredit)
      default:
        return false
    }
  }
}
