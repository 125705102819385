export const applyNutritionistMask = (value: string) => {
  return value
    .replace(/\b(rd|dietitian|coach)\b/gi, 'nutritionist')
    .replace(/(\b|_)(rd|dietitian|coach)(?=\b|_)/gi, function (match, p1, p2) {
      if (p1 === '_' && p2 === 'dietitian') {
        return p1 + 'nutritionist'
      } else {
        return 'nutritionist'
      }
    })
}
