import {
  addHours,
  addMinutes,
  differenceInMinutes,
  getUnixTime,
  isAfter,
  isBefore,
  subHours,
  subMinutes
} from 'date-fns'
import { first, isEmpty, last } from 'lodash'
import { IEntryGlucose, IMeasurement } from 'types'

const MEASUREMENTS_WINDOW_IN_HOURS = 2

type GlucoseMeasurementValue = IMeasurement

export type ItemPoint = {
  x: Date
  y: number
  name?: string
  fake?: boolean
}

// it should be consistent with timeline chart and for timeline chart we add gaps only if interval is > 30 minutes
// (controlled on BE side: TimelineChartService#merge_measurements)
const MAX_MEASUREMENTS_INTERVAL_IN_MINUTES = 30

export function getChartData(
  item:
    | { occurredAt: string }
    | {
        glucose: IEntryGlucose
        occurredAt: string
      }
) {
  const { values = [] } = 'glucose' in item ? item.glucose : {}

  const startTime = new Date(item.occurredAt)
  const endTime = addHours(new Date(startTime), MEASUREMENTS_WINDOW_IN_HOURS)
  const chartStartTime = subHours(new Date(startTime), 1)
  const chartEndTime = addHours(new Date(endTime), 1)

  const filteredValues = filterChartData({ values, start: chartStartTime, end: chartEndTime })

  const minValue = Math.min(...values.map((v) => v.value as number))
  const maxValue = Math.max(...values.map((v) => v.value as number))

  // we use 30% of delta as bottom offset for chart
  const offset = (maxValue === minValue ? minValue : maxValue - minValue) / 3.0
  const bottomValue = minValue - offset

  const chartData = toChartData(filteredValues, true, bottomValue)
  const activityData = filterChartData({ values: chartData, start: startTime, end: endTime })

  const itemPoint = first(activityData)
  const limitPoint = last(activityData)

  const centeredChartData = [...chartData]
  fillEmptyIntervals({
    values: centeredChartData,
    leftLimit: chartStartTime,
    rightLimit: chartEndTime,
    bottomValue
  })

  const startTimeTick = {
    x: getUnixTime(startTime),
    y: interpolate(centeredChartData, startTime) ?? (itemPoint?.y || 0),
    time: startTime
  }
  const endTimeTick = {
    x: getUnixTime(endTime),
    y: interpolate(centeredChartData, endTime) ?? (limitPoint?.y || 0),
    time: endTime
  }

  return {
    activityData,
    centeredChartData,
    startTimeTick,
    endTimeTick
  }
}

const toChartData = (
  values: GlucoseMeasurementValue[],
  fillSpaces = true,
  bottomValue = 0
): ItemPoint[] => {
  if (isEmpty(values)) {
    return []
  }

  const data = values.map((v: GlucoseMeasurementValue) => ({
    x: new Date(v.timestamp),
    y: Number(v.value),
    fake: false
  }))

  if (fillSpaces) {
    let i = data.length - 1
    while (i !== 0) {
      const d1 = data[i]
      const d2 = data[i - 1]
      const intervalLength = differenceInMinutes(new Date(d1.x), new Date(d2.x))

      if (intervalLength > MAX_MEASUREMENTS_INTERVAL_IN_MINUTES) {
        if (d1.y) {
          data.splice(i, 0, { x: new Date(d1.x), y: bottomValue, fake: true })
        }

        data.splice(i, 0, {
          x: subMinutes(new Date(d1.x), MAX_MEASUREMENTS_INTERVAL_IN_MINUTES),
          y: bottomValue,
          fake: true
        })

        if (d2.y) {
          data.splice(i, 0, { x: new Date(d2.x), y: bottomValue, fake: true })
        }
      } else {
        i -= 1
      }
    }
  }

  return data
}

const filterChartData = <T>({ values, start, end }: { values: T[]; start: Date; end: Date }) => {
  return values.filter((point: any) => {
    const pointDate = new Date(point.x ?? point.timestamp)
    return (
      (pointDate === start || isAfter(pointDate, start)) &&
      (pointDate === end || isBefore(pointDate, end))
    )
  })
}

const fillEmptyIntervals = ({
  values,
  leftLimit,
  rightLimit,
  bottomValue
}: {
  values: ItemPoint[]
  leftLimit: Date
  rightLimit: Date
  bottomValue: number
}) => {
  if (values.length === 0) {
    return
  }

  const hasLeftSpaces = values.findIndex(
    (value: ItemPoint) => getUnixTime(leftLimit) > getUnixTime(value.x)
  )
  if (hasLeftSpaces === -1) {
    values.unshift({ x: new Date(values[0].x), y: bottomValue })
    while (getUnixTime(leftLimit) < getUnixTime(values[0].x)) {
      const x = subMinutes(values[0].x, MAX_MEASUREMENTS_INTERVAL_IN_MINUTES)
      values.unshift({ x, y: bottomValue })
    }
  }

  const hasRightSpaces = values.findIndex(
    (value: ItemPoint) => getUnixTime(rightLimit) < getUnixTime(value.x)
  )
  if (hasRightSpaces === -1) {
    values.push({ x: new Date(values[values.length - 1].x), y: bottomValue })
    while (getUnixTime(rightLimit) > getUnixTime(values[values.length - 1].x)) {
      const x = addMinutes(values[values.length - 1].x, MAX_MEASUREMENTS_INTERVAL_IN_MINUTES)
      values.push({ x, y: bottomValue })
    }
  }
}

const interpolate = (data: ItemPoint[], initX: Date): number | null => {
  const i1 = data.findIndex((value) => getUnixTime(value.x) - getUnixTime(initX) > 0)
  if (i1 > 0) {
    const i2 = i1 - 1
    const v1 = data[i2]
    const v2 = data[i1]
    const k = (v2.y - v1.y) / (getUnixTime(v2.x) - getUnixTime(v1.x))
    const b = k * getUnixTime(v2.x) - v2.y
    return k * getUnixTime(initX) - b
  }

  return null
}
