import { ReactNode } from 'react'
import { Stack, TextField, Grid } from '@mui/material'
import { LinkedItems } from './LinkedItems'
import { LinkedItemsBox } from './styles'
import { EditorItem } from './types'

interface IEditorItems<T> {
  title: string
  items: T[]
  selectedItem: T | null
  onSelect: (item: T) => void
  children: ReactNode
}

export function EditorItems<T extends EditorItem>({
  title,
  items,
  selectedItem,
  onSelect,
  children
}: IEditorItems<T>) {
  return (
    <Grid item xs={6}>
      <Stack height="100%" spacing={2}>
        <TextField label="Answer" defaultValue={title} size="small" margin="dense" disabled />
        <LinkedItemsBox>
          <LinkedItems items={items} selectedItem={selectedItem} onSelect={onSelect} />
        </LinkedItemsBox>
        {children}
      </Stack>
    </Grid>
  )
}
