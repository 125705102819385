import { FormControl, TextField } from '@mui/material'
import { IAccountRequestModalProps } from 'components/AccountRequestsMenu'
import { AccountRequestModal } from 'components/AccountRequestsMenu/AccountRequestModal'
import { snackBarVisibleVar } from 'context'
import { useForm } from 'react-hook-form'
import {
  OpsAccountRequestKind,
  OpsAccountRequestPriority,
  useCreateAccountRequestMutation,
  useCurrentUserQuery
} from 'types'

export interface IMiscRequestForm {
  priority: OpsAccountRequestPriority
  note: string
  requestDetails: string
}

export const MiscRequestModal = ({ closeModal, modalState }: IAccountRequestModalProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { handleSubmit, control, register, reset, formState } = useForm<IMiscRequestForm>({
    mode: 'onTouched',
    defaultValues: {
      priority: OpsAccountRequestPriority.Regular,
      note: '',
      requestDetails: ''
    }
  })

  const onClose = () => {
    reset()
    closeModal()
  }

  const [createAccountRequest, { loading }] = useCreateAccountRequestMutation({
    onError: (error) => snackBarVisibleVar({ open: true, message: error.message })
  })

  if (!currentUser) {
    return null
  }

  const miscRequestHandler = async (formData: IMiscRequestForm) => {
    createAccountRequest({
      variables: {
        userId: currentUser.id,
        kind: OpsAccountRequestKind.MiscRequest,
        priority: formData.priority,
        notes: formData.note,
        payload: {
          type: OpsAccountRequestKind.MiscRequest,
          miscRequest: {
            requestDetails: formData.requestDetails
          }
        }
      }
    })

    onClose()
  }

  const innerForm = (
    <FormControl variant="outlined" margin="dense" fullWidth>
      <TextField
        label="Request Details"
        fullWidth
        multiline
        minRows={2}
        {...register('requestDetails', { required: true })}
        error={!!formState.errors.requestDetails}
      />
    </FormControl>
  )

  return (
    <AccountRequestModal<IMiscRequestForm>
      register={register}
      modalTitle="Misc Request"
      control={control}
      onClose={onClose}
      modalState={modalState}
      handleSubmit={handleSubmit(miscRequestHandler)}
      loading={loading}
      callToActionText="Escalate"
    >
      {innerForm}
    </AccountRequestModal>
  )
}
