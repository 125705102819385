import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import { IDataGridColumn } from 'components/DataGrid/DataGrid'
import { startCase } from 'lodash'
import { FORMATTED_COACH_ASSIGNMENTS } from 'screens/AdminPanel/CoachAssignmentDataGrid/constants'
import { distinctSubscriptionStatusFilterOperators } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/distinctSubscriptionStatusFilterOperators'
import { getLastCoachAssignment } from 'screens/AdminPanel/CoachAssignmentDataGrid/helpers/getLastCoachAssignment'
import { UserPropertyCell } from 'screens/AdminPanel/CoachAssignmentDataGrid/UserPropertyCell'
import { COLUMNS } from 'screens/AdminPanel/MembersDataGrid/types/membersViewColumn.types'
import { coachEndDateDescriptor, tooltipLookup } from 'utility/coachEndDateDescriptor'
import {
  formatSubscriptionStatus,
  renderAsChip,
  renderAsChipWithTooltip,
  renderAsTextWithTooltip,
  renderAsTextWithTooltipPrepend,
  renderAsUserHomePageLink,
  renderCoachEndDate
} from 'utility/dataGrid'
import { formatWithSubscriptionStatusMask } from 'utility/formatWithSubscriptionStatusMask'
import {
  CoachAssignmentKind,
  ICoachAssignment,
  IPlan,
  ISubscription,
  IUser,
  IUserProperties,
  IUserSensorInfo,
  SubscriptionStatus,
  UserDynamicFilterField,
  UserGoalKind,
  UserPropertyKind,
  UserSortField
} from 'types'

const coachEndDateValue = ({ row }: GridValueGetterParams) => {
  const { coachAssignments, ongoingDietitianSubscription } = row
  return (
    ongoingDietitianSubscription?.cancelAt ||
    coachEndDateDescriptor({
      subscription: ongoingDietitianSubscription,
      lastCoachAssignmentKind: getLastCoachAssignment(coachAssignments)?.kind
    })
  )
}

type IAdminPanelUser = { [K in keyof IUser as K]?: IUser[K] } & {
  [K in keyof IUserSensorInfo as `sensorInfo.${K}`]?: IUserSensorInfo[K]
} & {
  [K in keyof ISubscription as `ongoingDietitianSubscription.${K}`]?: ISubscription[K]
} & {
  [K in keyof ICoachAssignment as `lastCoachAssignment.${K}`]?: ICoachAssignment[K]
} & {
  [K in keyof ISubscription as `lastDietitianSubscription.${K}`]?: ISubscription[K]
} & {
  [K in keyof IUserProperties as `userProperties.${K}`]?: IUserProperties[K]
} & {
  [COLUMNS.PLAN_NICKNAME]: string
}

export const columns: (IDataGridColumn & { field: keyof IAdminPanelUser | 'index' })[] = [
  {
    field: COLUMNS['#'],
    headerName: '#',
    type: 'number',
    width: 25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) =>
      params.api.getRowIndex(params.row.id) + 1
  },
  {
    field: COLUMNS.NAME,
    headerName: 'Name',
    width: 200,
    type: 'string',
    renderCell: renderAsUserHomePageLink
  },
  { field: COLUMNS.EMAIL, headerName: 'Email', width: 250 },
  {
    field: COLUMNS.SCHEDULED_VIDEO_CALL_STATUS,
    headerName: 'Video Call Status',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.userProperties?.scheduledVideoCallStatus?.value,
    renderCell: renderAsTextWithTooltip,
    backendFilterName: UserDynamicFilterField.ScheduledVideoCallStatusValue,
    backendSortName: UserSortField.ScheduledVideoCallStatusValue
  },
  {
    field: COLUMNS.COACH_ASSIGNMENT_KIND,
    headerName: 'Nutritionist Assignment Kind',
    groupable: true,
    type: 'singleSelect',
    valueOptions: Object.keys(CoachAssignmentKind).map((key: keyof typeof CoachAssignmentKind) => {
      return { label: key, value: CoachAssignmentKind[key] }
    }),
    valueGetter: ({ row }) => getLastCoachAssignment(row?.coachAssignments)?.kind,
    valueFormatter: ({ value }) =>
      FORMATTED_COACH_ASSIGNMENTS[value as keyof typeof FORMATTED_COACH_ASSIGNMENTS] ||
      startCase(value),
    width: 250,
    renderCell: renderAsChipWithTooltip(
      'Use the Nutritionist Assignment Kind column to establish the nutritionist type to assign for this user'
    )
  },
  {
    field: COLUMNS.COACH_END_DATE,
    headerName: 'Chat End Date',
    width: 150,
    type: 'date',
    valueGetter: coachEndDateValue,
    renderCell: renderCoachEndDate(tooltipLookup)
  },
  {
    field: COLUMNS.PLAN_NICKNAME,
    headerName: 'Plan',
    width: 250,
    valueGetter: ({ row }) =>
      row?.ongoingDietitianSubscription?.plans?.map((plan: IPlan) => plan.nickname).join(),
    renderCell: renderAsTextWithTooltipPrepend('Ongoing Nutritionist Subscription Plan Name')
  },
  {
    field: COLUMNS.DIETITIAN_SUBSCRIPTION_STATUS,
    headerName: 'Nutritionist Subscription Status',
    groupable: true,
    width: 270,
    type: 'singleSelect',
    valueOptions: Object.values(SubscriptionStatus).map((value) => {
      return { label: formatWithSubscriptionStatusMask(value), value: value }
    }),
    renderCell: renderAsChip,
    valueGetter: ({ row }) => row.lastDietitianSubscription?.stripeStatus,
    valueFormatter: formatSubscriptionStatus,
    filterOperators: distinctSubscriptionStatusFilterOperators()
  },
  {
    field: COLUMNS.DIETITIAN_REQUEST,
    headerName: 'Nutritionist Request',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.userProperties?.dietitianRequest,
    renderCell: ({ row, value }) => {
      return (
        <UserPropertyCell
          value={value}
          userId={row.id}
          kind={UserPropertyKind.DietitianRequest}
          multiline={false}
          title="Nutritionist Request"
        />
      )
    },
    backendFilterName: UserDynamicFilterField.DietitianRequestValue,
    backendSortName: UserSortField.DietitianRequestValue
  },
  {
    field: COLUMNS.TEST_GROUP,
    backendFilterName: UserDynamicFilterField.TestGroupValue,
    backendSortName: UserSortField.TestGroupValue,
    headerName: 'Test Group',
    width: 200,
    type: 'string',
    valueGetter: ({ row }) => row.userProperties?.testGroup,
    renderCell: ({ row, value }) => {
      return (
        <UserPropertyCell
          value={value}
          userId={row.id}
          kind={UserPropertyKind.TestGroup}
          multiline={false}
          tooltipLabel={`Member is assigned to the following test group: ${value?.value}`}
        />
      )
    }
  },
  {
    field: COLUMNS.HEALTH_GOAL,
    headerName: 'Goals Main',
    width: 200,
    type: 'string',
    renderCell: renderAsTextWithTooltip,
    valueGetter: ({ row }) => row.userProperties?.healthGoal?.value,
    backendFilterName: UserDynamicFilterField.HealthGoalValue,
    backendSortName: UserSortField.HealthGoalValue
  },
  {
    field: COLUMNS.ONE_MONTH_ACTIVE_FEEDBACK_DIETITIAN,
    headerName: 'One Month Active Feedback Nutritionist',
    width: 200,
    type: 'string',
    backendFilterName: UserDynamicFilterField.OneMonthActiveFeedbackDietitianValue,
    backendSortName: UserSortField.OneMonthActiveFeedbackDietitianValue,
    renderCell: (params) => renderAsTextWithTooltip(params, true),
    valueGetter: ({ row }) => row.userProperties?.oneMonthActiveFeedbackDietitian?.value
  },
  {
    field: COLUMNS.SENSOR_COUNT,
    headerName: 'Sensor Count',
    width: 100,
    align: 'center',
    type: 'number',
    valueGetter: ({ row }) => row.sensorInfo?.totalCount
  },
  {
    field: COLUMNS.PRIMARY_GOAL,
    headerName: 'Primary Goal',
    width: 200,
    type: 'singleSelect',
    valueOptions: Object.keys(UserGoalKind).map((key: keyof typeof UserGoalKind) => {
      return { label: startCase(key), value: UserGoalKind[key] }
    }),
    valueGetter: ({ row }) => row.primaryGoal,
    valueFormatter: ({ value: goal }) => goal?.description || goal?.title,
    backendFilterName: UserDynamicFilterField.GoalKind,
    backendSortName: UserSortField.GoalKind
  }
]
