import { GridValueGetterParams } from '@mui/x-data-grid'
import { addDays } from 'date-fns'
import { coachEndDateDescriptor } from 'utility/coachEndDateDescriptor'
import { CoachAssignmentSupportType, ICoachAssignment } from 'types'

export const coachEndDateValue = ({ row }: GridValueGetterParams) => {
  switch (row.supportType) {
    case CoachAssignmentSupportType.VideoCall:
      return videoCallEndDate(row as ICoachAssignment)
    case CoachAssignmentSupportType.VideoChat:
      return videoChatEndDate(row as ICoachAssignment)
    case CoachAssignmentSupportType.AsyncChat:
      return asyncChatEndDate(row as ICoachAssignment)
    default:
      return null
  }
}

const videoCallEndDate = (row: ICoachAssignment) => row.appointment?.date

const VIDEO_CHAT_ACTIVE_DAYS_SINCE_LAST_CALL = 7
const videoChatEndDate = (row: ICoachAssignment) => {
  const videoCallDates = (row.user?.coachAssignments || []).flatMap(
    (assignment: ICoachAssignment) => {
      if (
        assignment.supportType === CoachAssignmentSupportType.VideoCall &&
        assignment.appointment?.date
      ) {
        return [new Date(assignment.appointment.date).getTime()]
      }

      return []
    }
  )

  if (videoCallDates.length === 0) return null

  return addDays(Math.max(...videoCallDates), VIDEO_CHAT_ACTIVE_DAYS_SINCE_LAST_CALL)
}

const asyncChatEndDate = (row: ICoachAssignment) => {
  const { ongoingDietitianSubscription } = row.user
  return (
    ongoingDietitianSubscription?.cancelAt ||
    coachEndDateDescriptor({
      subscription: ongoingDietitianSubscription,
      lastCoachAssignmentKind: row.kind
    })
  )
}
