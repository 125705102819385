import Check from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useMenu } from 'hooks'

export interface IGridToolbarGroupProps {
  groupField: string | null
  availableGroupColumns: { headerName: string; field: string }[]
  setGroupField: (field: string | null) => void
}

const GridToolbarGroup = (props: IGridToolbarGroupProps) => {
  const { groupField, setGroupField, availableGroupColumns } = props

  const { menuAnchorEl, menuState, openMenu, closeMenu } = useMenu()

  if (availableGroupColumns.length === 0) {
    return null
  }

  return (
    <>
      <Button size="small" variant={groupField ? 'outlined' : 'text'} onClick={openMenu}>
        Group
      </Button>
      <Menu anchorEl={menuAnchorEl} open={menuState} onClose={closeMenu}>
        {groupField && (
          <MenuItem
            onClick={() => {
              setGroupField(null)
              closeMenu()
            }}
          >
            {groupField && (
              <ListItemIcon>
                <ClearIcon color="error" />
              </ListItemIcon>
            )}
            Remove grouping
          </MenuItem>
        )}

        {availableGroupColumns.map((column) => (
          <MenuItem
            key={column.field}
            onClick={() => {
              setGroupField(column.field)
              closeMenu()
            }}
          >
            {column.field === groupField && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {column.headerName}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default GridToolbarGroup
