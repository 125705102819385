import Bugsnag from '@bugsnag/js'
import {
  ChatConversationEventKind,
  IChatConversation,
  IChatConversationEvent,
  ICurrentAdminQuery,
  useCurrentAdminQuery
} from 'types'
import { AssignmentEvent } from './AssignmentEvent'
import { CloseEvent } from './CloseEvent'
import { MessageEvent } from './MessageEvent'
import { NoteEvent } from './NoteEvent'
import { OpenEvent } from './OpenEvent'
import { SnoozedEvent } from './SnoozedEvent'
import { EventWrapper } from './styles'
import { UnsnoozeEvent } from './UnsnoozeEvent'

interface IEvent {
  event: IChatConversationEvent
  conversation: IChatConversation
}

export interface IEventElement {
  event: IChatConversationEvent
  conversation: IChatConversation
  currentAdmin?: ICurrentAdminQuery['currentAdmin']
}

const EVENT_KIND_TO_COMPONENT = {
  [ChatConversationEventKind.Message]: MessageEvent,
  [ChatConversationEventKind.Snoozed]: SnoozedEvent,
  [ChatConversationEventKind.TimerUnsnooze]: UnsnoozeEvent,
  [ChatConversationEventKind.Unsnooze]: UnsnoozeEvent,
  [ChatConversationEventKind.Open]: OpenEvent,
  [ChatConversationEventKind.Close]: CloseEvent,
  [ChatConversationEventKind.Assignment]: AssignmentEvent,
  [ChatConversationEventKind.Note]: NoteEvent
}

export const Event = ({ event, conversation }: IEvent) => {
  const { data: { currentAdmin } = {} } = useCurrentAdminQuery()

  const EventComponent = EVENT_KIND_TO_COMPONENT[event.kind]

  if (!EventComponent) {
    Bugsnag.notify('ChatConversationEvent component does not exist')
    return null
  }

  return (
    <EventWrapper event={event} conversation={conversation}>
      <EventComponent event={event} conversation={conversation} currentAdmin={currentAdmin} />
    </EventWrapper>
  )
}
