import { Box, Button } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid'
import { isEqual, uniqWith } from 'lodash'
import { IGetFields } from 'screens/Shipments/helpers/getFields'

interface IGridToolbarCopyFieldsProps {
  selectedRows: Map<GridRowId, any>
  buildRow: IGetFields
  uniqueRows?: boolean
  title: string
}

const GridToolbarCopyFields = (props: IGridToolbarCopyFieldsProps) => {
  const { selectedRows, buildRow, title, uniqueRows = false } = props

  const onClick = () => {
    const rows = Array.from(selectedRows.values()).map((row) => buildRow(row))
    const formattedData = (uniqueRows ? uniqWith(rows, isEqual) : rows)
      .map((row) => row.join('	'))
      .join('\n')
    navigator.clipboard.writeText(formattedData)
  }

  return (
    <Box mx={1}>
      <Button size="small" variant="contained" onClick={onClick}>
        {title}
      </Button>
    </Box>
  )
}

export default GridToolbarCopyFields
