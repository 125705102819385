import { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Card, CardContent, Paper } from '@mui/material'
import { EventListItem } from 'components/Events/EventListItem'
import { IEventListItem } from 'components/Events/types'
import { NoDataMessage } from 'components/NoDataMessage'
import {
  currentEventIdVar,
  orderFilterVar,
  SortDirectionEnum,
  verticalCursorEventIndexVar
} from 'context'
import { EventTypes } from 'event.types'
import { ILastItem, ListItemTypename } from 'listItem.types'
import { isEmpty } from 'lodash'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VirtualizedList,
  ListRowProps,
  Size
} from 'react-virtualized'
import { RenderedRows } from 'react-virtualized/dist/es/List'
import { theme } from 'theme'

const COLUMN_INDEX = 0

const LAST_ITEM: ILastItem = { id: 'last-item', __typename: 'LastItem' }

const cache = new CellMeasurerCache({ fixedWidth: true })

const INITIAL_SCROLL_INDEX = 0
const INITIAL_EVENTS_LIST_HEIGHT = 0

export const EventList: FC<{
  events: IEventListItem[]
  lastVisitedEventIndex: MutableRefObject<number | null>
  setLastHighlightedEventIndex?: (eventIndex: number) => void
}> = ({ events, lastVisitedEventIndex }) => {
  const listRef = useRef<any>(null)

  const direction = useReactiveVar(orderFilterVar)

  const scrollIndex = useRef(INITIAL_SCROLL_INDEX)

  const eventsListHeight = useRef(INITIAL_EVENTS_LIST_HEIGHT)

  const eventId = useReactiveVar(currentEventIdVar)

  const isEventType = (event: IEventListItem) => {
    return event.__typename && Object.values<string>(EventTypes).includes(event.__typename)
  }

  useEffect(() => {
    if (isEmpty(events)) return

    const lastEvent = events[events.length - 1]

    if (lastEvent.__typename !== ListItemTypename.LastItem) {
      events.push(LAST_ITEM)
    }
  }, [events])

  useEffect(() => {
    cache.clearAll()
    listRef.current?.forceUpdateGrid()
  }, [events])

  const revertIndexIfDescending = (index: number) => {
    const lastIndexCount = 1
    return direction === SortDirectionEnum.DESCENDING
      ? events.length - 1 - lastIndexCount - index
      : index
  }

  const countLastItemAndDateDelimiters = useCallback(
    (index: number) => {
      let slicedEvents
      slicedEvents = events.slice(0, index)
      const notEvents = slicedEvents.filter((event) => !isEventType(event))

      return notEvents.length
    },
    [events]
  )

  const adjustLastVisitedIndex = useCallback(
    (index: number) => {
      const notEventsCount = countLastItemAndDateDelimiters(index)
      return index - notEventsCount
    },
    [countLastItemAndDateDelimiters]
  )

  useEffect(() => {
    if (!listRef.current || !eventId) {
      return
    }

    const index = events.findIndex((event) => event.id === eventId)
    if (index === -1) {
      return
    }
    scrollIndex.current = index

    lastVisitedEventIndex.current = adjustLastVisitedIndex(index)
    currentEventIdVar('')
  }, [eventId, events, lastVisitedEventIndex, adjustLastVisitedIndex])

  const handleRowRender = ({ index, style, parent, key }: ListRowProps) => {
    const event = events[index]

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={COLUMN_INDEX}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <Paper
            ref={registerChild as any}
            key={key}
            style={{ ...style, backgroundColor: 'white' }}
            sx={{ padding: 0, display: 'flex', justifyContent: 'center' }}
          >
            <EventListItem event={event} eventsListHeight={eventsListHeight.current} />
          </Paper>
        )}
      </CellMeasurer>
    )
  }

  const handleRowsRendered = ({ startIndex }: RenderedRows) => {
    let revertedIndex = revertIndexIfDescending(startIndex)

    let fixedIndex = adjustLastVisitedIndex(revertedIndex)

    if (verticalCursorEventIndexVar() === null) {
      lastVisitedEventIndex.current = fixedIndex
      return
    }

    verticalCursorEventIndexVar(fixedIndex)
  }

  const handleResize = ({ height }: Size) => {
    if (!eventsListHeight.current) {
      eventsListHeight.current = height
      return
    }

    if (eventsListHeight.current !== height) {
      eventsListHeight.current = height
      const lastEventIndex = events.length - 1
      cache.clear(lastEventIndex, COLUMN_INDEX)
    }
  }

  return events.length > 0 ? (
    <AutoSizer onResize={handleResize}>
      {({ height, width }) => (
        <VirtualizedList
          ref={listRef}
          width={width}
          height={height}
          rowHeight={cache.rowHeight}
          scrollToIndex={scrollIndex.current}
          scrollToAlignment="start"
          rowCount={events.length}
          onRowsRendered={handleRowsRendered}
          rowRenderer={handleRowRender}
        />
      )}
    </AutoSizer>
  ) : (
    <Card sx={{ margin: 1, backgroundColor: theme.palette.grey[50] }}>
      <CardContent>
        <NoDataMessage />
      </CardContent>
    </Card>
  )
}
