import { SelectSetting } from '.'
import { UserSettingsSection } from './UserSettingsSection'

export const System = () => {
  const rows = [
    {
      label: 'Unit System',
      cells: [
        <SelectSetting
          key="unitSystemOptions"
          clientConfigKey="unitSystemOptions"
          settingKey="unitSystem"
        />
      ]
    }
  ]

  return <UserSettingsSection header="Look and Feel" rows={rows} />
}
