import { Suspense } from 'react'
import { Stack } from '@mui/material'
import { Loader } from 'components'
import { OrdersAppBar } from 'components'
import { Outlet } from 'react-router-dom'

export const OpsPageLayout = () => {
  return (
    <Stack minHeight="100%">
      <OrdersAppBar />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </Stack>
  )
}
