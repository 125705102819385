import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import { AvatarPlaceholder, MessageAvatar, MessageBody } from 'screens/Chat/styles'
import { shortenDistanceTime } from 'screens/Chat/utils'
import { nameInitials } from 'utility/nameInitials'
import { MMM_DD_HH_MM_A } from 'utility/timeFormats'
import { IEventElement } from './Event'
import { MessageWrapper } from './styles'
import { getSenderFullName } from './utils'

export const MessageEvent = ({ event, conversation }: IEventElement) => {
  const message = event.message!
  const sender = message.sender
  const senderFullName = getSenderFullName(message)
  const sentAtIso = parseISO(message.sentAt)
  const distance = shortenDistanceTime(formatDistanceToNowStrict(sentAtIso))

  return (
    <Stack>
      <MessageWrapper direction="row" event={event} conversation={conversation}>
        <Tooltip title={senderFullName}>
          <MessageAvatar src={sender?.avatar || ''} alt={senderFullName}>
            {nameInitials(senderFullName)}
          </MessageAvatar>
        </Tooltip>
        <MessageBody dangerouslySetInnerHTML={{ __html: message.value }} />
      </MessageWrapper>
      <MessageWrapper direction="row" event={event} conversation={conversation}>
        <AvatarPlaceholder />
        <Typography color="text.secondary" variant="caption">
          <Tooltip title={format(sentAtIso, MMM_DD_HH_MM_A)}>
            <Box component="span">{distance}</Box>
          </Tooltip>
          {conversation.lastMessage?.id === message.id && conversation.user.id !== sender?.id && (
            <> · {conversation.candidateRead ? 'seen' : 'delivered'}</>
          )}
        </Typography>
      </MessageWrapper>
    </Stack>
  )
}
