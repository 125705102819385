import MuiDateRangePickerDay from '@mui/lab/DateRangePickerDay'
import { styled } from '@mui/system'

export const DateRangePickerDayStyled = styled(MuiDateRangePickerDay)({
  '& .MuiDateRangePickerDay-day:before': {
    fontSize: 9,
    position: 'absolute',
    top: 21
  }
})
