import {
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  SortDirection,
  UserDynamicFilterField,
  UserSortField
} from 'types'

export const getCoachDynamicFilters = (searchValue: string) => {
  return {
    dynamicFilters: [
      {
        items: [
          {
            field: UserDynamicFilterField.IsCoach,
            operation: DynamicFilterItemOperation.Eq,
            value: 'true'
          },
          {
            field: UserDynamicFilterField.FullName,
            operation: DynamicFilterItemOperation.ICont,
            value: searchValue
          }
        ],
        operator: DynamicFilterOperator.And
      }
    ],
    sorts: [
      {
        direction: SortDirection.Asc as SortDirection,
        field: UserSortField.FullName
      }
    ]
  }
}
