import { useReactiveVar } from '@apollo/client'
import { GridVars } from 'context'
import { DynamicFilterOperator, SortDirection } from 'types'

export type ReactiveGridVars = {
  page?: number
  perPage?: number
  sortBy?: any
  sortOrder?: SortDirection
  dynamicFilters: any
  dynamicFilterOperator: DynamicFilterOperator
}

export const getCommonListQueryVariables = ({
  page,
  perPage,
  sortOrder,
  sortBy,
  dynamicFilters,
  dynamicFilterOperator
}: ReactiveGridVars) => {
  return {
    page,
    perPage,
    sorts: sortOrder && sortBy ? { direction: sortOrder, field: sortBy } : undefined,
    dynamicFilters:
      dynamicFilters?.length && dynamicFilterOperator
        ? { items: dynamicFilters, operator: dynamicFilterOperator }
        : undefined
  }
}

export const useReactiveGridVars = (gridVars: GridVars): ReactiveGridVars => {
  const {
    dynamicFiltersVar,
    dynamicFilterOperatorVar,
    pageVar,
    perPageVar,
    sortByVar,
    sortOrderVar
  } = gridVars

  return {
    page: useReactiveVar(pageVar),
    perPage: useReactiveVar(perPageVar),
    sortBy: useReactiveVar(sortByVar),
    sortOrder: useReactiveVar(sortOrderVar) as SortDirection,
    dynamicFilters: useReactiveVar(dynamicFiltersVar),
    dynamicFilterOperator: useReactiveVar(dynamicFilterOperatorVar)
  }
}
