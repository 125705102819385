import { Switch, SwitchProps } from '@mui/material'
import { useSegmentInteraction } from 'hooks/analytics/segment'
import { useSaveUserSetting } from 'hooks/useSaveUserSetting'
import { TypeMatch } from 'utility.types'
import { IUserFragment, useCurrentUserQuery } from 'types'

interface IToggleSettingProps {
  disabled?: boolean
  settingKey: TypeMatch<IUserFragment['settings'], boolean>
}

export const ToggleSetting = ({
  settingKey,
  disabled = false
}: IToggleSettingProps & SwitchProps) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const settings = currentUser?.settings
  const checked = settings?.[settingKey]

  const { track } = useSegmentInteraction()
  const saveUserSettings = useSaveUserSetting(() => track(`${settingKey} has been updated`))

  return (
    <Switch
      checked={checked}
      disabled={disabled}
      size="small"
      onChange={(event) => saveUserSettings({ [settingKey]: event.target.checked })}
    />
  )
}
