import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack } from '@mui/material'
import { PreviewStack, PreviewBox, PreviewTitle, PreviewHeader } from './styles'
import { EditorItem } from './types'

interface IEditorPreviewProps<T> {
  item: T | null
  value?: string
  linked: boolean
  loading: boolean
  onUpdateLink: () => void
}

export function EditorPreview<T extends EditorItem>({
  item,
  value = '',
  linked,
  loading,
  onUpdateLink
}: IEditorPreviewProps<T>) {
  return (
    <Grid item xs={6} height="100%">
      {item && (
        <Stack spacing={2} height="100%">
          <PreviewHeader>Preview</PreviewHeader>
          <PreviewStack>
            <PreviewBox>
              <PreviewTitle>{item.title}</PreviewTitle>
              <Box sx={{}}>{value}</Box>
            </PreviewBox>
            <Stack direction="row-reverse">
              <LoadingButton loading={loading} onClick={onUpdateLink}>
                {linked ? 'Unlink' : 'Link'}
              </LoadingButton>
            </Stack>
          </PreviewStack>
        </Stack>
      )}
    </Grid>
  )
}
