import { Suspense } from 'react'
import { Stack, Toolbar, Tabs, Tab } from '@mui/material'
import { AuthorizedFeature, Loader } from 'components'
import { KnowledgeResource } from 'knowledgeResource.types'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { HealthPortalFeature } from 'types'

export const KnowledgeLayout = () => {
  const location = useLocation()

  const tabValue =
    Object.values(KnowledgeResource).indexOf(
      location.pathname.split('/').pop() as KnowledgeResource
    ) ?? 0

  return (
    <AuthorizedFeature feature={HealthPortalFeature.ChatbotViewKnowledgeBase}>
      <Stack height="100%">
        <Toolbar>
          <Tabs value={tabValue}>
            <Tab label="Questions" component={NavLink} to={KnowledgeResource.Questions} />
            <Tab label="Answers" component={NavLink} to={KnowledgeResource.Answers} />
            <Tab label="Sources" component={NavLink} to={KnowledgeResource.Sources} />
            <Tab label="Tags" component={NavLink} to={KnowledgeResource.Tags} />
          </Tabs>
        </Toolbar>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Stack>
    </AuthorizedFeature>
  )
}
