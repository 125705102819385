import {
  Editor,
  Element as SlateElement,
  Point,
  Range,
  TextUnit,
  Transforms,
  Node,
  BaseElement
} from 'slate'
import { ShortcutsEditor } from './types'

const SHORTCUTS = {
  '*': 'list-item',
  '-': 'list-item',
  '+': 'list-item',
  '>': 'block-quote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
  '####': 'heading-four',
  '#####': 'heading-five',
  '######': 'heading-six'
}

const newBulletedList = () => ({
  type: 'bulleted-list',
  children: []
})

const customInsertText = (editor: ShortcutsEditor, insertText: (text: string) => void) => {
  return (text: string) => {
    const { selection } = editor

    if (text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n)
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range) + text.slice(0, -1)
      const type = SHORTCUTS[beforeText as keyof typeof SHORTCUTS]

      if (type) {
        Transforms.select(editor, range)

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor)
        }

        const newProperties = { type }
        Transforms.setNodes<SlateElement>(editor, newProperties as Partial<BaseElement>, {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n)
        })

        if (type === 'list-item') {
          Transforms.wrapNodes(editor, newBulletedList(), {
            match: (n) =>
              !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'list-item'
          })
        }

        return
      }
    }

    insertText(text)
  }
}

const customDeleteBackward = (
  editor: ShortcutsEditor,
  deleteBackward: (unit: TextUnit) => void
) => {
  return (...args: any) => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n)
      })

      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          (block as any).type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties = {
            type: 'paragraph'
          }
          Transforms.setNodes(editor, newProperties as Partial<BaseElement>)

          if ((block as any).type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n as any).type === 'bulleted-list',
              split: true
            })
          }

          return
        }
      }

      // @ts-ignore
      deleteBackward(...args)

      // there is an open slate issue for selecting all text (containing a list) and deleting it - it leaves an empty list behind
      // https://github.com/ianstormtaylor/slate/issues/2500
      // this is a workaround for that issue
      if (
        editor.children.length === 1 &&
        (editor.children[0] as any).type === 'bulleted-list' &&
        Node.string(editor) === ''
      ) {
        Transforms.unwrapNodes(editor)
      }
    }
  }
}

export const withShortcuts = (editor: ShortcutsEditor) => {
  const { deleteBackward, insertText } = editor

  editor.insertText = customInsertText(editor, insertText)
  editor.deleteBackward = customDeleteBackward(editor, deleteBackward)

  return editor
}
