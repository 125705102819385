export enum ActivityEventTypes {
  Exercise = 'Exercise'
}

export enum EventTypes {
  Activity = 'Activity',
  Meal = 'Meal',
  Measurement = 'Measurement',
  Note = 'Note',
  JournalEntry = 'JournalEntry'
}
