import { Divider, Paper, Popover, PopoverProps, Typography } from '@mui/material'
import { IEntryGlucose } from 'types'
import { TwoHourGraph } from '..'

interface ITwoHourGraphPopoverProps {
  event:
    | { occurredAt: string }
    | {
        glucose: IEntryGlucose
        occurredAt: string
      }
}

const TWO_HOUR_CHART_ID = 'two-hour-chart-popover'

export const TwoHourGraphPopover = ({
  event,
  anchorEl,
  onClose,
  ...popoverProps
}: ITwoHourGraphPopoverProps & Partial<PopoverProps>) => {
  const open = Boolean(anchorEl)

  return (
    <Popover
      id={TWO_HOUR_CHART_ID}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      {...popoverProps}
    >
      <Paper sx={{ width: 400, padding: 1 }} elevation={3}>
        <Typography color="initial">Meal 2Hour Graph</Typography>
        <Divider />
        <TwoHourGraph meal={event} />
      </Paper>
    </Popover>
  )
}
